
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseButton',

  props: {
    buttonID: String,
    title: String,
    disabled: Boolean,
    buttonStyle: Object,
  },

  setup(props, { emit }) {
    return { emit }
  },
})
