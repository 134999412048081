import { queryStudentInformationList, getUsageDailySchedule } from '@/request/api'
import { PassDiaryScheduleSummaryModel } from '@/request/api/pass-diary/models/summaryInformation'
import { queryPassDiaryScheduleSummary } from '@/request/api/pass-diary/summaryInformation'

import { Classroom, LabelClassification, StudentInformation } from '@/typings'
import { DailyScheduleModel, ResultList } from '@/typings/PassDiary'
import { ActionHandler, Commit, Dispatch, Getter, Mutation } from 'vuex'
import {
  SET_CLASSROOM_SUMMARY,
  SET_LABEL_CLASSIFICATION_LIST,
  SET_DEFAULT_SCHEDULE,
  SET_MODIFIED_SCHEDULE,
  SET_SCHEDULE_LIST,
  SET_SCHEDULE_MEMO,
  SET_STUDENT_SUMMARY,
  SET_TRANSFER_MEMO_STATUS,
  SET_IS_UPDATE_ACTIVE,
  SET_USAGE_SCHEDULE_DIARY,
  SET_STUDENT_SUMMARY_ERROR,
  SET_CLASSROOM_SUMMARY_ERROR,
} from './actionTypes'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import {
  getRoleIDFromAccessToken,
  getUserIDFromAccessToken,
} from '@/services/UserInformation'

export interface State {
  memo: string
  transferFromMemo: boolean
  isUpdateActivated: boolean
  scheduleList: DailyScheduleModel[]
  defaultSchedule: DailyScheduleModel | undefined
  modifiedSchedule: DailyScheduleModel | undefined
  labelClassificationList: LabelClassification[]
  classroomSummary: PassDiaryScheduleSummaryModel
  studentSummary: StudentInformation[]
  activePassDialy: ResultList[]
  studentSummaryError: Error | undefined
  classroomSummaryError: Error | undefined
}

const state: State = {
  memo: '',
  transferFromMemo: false,
  isUpdateActivated: false,
  scheduleList: [],
  defaultSchedule: undefined,
  modifiedSchedule: undefined,
  labelClassificationList: [],
  classroomSummary: [],
  studentSummary: [],
  activePassDialy: [],
  studentSummaryError: undefined,
  classroomSummaryError: undefined,
}

const getters: { [type: string]: Getter<State, any> } = {}

export interface StoreContent {
  commit: Commit
  state: State
  rootState: any
  dispatch: Dispatch
}

const actions: { [type: string]: ActionHandler<State, any> } = {
  [SET_SCHEDULE_MEMO]: ({ commit }, memo: string): void => {
    commit(SET_SCHEDULE_MEMO, memo)
  },

  [SET_TRANSFER_MEMO_STATUS]: ({ commit }, status: boolean): void => {
    commit(SET_TRANSFER_MEMO_STATUS, status)
  },

  [SET_IS_UPDATE_ACTIVE]: ({ commit }, status: boolean): void => {
    commit(SET_IS_UPDATE_ACTIVE, status)
  },

  [SET_SCHEDULE_LIST]: ({ commit }, list: DailyScheduleModel[]): void => {
    commit(SET_SCHEDULE_LIST, list)
  },

  [SET_DEFAULT_SCHEDULE]: ({ commit }, schedule: DailyScheduleModel): void => {
    commit(SET_DEFAULT_SCHEDULE, schedule)
  },

  [SET_MODIFIED_SCHEDULE]: ({ commit }, schedule: DailyScheduleModel): void => {
    commit(SET_MODIFIED_SCHEDULE, schedule)
  },

  [SET_LABEL_CLASSIFICATION_LIST]: (
    { commit },
    list: LabelClassification[]
  ): void => {
    commit(SET_LABEL_CLASSIFICATION_LIST, list)
  },

  [SET_CLASSROOM_SUMMARY]: async ({
    commit,
    rootState,
  }: StoreContent): Promise<void> => {
    const searchCondition = rootState.searchCondition

    // 送信する教室を設定する
    let classroomCDList: string[] = []

    const classroomCD = searchCondition.classroom.classroomCd

    if (classroomCD) {
      classroomCDList.push(classroomCD)
    } else {
      classroomCDList = (
        searchCondition.classroomList as Classroom[]
      ).map<string>((item: Classroom): string => item.classroomCd as string)
    }
    commit(SET_CLASSROOM_SUMMARY_ERROR, null)
    try {
      const list: PassDiaryScheduleSummaryModel =
        await queryPassDiaryScheduleSummary({
          jukoFiscalYear: searchCondition.attendFiscalYear,
          jukoClassCd: searchCondition.certification.kbn,
          branchCd: searchCondition.branchStore.branchCd,
          mainClassroomCd: classroomCDList,
          startDate: searchCondition.summaryPeriod.begin,
          endDate: searchCondition.summaryPeriod.end,
          label: searchCondition.label.kbn,
        })
      commit(SET_CLASSROOM_SUMMARY, list)
    } catch (error) {
      commit(SET_CLASSROOM_SUMMARY, [])
      commit(SET_CLASSROOM_SUMMARY_ERROR, error)
    }
  },

  [SET_STUDENT_SUMMARY]: async ({ commit, rootState }): Promise<void> => {
    const searchCondition = rootState.searchCondition
    const token: string = getAccessTokenFromSession()
    const userType = getRoleIDFromAccessToken(token)
    const userID: string = getUserIDFromAccessToken(token)
    commit(SET_STUDENT_SUMMARY_ERROR, null)
    try {
      const list: StudentInformation[] = await queryStudentInformationList({
        attendFiscalYear: searchCondition.attendFiscalYear,
        attendID: searchCondition.attendID.value,
        branchStoreCode: searchCondition.branchStore.branchCd,
        certificationCode: searchCondition.certification.kbn,
        courseGroupCode: searchCondition.courseGroup.courseGroupCd,
        classroomCode: searchCondition.classroom.classroomCd,
        fullName: searchCondition.fullName.value,
        fullNameKatakana: searchCondition.fullNameKatakana.value,
        personalNumber: searchCondition.personalNumber.value,
        userType,
        userID,
      })

      commit(SET_STUDENT_SUMMARY, list)
    } catch (error) {
      commit(SET_STUDENT_SUMMARY, [])
      commit(SET_STUDENT_SUMMARY_ERROR, error)
    }
  },
  [SET_USAGE_SCHEDULE_DIARY]: async ({ commit }, userCdList: string[]): Promise<void> => {
    try {
      let list: ResultList[] = []
      if(userCdList.length !== 0) {
        list = await getUsageDailySchedule(userCdList)        
      }
      commit(SET_USAGE_SCHEDULE_DIARY, list)
    } catch (error) {
      commit(SET_USAGE_SCHEDULE_DIARY, [])
    }
  },
}

const mutations: { [type: string]: Mutation<State> } = {
  [SET_SCHEDULE_MEMO]: (_state, memo: string): void => {
    _state.memo = memo
  },

  [SET_TRANSFER_MEMO_STATUS]: (_state, status: boolean): void => {
    _state.transferFromMemo = status
  },

  [SET_IS_UPDATE_ACTIVE]: (_state, status: boolean): void => {
    _state.isUpdateActivated = status
  },

  [SET_SCHEDULE_LIST]: (_state, list: DailyScheduleModel[]) => {
    _state.scheduleList = [...list]
  },

  [SET_DEFAULT_SCHEDULE]: (_state, schedule: DailyScheduleModel): void => {
    _state.defaultSchedule = { ...schedule }
  },

  [SET_MODIFIED_SCHEDULE]: (_state, schedule: DailyScheduleModel): void => {
    _state.modifiedSchedule = { ...schedule }
  },

  [SET_LABEL_CLASSIFICATION_LIST]: (
    _state,
    list: LabelClassification[]
  ): void => {
    _state.labelClassificationList = [...list]
  },

  [SET_CLASSROOM_SUMMARY]: (
    _state: State,
    list: PassDiaryScheduleSummaryModel
  ): void => {
    _state.classroomSummary = [...list]
  },

  [SET_STUDENT_SUMMARY]: (_state: State, list: StudentInformation[]): void => {
    _state.studentSummary = [...list]
  },
  [SET_USAGE_SCHEDULE_DIARY]: (_state: State, list: ResultList[]): void => {
    _state.activePassDialy = [...list]
  },
  [SET_STUDENT_SUMMARY_ERROR]: (_state: State, error: Error): void => {
    _state.studentSummaryError = error
  },
  [SET_CLASSROOM_SUMMARY_ERROR]: (_state: State, error: Error): void => {
    _state.classroomSummaryError = error
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
