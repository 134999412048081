
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import SummaryConditionInput from './components/SummaryConditionInput.vue'
import ClassroomDetail from './components/ClassroomDetail.vue'
import GradeDataDetailTable from '@/components/views/GradeDataSearch/GradeDataDetailTable.vue'

import { computed, defineComponent } from 'vue'
import { Store } from 'vuex'
import { useStore } from '@/store'
import { ACTIVE_TABLE } from '@/store/modules/search-condition'
import { PassDiaryScheduleSummaryModel } from '@/request/api/pass-diary/models/summaryInformation'
import { StudentInformation } from '@/typings'
import { ResultList } from '@/typings/PassDiary'

export default defineComponent({
  name: 'SummaryForStaff',

  setup() {
    const store: Store<any> = useStore()

    const activeTable = computed<string>(
      (): string => store.state.searchCondition.activeTable
    )

    const classroomSummary = computed<PassDiaryScheduleSummaryModel>(
      (): PassDiaryScheduleSummaryModel => {
        return store.state.passDiary.classroomSummary
      }
    )

    const studentSummary = computed<StudentInformation[]>(
      (): StudentInformation[] => store.state.passDiary.studentSummary
    )

    const activePassDialy = computed<ResultList[]>(
      (): ResultList[] => store.state.passDiary.activePassDialy
    )

    return {
      activeTable,
      ACTIVE_TABLE,
      classroomSummary,
      studentSummary,
      activePassDialy,
    }
  },

  components: {
    ManagePageContainer,
    SummaryConditionInput,
    ClassroomDetail,
    GradeDataDetailTable,
  },
})
