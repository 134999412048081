// Dependencies
import router from '@/router'
import {
  getRefreshTokenTimelimit,
  setRefrehTokenToSession,
} from '@/services/ControlToken'
import { ATTRIBUTES_KEYS, URIS, CODE_CHALLENGE_METHOD, DOMAINS } from '@/config'

import {
  generateCodeVerifier,
  generateCodeChallenge,
  generateRandomString,
  transferToMessagePage,
  getMessage,
} from '@/Utils'
import { VIEW_ICON_PREVIEW } from '@/router/routesName'
import Cookies from 'js-cookie'

/**
 * oauth2 認証を行う。
 * @param path ドメイン抜きの URL
 */
export const moveToAuthorize = (): void => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)
  sessionStorage.setItem(ATTRIBUTES_KEYS.CODE_VERIFIER, codeVerifier)
  sessionStorage.setItem(ATTRIBUTES_KEYS.CODE_CHALLENGE, codeChallenge)

  const state = generateRandomString(128)
  sessionStorage.setItem(ATTRIBUTES_KEYS.STATE, state)

  const pkceParameters = `&${ATTRIBUTES_KEYS.CODE_CHALLENGE_METHOD}=${CODE_CHALLENGE_METHOD}&${ATTRIBUTES_KEYS.CODE_CHALLENGE}=${codeChallenge}&${ATTRIBUTES_KEYS.STATE}=${state}`
  const redirectURL = `&${ATTRIBUTES_KEYS.REDIRECT_URI}=${DOMAINS.WEB_SERVER}`

  window.location.assign(
    URIS.AUTHORIZE + redirectURL + pkceParameters + '#login'
  )
}

/**
 * タイムアウトであるかを確認。
 * @returns { boolean }
 */
export const checkTimeout = (): boolean => {
  try {
    const lastActiveTime = sessionStorage.getItem(
      process.env.VUE_APP_LAST_ACTIVE_TIME ?? ''
    )

    // 前回の活動時間が存在しない場合はタイムアウトと見なさない
    if (!lastActiveTime) return false

    // リフレッシュトークンの有効期間を取得
    const refreshTimelimit = new Date(getRefreshTokenTimelimit()).getTime()

    // セッションストレージから取得したタイムスタンプをリフレッシュトークンの有効期間と比較
    return parseInt(lastActiveTime) >= refreshTimelimit
  } catch (error) {
    console.log(error)
    return true
  }
}

/**
 * ログアウト
 */
export const logout = (): void => {
  // ストレージをクリア
  sessionStorage.clear()
  // ログアウト URI をコール
  window.location.assign(process.env.VUE_APP_LOGOUT as string)
  const cookies = Cookies.get()
  for (const item in cookies) {
    Cookies.set(item, '', { expires: 0 })
  }
}

/**
 * ユーザーの最後の操作時間を記録する。
 */
export const refreshActiveTime = (): void => {
  if (router.currentRoute.name === VIEW_ICON_PREVIEW) {
    return
  }

  if (checkTimeout()) {
    setRefrehTokenToSession('')
    transferToMessagePage(router, { message: getMessage('EFR0009') })
    return
  }

  sessionStorage.setItem(
    process.env.VUE_APP_LAST_ACTIVE_TIME ?? '',
    new Date().getTime().toString()
  )
}
