
/**
 * 合格ダイアリー - 入力画面用共通ボタン
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PassDiaryInputPageButton',

  props: {
    title: {
      type: String,
      default: 'ボタンネーム',
      required: true,
    },

    useBackIcon: Boolean,
    buttonStyle: Object,
  },

  setup(props, { emit }) {
    const handleClick = (): void => {
      emit('click')
    }

    return { handleClick }
  },
})
