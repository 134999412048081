
import { defineComponent, reactive, toRefs } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'

export default defineComponent({
  name: 'DateSelector',
  components: { DatePicker },
  props: {
    title: {
      type: String,
    },

    value: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      dateValue: props.value,
    })
    const handleChange = (): void => {
      emit('input', state.dateValue)
    }
    return { ...toRefs(state), handleChange }
  },
})
