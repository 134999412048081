
// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  watch,
} from 'vue'

import {
  queryApplicationInformationList,
  querySubjectInitializeData,
  queryGradeBySubject,
  getTestInformation,
  queryMogiShikenDetail,
} from '@/request/api'
import {
  GetTestInformationArguments,
  TestInformation,
} from '@/typings/TrainingDay'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useRouter } from '@/router'
// Components
import BaseSelector from '@/components/common/BaseSelector.vue'
import GradeTable from '@/components/views/GradeData/GradeTable.vue'
import { ApplicationInformation } from '@/typings'
import { SubjectDetail } from '@/typings/Subject'
import { GradeBySubject, GradeDetailBySubject } from '@/typings/GradeBySubject'
import { useGlobalError } from '@/services/Hooks'
import {
  transferToInputMarkList,
  transferTestClassKbn,
  SEISEKI_TYPE_KAMOKU,
} from './common'
import SwitchDetailButton from '@/components/views/GradeData/SwitchDetailButton.vue'
import MogishikenDetailTable from '@/components/views/GradeData/MogishikenDetailTable.vue'
import { MogiShikenDetailLst } from '@/typings/MogiShiken'
import { CONST_SELECTED_SUBJECT } from '@/Utils'

interface Flag {
  leftButtonVisible: boolean
  rightButtonVisible: boolean
}
export default defineComponent({
  components: {
    BaseSelector,
    GradeTable,
    SwitchDetailButton,
    MogishikenDetailTable,
  },

  props: {
    userIDProps: String,
    applicationNo: String,
    isShowMenu: Boolean
  },

  setup(props) {
    const { setGlobalError } = useGlobalError()
    const router = useRouter()

    // State
    const state = reactive({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),
      subjectApplicationInfoList: [] as ApplicationInformation[],
      subjectApplicationInfo: {} as ApplicationInformation,
      subjectList: [] as SubjectDetail[],
      subject: {} as SubjectDetail,
      gradeBySubject: {} as GradeBySubject,
      subjectGradeList: [] as GradeDetailBySubject[],
      jukoClassCode: "",
      refreshGradeTable: 0,
      testKamokuCd: '',
      testCd: '',
      testNmDetail: '',
      subjectSeisekiPage: true,
      subjectDetailLst: [] as MogiShikenDetailLst[],
      gradeOfDetailToShowSubject: {} as GradeDetailBySubject,
      isLoadingGradeList:false
    })

    // Computed
    const subjectFormattedCourse = computed(() => {
      return `${state.subjectApplicationInfo?.jukoFiscalYear ?? ''}　${state.subjectApplicationInfo?.courseName ?? ''}`
    })

    const subjectFormattedCourselist = computed(() =>
      state.subjectApplicationInfoList.map((item) => {
        return `${item.jukoFiscalYear}　${item.courseName ?? ''}`
      })
    )

    const formattedSubject = computed(() => {
      return state.subject?.hasOwnProperty('kamokuNm')
        ? `${state.subject.kamokuNm ?? ''}`
        : `${state.subjectList?.[0]?.kamokuNm}`
    })

    const formattedSubjectList = computed(() => {
      return state.subjectList.map((item) => {
        return `${item.kamokuNm ?? ''}`
      })
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        if (props.applicationNo) {
          state.isLoadingGradeList = true
          // 申込情報初期化
          await setSubjectApplicationInfo()
          // 科目回初期化
          await setSubjectList()
          // 科目回別成績初期化
          await setSubjectGradeList()
          state.isLoadingGradeList = false
        }
      } catch (error) {
        handleError(error as Error)
      }
    })

    watch(
      () => [props.applicationNo],

      async () => {
        try {
          state.isLoadingGradeList = true
          await setSubjectApplicationInfo()
          await setSubjectList()
          await setSubjectGradeList()
          state.isLoadingGradeList = false
        } catch (error) {
          handleError(error as Error)
        }
      }
    )

    // Methods
    const handleError = (error: Error | unknown | string): void => {
      state.isLoadingGradeList = false
      state.subjectGradeList = []
      console.log(error)
      setGlobalError(error as Error)
    }

    const setSubjectApplicationInfo = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInformationList =
          await queryApplicationInformationList(state.userID)

        state.subjectApplicationInfoList = applicationInformationList ?? []

        state.subjectApplicationInfo =
          applicationInformationList?.find(
            (item) => item.applicationNo === props?.applicationNo
          ) ??
          applicationInformationList?.[0] ??
          {}
        state.jukoClassCode = state.subjectApplicationInfo.jukoClassCd ?? ""
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setSubjectGradeList = async () => {
      try {
        if (!state.subjectList.length) {
          return
        }
        if (!state.subjectApplicationInfoList?.length) {
          return
        }
        const getSeisekiByKamoku = await queryGradeBySubject({
          userID: state.userID,

          applicationNo:
            state.subjectApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          seqNo: state.subject?.seqNo ?? state.subjectList?.[0]?.seqNo ?? '',
        })

        state.gradeBySubject = getSeisekiByKamoku ?? {}

        let kamokuInfoLst = getSeisekiByKamoku?.kamokuSeisekiInfoLst ?? []
        for (let item of kamokuInfoLst) {
          if (!item.teishutsuFlg) {
            const jukoDay = state.subjectApplicationInfo
              .jukoFiscalYear as string

            const params = {
              testCd: item.testCd,
              testKamokuCd: item.testKamokuCd,
              jukoFiscalYear: jukoDay.substring(0, 4),
              jukoClassKbn: state.jukoClassCode,
            } as GetTestInformationArguments

            const testInfo: TestInformation = await getTestInformation(params)
            item.enableInputAnswerFlg = testInfo.teishutsuFlg
          }
          item.testCdDisplay = transferTestClassKbn(state.subjectApplicationInfo.jukoClassCd ?? "") + item.testCd
        }

        getSeisekiByKamoku.kamokuSeisekiInfoLst = kamokuInfoLst
        state.subjectGradeList = kamokuInfoLst
        state.subjectSeisekiPage = true
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setSubjectList = async () => {
      try {
        const getKamokuList = await querySubjectInitializeData({
          userID: state.userID,

          applicationNo:
            state.subjectApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',
        })

        state.subjectList =
          getKamokuList?.kamokuInfoLst?.sort(
            (ela, elb) =>
              parseInt(ela.sortNo ?? '99') - parseInt(elb.sortNo ?? '99')
          ) ?? []

        if(sessionStorage.getItem(CONST_SELECTED_SUBJECT)){
          state.subject = JSON.parse(sessionStorage.getItem(CONST_SELECTED_SUBJECT) as string) 
        }else{
          state.subject =
          state.subjectList?.find(
            (el) => el.seqNo === getKamokuList?.chokkinSeqNo
          ) ?? {}
          sessionStorage.setItem(CONST_SELECTED_SUBJECT,JSON.stringify(state.subject))
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const handleSelectCourse = async (index: number) => {
      try {
        state.subjectApplicationInfo = state.subjectApplicationInfoList[index]
        await setSubjectList()
        await setSubjectGradeList()
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleSelectSubjuct = async (index: number) => {
      try {
        state.isLoadingGradeList = true
        state.subject = state.subjectList[index]
        sessionStorage.setItem(CONST_SELECTED_SUBJECT,JSON.stringify(state.subject))
        await setSubjectGradeList()
        state.isLoadingGradeList = false
      } catch (error) {
        handleError(error as Error)
      }
    }
    const handleTransferToInputMarkList = async (
      testCd: string,
      testKamokuCd: string
    ) => {
      transferToInputMarkList(
        testCd,
        testKamokuCd,
        state.subjectApplicationInfo.jukoFiscalYear as string,
        state.jukoClassCode,
        state.userID,
        state.subjectApplicationInfo.applicationNo as string,
        router
      )
    }
    const flag = reactive<Flag>({
      leftButtonVisible: true,
      rightButtonVisible: true,
    })
    const resetFlag = (): void => {
      flag.leftButtonVisible = true
      flag.rightButtonVisible = true
    }

    const setDetailList = async (testKamokuCd: string, testCd: string): Promise<void> => {
      try {
        if (!testKamokuCd || !testCd ) {
          return
        }

        const mogiShikenDetailList = await queryMogiShikenDetail({
          userID: state.userID,

          applicationNo:
            state.subjectApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: testCd ?? '',
          testKamokuCd: testKamokuCd ?? '',
        })

        state.subjectDetailLst = mogiShikenDetailList?.list ?? []
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleTransferToDetail = async (
      testNm: string,
      testKamokuCd: string,
      testCd: string
    ): Promise<void> => {
      state.testKamokuCd = testKamokuCd
      state.testCd = testCd
      resetFlag()

      const currentDetailIndex = state.subjectGradeList.findIndex(
        (item) => item.testKamokuCd === testKamokuCd && item.testCd === testCd
      )
      state.gradeOfDetailToShowSubject = state.subjectGradeList[currentDetailIndex]
      currentDetailIndex - 1 <= 0 && (flag.leftButtonVisible = false)

      currentDetailIndex + 1 >= state.subjectGradeList.length &&
        (flag.rightButtonVisible = false)

      await setDetailList(testKamokuCd, testCd)
      state.testNmDetail = testNm
      state.subjectSeisekiPage = false
    }

    const handlePrevious = async (): Promise<void> => {
      try {
        resetFlag()
        // 一個前の科目を取得
        const previousDetailIndex =
          state.subjectGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) - 1

        if (previousDetailIndex - 1 <= 0) {
          flag.leftButtonVisible = false
        }

        const previousDetail = state.subjectGradeList[previousDetailIndex]
        state.gradeOfDetailToShowSubject = previousDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(previousDetail.testKamokuCd as string, previousDetail.testCd as string)

        state.testKamokuCd = previousDetail.testKamokuCd as string
        state.testCd = previousDetail.testCd as string
        state.testNmDetail = previousDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleNext = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const nextDetailIndex =
          state.subjectGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) + 1

        if (nextDetailIndex + 1 >= state.subjectGradeList.length) {
          flag.rightButtonVisible = false
        }

        const nextDetail = state.subjectGradeList[nextDetailIndex]
        state.gradeOfDetailToShowSubject = nextDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(nextDetail.testKamokuCd as string, nextDetail.testCd as string)

        state.testKamokuCd = nextDetail.testKamokuCd as string
        state.testCd = nextDetail.testCd as string
        state.testNmDetail = nextDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    // Style
    const courseSelectBarStyle = {
      background: '#f3f3f3',
      border: 0,
    }

    const subjectSelectBarStyle = {
      border: 'solid 1px #979797',
      borderRadius: '5px',
    }

    return {
      ...toRefs(state),
      ...toRefs(flag),
      handleSelectCourse,
      handleSelectSubjuct,
      handleTransferToInputMarkList,
      handleTransferToDetail,
      handlePrevious,
      handleNext,
      courseSelectBarStyle,
      subjectSelectBarStyle,
      subjectFormattedCourse,
      subjectFormattedCourselist,
      formattedSubject,
      formattedSubjectList,
      SEISEKI_TYPE_KAMOKU,
    }
  },
})
