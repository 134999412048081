export const mainContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}
export const mainContainerStyleAPP = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh-65)',
  maxHeight: 'calc(100vh-129)',
}
