// Dependencies
import messagesJSON from '@/assets/Messages.json'
import cryptoJSSHA256 from 'crypto-js/sha256'
import encBase64 from 'crypto-js/enc-base64'
import encUTF8 from 'crypto-js/enc-utf8'
import {
  FORM_TARGET,
  FULL_SIZE_LETTER_LENGTH,
  HALF_SIZE_LETTER_LENGTH,
  HTTP_METHOD,
  LAST_HALF_SIZE_CODE,
  MARKS,
  VIEW_WITHOUT_AUTHORIZE,
} from '@/config'
import VueRouter from 'vue-router'

interface JSON {
  [key: string]: string
}

/**
 * メッセージコードでメッセージ文を特定、代入ワードを配列にして代入し、
 * インデックスで替える場所を特定する。
 *
 * @param {string} messageCode
 * @param {Array} customWords カスタムワード。例：['word1', 'word2']
 *
 * @returns {string}
 */
export const getMessage = (
  messageCode: string,
  customWords: (string | number)[] = []
): string => {
  // デフォルトエラー文のコピーを作成
  const typedMessage: JSON = messagesJSON
  let newMessage: string = typedMessage[messageCode.toUpperCase()] ?? ''

  // マスタマイズ内容に応じてコピーを更新
  if (customWords.length > 0) {
    customWords.forEach((word: any, index) => {
      newMessage = newMessage.replace(`{${index}}`, word)
    })
  }

  // コピーを戻す
  return newMessage
}

/**
 * トークンからペイロードを取得し、デコードする。
 *
 * 異常の場合、nullをリターンする。
 * @param {string} token
 *
 * @returns {JSON}
 */
export const getDecodedPayloadFromJwt = (
  token: string
): { [key: string]: any } | null => {
  const base64Url = token.split('.')[1]
  if (base64Url) {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    try {
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
    } catch (e) {
      return null
    }
  } else {
    //トークンにペイロードが存在しない場合、nullをリターンする。
    return null
  }
}

/**
 * トークンからログインユーザーのロールIDを取得する。
 *
 *　異常の場合、nullをリターンする。
 * @param {string} token
 *
 * @returns {string}
 */
export const getRoleIdFromJwt = (token: string): string | null => {
  const payload = getDecodedPayloadFromJwt(token)
  if (payload) {
    try {
      return payload.roleId[0].roleId.toString()
    } catch (e) {
      return null
    }
  } else {
    return null
  }
}

/**
 * トークンからログインユーザーの受講 ID を取得する。
 *
 * @param {string} token
 *
 * @returns {string | undefined}
 */
export const getUserIdFromJwt = (token: string): string | undefined => {
  const payload = getDecodedPayloadFromJwt(token)

  if (payload?.user_name) {
    return payload.user_name
  }
}

/**
 * 受講 ID を取得
 * @param
 * @returns
 */
export const getUserIDFromSessionStorage = (): string => {
  return sessionStorage.getItem('userId') ?? ''
}

/**
 * ロール を取得
 * @param
 * @returns
 */
export const getRoleIDFromSessionStorage = (): string => {
  return sessionStorage.getItem('roleId') ?? ''
}

/**
 * codeVerifierを生成する。
 *
 *
 * @returns {string}
 */
export const generateCodeVerifier = (): string => {
  return generateRandomString(32)
}
/**
 * 一定の長さのランダムな文字列を生成する。
 *
 * @param { number} length
 *
 * @returns {string}
 */
export const generateRandomString = (length: number): string => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  if (length > 0) {
    var randomString = ''
    for (var i = 0; i < length; i++) {
      var randomPoz = Math.floor(secureRandom() * possible.length)
      randomString += possible.substring(randomPoz, randomPoz + 1)
    }
    return randomString
  } else {
    return ''
  }
}
/**
 * [0~1]のランダム数を取得する。
 *
 *
 * @returns {number}}
 */
export const secureRandom = (): number => {
  const crypto = require('crypto')

  // crypto.randomBytes()で生成するときのバイト数
  const nBytes = 4
  // nBytesの整数の最大値
  const maxValue = 4294967295
  // nBytesバイトのランダムなバッファを生成する
  const randomBytes = crypto.randomBytes(nBytes)
  // ランダムなバッファを整数値に変換する
  const r = randomBytes.readUIntBE(0, nBytes)
  // 最大値で割ることで、[0.0, 1.0]にする
  return r / maxValue
}

/**
 * PKCE認可リクエストパラメーターのCodeChallengeを生成する。
 *
 *　異常の場合、nullをリターンする。
 * @param { string} codeVerifier
 *
 * @returns {string}
 */
export const generateCodeChallenge = (codeVerifier: string): string => {
  return convertToBase64URL(cryptoJSSHA256(codeVerifier).toString())
}

/**
 * テキスト文字列をbase64URL文字列に変換する。
 *
 *　異常の場合、nullをリターンする。
 * @param { wordArray } any
 *
 * @returns {string}
 */
export const convertToBase64URL = (text: string): string => {
  const wordArray = encUTF8.parse(text)
  const base64Url = encBase64.stringify(wordArray)
  return base64Url.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

// ====================
type Params = {
  message?: string
  isDisplayConfirmButton?: boolean
}

/**
 * メッセージ画面へ遷移する。
 * @param {VueRouter} router ルーターのインスタンス
 * @param {string} message 表示したいメッセージ
 * @param {boolean} isDisplayConfirmButton 確認ボタンを表示させるか
 */
export const transferToMessagePage = (
  router: VueRouter,
  data: Params = { isDisplayConfirmButton: true }
) => {
  // 権限の検証が必要ない画面であればメッセージ画面に遷移しない
  const notNeedAuthorize = VIEW_WITHOUT_AUTHORIZE.some(
    (view) => view === router?.currentRoute?.name
  )

  if (notNeedAuthorize) {
    return
  }

  const { message, isDisplayConfirmButton } = data

  router?.push(
    {
      name: 'Message',
      params: { caution: message, isDisplayConfirmButton } as any,
    },

    () => {
      // 遷移後に操作をしない
    }
  )
}

/**
 * ユーザー使っているブラウザを判定する。
 *
 * @returns {string}
 */
export const getUserAgentType = (): string => {
  var userAgent = window.navigator.userAgent.toLowerCase()

  if (userAgent.includes('mobileapp')) {
    return 'mobileAPP'
  }

  if (userAgent.includes('mobile')) {
    return getMobileUserAgentType(userAgent)
  } else if (userAgent.includes('msie') || userAgent.includes('trident')) {
    return 'IE'
  } else if (userAgent.includes('opr')) {
    return 'Opera'
  } else if (userAgent.includes('edg')) {
    return 'Edge'
  } else if (userAgent.includes('ubrowser')) {
    return 'UBrowser'
  } else if (userAgent.includes('chrome')) {
    return 'Chrome'
  } else if (userAgent.includes('safari')) {
    return 'Safari'
  } else if (userAgent.includes('firefox')) {
    return 'FireFox'
  }

  return 'Other'
}
/**
 * mobileの場合、ユーザー使っているブラウザを判定する。
 *
 * @returns {string}
 */
export const getMobileUserAgentType = (userAgent: string): string => {
  if (userAgent.includes('opr') || userAgent.includes('opt')) {
    return 'Opera'
  } else if (userAgent.includes('edg')) {
    return 'Edge'
  } else if (
    userAgent.includes('ubrowser') ||
    userAgent.includes('ucbrowser')
  ) {
    return 'UBrowser'
  } else if (userAgent.includes('firefox') || userAgent.includes('fxios')) {
    return 'FireFox'
  } else if (userAgent.includes('chrome') || userAgent.includes('crios')) {
    return 'Chrome'
  } else if (userAgent.includes('safari')) {
    return 'Safari'
  }

  return 'Other'
}

/**
 * APP からのアクセスかどうかを判断する。
 * @returns
 */
export const checkIsFromAPP = (windowElement: Window): boolean => {
  const userAgent: string = windowElement.navigator.userAgent
  return userAgent.includes('mobileAPP')
}

/**
 * URL からパラメータを取得
 * @param parameterName 取得したいパラメータのキー名
 * @returns
 */
export const getUrlParameter = (parameterName: string) => {
  // location.search: "?xxx"の部分を戻す；substring(1): "?"を無視した部分を戻す。
  const parameters = window.location.search.substring(1)
  const parametersArray = parameters.split(MARKS.PARAMETER_SPLITER)

  const target = parametersArray.find((parameter) => {
    return parameter.split(MARKS.KEY_VALUE_SPLITER)[0] === parameterName
  })

  return target?.split(MARKS.KEY_VALUE_SPLITER)[1]
}

/**
 * 数字3桁区切り
 * @param num 数字3桁区切る数字(正整数のみ、負や少数対応できない)
 * @returns　数字3桁区切した数字
 */
export const toThousands = (num: string) => {
  var result = '',
    counter = 0
  num = (num || 0).toString()
  for (var i = num.length - 1; i >= 0; i--) {
    counter++
    result = num.charAt(i) + result
    if (!(counter % 3) && i != 0) {
      result = ',' + result
    }
  }
  return result
}

/**
 * アクセス元をチェックする
 */
export const checkUserAgent = (): void => {
  const userAgentType = getUserAgentType()

  if (userAgentType == 'IE') {
    alert(
      'Internet Explorerは推奨されているブラウザではありませんので、操作に不具合が生じる可能性があります。（推奨ブラウザはChromeとSafariです。）'
    )
  } else if (
    userAgentType !== 'Chrome' &&
    userAgentType !== 'Safari' &&
    userAgentType !== 'mobileAPP'
  ) {
    alert(
      '推奨されているブラウザではありませんので、操作に不具合が生じる可能性があります。（推奨ブラウザはChromeとSafariです。）'
    )
  }
}

/**
 * JsonArrayをソートする
 * @param　fields:string[]（ 降順の場合「-field名」、昇順の場合「field名」
 * @returns　ソート後JsonArray
 * 例： const homes = [{"h_id":"3", "city":"Dallas", "state":"TX","zip":"75201","price":162500},{"h_id":"4","city":"Bevery Hills", "state":"CA", "zip":"90210", "price":319250},{"h_id":"6", "city":"Dallas", "state":"TX", "zip":"75000", "price":556699},{"h_id":"5", "city":"New York", "state":"NY", "zip":"00010", "price":962500}];
 *     const sortedHomes = homes.sort(fieldSorter(['state', '-price']));
 */
export const fieldSorter = (fields: string[]) => (a: any, b: any) =>
  fields
    .map((o: string) => {
      let dir = 1
      if (o[0] === '-') {
        dir = -1
        o = o.substring(1)
      }
      let r = a[o] < b[o] ? -dir : 0
      return a[o] > b[o] ? dir : r
    })
    .reduce((p: any, n: any) => (p ? p : n), 0)

/**
 * ゼロ補完
 * @param element 数字
 */
export const zeroPadding = (element: number | string): string => {
  const length = element.toString().length

  if (length > 0 && length < 2) {
    return `0${element}`
  }

  return element.toString()
}

// NOTE: safari では正確に実行されないので目下このメソッドを使用しないでください。
/**
 * @description 日付をフォーマットする
 * @param time
 */
export const formatDate = (time: string | Date): string => {
  // safari では「-」で区切られる日付を認識できない
  const _time: Date = new Date((time as string).replace(/-/g, '/'))
  const year: number = _time.getFullYear()
  const month: number = _time.getMonth() + 1
  const dayOfMonth: number = _time.getDate()
  const week: string[] = ['日', '月', '火', '水', '木', '金', '土']
  const dayOfWeek: string = week[_time.getDay()]
  const hours: number = _time.getHours()
  const minutes: number = _time.getMinutes()

  return `${year}年${month}月${dayOfMonth}日 ${dayOfWeek} ${zeroPadding(
    hours
  )}:${zeroPadding(minutes)}`
}

/**
 * @description テキストの半角桁数を取得
 * @param text
 */
export const getTextHalfSizeLength = (text: string): number => {
  const getLetterLength = (letter: string): number => {
    return letter.charCodeAt(0) > LAST_HALF_SIZE_CODE
      ? FULL_SIZE_LETTER_LENGTH
      : HALF_SIZE_LETTER_LENGTH
  }

  const reducer = (previous: number, current: number): number => {
    return previous + current
  }

  return text.split('').map(getLetterLength).reduce(reducer)
}

/**
 * @description フォームにデータをセットするためにデータが入力された入力元素を作成
 * @param name 入力元素名称
 * @param value 入力値
 * @returns データが入力された入力元素
 */
export const generateHiddenInput = (
  name: string,
  value: string
): HTMLInputElement => {
  const input: HTMLInputElement = document.createElement('input')
  input.type = 'hidden'
  input.name = name
  input.value = value
  return input
}

/**
 * @description 送信先リスト名を格式化（記号を取り除く）
 * @param name 格式化前の送信先リスト名
 * @returns 格式化済み送信先リスト名
 */
export const formatDestinationListName = (name: string): string => {
  return name.replace(/^『(.+)』$/, '$1')
}

// transferInSynchronous の引数
interface SynchronousTransferParameters {
  // 転送する鍵と値
  data: Map<string, string>
  // HTTP メソッド
  method?: string
  // 遷移先の開く場所（form.target）
  target?: string
  // 遷移先のアドレス（form.action）
  path: string
}

/**
 * @description 外部サイトに同期的にデータを転送して遷移する。
 * @param parameters
 */
export const transferInSynchronous = ({
  data,
  method,
  target,
  path,
}: SynchronousTransferParameters): void => {
  // フォーム作成
  const form: HTMLFormElement = document.createElement('form')
  document.body.appendChild(form)

  // フォームにデータをセット
  data.forEach((value: string, key: string) => {
    form.appendChild(generateHiddenInput(key, value))
  })

  // フォームを送信
  form.method = method || HTTP_METHOD.GET
  form.target = target || FORM_TARGET.SELF
  form.action = path
  form.submit()
}
/**
 * @description 時間を２桁に転換。
 * @param num
 */
export const formatTime = (num: number): string => {
  if (0 <= num && num < 10) {
    return '0' + num
  } else if (num >= 10) {
    return '' + num
  } else {
    return ''
  }
}
/**
 * @description 日付を取得する
 * @param date
 */
export const getDate = (date: any): string => {
  const dateTypeDate = date ? new Date(date) : new Date()
  const fullYear = dateTypeDate.getFullYear()
  const month = formatTime(dateTypeDate.getMonth() + 1)
  const day = formatTime(dateTypeDate.getDate())

  return `${fullYear}-${month}-${day}`
}

/**
 * @description yyyy-mm-ddを日付に転換する
 * @param date
 */
export const getDateBaseToYyyyMmDd = (date: string): Date => {
  const ret = new Date()
  ret.setTime(new Date(date).getTime() - 9 * 60 * 60 * 1000)
  return ret
}
/**
 * @description objが値を設定されたかを判断する
 * @param obj
 */
export const isValueSetted = (obj: Object): boolean => {
  if (obj !== undefined && obj && obj !== '') {
    return true
  }
  return false
}

/**
 *  @description 画面を最上部にする
 */
export const moveTopPage = (): void => {
  document.querySelectorAll('.os-viewport')[0].scroll(0, 0)
}

/**
 *  @description NULL,undefinedなど可能性ある値を正確に設定する
 */
export const setSuitStringValue = (value: any): string => {
  return value ? value : ''
}
/**
 *  @description NULL,undefinedなど可能性ある値を正確に設定する
 */
export const setSuitObjectValue = (value: any): any => {
  return value ? value : {}
}
/**
 *  @description NULL,undefinedなど可能性ある値を正確に設定する
 */
export const setSuitArrayValue = (value: any): any => {
  return value ? value : []
}

/**
 *  @description active tab を削除する
 */
export const CONST_ACTIVE_TAB = "activeTabIndex";
export const CONST_SELECTED_DAY = "selectedDay";
export const CONST_SELECTED_SUBJECT = "selectedSubject";
export const CONST_SELECTED_TESTCLASS = "selectedTestclass";
export const CONST_SELECTED_SIMULATE_TEST = "selectedSimulateTest";
export const CONST_SELECTED_OTHER = "selectedOther";
export const CONST_FROM_ANSWER_INPUT_FINISH = "fromAnswerInputFinish";
export const removeSelectTabAndPullDownState = (): void => {
  sessionStorage.removeItem(CONST_ACTIVE_TAB)
  sessionStorage.removeItem(CONST_SELECTED_DAY)
  sessionStorage.removeItem(CONST_SELECTED_SUBJECT)
  sessionStorage.removeItem(CONST_SELECTED_TESTCLASS)
  sessionStorage.removeItem(CONST_SELECTED_SIMULATE_TEST)
  sessionStorage.removeItem(CONST_SELECTED_OTHER)
}
