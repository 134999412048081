import { useRouter } from '@/router'
import {
  getDecodedPayloadFromJwt,
  getMessage,
  transferToMessagePage,
} from '@/Utils'

/**
 * アクセストークンから受講 ID を取得
 * @param token アクセストークン
 * @returns
 */
export const getRoleIDFromAccessToken = (token: string): string => {
  const payload = getDecodedPayloadFromJwt(token)
  const roleID: string = payload?.roleId[0].roleId.toString()

  if (!roleID) {
    const router = useRouter()
    transferToMessagePage(router, { message: getMessage('EFR0009') })

    return ''
  }

  return roleID
}

/**
 * アクセストークンから受講 ID を取得
 * @param token アクセストークン
 * @returns
 */
export const getUserIDFromAccessToken = (token: string): string => {
  const payload = getDecodedPayloadFromJwt(token)
  const userID: string = payload?.roleId[0].userCd

  if (!userID) {
    const router = useRouter()
    transferToMessagePage(router, { message: getMessage('EFR0009') })

    return ''
  }

  return userID
}
