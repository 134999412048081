
// Dependencies
import { defineComponent, reactive, toRefs } from 'vue'

// Components
import TermsPreamble from '@/components/common/Terms/TermsPreamble.vue'
import TermsParagraph from '@/components/common/Terms/TermsParagraph.vue'
import TermsBackMatter from '@/components/common/Terms/TermsBackMatter.vue'
import TermsList from '@/components/common/Terms/TermsList.vue'

export default defineComponent({
  components: { TermsPreamble, TermsParagraph, TermsBackMatter, TermsList },

  setup() {
    const state = reactive({
      title: '利用規約',
      signature: 'SHIKAKU APP', // 右上の署名
    })

    return {
      ...toRefs(state),
    }
  },
})
