import { requestBFF } from '@/request'
import { useGlobalError } from '@/services/Hooks'
import { OnDemandSSOParametersModel } from './models'

const { setGlobalError } = useGlobalError()

/**
 * @description オンデマンド SSO パラメータを習得
 * @param userID
 * @returns
 */
export const queryOnDemandSSOParameters = async (
  userID: string
): Promise<OnDemandSSOParametersModel> => {
  try {
    const query = `
      query {
        getSSOParameter(userId: "${userID}") {
          id
          ts
        }
      }
    `

    const { data } = await requestBFF(query)
    return data.data.getSSOParameter
  } catch (error) {
    setGlobalError(error)
    return { id: '', ts: '' }
  }
}
