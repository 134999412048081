
/**
 * メッセージ作成画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ManagePageTitle from '@/components/common/ManagePageTitle.vue'
import SelectedDestination from '@/components/views/InformationCreateMessage/SelectedDestination.vue'
import MessageBodyInput from '@/components/views/InformationCreateMessage/MessageBodyInput/index.vue'
import MessageSubjectInput from '@/components/views/InformationCreateMessage/MessageSubjectInput/index.vue'
import DestinationNameInput from '@/components/views/InformationCreateMessage/DestinationNameInput/index.vue'
import BranchList from '@/components/common/InformationMessage/BranchList.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import { PDFJS_CONFIG } from '@/config/pdfjsConfig'
// Dependencies
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { SelectedDestinationItem, StudentInformation } from '@/typings'
import { mainContainerStyle } from './style'
import { useRouter } from '@/router'
import {
  formatDestinationListName,
  getMessage,
  getRoleIdFromJwt,
  moveTopPage,
} from '@/Utils'
import { useStore } from '@/store'
import {
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  ref,
  watch,
  computed,
} from 'vue'

import {
  queryBranchStoreList,
  sendFilesOfMessage,
  downloadFile,
} from '@/request/api'

import { useGlobalError } from '@/services/Hooks'
import {
  setGlobalParams,
  MESSAGE_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import { FileData } from '@/typings/InformationMessage'
import {
  getStudentInformationListFromStorage,
  removeStudentInformationListFromStorage,
} from '@/services/InformationMessage/StudentInformation'
import {
  getDestinationListArrayFromStorage,
  removeDestinationListArrayFromStorage,
} from '@/services/InformationMessage'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'
import { BranchStore } from '@/typings/index'

export interface State {
  userID: string
  selectedDestinationList: Array<SelectedDestinationItem | DestinationListModel>
  formatedDestinationList: FormatedDestination[]
  isAddToDestiList: boolean
  subject: string
  body: string
  enableReply: boolean
  updatedFileList: FileData[]
  listName: string
  selectedBranchList: BranchStore[]
  allBranchs: BranchStore[]
  isSelectAllBranch: boolean
  uploadFileError: string
  uploadFileIdList: string[]
}

interface FormError {
  // 件名エラー
  subjectError: string
  // 本文エラー
  bodyError: string
  // 宛先エラー
  destinationError: string
  // 送信先リスト名エラー
  listNameError: string
  // 選択範囲エラー
  branchError: string
}

interface FormatedDestination {
  key: string
  showValue: string
}

// セッションストレージでのメッセージ作成状態のキー名
const CREATE_MESSAGE_STATE_KEY = 'createMessegeState'

export default defineComponent({
  name: 'InformationCreateMessage',

  beforeRouteEnter(to, from, next) {
    from.name ? next() : next({ name: 'InformationMenu' })
  },

  props: {
    //遷移元画面
    transitionSource: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { setGlobalError, clearGlobalError } = useGlobalError()
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const fileImageTypeCheck = 'image/'
    const filePDFTypeCheck = 'application/pdf'

    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      selectedDestinationList: [],
      formatedDestinationList: [],
      isAddToDestiList: false,
      subject: '',
      body: '',
      enableReply: false,
      updatedFileList: [],
      listName: '',
      selectedBranchList: [],
      allBranchs: [],
      isSelectAllBranch: false,
      uploadFileError: '',
      uploadFileIdList: [],
    })

    onBeforeMount(async () => {
      switch (props.transitionSource) {
        case 'InformationMenu':
          getBranchList()
          break

        case 'SelectJukoSei': //宛先選択画面から
          await getStateFromSessionStorage()
          setDestinationListFromStudentInformation()
          break

        case 'SelectDestinationList': //リスト選択画面から
          await getStateFromSessionStorage()
          setDestinationListFromListArray()
          break

        case 'Confirm': //メッセージ確認画面戻る
          await getStateFromSessionStorage()
          break

        default:
          getBranchList()
          break
      }

      setDestinationList()
      moveTopPage()
    })
    const refreshBranch = ref(0)

    const destinationTotal = computed<number>(() => {
      return getSelectedDestinationListArrayCount()
    })

    const reRenderBranch = () => {
      refreshBranch.value++
    }
    const getBranchList = async () => {
      const accessToken = getAccessTokenFromSession()
      const userType = getRoleIdFromJwt(accessToken) ?? ''
      const userID = getUserIDFromAccessToken(accessToken)
      try {
        const branchStoreList = await queryBranchStoreList(userType, userID)
        state.allBranchs = branchStoreList ?? ([] as BranchStore[])
        if (state.allBranchs.length > 0) {
          state.selectedBranchList = [
            ...state.selectedBranchList,
            state.allBranchs[0],
          ]
        }
        reRenderBranch()
      } catch (error) {
        console.log(error)
        setGlobalError(error as Error)
      }
    }
    const getStateFromSessionStorage = async () => {
      const jsonData = sessionStorage.getItem(CREATE_MESSAGE_STATE_KEY)

      if (jsonData) {
        let sessionState = JSON.parse(jsonData ?? '{}') as State

        state.selectedDestinationList = sessionState.selectedDestinationList
        state.isAddToDestiList = sessionState.isAddToDestiList
        state.subject = sessionState.subject
        state.body = sessionState.body
        state.enableReply = sessionState.enableReply
        state.uploadFileIdList = sessionState.uploadFileIdList
        state.updatedFileList = []
        let fileIndex = 0
        try {
          store?.commit('setIsLoadingWithText', true)
          store?.commit('setIsLoadingText', "ダウンロード中...")
          for await (let item of sessionState.updatedFileList) {
            item.url = await downloadFile(sessionState.uploadFileIdList[fileIndex])
            if (!item.url) {
              store?.commit('setIsLoadingWithText', false)
              state.uploadFileError = getMessage('EFR0041')
              throw new Error(getMessage('EFR0041'))
            }
            state.updatedFileList = [...state.updatedFileList, item]
            fileIndex++
          }
          store?.commit('setIsLoadingWithText', false)
        } catch (error) {
          console.log(error)
          setGlobalError(error as Error)
        }

        state.listName = sessionState.listName
        state.allBranchs = sessionState.allBranchs
        state.selectedBranchList = sessionState.selectedBranchList
        state.isSelectAllBranch = sessionState.isSelectAllBranch
        reRenderBranch()
      }
    }

    const setStateToSessionStorage = () => {
      if (state.updatedFileList.length > 0) {
        state.updatedFileList.forEach((item) => {
          //容量オーバーしないように、添付ファイル内容をsessionStorageに保存しない。
          item.url = ''
        })
      }
      sessionStorage.setItem(CREATE_MESSAGE_STATE_KEY, JSON.stringify(state))
    }

    const clearStateOfSessionStorage = () => {
      sessionStorage.removeItem(CREATE_MESSAGE_STATE_KEY)
    }

    const setDestinationListFromStudentInformation = (): void => {
      const list =
        getStudentInformationListFromStorage().map<SelectedDestinationItem>(
          destinationFormatter
        )

      state.selectedDestinationList = [
        ...state.selectedDestinationList,
        ...list,
      ]

      removeStudentInformationListFromStorage()
      reset()
    }

    const destinationFormatter = (
      item: StudentInformation
    ): SelectedDestinationItem => {
      return {
        userCd: [item.jukoId as string],
        name: item.name as string,
        branch: item.branchNm as string,
        classroom: item.classroomNm as string,
        destinationListName: '',
      }
    }

    const setDestinationListFromListArray = (): void => {
      let destinationListArray: DestinationListModel[] =
        getDestinationListArrayFromStorage()

      // 登録済みリストが選択された場合はリスト登録操作をクリア
      if (destinationListArray.length) {
        state.isAddToDestiList = false
        state.listName = ''
      }

      destinationListArray = destinationListArray.filter(existedListFilter)

      state.selectedDestinationList = [
        ...state.selectedDestinationList,
        ...destinationListArray,
      ]

      removeDestinationListArrayFromStorage()
      reset()
    }

    const existedListFilter = (target: DestinationListModel): boolean => {
      return !state.selectedDestinationList.some(
        (item: DestinationListModel | SelectedDestinationItem): boolean => {
          return item.destinationListName === target.destinationListName
        }
      )
    }

    const handleDelete = (key: string) => {
      state.selectedDestinationList = state.selectedDestinationList.filter(
        (item: SelectedDestinationItem | DestinationListModel): boolean => {
          return item.hasOwnProperty('createDate')
            ? item.destinationListName !== key
            : (item as SelectedDestinationItem).userCd[0] !== key
        }
      )

      setDestinationList()
      reset()
    }

    const resetKey = ref(0)

    const reset = () => {
      resetKey.value++
    }

    const clearSelected = () => {
      state.selectedDestinationList = []
      setDestinationList()
      reset()
    }

    // 画面遷移
    const router = useRouter()

    const goBack = () => {
      clearStateOfSessionStorage()
      router.push({ name: 'InformationMenu' })
    }

    const selectJukoSei = () => {
      setStateToSessionStorage()
      router.push({ name: 'InformationSearch' })
    }

    const selectDestinationList = () => {
      setStateToSessionStorage()
      router.push({ name: 'DestinationListSelect' })
    }

    const confirm = () => {
      if (validateAll()) {
        setStateToSessionStorage()
        setGlobalParams(MESSAGE_COMPLETE_PAGE, false)
        router.push({ name: 'InformationMessageConfirm' })
      }
    }

    const listRegistButtonLocked = computed<boolean>(() =>
      state.selectedDestinationList.some(
        (item: SelectedDestinationItem | DestinationListModel) =>
          item.destinationListName.includes('『')
      )
    )

    watch(
      () => state.subject,

      () => {
        clearGlobalError()
        formError.subjectError = ''
      }
    )

    watch(
      () => state.body,

      () => {
        clearGlobalError()
        formError.bodyError = ''
      }
    )

    watch(
      () => state.selectedDestinationList,

      () => {
        clearGlobalError()
        formError.destinationError = ''
      }
    )

    watch(
      () => state.listName,

      () => {
        clearGlobalError()
        formError.listNameError = ''
      }
    )

    watch(
      () => state.isAddToDestiList,

      () => {
        clearGlobalError()
        formError.listNameError = ''
      }
    )

    watch(
      () => state.selectedBranchList,

      () => {
        clearGlobalError()
        formError.branchError = ''
      }
    )

    watch(
      () => state.updatedFileList,
      () => clearGlobalError()
    )

    const inputFileList = async (e: Event) => {
      try {
        state.uploadFileError = ''
        state.updatedFileList = []
        const fileList = (e.target as HTMLInputElement).files
        var len = fileList?.length ?? 0
        if (len === 0) {
          return
        } else if (len > 5) {
          state.uploadFileError = getMessage('EFR0033')
          return
        }
        var formData = new FormData()
        for (var i = 0; i < len; i++) {
          if ((fileList as FileList)[i].size / 1024 > 20480) {
            state.uploadFileError = getMessage('EFR0032', [
              '20MB',
              (fileList as FileList)[i].name,
            ])
            return
          }
          if (
            (fileList as FileList)[i].type.indexOf(fileImageTypeCheck) &&
            (fileList as FileList)[i].type.indexOf(filePDFTypeCheck)
          ) {
            state.uploadFileError = getMessage('EFR0035')
            return
          }
          const fileData = {
            name: (fileList as FileList)[i].name,
            type: '',
            url: '',
          }
          const fileReader = new FileReader()
          await (async () => {
            fileReader.onload = async () => {
              if (typeof fileReader.result === 'string') {
                fileData.url = fileReader.result
              } else if (fileReader.result == null) {
                fileData.url = ''
              } else {
                fileData.url = fileReader.result.toString()
              }
            }
            var file = fileList == null ? new Blob() : fileList[i]
            fileReader.readAsDataURL(file)
          })()

          state.updatedFileList.push(fileData)
          formData.append('File', (fileList as FileList)[i])
        }
        store?.commit('setIsLoadingWithText', true)
        store?.commit('setIsLoadingText', "アップロード中...")
        state.uploadFileIdList = await sendFilesOfMessage(formData)
        store?.commit('setIsLoadingWithText', false)
        if (!state.uploadFileIdList.length) {
          state.updatedFileList = []
          state.uploadFileError = getMessage('EFR0034')
          return
        }
      } catch (error) {
        store?.commit('setIsLoadingWithText', false)
        setGlobalError(error as Error)
      }
    }

    const setDestinationList = () => {
      state.formatedDestinationList =
        state.selectedDestinationList.map<FormatedDestination>((item) => {
          const obj = {} as FormatedDestination

          if (item.hasOwnProperty('createDate')) {
            obj.key = item.destinationListName
            obj.showValue = formatDestinationListName(item.destinationListName)
          } else {
            const _item = item as SelectedDestinationItem
            obj.key = _item.userCd[0]
            obj.showValue = `${_item.name} ${_item.branch} ${_item.classroom}`
          }

          return obj
        })
    }

    // フォーム検証
    const formError = reactive<FormError>({
      subjectError: '',
      bodyError: '',
      destinationError: '',
      listNameError: '',
      branchError: '',
    })

    const validateAll = (): boolean => {
      const validateList: boolean[] = [
        validateDestination(),
        validateSubject(),
        validateBody(),
        validateListName(),
        validateBranch(),
      ]

      return validateList.every((element: boolean) => element)
    }

    const validateSubject = (): boolean => {
      let isPassed = true
      if (!state.subject) {
        formError.subjectError = getMessage('EFR0005')
        isPassed = false
      }
      return isPassed
    }

    const validateBody = (): boolean => {
      let isPassed = true
      if (!state.body) {
        formError.bodyError = getMessage('EFR0005')
        isPassed = false
      }
      return isPassed
    }

    const validateDestination = (): boolean => {
      let isPassed = true
      if (state.selectedDestinationList.length == 0) {
        formError.destinationError = getMessage('EFR0005')
        isPassed = false
      }
      return isPassed
    }

    const validateListName = (): boolean => {
      let isPassed = true
      if (!state.isAddToDestiList) {
        return isPassed
      }
      if (!state.listName) {
        formError.listNameError = getMessage('EFR0005')
        isPassed = false
      }
      return isPassed
    }

    const validateBranch = (): boolean => {
      let isPassed = true
      if (!state.selectedBranchList.length) {
        formError.branchError = getMessage('EFR0005')
        isPassed = false
      }
      return isPassed
    }

    const handleSelectedBranch = (
      selected: BranchStore[],
      isSelectAll: boolean
    ): void => {
      state.selectedBranchList = selected
      state.isSelectAllBranch = isSelectAll
    }

    const getSelectedDestinationListArrayCount = (): number => {
      let total = 0
      state.selectedDestinationList.forEach(
        (item: SelectedDestinationItem | DestinationListModel) => {
          if (item.hasOwnProperty('createDate')) {
            total = total + (item as DestinationListModel).userIdList.length
          } else {
            total++
          }

        }
      )
      return total;
    }

    return {
      ...toRefs(state),
      ...toRefs(formError),
      mainContainerStyle,
      handleDelete,
      resetKey,
      clearSelected,
      inputFileList,
      goBack,
      confirm,
      destinationTotal,
      getStateFromSessionStorage,
      setStateToSessionStorage,
      clearStateOfSessionStorage,
      selectJukoSei,
      selectDestinationList,
      isUserFromAPP,
      handleSelectedBranch,
      refreshBranch,
      listRegistButtonLocked,
      PDFJS_CONFIG,
    }
  },

  components: {
    ManagePageContainer,
    ManagePageTitle,
    SelectedDestination,
    MessageBodyInput,
    MessageSubjectInput,
    DestinationNameInput,
    BranchList,
    VuePdfApp,
  },
})
