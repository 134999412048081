
// Components
import NavigationButton from '@/components/common/NavigationButton.vue'
// Icons
import IconHome from '@/components/icons/IconHome.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import IconMyPage from '@/components/icons/IconMyPage.vue'
import IconGrade from '@/components/icons/IconGrade.vue'
import IconLive from '@/components/icons/IconLive02.vue'
import IconHomePresent from '@/components/icons/IconHomePresent.vue'
import IconInformationPresent from '@/components/icons/IconInformationPresent.vue'
import IconLivePresent from '@/components/icons/IconLivePresent.vue'
import IconMyPagePresent from '@/components/icons/IconMyPagePresent.vue'
import IconGradePresent from '@/components/icons/IconGradePresent.vue'
// Dependencies
import { defineComponent, computed, reactive, toRefs, onBeforeMount } from 'vue'

import { useStore } from '@/store'
import { useRouter } from '@/router'
import { UseOnDemand, useOnDemand, useGlobalError } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  FORM_TARGET,
  HTTP_METHOD,
  ON_DEMAND_PARAMETERS_KEY_SEND,
} from '@/config'
import { NavigationButtonInterface } from '@/typings'
import { transferInSynchronous, removeSelectTabAndPullDownState } from '@/Utils'
import { titles } from './common'
import { countUnreadMessage } from '@/request/api/information-message/message'
// Vue インスタンス
export default defineComponent({
  name: 'BaseMobileFooter',
  components: {
    NavigationButton,
    IconHome,
    IconInformation,
    IconMyPage,
    IconGrade,
    IconLive,
  },
  props: {
    refreshNotReadNumber: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const handleTransferScreen = (button: NavigationButtonInterface): void => {
      setPresentPage(button.title)
      // 外部サイトへ遷移
      if (button.toOtherSite) {
        button.transferMethod?.()
        return
      }
      if (button.routePath === '/GradeData') {
        removeSelectTabAndPullDownState()
      }
      // 内部で遷移
      router.push(button.routePath as string, () => {
        // 遷移後に何もしない
      })
    }
    const presentPage = computed<string>(() => {
      return `${!store.state.presentTab ? '' : store.state.presentTab}`
    })

    const setPresentPage = (value: string) =>
      store?.commit('setPresentTab', value)
    const transferOnDemand = (): void => {
      const ssoParams = store.state.onDemand.ssoParameters

      setOnDemandParametersToStorage({
        id: ssoParams.id,
        timestamp: ssoParams.ts,
      })

      const data = new Map([
        [ON_DEMAND_PARAMETERS_KEY_SEND.ID, ssoParams.id],
        [ON_DEMAND_PARAMETERS_KEY_SEND.TIMESTAMP, ssoParams.ts],
      ])

      transferInSynchronous({
        data,
        path: process.env.VUE_APP_ON_DEMAND_DOMAIN,
        method: HTTP_METHOD.POST,
        target: FORM_TARGET.BLANK,
      })
    }

    const navigationList = computed<NavigationButtonInterface[]>(() => {
      return [
        {
          component:
            presentPage.value === titles.home ? IconHomePresent : IconHome,
          title: titles.home,
          routePath: '/',
        },
        {
          component:
            presentPage.value === titles.information
              ? IconInformationPresent
              : IconInformation,
          title: titles.information,
          routePath: '/InformationMessageList',
          notReadNumber: state.notReadNumber,
        },
        {
          component:
            presentPage.value === titles.grade ? IconGradePresent : IconGrade,
          title: titles.grade,
          routePath: '/GradeData',
        },

        {
          component:
            presentPage.value === titles.eLearning ? IconLivePresent : IconLive,
          title: titles.eLearning,
          toOtherSite: true,
          transferMethod: transferOnDemand,
          isToNewTab: true,
        },

        {
          component:
            presentPage.value === titles.myPage
              ? IconMyPagePresent
              : IconMyPage,
          title: titles.myPage,
          routePath: '/AccountInformationRegist',
        },
      ]
    })

    const router = useRouter()
    const store = useStore()
    const { setGlobalError } = useGlobalError()
    const {
      setOnDemandSSOParameters,
      setOnDemandParametersToStorage,
    }: UseOnDemand = useOnDemand()

    const state = reactive({
      userID: '',
      score: 0,
      days: '-' as string | number,
      notReadNumber: 0,
      refreshButtons: 0,
    })

    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    // Lifecycle
    onBeforeMount(async () => {
      state.userID = getUserID()
      await getNotRead()
      await setOnDemandSSOParameters(state.userID)
      emit('handleMobileFooterMounted')
    })

    // Methods
    const getUserID = (): string => {
      return getUserIDFromAccessToken(getAccessTokenFromSession())
    }
    const getNotRead = async (): Promise<void> => {
      try {
        const count: number = await countUnreadMessage(state.userID)
        state.notReadNumber = count ?? 0
        state.refreshButtons++
      } catch (error) {
        setGlobalError(error as Error)
      }
    }
    return {
      ...toRefs(state),
      navigationList,
      isUserFromAPP,
      handleTransferScreen,
    }
  },
})
