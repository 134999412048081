
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LabelAfterCheckbox',

  props: {
    label: {
      type: String,
      require: true,
    },

    checked: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
