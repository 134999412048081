// Dependencies
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import routes from './routes'
import Vue, { getCurrentInstance } from 'vue'
import {
  getAccessTokenFromSession,
  setAccessTokenToSession,
  setRefrehTokenToSession,
} from '@/services/ControlToken'
import {
  checkIsFromAPP,
  checkUserAgent,
  getRoleIdFromJwt,
  getUrlParameter,
  CONST_FROM_ANSWER_INPUT_FINISH,
} from '@/Utils'

import { moveToAuthorize, refreshActiveTime } from '@/services/ControlLogin'
import { ATTRIBUTES_KEYS } from '@/config'
import { queryToken } from '@/request/api'
import { useGlobalError } from '@/services/Hooks'
import { VIEW_PAGE_NOT_FOUND } from './routesName'

Vue.use(VueRouter)

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// ナビゲーションガード
router.beforeEach(
  async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    try {
      const { clearGlobalError } = useGlobalError()
      clearGlobalError()
      checkUserAgent()

      // ルートにログインチェックが必要であるかをチェック
      if (!to.matched.some((record) => record?.meta?.requireAuth)) {
        next()
        return
      }

      // トークンをチェック
      if (getAccessTokenFromSession()) {
        // 訪問できるユーザータイプであるかをチェック
        const isAllowableUser = to.matched.some((record) => {
          const userType = getRoleIdFromJwt(getAccessTokenFromSession())
          const allowableUser = record?.meta?.allowableUser

          return (
            !allowableUser ||
            allowableUser.some((element: string) => element === userType)
          )
        })

        isAllowableUser ? next() : next({ name: VIEW_PAGE_NOT_FOUND })
        return
      }

      const authorizeCode = getUrlParameter(ATTRIBUTES_KEYS.CODE)

      // accessToken, code 両方ない場合は認証サーバーへ遷移
      if (!authorizeCode) {
        sessionStorage.setItem('pathBeforeTransfer', to.path)
        moveToAuthorize()
        return
      }

      // Code でトークンを取得
      const [accessToken, refreshToken] = await queryToken(authorizeCode)
      setAccessTokenToSession(accessToken)
      setRefrehTokenToSession(refreshToken)

      // アプリからのアクセスは元のページへ、ブラウザからのアクセスはトップ画面へ
      checkIsFromAPP(window)
        ? next({ path: sessionStorage.getItem('pathBeforeTransfer') ?? '/' })
        : next({ path: '/' })

      refreshActiveTime()
    } catch (error) {
      console.log(error)

      next({
        name: 'Message',
        params: { caution: (error as Error).message ?? error },
      })
    }
  }
)
router.afterEach((to, from) => {
  if(to.name ==="AnswerInput" && from.name === "NormalCompleted" ){
    sessionStorage.setItem(CONST_FROM_ANSWER_INPUT_FINISH,"true")
  }else{
    sessionStorage.removeItem(CONST_FROM_ANSWER_INPUT_FINISH)
  }
})
// Composition API でルートを取得する方法
export const useRouter = () => {
  const instance = getCurrentInstance()

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }

  // proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy.$router
}

export default router
