
// Dependencies
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue'

// Icons
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'

export default defineComponent({
  name: 'PeriodSelector',
  components: { IconArrowDownTriangle },

  props: {
    list: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedOption: {
      type: String,
      required: true,
    },
    containerStyle: Object,
    displayAreaStyle: Object,
    optionStyle: Object,
    arrowStyle: Object,
  },

  setup(props, { emit }) {
    // State
    const state = reactive({
      selectedValue: props.selectedOption,
    })

    const option = computed(() => {
      return state.selectedValue
    })
    const arrowFillcolor = computed(() => {
      return props.arrowStyle?.arrowFillcolor ?? '#c6c6c6'
    })
    // Methods
    const handleSelect = (event: Event): void => {
      const targetIndex = Number((event.target as HTMLSelectElement).value)
      state.selectedValue = props.list[targetIndex]
      emit('emitting', targetIndex)
    }

    return {
      ...toRefs(state),
      option,
      handleSelect,
      arrowFillcolor,
    }
  },
})
