
import {
  defineComponent,
  reactive,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  PropType,
} from 'vue'
import { useRouter } from '@/router'
import {
  GradeDataDetailLstView,
  GradeDataDetailLst,
} from '@/typings/GradeSearch'
import { ResultList } from '@/typings/PassDiary'
import { fieldSorter, removeSelectTabAndPullDownState } from '@/Utils'
import 'vue-easytable/libs/theme-default/index.css'
import { VePagination } from 'vue-easytable'
import { useStore } from '@/store'
import { Store } from 'vuex'
import VueRouter from 'vue-router'
import {
  VIEW_GRADE_DATA,
  VIEW_STUDY_DIARY,
  VIEW_SUMMARY_FOR_STAFF,
} from '@/router/routesName'
import {
  SET_APPLICATION_NUMBER,
  SET_STUDENT_USER_ID,
} from '@/store/actionTypes'

export default defineComponent({
  name: 'GradeDataDetailTable',
  components: { VePagination },

  props: {
    list: {
      type: Array,
      required: true,
    },
    activePassDialy: {
      type: Array as PropType<ResultList[]>,
      default: [],
    },
  },

  setup(props) {
    const router: VueRouter = useRouter()
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store?.state?.isUserFromAPP)

    const state = reactive({
      formatedGradeDataDetailLst: [] as GradeDataDetailLstView[],
      pageIndex: 1,
      pageSize: 30,
    })

    // Lifecycle
    onBeforeMount(async () => {
      setDetailList()
    })

    watch(
      () => props.list,
      () => setDetailList()
    )

    const setDetailList = () => {
      state.pageIndex = 1
      let list = []

      for (let elem of props.list as GradeDataDetailLst[]) {
        let elemView = {} as GradeDataDetailLstView
        elemView.personalNumber = elem.personalNo
        elemView.attendID = elem.jukoId
        elemView.fullName = elem.name
        elemView.fullNameKatakana = elem.kana
        elemView.courseCode = elem.courseCd
        elemView.wholeConfigFlag = elem.sogoFlg ? '〇' : ''
        elemView.branchName = elem.branchNm
        elemView.classroomName = elem.classroomNm
        elemView.applicationNumber = elem.applicationNo
        list.push(elemView)
      }

      list = list.sort(fieldSorter(['fullNameKatakana', 'attendID']))
      state.formatedGradeDataDetailLst = list
    }

    const paginationLayout = ['prev', 'pager', 'next']

    const tableData = computed(() => {
      return state.formatedGradeDataDetailLst.slice(
        (state.pageIndex - 1) * state.pageSize,
        state.pageIndex * state.pageSize
      )
    })

    const usageScheduleDialyList = computed(() => {
      return props.activePassDialy.slice(
        (state.pageIndex - 1) * state.pageSize,
        state.pageIndex * state.pageSize
      )
    })

    const totalCount = computed(() => {
      return state.formatedGradeDataDetailLst.length
    })

    const pageNumberChange = (pageIndex: number) => {
      state.pageIndex = pageIndex
    }

    // 選択ボタン
    const setStudentUserID = (userID: string): void => {
      store.dispatch(SET_STUDENT_USER_ID, userID)
    }

    const setApplicationNumber = (value: string): void => {
      store.dispatch(SET_APPLICATION_NUMBER, value)
    }

    const transitionPage = (applicationNumber: string, attendID: string) => {
      setStudentUserID(attendID)
      setApplicationNumber(applicationNumber)

      if (router.currentRoute.name === VIEW_SUMMARY_FOR_STAFF) {
        router.push({
          name: VIEW_STUDY_DIARY,
          params: { applicationNo: applicationNumber, userIDProps: attendID },
        })
      } else {
        removeSelectTabAndPullDownState()
        router.push({
          name: VIEW_GRADE_DATA,
          params: { applicationNumber, userIDProps: attendID },
        })
      }
    }

    return {
      ...toRefs(state),
      isUserFromAPP,
      tableData,
      usageScheduleDialyList,
      totalCount,
      pageNumberChange,
      paginationLayout,
      transitionPage,
    }
  },
})
