
/**
 * インフォメーション - 確認用メッセージ詳細部品
 */

// Components
import MessageWrapper from './MessageDetailItemWrapper.vue'
import LabelAfterCheckbox from '@/components/common/checkbox/LabelAfterCheckbox.vue'
import BranchList from '@/components/common/InformationMessage/BranchList.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
// Dependencies
import { computed, defineComponent, PropType,ref } from 'vue'
import { ConfirmState } from '@/views/InformationMessageConfirm/index.vue'
import { SelectedDestinationItem } from '@/typings'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'
import { formatDestinationListName } from '@/Utils'
import { PDFJS_CONFIG } from '@/config/pdfjsConfig'

export default defineComponent({
  name: 'MessageDetailConfirm',

  props: {
    messageDetail: {
      type: Object as PropType<ConfirmState>,
      require: true,
    },
    destinationTotal: {
      type: Number,
      default: 0,
    },
    uploadFileError: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const destinationListForDisplay = computed<string>(() => {
      if (!props.messageDetail?.destinationList.length) {
        return ''
      }

      return destinationListFormat(props.messageDetail.destinationList)
    })
    const destinationListFormat = (destinationList:(SelectedDestinationItem | DestinationListModel)[]):string=>{
      let arr=[] as string []
      destinationList.forEach((item)=>{
        arr= [...arr,destinationFormatter(item)]
      })
      return arr.join(', ')
    }
    const destinationFormatter = (
      item: SelectedDestinationItem | DestinationListModel
    ): string => {
      if (item.hasOwnProperty('createDate')) {
        return formatDestinationListName(item.destinationListName)
      }

      const _item = item as SelectedDestinationItem
      let text = ''

      _item.name && (text += ` ${_item.name}`)
      _item.branch && (text += ` ${_item.branch}`)
      _item.classroom && (text += ` ${_item.classroom}`)

      return text
    }
    const propsMessageDetail = ref<ConfirmState>(props.messageDetail as ConfirmState)
    return { destinationListForDisplay, formatDestinationListName ,PDFJS_CONFIG,propsMessageDetail}
  },

  components: { MessageWrapper, LabelAfterCheckbox, BranchList, VuePdfApp },
})
