
/**
 * インフォメーション - ブランチリスト部品
 */

// Dependencies
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  onBeforeMount,
  watch,
} from 'vue'

import { BranchStore } from '@/typings/index'

export default defineComponent({
  name: 'SentMessageDetail',

  props: {
    branchList: {
      type: Array as PropType<BranchStore[]>,
      require: true,
    },
    isConfirm: {
      type: Boolean,
      require: true,
    },
    selectedBranchLit: {
      type: Array as PropType<BranchStore[]>,
    },
    error: String,
    errorID: String,
    errorStyle: Object,
    selectAllBranch: Boolean,
    tableStyle: Object,
  },

  setup(props, { emit }) {
    const state = reactive({
      stateBranchList: [] as BranchStore[],
      isSelectAll: false,
      selectedBranchList: [] as BranchStore[],
      stateIsConfirm: false,
    })

    onBeforeMount(() => {
      state.stateBranchList = props.branchList ?? ([] as BranchStore[])
      state.stateIsConfirm = props.isConfirm
      state.selectedBranchList =
        props.selectedBranchLit ?? ([] as BranchStore[])
      state.isSelectAll = props.selectAllBranch ?? false
    })

    watch(
      () => state.selectedBranchList,
      () => setSelectedToParentComp()
    )
    const setSelectedToParentComp = () => {
      emit('selected', state.selectedBranchList, state.isSelectAll)
    }

    const handleSelectAll = () => {
      state.isSelectAll = !state.isSelectAll
      state.selectedBranchList = []
      if (state.isSelectAll) {
        state.selectedBranchList = props.branchList ?? ([] as BranchStore[])
      }
    }

    const handleSelectBranch = (branchCd: string | undefined) => {
      state.isSelectAll = false

      const isItemSelected = state.selectedBranchList.find(
        (item) => item.branchCd === branchCd
      )

      if (isItemSelected) {
        state.selectedBranchList = state.selectedBranchList.filter(
          (item) => item.branchCd !== branchCd
        )
      } else {
        var selectedItem = {} as BranchStore
        props.branchList?.forEach((item: any) => {
          if (item.branchCd === branchCd) {
            selectedItem = item
          }
        })
        state.selectedBranchList = [...state.selectedBranchList, selectedItem]
      }
    }
    return { ...toRefs(state), handleSelectAll, handleSelectBranch }
  },
})
