var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"ServiceTerms"}},[_c('main',[_c('TermsPreamble',{attrs:{"title":_vm.title,"signature":_vm.signature,"isTitleCenter":true}},[_vm._v(" この規約は、株式会社総合資格（以下「当社」）が提供する「SHIKAKU APP」（以下「本サービス」）をご利用頂く際の取り扱いについて定めるものです。 ")]),_c('TermsParagraph',{attrs:{"subtitle":"第１条（本規約への同意）"}},[_c('TermsList',{attrs:{"textList":[
          '利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。',
          '利用者が、本サービスをスマートフォンその他の情報端末にダウンロードし、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。',
        ]}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第２条（規約の変更）"}},[_c('TermsList',{attrs:{"textList":[
          '当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。',
          '当社は、本規約を改定するときは、その内容について当社所定の方法（本アプリ上の通知、当社ホームページ、本規約第３条３にて設定されたメールアドレスへの連絡等）により利用者に通知します。',
          '前本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。',
        ]}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第３条（登録）"}},[_c('TermsList',{attrs:{"textList":[
          '本サービスへの入会を希望する方（以下「登録希望者」）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。',
          '入会の申込をした方は、当社がその申込を承諾し、ＩＤ登録が完了した時点から登録ユーザとなります。',
          '当社は、登録ユーザ向けにメールで連絡事項の告知や広告その他の情報提供を行います。',

          {
            mainText:
              '当社は、登録希望者が次の各号のいずれか一つに該当する場合は、当社の判断により入会申込を承諾しないことがあります。',
            useCustomNumber: true,
            subList: [
              '一 登録希望者が、当社の定める方法によらず入会の申込を行った場合',
              '二 登録希望者が、過去に本規約もしくは当社の定めるその他の利用規約等又は当社と登録希望者が締結した受講契約やこれに関する利用規約等（これらに付随するものを含む）に違反したことを理由にして退会処分を受けた者である場合',
              '三 登録希望者が、不正な手段をもって登録を行っていると当社が判断した場合',
              '四 登録希望者が、本人以外の情報を登録している場合',
              '五 その他当社が不適切と判断した場合',
            ],
          },
        ]}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第４条（アカウントの管理）"}},[_c('TermsList',{attrs:{"textList":[
          '利用者は、利用に際して登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならず、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。',
          '当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。',
          '利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。',
          '登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。',
        ]}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第５条（個人情報等の取り扱い）"}},[_vm._v(" 個人情報及び利用者情報については、当社が別途定める「プライバシーポリシー」に則り、適正に取り扱うこととします。 ")]),_c('TermsParagraph',{attrs:{"subtitle":"第６条（禁止行為）"}},[_vm._v(" 本サービスの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。当社において、利用者が禁止事項に違反したと認めた場合サービスの一時停止、退会処分その他当社が必要と判断した措置を取ることができます。 "),_c('br'),_c('br'),_c('TermsList',{attrs:{"textList":[
          {
            subList: [
              '当社または第三者の知的財産権を侵害する行為',
              '当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為',
              '当社または第三者の財産を侵害する行為、または侵害する恐れのある行為',
              '当社または第三者に経済的損害を与える行為',
              '当社または第三者に対する脅迫的な行為',
              'コンピューターウィルス、有害なプログラムを使用またはそれを誘発する行為',
              '本サービス用インフラ設備に対して過度な負担となるストレスをかける行為',
              '本サービスのサーバーやシステム、セキュリティへの攻撃',
              '当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為',
              '一人の利用者が、複数の利用者IDを取得する行為',
              '上記の他、当社が不適切と判断する行為',
            ],
          },
        ],"useListNumber":false}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第７条（免責）"}},[_c('TermsList',{attrs:{"textList":[
          '当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。',
          '当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。',
          '当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。',
          '当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。',
          '利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。',
          '当社は、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。',
          '当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。',
          '第１項乃至前項の規定は、当社に故意または重過失が存する場合には適用しません。',
          '前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。',
          '利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。',
          '利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。',
          '利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。',
          '利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。',
        ]}})],1),_c('TermsParagraph',{attrs:{"subtitle":"第８条（広告の掲載について）"}},[_vm._v(" 利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。 ")]),_c('TermsParagraph',{attrs:{"subtitle":"第９条（分離可能性）"}},[_vm._v(" 本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。 ")]),_c('TermsParagraph',{attrs:{"subtitle":"第10条（管轄裁判所）"}},[_vm._v(" 本規約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。 ")])],1),_c('footer',[_c('TermsBackMatter',{staticClass:"back-matter"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }