
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  props: {
    list: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const getReceiverName = (item: string, index: number): void => {
      const receiver: string[] = [item]
      emit('emitting', receiver, index)
    }

    return { getReceiverName }
  },
})
