import Vuex from 'vuex'
import Vue, { getCurrentInstance } from 'vue'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import onDemand from './modules/on-demand'
import passDiary from './modules/pass-diary'
import searchCondition from './modules/search-condition'
import gradeDataSearchCondition from './modules/grade-data-search-condition'

Vue.use(Vuex)

export const useStore = () => {
  const instance = getCurrentInstance()

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }

  // proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy.$store
}

export default new Vuex.Store({
  state,
  getters: {},
  mutations,
  actions,

  modules: {
    onDemand,
    passDiary,
    searchCondition,
    gradeDataSearchCondition,
  },
})
