
/**
 * 回答一覧画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ReplyListComp from '@/components/views/ReplyListComp/ReplyListComp.vue'

// Dependencies
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { queryReceiveReply, queryPersonalInformationName } from '@/request/api'
import { mainContainerStyle } from './style'
import { JukoUserPersonalInformation } from '@/typings/PersonalInformationConfig'
import { ReceiveReplyMessage } from '@/typings'
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { STUDENT_USER_CODE_LENGTH } from '@/config'

interface State {
  userID: string
  replyList: ReceiveReplyMessage[]
  replyListDefault: string[]
}

interface PropsParams {
  sender: string
  messageNo: string
}

export const CURRENT_MESSAGE_INFORMATION = 'messageInforamation'

export default defineComponent({
  name: 'ReplyList',

  props: {
    sender: {
      type: String,
    },

    messageNo: {
      type: String,
    },
  },

  setup(props) {
    onBeforeMount(async () => {
      setMessageInformation()
      await setReplyList()
    })

    // メッセージインフォメーション
    const messageInformation = reactive<PropsParams>({
      sender: props.sender ?? '',
      messageNo: props.messageNo ?? '',
    })

    const setMessageInformation = (): void => {
      if (!props.sender || !props.messageNo) {
        const _messageInformation: PropsParams = JSON.parse(
          sessionStorage.getItem(CURRENT_MESSAGE_INFORMATION) as string
        )

        messageInformation.sender = _messageInformation.sender
        messageInformation.messageNo = _messageInformation.messageNo
      }
    }

    // リプライリスト
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      replyList: [] as ReceiveReplyMessage[],
      replyListDefault: [] as string[],
    })

    const setReplyList = async (): Promise<void> => {
      let receiverList: ReceiveReplyMessage[] = await queryReceiveReply(
        props.sender ?? messageInformation.sender,
        props.messageNo ?? messageInformation.messageNo
      )
      // receiverをグループ化
      const receiverGroups = new Map()
      receiverList.forEach((item) => {
        const receiver = item.receiver
        if (!receiverGroups.has(receiver)) {
          receiverGroups.set(receiver, [])
        }
        receiverGroups.get(receiver).push(item)
      })

      // グループ毎に最新のsendTimestampを検索
      receiverGroups.forEach((group) => {
        let latestTimestamp = new Date(0)
        let latestItem: any = null
        group.forEach((item: ReceiveReplyMessage) => {
          if (item.message !== null) {
            const itemTimestamp = new Date(item.message.sendTimestamp)
            if (itemTimestamp > latestTimestamp) {
              latestTimestamp = itemTimestamp
              latestItem = item
            }
          }
        })
        if (latestItem) {
          if (
            !state.replyList.some(
              (existingItem) => existingItem.receiver === latestItem.receiver
            )
          ) {
            state.replyList.push(latestItem)
          }
        }
      })

      const filteredReceiverList = receiverList.filter(
        (item: ReceiveReplyMessage): boolean =>
          item.receiver.length === STUDENT_USER_CODE_LENGTH
      )

      filteredReceiverList.forEach((item) => {
        if (
          !state.replyList.some(
            (existingItem) => existingItem.receiver === item.receiver
          )
        ) {
          state.replyList.push(item)
        }
      })
      state.replyList.forEach((item, index) => {
        state.replyListDefault[index] = item.receiver
      })

    }

    const getSenderName = async (
      list: string[],
      index: number
    ): Promise<void> => {
      if (state.replyList[index].receiver === state.replyListDefault[index]) {
        const jukoUserInfo: JukoUserPersonalInformation[] =
          await queryPersonalInformationName(list)
        state.replyList[index].receiver = jukoUserInfo[0].name
      } else {
        state.replyList[index].receiver = state.replyListDefault[index]
      }
    }

    return { ...toRefs(state), mainContainerStyle, getSenderName, messageInformation }
  },

  components: { ManagePageContainer, ReplyListComp },
})
