// アイコン閲覧画面
export const VIEW_ICON_PREVIEW = 'IconPreview'
// アプリでオンデマンドへ遷移する際の中継ルート
export const ROUTE_APP_TO_ON_DEMAND = 'APPToOnDemand'

// 受講生トップ画面
export const VIEW_HOME_PAGE = 'HomePage'
// 管理者トップ画面
export const VIEW_MANAGE_TOP = 'ManageTop'

// ページが見つかりません画面
export const VIEW_PAGE_NOT_FOUND = 'PageNotFound'
// メッセージ（エラー）画面
export const VIEW_ERROR_MESSAGE = 'Message'

// プライバシーポリシー画面
export const VIEW_PRIVACY_POLICY = 'PrivacyPolicy'
// 利用規約画面
export const VIEW_SERVICE_TERMS = 'ServiceTerms'

// QR コード画面
export const VIEW_QR_ATTEND_CERT = 'QrAttendCert'
// 試験日マスタ登録画面
export const VIEW_EXAM_DATE_MAINTENANCE = 'ExamDateMaintenance'
// アカウント情報登録画面
export const VIEW_ACCOUNT_INFORMATION_REGIST = 'AccountInformationRegist'
// 個人情報変更画面
export const VIEW_PERSONAL_INFORMATION_CONFIG = 'PersonalInformationConfig'

// 成績
// 成績参照画面
export const VIEW_GRADE_DATA = 'GradeData'
// 成績参照検索画面
export const VIEW_GRADE_DATA_SEARCH = 'GradeDataSearch'

// インフォメーションメッセージ
// インフォメーションメニュー画面
export const VIEW_INFORMATION_MENU = 'InformationMenu'
// 送信先リスト選択画面
export const VIEW_DESTINATION_LIST_SELECT = 'DestinationListSelect'
// メッセージ作成画面
export const VIEW_CREATE_MESSAGE = 'CreateMessage'
// 送信先リスト削除画面
export const VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM = 'DestinationListSelectDeleteConfirm'
// メッセージ送信完了画面
export const VIEW_NORMAL_COMPLETED = 'NormalCompleted'
// メッセージ送信完了画面(管理)
export const VIEW_MANAGE_NORMAL_COMPLETED = 'ManageNormalCompleted'
// メッセージ確認画面
export const VIEW_INFORMATION_MESSAGE_CONFIRM = 'InformationMessageConfirm'
// インフォメーション検索画面
export const VIEW_INFORMATION_SEARCH = 'InformationSearch'
// 送信先リスト一括取込画面
export const VIEW_DESTINATION_LIST_BULK_IMPORT = 'DestinationListBulkImport'
// メッセージ受信一覧画面
export const VIEW_INFORMATION_MESSAGE_LIST = 'InformationMessageList'
// メッセージ受信詳細画面
export const VIEW_INFORMATION_MESSAGE_DETAIL = 'InformationMessageDetail'
// 送信履歴一覧画面
export const VIEW_SENT_MESSAGE_HISTORY_LIST = 'SentMessageHistoryList'
// 送信履歴詳細画面
export const VIEW_SENT_MESSAGE_DETAIL = 'SentMessageDetail'
// 送信履歴詳細削除確認画面
export const VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM = 'SentMessageDetailDeleteConfirm'
// 支店一覧画面
export const VIEW_BRANCH_LIST = 'BranchList'
// 支店送信履歴一覧画面
export const VIEW_BRANCH_SENT_MESSAGE_HISTORY_LIST =
  'BranchSentMessageHistoryList'
// 未読者一覧画面
export const VIEW_LIST_OF_NOT_READ = 'ListOfNotRead'
// 既読者一覧画面
export const VIEW_LIST_OF_READ = 'ListOfRead'

// マークシート
// 回答一覧画面
export const VIEW_REPLY_LIST = 'ReplyList'
// 返信画面（詳細確認画面）
export const VIEW_REPLY_DETAIL = 'ReplyDetail'

// 解答登録（マークシート）画面
export const VIEW_ANSWER_INPUT = 'AnswerInput'

// 合格ダイアリー
// 親ルート
const ROUTE_PASS_DIARY = 'PassDiary'
// 学習ダイアリー
export const VIEW_STUDY_DIARY = `${ROUTE_PASS_DIARY}/StudyDiary`
// 学習レポート
export const VIEW_STUDY_REPORT = `${ROUTE_PASS_DIARY}/StudyReport`
// スタッフ集計画面
export const VIEW_SUMMARY_FOR_STAFF = `${ROUTE_PASS_DIARY}/SummaryForStaff`
