
/**
 * 合格ダイアリー - 予実入力画面 - タイトル部品
 */
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconClose04 from '@/components/icons/IconClose04.vue'

import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'InputWrapperTitle',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const handleGoBack = (): void => {
      emit('goBack')
    }

    const handleClose = (): void => {
      emit('close')
    }

    const wrapperStyle = computed(() => {
      if (window.innerHeight < 648) {
        return { margin: '0 0', padding: '0 0' }
      } else {
        return { margin: '1rem 0 0', padding: '0 0 1.5rem' }
      }
    })
    return { handleGoBack, handleClose, wrapperStyle }
  },

  components: { IconArrowLeft, IconClose04 },
})
