<script>
/**
 * アイコン - 閉じる 04
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconClose02',

  props: {
    fill: { type: String, default: '#fff' },
  },
})
</script>

<template>
  <svg
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m333.658385 20 1.341615-1.3416149-7.630435-7.6583851 7.630435-7.65838509-1.341615-1.34161491-7.658385 7.65838509-7.630435-7.65838509-1.369565 1.36956522 7.658385 7.63043478-7.658385 7.6583851 1.369565 1.3416149 7.630435-7.6583851z"
      :fill="fill"
      transform="translate(-317 -2)"
    />
  </svg>
</template>
