
/**
 * アイコン - 宿題
 */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: '#1CA1FD',
    },
  },
})
