import { requestBFF } from '@/request'
import { useGlobalError, UseGlobalError } from '@/services/Hooks'
import {
  QueryMessageSentHistoryListParameters,
  MessageSentHistoryListModel,
} from './model/sentHistory'

const { setGlobalError }: UseGlobalError = useGlobalError()

/**
 * @description メッセージ送信履歴一覧を取得
 * @param parameters
 */
export const queryMessageSentHistoryList = async (
  parameters: QueryMessageSentHistoryListParameters
): Promise<MessageSentHistoryListModel> => {
  try {
    const query = `
      query {
        receiveMessageList(
          input: {
            userId: "${parameters.userID}",
            lastMessageNo: "${parameters.lastMessageNumber ?? ''}",
            max: ${parameters.max},
            send: ${true},
            range: ${JSON.stringify(parameters.range ?? [])},
            sender: ${JSON.stringify(parameters.sender ?? [])},
            subject:"${encodeURIComponent(parameters.subject ?? '')}",
            body:"${encodeURIComponent(parameters.body ?? '')}",
            sendDtFrom: "${parameters.sendDtFrom ?? ''}",
            sendDtTo: "${parameters.sendDtTo ?? ''}",
            attachment:"${parameters.attachment ?? ''}",
            enableReply:"${parameters.enableReply ?? ''}",
            isNeedReply:"${parameters.isNeedReply ?? ''}",
          }
        ) {
          messageNo
          replyNo
          sender
          receiver
          sendTo
          subject
          body
          sendTimestamp
          readTimestamp
          read
          attachment
          attachmentList
          enableReply
          updatedTimestamp
          updatedUserCd
        }
      }
    `

    const { data } = await requestBFF(query)

    return data.data.receiveMessageList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * @description 利用支店のメッセージ送信履歴一覧を取得
 * @param parameters
 */
export const queryBranchMessageSentHistoryList = async (
  parameters: QueryMessageSentHistoryListParameters
): Promise<MessageSentHistoryListModel> => {
  try {
    const query = `
      query {
        receiveMessageList(
          input:{
            userId: "${parameters.userID}",
            lastMessageNo: "${parameters?.lastMessageNumber ?? ''}",
            max: ${parameters.max},
            send: ${false},
            range: ${JSON.stringify(parameters?.range ?? [])},
            sender: ${JSON.stringify([])},
            subject:"${''}",
            body:"${''}",
            sendDtFrom: "${''}",
            sendDtTo: "${''}",
            attachment:"${''}",
            enableReply:"${''}",
          }
        ) {
          messageNo
          replyNo
          sender
          receiver
          sendTo
          subject
          body
          sendTimestamp
          readTimestamp
          read
          attachment
          attachmentList
          enableReply
        }
      }
    `

    const { data } = await requestBFF(query)

    return data.data.receiveMessageList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}
