import {
  QueryRecievedMessageParameters,
  RecievedMessageModel,
  ReplyMessageParameters,
  UpdateMessageReadStatusParameter,
  DeleteMessageByMessageNoParameters,
} from './model/message'

import { useGlobalError, UseGlobalError } from '@/services/Hooks'
import { instanceBFF, requestBFF } from '@/request'

const { setGlobalError }: UseGlobalError = useGlobalError()

/**
 * @description メッセージ受信一覧画面 - 受信メッセージを取得
 * @param queryArguments
 */
export const queryRecievedMessageList = async (
  queryArguments: QueryRecievedMessageParameters
): Promise<RecievedMessageModel[]> => {
  try {
    const query = `
      query {
        receiveMessageList(
          input: {
            userId: "${queryArguments?.userID ?? ''}",
            lastMessageNo: "${queryArguments?.lastMessageNumber ?? ''}",
            max: ${queryArguments.max},
            sender: ${JSON.stringify([])},
            subject:"${''}",
            body:"${''}",
            sendDtFrom: "${''}",
            sendDtTo: "${''}",
            attachment:"${''}",
            enableReply:"${''}",
          }
        ) {
          messageNo
          replyNo
          sender
          receiver
          sendTo
          subject
          body
          sendTimestamp
          readTimestamp
          read
          attachment
          attachmentList
          enableReply
          replyed
          updatedTimestamp
          updatedUserCd
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.receiveMessageList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * @description メッセージの既読を更新
 * @param updateMessageReadStatusParameter メッセージ既読更新引数
 */
export const updateMessageReadStatus = async ({
  userID,
  messageNumber,
}: UpdateMessageReadStatusParameter): Promise<void> => {
  try {
    const mutation = `
      mutation updateMessageReadStatus {
        updateMessage(userId: "${userID}", messageNo: "${messageNumber}") {
          read
        }
      }
    `

    await requestBFF(mutation)
  } catch (error) {
    setGlobalError(error)
  }
}

/**
 * @description メッセージを返信
 * @param parameter メッセージ返信引数
 * @returns メッセージ返信成功か否か
 */
export const replyMessage = async (parameters: ReplyMessageParameters) => {
  try {
    const mutation = `
      mutation replyMessage {
        sendMessage(
          input: {
            sender: "${parameters.sender}",
            replyNo: "${parameters.replyNumber}",
            subject: "${encodeURIComponent(parameters.subject) ?? ''}"
            body: "${encodeURIComponent(parameters.body) ?? ''}"
            enableReply: ${parameters.enableReply ?? false}
            sendTo: ${JSON.stringify(parameters.sendTo)}
          }
        )
      }
    `

    const { data } = await requestBFF(mutation)

    return data.data.sendMessage
  } catch (error) {
    setGlobalError(error)
  }
}

/**
 * @description 有効期限切れのメッセージを削除
 */
export const deleteMessageByPeriod = async (): Promise<void> => {
  try {
    const mutation = `
      mutation {
        deleteMessageByPeriod
      }
    `

    await requestBFF(mutation)
  } catch (error) {
    setGlobalError(error)
  }
}

/**
 * @description メッセージ番号指定のメッセージを削除
 */
export const deleteMessageByMessageNo = async (
  parameters: DeleteMessageByMessageNoParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        deleteMessageByMessageNo(
          userId: "${parameters.userId ?? ''}"
          messageNo: "${parameters.messageNo ?? ''}"
          range: ${JSON.stringify(parameters.range)}
        )
      }
    `

    const { data } = await instanceBFF.post('', mutation)

    return data.data.deleteMessageByMessageNo
  } catch (error) {
    setGlobalError(error)
    return false
  }
}

/**
 * @description 未読件数取得
 * @param queryArguments
 */
export const countUnreadMessage = async (userId: string): Promise<number> => {
  try {
    const query = `
      query {
        countUnreadMessage(
          userId: "${userId ?? ''}",
        ) {
          count
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.countUnreadMessage.count
  } catch (error) {
    setGlobalError(error as Error)
    return 0
  }
}

/**
 * @description 未回答件数取得
 * @param queryArguments
 */
export const countUnreplyMessage = async (userId: string): Promise<number> => {
  try {
    const query = `
      query {
        countUnreplyMessage(
          userId: "${userId ?? ''}",
        ) {
          count
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.countUnreplyMessage.count
  } catch (error) {
    setGlobalError(error as Error)
    return 0
  }
}
