
import BaseTextBox from '@/components/common/BaseTextBox.vue'
import { defineComponent, toRefs, onBeforeMount, reactive, computed } from 'vue'

export default defineComponent({
  name: 'AttendIDInput',
  props: { list: String, error: String },

  setup(props, { emit }) {
    const filterArray = (arrayA: string[]) => {
      if(!arrayA || arrayA.length===0){
        return []
      }
      return arrayA.filter((element, index) => {
        return arrayA.indexOf(element) == index;
      })
    }
    const getCount = (arr: string) => {
      return arr ? filterArray(arr
        .replace(/[\s|\u3000]+/g, '\n')
        .split('\n')).length : 0
    }
    const cnt = computed<number>(()=>getCount(props.list ?? "".trim()))
    const state = reactive({
      attendIDList: props.list ?? "".trim(),
      countAttentId: getCount(props.list ?? "".trim()),
    })
    
    const handleInput = (event: InputEvent): void => {
      const inputValue = (event.target as HTMLInputElement).value
      state.attendIDList = inputValue.trim()
      state.countAttentId = getCount(state.attendIDList)
      emit('input', state.attendIDList)
    }

    return { handleInput, ...toRefs(state), getCount, cnt }
  },

  components: { BaseTextBox },
})
