export const SET_ATTEND_FISCAL_YEAR = 'SET_ATTEND_FISCAL_YEAR'
export const SET_CERTIFICATION_LIST = 'SET_CERTIFICATION_LIST'
export const SET_CERTIFICATION = 'SET_CERTIFICATION'
export const SET_COURSE_GROUP_LIST = 'SET_COURSE_GROUP_LIST'
export const SET_COURSE_GROUP = 'SET_COURSE_GROUP'
export const SET_BRANCH_STORE_LIST = 'SET_BRANCH_STORE_LIST'
export const SET_BRANCH_STORE = 'SET_BRANCH_STORE'
export const SET_CLASSROOM_LIST = 'SET_CLASSROOM_LIST'
export const SET_CLASSROOM = 'SET_CLASSROOM'
export const SET_SUMMARY_PERIOD = 'SET_SUMMARY_PERIOD'
export const SET_LABEL_LIST = 'SET_LABEL_LIST'
export const SET_LABEL = 'SET_LABEL'
export const SET_PERSONAL_NUMBER = 'SET_PERSONAL_NUMBER'
export const SET_ATTEND_ID = 'SET_ATTEND_ID'
export const SET_FULL_NAME_KATAKANA = 'SET_FULL_NAME_KATAKANA'
export const SET_FULL_NAME = 'SET_FULL_NAME'
export const SET_ACTIVE_TABLE = 'SET_ACTIVE_TABLE'
