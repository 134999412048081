
import DateSelector from '@/components/common/selector/DateSelector.vue'
import SelectionBox from '@/components/views/GradeDataSearch/SelectionBox.vue'
import { useStore } from '@/store'
import { SummaryPeriod } from '@/store/modules/search-condition'
import {
  SET_LABEL,
  SET_LABEL_LIST,
  SET_SUMMARY_PERIOD,
} from '@/store/modules/search-condition/actionTypes'

import { computed, defineComponent, onBeforeMount } from 'vue'
import { Store } from 'vuex'

export default defineComponent({
  name: 'SummaryInformationSelector',

  setup() {
    const store: Store<any> = useStore()

    // 集計期間
    const summaryPeriod = computed<SummaryPeriod>(
      () => store.state.searchCondition.summaryPeriod
    )

    const setBeginDate = (date: string): void => {
      store.dispatch(SET_SUMMARY_PERIOD, {
        begin: date,
        end: summaryPeriod.value.end,
      })
    }

    const setEndDate = (date: string): void => {
      store.dispatch(SET_SUMMARY_PERIOD, {
        begin: summaryPeriod.value.begin,
        end: date,
      })
    }

    // 集計ラベル
    const setLabelList = async (): Promise<void> => {
      await store.dispatch(SET_LABEL_LIST)
    }

    onBeforeMount(async (): Promise<void> => setLabelList())

    const labelList = computed<string[]>(
      (): string[] => store.getters.labelListForDisplay
    )

    const label = computed<string>((): string => store.getters.labelForDisplay)
    const setLabel = (name: string) => store.dispatch(SET_LABEL, name)

    return {
      summaryPeriod,
      setBeginDate,
      setEndDate,
      labelList,
      label,
      setLabel,
    }
  },

  components: { DateSelector, SelectionBox },
})
