<script>
// Components
import iconBin from '@/assets/icon/bin.png'
import Header from '@/components/common/ManageHeader.vue'
import Footer from '@/components/common/ManageFooter.vue'
import BaseErrorBox from '@/components/common/BaseErrorBox.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'
// Dependecies
import { mapMutations } from 'vuex'
import { getMessage } from '@/Utils'
import { isApplicationException } from '@/services/Exception'
import {
  queryExamDateMaintenanceInitializeData,
  createExaminationDate,
  deleteExaminationDate,
  modifyExaminationDate,
  queryExaminationDates,
} from '@/request/api'

// Constans
const DEFAULT_DECISION = true // デフォルトに選択されてある「確定: true / 未定: false」
const KBN_MAX_LENGTH = 20 // 試験区分の上限字数

const today = new Date()

/**
 * 文字列の日付に前置き 0 を入れる。
 * @param {string} date
 * @return {string} 0 を入れた日付の文字列
 */
const dateAddZero = (date) => {
  let dateArray = date.replace(/ /g, '').split(/[\/\-]/g)

  dateArray = dateArray.map((item) => {
    if (item.length === 1) {
      return `0${item}`
    }

    return item
  })

  return dateArray.join('-')
}

export default {
  name: 'ExamDateMaintenance',

  data() {
    return {
      // [{ decision: boolean, examDate: string, jukoClassCd: string, kbnName: string }]
      examDateList: [],
      // [{ kana: string, kbn: string, name: string, sort: string }]
      jukoClasses: [],
      iconBin,
      router: this.$router,

      // States
      targetExaminationDate: {}, // 更新に使う判別用データ
      selectedJukoClass: {}, // 受講種別
      inputedKbnName: '', // 試験区分
      selectedExamDate: dateAddZero(this.getStringDate(today)),
      decision: DEFAULT_DECISION, // 確定 / 未定

      // Errors
      isDataSelected: false,
      isErrorExist: false,
      errorMessage: {},
    }
  },

  components: { Header, Footer, BaseErrorBox, DatePicker },

  computed: {
    errorKbnName() {
      return this.isErrorExist ? this.errorMessage.kbnName : ''
    },
  },

  async created() {
    await this.setListAndOption()
  },

  watch: {
    selectedJukoClass(newValue, oldValue) {
      if (newValue.cd !== oldValue.cd) {
        this.errorMessage = { ...this.errorMessage, updateDataFailed: '' }
      }
    },

    inputedKbnName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.errorMessage = { ...this.errorMessage, updateDataFailed: '' }
      }
    },

    selectedExamDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.errorMessage = { ...this.errorMessage, updateDataFailed: '' }
      }
    },

    decision(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.errorMessage = { ...this.errorMessage, updateDataFailed: '' }
      }
    },

    isDataSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.errorMessage = { ...this.errorMessage, updateDataFailed: '' }
      }
    },
  },

  methods: {
    dateAddZero,
    ...mapMutations(['setGlobalError']),

    /**
     * 日付型の日付を文字列にする。
     * @param {Date} date
     * @return {string} 文字列型の日付
     */
    getStringDate(date) {
      const dateTypeDate = new Date(date)
      const fullYear = dateTypeDate.getFullYear()
      const month = dateTypeDate.getMonth() + 1
      const day = dateTypeDate.getDate()

      return `${fullYear}/${month}/${day}`
    },

    // リストデータの初期化
    initializeExamination(examinaitonDates) {
      examinaitonDates.forEach((item) => {
        item.jukoClassName = this.jukoClasses?.find(
          (jukoClass) => item.jukoClassCd === jukoClass.kbn
        )?.name

        item.examDate = dateAddZero(item.examDate).replace(/-/g, '/')
      })

      this.examDateList = [...examinaitonDates]
    },

    /**
     * 新データを請求する際のエラー処理。
     * @param {string} error エラーメッセージ
     */
    responseErrorHandling(error) {
      if (!isApplicationException(error)) {
        this.setGlobalError(error)
        return
      }

      const message = getMessage(
        error.includes?.('キー重複') ? 'EFR0012' : 'EFR0016'
      )

      this.errorMessage = { ...this.errorMessage, updateDataFailed: message }
    },

    async setListAndOption() {
      try {
        const { findKbnMaster, findExaminationDate } =
          await queryExamDateMaintenanceInitializeData()

        this.jukoClasses = findKbnMaster
        this.selectedJukoClass.cd = this.jukoClasses?.[0]?.kbn
        this.initializeExamination(findExaminationDate)
      } catch (error) {
        console.log(error)
        this.responseErrorHandling(error.message ?? error)
      }
    },

    // 登録
    async insertRecord() {
      try {
        // フォーム検証
        this.validateExamination()
        if (this.isErrorExist) return

        // 登録を行う。
        const response = await createExaminationDate({
          jukoClassCd: this.selectedJukoClass.cd,
          kbnName: this.inputedKbnName,
          examDate: this.selectedExamDate.replace(/\//g, '-'),
          decision: this.decision,
        })

        // 登録が成功したらデータを再取得
        if (response.data?.data?.addExaminationDate) {
          await this.redisplayExaminationList()
          this.resetDefaultInputs()
        }
      } catch (error) {
        console.log(error)
        this.responseErrorHandling(error.message ?? error)
      }
    },

    // 更新
    async updateRecord() {
      try {
        // フォーム検証
        this.validateExamination()
        if (this.isErrorExist) return

        // 入力欄にあるのは選択されたデータではない場合後続操作を行わない
        if (!this.isDataSelected) {
          this.errorMessage = {
            ...this.errorMessage,
            updateDataFailed: getMessage('EFR0011'),
          }

          return
        }

        // 選択されたデータかどうかを判断するラベルを初期化
        this.isDataSelected = false

        // 更新を行う
        const response = await modifyExaminationDate({
          targetExaminationDate: this.targetExaminationDate,
          jukoClassCd: this.selectedJukoClass.cd,
          kbnName: this.inputedKbnName,
          examDate: this.selectedExamDate.replace(/\//g, '-'),
          decision: this.decision,
        })

        if (response?.data?.data?.updateExaminationDate) {
          await this.redisplayExaminationList()
          this.resetDefaultInputs()
        }
      } catch (error) {
        console.log(error)
        this.responseErrorHandling(error.message ?? error)
      }
    },

    // 削除
    async handleDeleteRecord(examination) {
      try {
        const response = await deleteExaminationDate(examination)

        if (response?.data?.data?.deleteExaminationDate) {
          await this.redisplayExaminationList()
          this.resetDefaultInputs()
        }
      } catch (error) {
        console.log(error)
        this.responseErrorHandling(error.message ?? error)
      }
    },

    // フォーム検証
    validateExamination() {
      this.errorMessage.kbnName = ''
      this.isErrorExist = false

      if (this.inputedKbnName === '') {
        this.errorMessage.kbnName = getMessage('EFR0006', ['試験区分'])
        this.isErrorExist = true

        return
      }

      if (this.inputedKbnName.length > KBN_MAX_LENGTH) {
        this.errorMessage.kbnName = getMessage('EFR0007', [
          ` ${KBN_MAX_LENGTH} `,
        ])

        this.isErrorExist = true

        return
      }

      this.errorMessage.kbnName = ''
    },

    // データリストを再描画
    async redisplayExaminationList() {
      const response = await queryExaminationDates()
      this.initializeExamination(response?.data?.data?.findExaminationDate)
    },

    // 入力欄の初期化
    resetDefaultInputs() {
      this.selectedJukoClass.cd = this.jukoClasses[0].kbn
      this.inputedKbnName = ''
      this.selectedExamDate = dateAddZero(this.getStringDate(today))
      this.decision = DEFAULT_DECISION
    },

    // 受講種別でソート
    sortBySortFlag() {
      const newList = this.examDateList.sort((previous, next) => {
        const previousSort = this.jukoClasses.find(
          (item) => previous.jukoClassCd === item.kbn
        )?.sort

        const nextSort = this.jukoClasses.find(
          (item) => next.jukoClassCd === item.kbn
        )?.sort

        if (previousSort > nextSort) return 1
        if (previousSort < nextSort) return -1

        return 0
      })

      this.examDateList = [...newList]
    },

    // 編集対象の選択
    selectItem({ jukoClassCd, kbnName, examDate, decision }) {
      // 既存のエラーをリセット
      this.errorMessage = {}
      this.isErrorExist = false

      this.targetExaminationDate = {
        jukoClassCd,
        kbnName,
        examDate: examDate.replace(/\//g, '-'),
        decision,
      }

      // 選択したデータを入力欄に入れる
      this.selectedJukoClass.cd = jukoClassCd
      this.inputedKbnName = kbnName
      this.selectedExamDate = examDate
      this.decision = decision
      this.isDataSelected = true
    },
  },
}
</script>

<template>
  <div id="ExamDateMaintenance">
    <!-- ヘッダー -->
    <div><Header /></div>
    <div><BaseErrorBox /></div>

    <!-- ボディ -->
    <main>
      <!-- 登録 -->
      <section class="regist_container">
        <h3>試験日マスタ登録</h3>

        <form>
          <div class="regist_inputs_container">
            <div class="jukoClassName">
              <label for="jukoClassName">受講種別</label>

              <select id="jukoClassName" v-model="selectedJukoClass">
                <option
                  v-for="(className, index) in jukoClasses"
                  :key="index"
                  :value="{ cd: className.kbn }"
                >
                  {{ className.name }}
                </option>
              </select>
            </div>

            <div class="kbnName">
              <label for="kbnName">試験区分</label>
              <input id="kbnName" type="text" v-model="inputedKbnName" />

              <strong class="error-message">
                {{ errorKbnName }}
              </strong>
            </div>

            <div class="examDate">
              <label for="date">年月日</label>

              <DatePicker
                id="date"
                class="date"
                input-class="mx-input2"
                v-model="selectedExamDate"
                type="date"
                placeholder="年/月/日"
                format="YYYY/MM/DD"
                value-type="YYYY/MM/DD"
                :clearable="false"
              ></DatePicker>
            </div>

            <div class="decision">
              <label for="decision">確定 / 未定</label>
              <select id="decision" v-model="decision">
                <option value="true">確定</option>
                <option value="false">未定</option>
              </select>
            </div>
          </div>

          <strong class="error_response" v-show="errorMessage.updateDataFailed">
            {{ errorMessage.updateDataFailed }}
          </strong>

          <div class="main-button_container">
            <button type="button" id="update" @click="updateRecord">
              更　新
            </button>

            <button type="button" id="regist" @click="insertRecord">
              登　録
            </button>
          </div>
        </form>
      </section>

      <!-- 展示 -->
      <div class="list_background">
        <div class="list_container">
          <button type="button" id="sort" @click="sortBySortFlag">
            受講種別でソート
          </button>

          <table>
            <caption>
              {{ /** NOTE: sonarqube 対応（表の説明が必須） */ }}
            </caption>

            <thead>
              <tr>
                <th scope="col" class="list_jukoClassName_head">受講種別</th>
                <th scope="col" class="list_name_head">試験名</th>
                <th scope="col" class="list_date_head">年月日</th>
                <th scope="col">確定 / 未定</th>
                <th scope="col" class="delete_head" />
                <th scope="col" class="select_head" />
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(examination, index) in examDateList"
                :key="index"
                :style="{
                  backgroundColor: index % 2 === 0 ? '#fff' : '#eff0f1',
                }"
              >
                <td class="list_jukoClassName_body">
                  {{ examination.jukoClassName }}
                </td>

                <td class="list_name_body">
                  {{
                    `${examination.examDate}　${examination.jukoClassName}　${examination.kbnName}`
                  }}
                </td>

                <td class="list_name_body_media">
                  <span>{{ examination.examDate }}{{ '　' }}</span>
                  <span>{{ examination.jukoClassName }}{{ '　' }}</span>
                  <span>{{ examination.kbnName }}</span>
                </td>

                <td class="list_date_body">{{ examination.examDate }}</td>
                <td>{{ examination.decision ? '確定' : '未定' }}</td>

                <td
                  class="delete_body_container"
                  @click="() => handleDeleteRecord(examination)"
                >
                  <img :src="iconBin" alt="bin" style="cursor: pointer" />
                </td>

                <td class="select_body_container">
                  <button
                    type="button"
                    id="select"
                    @click="() => selectItem(examination)"
                  >
                    選択
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>

    <!-- フッター -->
    <div><Footer /></div>
  </div>
</template>

<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap')

input, select {
  background: #fff
  border: 1px solid #869fb3
  border-radius: 5px
  box-sizing: border-box
  color: #c6c6c6
  font-family: 'Noto Sans JP', sans-serif
  outline: 0
  padding: 3px 0 1px 15px
  width: 100%

  &:focus {
    border: 2px solid #869fb3e0
  }
}

input { height: 34px }
select { height: 40px }

option {
  &:hover {
    background: red
  }
}

button {
  background: #1ca1fd
  border: 0
  border-radius: 5px
  color: #fff
  cursor: pointer
  display: block
  font-family: 'Noto Sans JP', sans-serif
  font-size: 15px
  height: 50px
  width: 150px

  &:active  {
    background: #1ca1fdcc;
  }
}

#ExamDateMaintenance {
  display: flex
  flex-direction: column
  width: 100%
}

main {
  align-items: center
  display: flex
  font-family: 'Noto Sans JP', sans-serif
  flex-direction: column
  width: 100%

  .regist_container {
    width: 70%

    h3 {
      margin: 30px 0 0
      font-size: 16px
      font-weight: normal
    }

    form {
      align-items: center
      display: flex
      flex-direction: column
      margin: 26px 0 0

      .regist_inputs_container {
        border-top: 1px solid #c6c6c6
        display: flex
        flex-flow: wrap
        font-size: 12px
        padding: 30px 0 0
        width: 100%

        div {
          display: flex
          flex-direction: column
        }

        label {
          color: #333
          font-size: 12px
        }

        input, select {
          box-sizing: border-box
          color: #333
          font-size: 12px
          margin 10px 0 0
        }

        .jukoClassName {
          flex: 3
        }

        .kbnName {
          box-sizing: border-box
          flex: 2
          margin: 0 0 0 10px

          input {
            height: 40px
          }
        }

        .examDate {
          box-sizing: border-box
          flex: 3
          margin: 0 0 0 10px

          input  {
            height: 40px
            text-align: center
          }
        }

        .decision {
          flex: 1.5
          margin: 0 0 0 10px
        }
      }

      .error-message {
        box-sizing: border-box
        color: #f76cad
        margin: 10px 0 0
      }

      .error_response {
        align-self: flex-start
        box-sizing: border-box
        color: #f76cad
        display: flex
        font-size: 12px
      }

      .main-button_container {
        display: flex
        justify-content: space-between
        margin: 40px 0 0
        min-width: 378px
        width: 45%
      }
    }
  }

  .list_background {
    background-color: #eaeaea
    display: flex
    flex: 1
    justify-content: center
    margin: 50px 0 0
    padding: 50px 0 120px
    width: 100%

    .list_container{
      height: 100%
      width: 70%

      button { width: 180px }

      table {
        font-size: 15px
        margin: 40px 0 0
        width: 100%

        thead {
          tr {
            background-color: #0d3f67
            border: 1px solid #0d3f67
            border-left: 0
            box-sizing: border-box
            color: #fff
            display: flex
            min-height: 40px
            width: 100%

            th {
              align-items: center
              border-left: 1px solid #0d3f67
              box-sizing: border-box
              display: flex
              flex: 1
              font-size: 12px
              font-weight: normal
              justify-content: center
              line-height: 120%
              padding: 5px 10px
            }

            .list_jukoClassName_head {
              flex: 6
            }

            .list_name_head {
              flex: 8
              justify-content: flex-start
            }

            .list_date_head {
              flex: 3
            }

            .delete_head {
              flex: 1
            }

            .select_head {
              flex: 2
            }
          }
        }

        tbody {
          tr {
            display: flex
            height: 100%
            border-bottom: 1px solid #c6c6c6
            border-right: 1px solid #c6c6c6
            box-sizing: border-box

            td {
              align-items: center
              border-left: 1px solid #c6c6c6
              box-sizing: border-box
              display: flex
              flex: 1
              justify-content: center
              padding: 5px 10px
              line-height: 120%
            }

            .list_jukoClassName_body {
              flex: 6
            }

            .list_name_body {
              flex: 8
              justify-content: flex-start
            }

            .list_name_body_media {
              display: none
            }

            .list_date_body {
              flex: 3
            }

            .delete_body_container {
              flex: 1
            }

            .select_body_container {
              flex: 2

              button {
                background: rgba(255, 204, 255, 100)
                box-sizing: border-box
                font-size: 12px
                font-weight: 600
                height: 20px
                width: 50px

                &:active {
                  background: #f76cad66
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  main .regist_container,
  main .list_background .list_container {
    width: 83%
  }
}

@media screen and (max-width: 800px) {
  main .regist_container,
  main .list_background .list_container {
    width: 90%
  }

  main .list_background .list_container table {
    font-size: 12px
  }

  main .list_background .list_container tbody tr .list_name_body {
    display: none
  }

  main .list_background .list_container table thead tr .list_name_head {
    flex: 3
  }

  main .list_background .list_container table tbody tr .list_name_body_media {
    align-items: flex-start
    display: flex
    flex: 3
    flex-direction: column
    justify-content: center

    span {
      margin-top: 5px
    }
  }
}

@media screen and (max-width: 600px) {
  main .regist_container form .regist_inputs_container {
    .jukoClassName {
      flex: 2
    }

    .kbnName {
      flex: 2
    }

    .decision {
      flex: 2
    }
  }

  main .regist_container form .main-button_container {
    min-width: 330px
  }

  main .list_background .list_container table thead tr {
    .list_jukoClassName_head {
      flex: 1
    }

    .list_name_head {
      flex: 6
    }

    .list_date_head {
      flex: 5
    }

    .select_head {
      flex: 4
    }
  }

  main .list_background .list_container table tbody tr {
    .list_jukoClassName_body {
      flex: 1
    }

    .list_name_body_media {
      flex: 6
    }

    .list_date_body {
      flex: 5
    }

    .select_body_container {
      flex: 4
    }
  }
}
.date {
  background: transparent;
  border: solid #0d3f67;
  border-radius: 5px;
  box-sizing: border-box;
  color: #333;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.875rem;
  height: 40px;
  margin: 10px 0 0;
  padding: 0 33px 0 15px;
  width: 100%;
}
</style>
<style>
.mx-input2 {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 30px;
  padding-left: 10px;
  line-height: 1.4;
  color: #333;
  background-color: #fff;
  border: none;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.875rem;
}
</style>
