import { LabelClassification } from '@/typings/index'
export interface Circle {
  circleCenterX: number
  circleCenterY: number
  radius: number
}

export interface Color {
  scheduleColor: string
  achieveColor: string
}

export interface Scale {
  x: number
  y: number
}
export interface SelectedLabelItem {
  label: LabelClassification
  isAchieveChecked: boolean
  achieveTime: number
  scheduleTime: number
}

export const drawBackground = (
  context: any,
  circle: Circle,
  startAngel: number,
  endAngel: number,
  fillStyle: string,
  lineWidth: number,
  strokeStyle: string
): void => {
  if (!context) {
    return
  }

  context.beginPath()
  context.arc(
    circle.circleCenterX,
    circle.circleCenterY,
    circle.radius,
    startAngel,
    endAngel,
    false
  )

  context.strokeStyle = strokeStyle
  context.lineWidth = lineWidth
  context.fillStyle = fillStyle
  context.fill()
  context.stroke()
}

export const drawGraduation = (
  context: any,
  circleCenterX: number,
  circleCenterY: number,
  fillStyle: string,
  font: string,
  strokeStyle: string
): void => {
  if (!context) {
    return
  }

  context.fillStyle = fillStyle
  context.font = font
  context.textAlign = 'center'
  context.textBaseline = 'middle'
  context.strokeStyle = strokeStyle
  context.lineWidth = 1

  for (let i = 0; i < 24; i++) {
    context.beginPath()
    context.fillText(
      `${i + 1}`,
      circleCenterX + Math.cos(Math.PI * 2 * ((i - 5) / 24)) * 150,
      circleCenterY + Math.sin(Math.PI * 2 * ((i - 5) / 24)) * 150
    )
    context.moveTo(
      circleCenterX + Math.cos(Math.PI * 2 * ((i - 0.5) / 24)) * 155,
      circleCenterY + Math.sin(Math.PI * 2 * ((i - 0.5) / 24)) * 155
    )
    context.lineTo(
      circleCenterX + Math.cos(Math.PI * 2 * ((i - 0.5) / 24)) * 145,
      circleCenterY + Math.sin(Math.PI * 2 * ((i - 0.5) / 24)) * 145
    )
    context.stroke()
  }
}

export const drawControlPanel = (
  context: any,
  circleCenterX: number,
  circleCenterY: number,
  radius: number,
  startAngel: number,
  endAngel: number,
  fillStyle: string
): void => {
  if (!context) {
    return
  }

  context.beginPath()
  context.arc(circleCenterX, circleCenterY, radius, startAngel, endAngel, false)

  context.fillStyle = fillStyle
  context.fill()
}

export const isLabelChecked = (
  label: string,
  selectedItemArray: SelectedLabelItem[]
): boolean => {
  let ret = false
  selectedItemArray.forEach((item) => {
    if (label === item.label.kbn && item.isAchieveChecked) {
      ret = true
    }
  })
  return ret
}

export const getColor = (
  name: string,
  selectedItemArray: SelectedLabelItem[]
): Color => {
  let defaultRet = { scheduleColor: '#ffffff', achieveColor: '#ffffff' }
  if (name && selectedItemArray && selectedItemArray.length > 0) {
    return {
      scheduleColor:
        '#' +
        selectedItemArray.find((item) => {
          return item.label.kbn === name
        })?.label.name1,
      achieveColor:
        '#' +
        selectedItemArray.find((item) => {
          return item.label.kbn === name
        })?.label.name2,
    }
  }

  return defaultRet
}
