
/**
 * インフォメーション - 送信先リスト選択画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'

// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { mainContainerStyle } from './style'
import SubHeader from '@/components/common/SubHeader.vue'
import DestinationListSelect from '@/components/views/DestinationListSelect/DestinationListSelectTable.vue'
import { queryApplicationInformation } from '@/request/api'
import { queryDestinationList } from '@/request/api/information-message/destinationList'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { fieldSorter } from '@/Utils'
import { useGlobalError } from '@/services/Hooks'
import { useRouter } from '@/router'
import { VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM } from '@/router/routesName'
import { setDestinationListArrayToStorage } from '@/services/InformationMessage'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'

interface State {
  userID: string
  applicationNo: string
  destinationListArray: DestinationListModel[]
}

export default defineComponent({
  components: {
    ManagePageContainer,
    SubHeader,
    DestinationListSelect,
  },

  props: {
    userIDProps: String,
  },

  setup(props) {
    const destinationListShowMax = 100

    const state = reactive<State>({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationNo: '',
      destinationListArray: [],
    })

    const { setGlobalError } = useGlobalError()

    onBeforeMount(async () => {
      try {
        const { applicationNo } = await queryApplicationInformation(
          state.userID
        )

        state.applicationNo = applicationNo ?? ''

        // 送信先リスト配列を取得
        state.destinationListArray = await queryDestinationList()
      } catch (error) {
        console.log(error)
        setGlobalError(error as Error)
      }

      state.destinationListArray.sort(
        fieldSorter(['-createDate', 'destinationListName'])
      )

      while (state.destinationListArray.length > destinationListShowMax) {
        state.destinationListArray.pop()
      }
    })

    // 選択された送信先リストの配列をメッセージ作成画面へ送信
    const router = useRouter()

    const handleSubmitSelectedListArray = async (
      selectedDestinationLists: DestinationListModel[]
    ): Promise<void> => {
      setDestinationListArrayToStorage(selectedDestinationLists)

      router.push({
        name: 'CreateMessage',
        params: { transitionSource: 'SelectDestinationList' },
      })
    }

    const handleDeleteConfirm = async (
      destinationListName: string
    ): Promise<void> => {
      router.push({
        name: VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM,
        params: { destinationListName: destinationListName },
      })
    }

    return {
      ...toRefs(state),
      handleSubmitSelectedListArray,
      handleDeleteConfirm,
      mainContainerStyle,
    }
  },
})
