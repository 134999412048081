
/**
 * 合格ダイアリー - 学習レポート画面
 */
import AllRoleContainer from '@/components/common/AllRoleContainer.vue'
import BaseSelector from '@/components/common/BaseSelector.vue'
import LabelSelector from '@/components/views/PassDiary/StudyReport/LabelSelector.vue'
import WeekReportGraph from '@/components/views/PassDiary/StudyReport/WeekReportGraph.vue'
import MonthReportGraph from '@/components/views/PassDiary/StudyReport/MonthReportGraph.vue'
import YearReportGraph from '@/components/views/PassDiary/StudyReport/YearReportGraph.vue'

import SubHeader from '@/components/views/PassDiary/StudyReport/SubHeader.vue'
import PeriodSelector from '@/components/views/PassDiary/StudyReport/PeriodSelector.vue'
import PeriodWeek from '@/components/views/PassDiary/StudyReport/PeriodWeek.vue'
import PeriodMonth from '@/components/views/PassDiary/StudyReport/PeriodMonth.vue'
import PeriodYear from '@/components/views/PassDiary/StudyReport/PeriodYear.vue'
import { TrainingDay, TrainingDayDetail } from '@/typings/TrainingDay'
import { SelectedLabelItem } from '@/components/views/PassDiary/Common'
import { getGlobalParams, EXAMNATION_DATE } from '@/services/GlobleParames'
import { mainContainerStyle, manageMainContainerStyle } from './style'

// Dependencies
import {
  queryApplicationInformation,
  queryApplicationInformationList,
  getUserScheduleSummary,
  getLabelOfKbnMaster,
  queryTraningDayInitializeData,
  queryExaminationDay,
} from '@/request/api'
import { LabelClassification } from '@/typings/index'
import { useGlobalError } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getDate, getDateBaseToYyyyMmDd } from '@/Utils'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  defineComponent,
  reactive,
  computed,
  onBeforeMount,
  toRefs,
  watch,
} from 'vue'

import { ApplicationInformation } from '@/typings'
import { UserScheduleSummary } from '@/typings/PassDiary'
import { useStore } from '@/store'
import { Store } from 'vuex'

interface Time {
  hour: number
  minute: number
}
export default defineComponent({
  name: 'StudyReport',
  props: {
    userIDProps: String,
    applicationNo: String,
  },
  setup(props) {
    const { setGlobalError, clearGlobalError } = useGlobalError()

    const store: Store<any> = useStore()

    const studentUserID = computed<string>(
      (): string => store.state.studentUserID
    )

    const applicationNumber = computed<string>(
      (): string => store.state.applicationNumber
    )

    // State
    const state = reactive({
      userID:
        (studentUserID.value || props.userIDProps) ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationInfoList: [] as ApplicationInformation[],
      applicationInfo: {} as ApplicationInformation,

      userScheduleSummary: {
        schedule: [] as UserScheduleSummary[],
        achievement: [] as UserScheduleSummary[],
      },

      targetDate: new Date(),
      initTargetDateForWeek: new Date(),
      rabelClassification: [] as LabelClassification[],
      periodKbn: 1, //1:週間、2:月間、3:年間

      totalTime: {
        schedule: { hour: 0, minute: 0 } as Time,
        achievement: { hour: 0, minute: 0 } as Time,
      },

      selectedItemArray: [] as SelectedLabelItem[],
      nearestTrainingDay: {} as TrainingDay,
      trainingDayList: [] as TrainingDayDetail[],
      refreshLabelSelector: false,
      examDate: getGlobalParams(EXAMNATION_DATE), //ログインでexamDateを取得しsetGlobalParamsを行う
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        clearGlobalError()

        await setApplicationInformation()
        // 講習日リスト初期化
        await setTrainingDayOptions()
        await handleGetScheduleSummary()
        await handleGetAchieveSummary()
        await getLabelArray()
      } catch (error) {
        handleError(error as Error)
      }
    })

    // Computed
    const formattedCourse = computed(() => {
      return `${state.applicationInfo?.courseName ?? ''}`
    })

    const formattedCourseList = computed(() =>
      state.applicationInfoList.map((item) => {
        return `${item.courseName ?? ''}`
      })
    )

    const startYearMonthOfPeriodYear = computed((): Date => {
      if (state.periodKbn !== 3) {
        return new Date()
      }

      return getYearMonthOfPeriodYear(state.examDate, state.targetDate, 11)
    })
    const endYearMonthOfPeriodYear = computed((): Date => {
      if (state.periodKbn !== 3) {
        return new Date()
      }
      return getYearMonthOfPeriodYear(state.examDate, state.targetDate, 0)
    })
    const getYearMonthOfPeriodYear = (
      examDate: string,
      targetDate: any,
      monthNum: number
    ) => {
      if (examDate) {
        return new Date(
          targetDate.getFullYear(),
          Number(examDate.substring(5, 7)) - monthNum,
          0
        )
      }
      return new Date(targetDate.getFullYear(), 11 - monthNum, 1)
    }

    const monthReportGraphHeight = computed(() => {
      let maxTotalAchieve = 0
      let totalTimePerWeek = 0
      state.userScheduleSummary.achievement.forEach((item) => {
        totalTimePerWeek = 0
        item.actual.forEach((element) => {
          totalTimePerWeek = totalTimePerWeek + element.value
        })
        maxTotalAchieve =
          totalTimePerWeek > maxTotalAchieve
            ? totalTimePerWeek
            : maxTotalAchieve
      })
      const maxHour = Math.ceil(maxTotalAchieve / 600) * 10
      return maxHour > 50 ? maxHour * 7.2 + 84 : 444
    })
    const yearReportGraphHeight = computed(() => {
      let maxTotalAchieve = 0
      let totalTimePerMonth = 0
      state.userScheduleSummary.achievement.forEach((item) => {
        totalTimePerMonth = 0
        item.actual.forEach((element) => {
          totalTimePerMonth = totalTimePerMonth + element.value
        })
        maxTotalAchieve =
          totalTimePerMonth > maxTotalAchieve
            ? totalTimePerMonth
            : maxTotalAchieve
      })
      const maxHour = Math.ceil(maxTotalAchieve / 6000) * 100
      return maxHour > 500 ? maxHour * 0.72 + 84 : 444
    })
    watch<SelectedLabelItem[]>(
      () => state.selectedItemArray,
      () => refreshTotal()
    )

    const refreshTotal = () => {
      let achievementSum = 0
      let scheduleSum = 0
      state.selectedItemArray.forEach((item) => {
        if (item.isAchieveChecked) {
          achievementSum = achievementSum + item.achieveTime
          scheduleSum = scheduleSum + item.scheduleTime
        }
      })
      state.totalTime.achievement.hour = Math.floor(achievementSum / 60)
      state.totalTime.achievement.minute = achievementSum % 60
      state.totalTime.schedule.hour = Math.floor(scheduleSum / 60)
      state.totalTime.schedule.minute = scheduleSum % 60
    }
    // Methods
    const getLabelArray = async () => {
      try {
        if (state.rabelClassification.length === 0) {
          state.rabelClassification = await getLabelOfKbnMaster()
        }
        const selectedItemArray = state.selectedItemArray
        state.selectedItemArray = []
        state.rabelClassification.forEach((item, index) => {
          if (item.name3 === '1') {
            state.selectedItemArray = [
              ...state.selectedItemArray,
              {
                label: item,
                isAchieveChecked: isAchieveChecked(
                  item.kbn as string,
                  selectedItemArray
                ),
                achieveTime: getAchieveTime(
                  item.kbn as string,
                  state.userScheduleSummary.achievement
                ),
                scheduleTime: getAchieveTime(
                  item.kbn as string,
                  state.userScheduleSummary.schedule
                ),
              } as SelectedLabelItem,
            ]
          }
        })
        state.refreshLabelSelector = !state.refreshLabelSelector
      } catch (error) {
        handleError(error as Error)
      }
    }
    const isAchieveChecked = (
      label: string,
      selectedItemArray: SelectedLabelItem[]
    ) => {
      if (selectedItemArray.length === 0) {
        return true
      }
      let ret = false
      for (const item of selectedItemArray) {
        if (label === item.label.kbn) {
          ret = item.isAchieveChecked
          break
        }
      }
      return ret
    }

    const getAchieveTime = (
      label: string,
      yojitsuList: UserScheduleSummary[]
    ): number => {
      let num = 0
      yojitsuList.forEach((item) => {
        item.actual.forEach((element) => {
          if (label === element.label) {
            num = num + element.value
          }
        })
      })
      return num
    }

    const setApplicationInformation = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInfoList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.applicationInfoList = applicationInfoList ?? []
        let settedApplicationStudent = {} as ApplicationInformation

        if (!props.applicationNo && !applicationNumber.value) {
          settedApplicationStudent = await queryApplicationInformation(
            state.userID
          )
        }

        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInfoList?.find(
          (item) =>
            (item.applicationNo === applicationNumber.value ||
              item.applicationNo === props.applicationNo) ??
            settedApplicationStudent.applicationNo
        )

        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.applicationInfo =
          settedApplicationInformation ?? applicationInfoList?.[0] ?? {}
      } catch (error) {
        handleError(error as Error)
      }
    }
    const handleGetAchieveSummary = async (): Promise<void> => {
      try {
        if (state.applicationInfo.applicationNo === undefined) {
          return
        }
        //実績サマリーを取得する
        let userAchieveSummary: UserScheduleSummary[] =
          await getUserScheduleSummary({
            userCode: state.userID,
            applicationNo: state.applicationInfo.applicationNo,
            startDate: getDate(state.targetDate),
            kbn: state.periodKbn,
            yojitsuFlg: false,
          })
        state.userScheduleSummary.achievement = [...(userAchieveSummary ?? [])]
      } catch (error) {
        handleError(error as Error)
      }
    }
    const handleGetScheduleSummary = async (): Promise<void> => {
      try {
        if (state.applicationInfo.applicationNo === undefined) {
          return
        }
        //予定サマリーを取得する
        let userScheduleSummary = await getUserScheduleSummary({
          userCode: state.userID,
          applicationNo: state.applicationInfo.applicationNo,
          startDate: getDate(state.targetDate),
          kbn: state.periodKbn,
          yojitsuFlg: true,
        })

        state.userScheduleSummary.schedule = [...(userScheduleSummary ?? [])]
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handlePrevWeek = async () => {
      state.targetDate = new Date(
        state.targetDate.getTime() - 7 * 24 * 60 * 60 * 1000
      )

      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    const handleNextWeek = async () => {
      state.targetDate = new Date(
        state.targetDate.getTime() + 7 * 24 * 60 * 60 * 1000
      )

      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }
    const handlePrevMonth = async () => {
      state.targetDate = new Date(
        state.targetDate.getFullYear(),
        state.targetDate.getMonth() - 1,
        1,
        0,
        0,
        0,
        0
      ) //先月の一日
      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    const handleNextMonth = async () => {
      state.targetDate = new Date(
        state.targetDate.getFullYear(),
        state.targetDate.getMonth() + 1,
        1,
        0,
        0,
        0,
        0
      ) //翌月の一日

      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    const handlePrevYear = async () => {
      state.targetDate = new Date(
        state.targetDate.getFullYear(),
        state.targetDate.getMonth() - 12,
        1
      )
      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    const handleNextYear = async () => {
      state.targetDate = new Date(
        state.targetDate.getFullYear(),
        state.targetDate.getMonth() + 12,
        1
      )
      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }
    const handleSelectCourse = async (index: number) => {
      state.applicationInfo = state.applicationInfoList[index]
      // 講習日リスト初期化
      await setTrainingDayOptions()
      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    const handleError = (error: Error | unknown | string): void => {
      console.log(error)
      setGlobalError(error as Error)
    }
    // Style
    const courseSelectBarStyle = {
      fontSize: '16px',
    }
    const periodOptionStyle = {
      color: 'rgb(51, 51, 51)',
    }

    const arrowStyle = {
      arrowFillcolor: '#ffffff',
    }
    const formattedPeriodList = ['週間', '月間', '年間']

    const setTrainingDayOptions = async (): Promise<void> => {
      try {
        const nearestTrainingDay = await queryTraningDayInitializeData({
          userID: state.userID,

          applicationNo:
            state.applicationInfo?.applicationNo ??
            props.applicationNo ??
            applicationNumber.value,
        })

        state.nearestTrainingDay = nearestTrainingDay ?? {}
        state.trainingDayList = nearestTrainingDay?.koshuDateInfoLst ?? []
        state.targetDate = getDateBaseToYyyyMmDd(
          nearestTrainingDay.koshuChokkinDt ?? ''
        )
        state.initTargetDateForWeek = state.targetDate
      } catch (error) {
        handleError(error as Error)
      }
    }
    const handleChangeSelectLabel = (input: SelectedLabelItem[]) => {
      state.selectedItemArray = [...input]
      state.refreshLabelSelector = !state.refreshLabelSelector
    }
    const handleSelectPeriod = async (index: number) => {
      state.periodKbn = index + 1
      if (state.periodKbn === 3) {
        const examinfo = await queryExaminationDay(state.userID)
        if (
          !examinfo?.examDate ||
          examinfo?.examDate === undefined ||
          examinfo.examDate === '1970-01-01'
        ) {
          var today = new Date()
          var year = today.getFullYear()
          var month = today.getMonth() + 1
          var day = today.getDate()
          state.examDate = year + '-' + month + '-' + day
        } else {
          state.examDate = examinfo.examDate
        }
        state.targetDate = new Date(state.examDate)
      }
      if (state.periodKbn === 2) {
        state.targetDate = new Date()
      }
      if (state.periodKbn === 1) {
        state.targetDate = state.initTargetDateForWeek
      }
      await handleGetScheduleSummary()
      await handleGetAchieveSummary()
      await getLabelArray()
    }

    return {
      ...toRefs(state),
      courseSelectBarStyle,
      periodOptionStyle,
      arrowStyle,
      mainContainerStyle,
      manageMainContainerStyle,
      formattedPeriodList,
      formattedCourse,
      formattedCourseList,
      handleSelectCourse,
      handlePrevWeek,
      handleNextWeek,
      handlePrevMonth,
      handleNextMonth,
      handleChangeSelectLabel,
      handleSelectPeriod,
      monthReportGraphHeight,
      yearReportGraphHeight,
      startYearMonthOfPeriodYear,
      endYearMonthOfPeriodYear,
      handlePrevYear,
      handleNextYear,
    }
  },

  components: {
    AllRoleContainer,
    SubHeader,
    BaseSelector,
    LabelSelector,
    WeekReportGraph,
    MonthReportGraph,
    YearReportGraph,
    PeriodSelector,
    PeriodWeek,
    PeriodMonth,
    PeriodYear,
  },
})
