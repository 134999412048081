import {
  queryBranchStoreList,
  queryClassroomList,
  queryCourseGroupList,
} from '@/request/api'

import { getAccessTokenFromSession } from '@/services/ControlToken'
import {
  getRoleIDFromAccessToken,
  getUserIDFromAccessToken,
} from '@/services/UserInformation'
import {
  BranchStore,
  Certification,
  Classroom,
  CourseGroup,
  StudentInformation,
} from '@/typings'
import { ActionHandler, Commit, Dispatch, Getter, Mutation } from 'vuex'
import {
  SET_GRADE_CERTIFICATION_LIST,
  SET_GRADE_ATTEND_FISCAL_YEAR,
  SET_GRADE_CERTIFICATION,
  SET_GRADE_COURSE_GROUP_LIST,
  SET_GRADE_COURSE_GROUP,
  SET_GRADE_BRANCH_STORE_LIST,
  SET_GRADE_BRANCH_STORE,
  SET_GRADE_CLASSROOM,
  SET_GRADE_CLASSROOM_LIST,
  SET_GRADE_PERSONAL_NUMBER,
  SET_GRADE_ATTEND_ID,
  SET_GRADE_FULL_NAME_KATAKANA,
  SET_GRADE_FULL_NAME,
  SET_GRADE_SEARCH_RESULT_TABLE,
} from './actionTypes'

export interface InputItem {
  value: string
  error: string
}

export interface State {
  attendFiscalYear: string
  certificationList: Certification[]
  certification: Certification
  courseGroupList: CourseGroup[]
  courseGroup: CourseGroup
  branchStoreList: BranchStore[]
  branchStore: BranchStore
  classroomList: Classroom[]
  classroom: Classroom
  personalNumber: InputItem
  attendID: InputItem
  fullNameKatakana: InputItem
  fullName: InputItem
  searchResultTable: StudentInformation[]
}

const state: State = {
  attendFiscalYear: '',
  certificationList: [],
  certification: { name: '', kbn: '' },
  courseGroupList: [],
  courseGroup: { courseGroupCd: '', courseGroupNm: '' },
  branchStoreList: [],
  branchStore: { branchCd: '', branchNm: '' },
  classroomList: [],
  classroom: { classroomCd: '', classroomNm: '' },
  personalNumber: { value: '', error: '' },
  attendID: { value: '', error: '' },
  fullNameKatakana: { value: '', error: '' },
  fullName: { value: '', error: '' },
  searchResultTable: [],
}

const getters: { [types: string]: Getter<State, any> } = {
  certificationListGradeForDisplay: (_state: State): Certification[] => {
    return [{ name: '', kbn: '' }, ..._state.certificationList]
  },

  certificationGradeForDisplay: (_state: State): Certification => {
    return _state.certification
  },

  courseGroupListGradeForDisplay: (_state: State): CourseGroup[] => {
    return [{ courseGroupCd: '', courseGroupNm: '' }, ..._state.courseGroupList]
  },

  courseGroupGradeForDisplay: (_state: State): CourseGroup => {
    return _state.courseGroup
  },

  branchStoreListGradeForDisplay: (_state: State): BranchStore[] => {
    return [{ branchCd: '', branchNm: '' }, ..._state.branchStoreList]
  },

  branchStoreGradeForDisplay: (_state: State): BranchStore => {
    return _state.branchStore
  },

  classroomListGradeForDisplay: (_state: State): Classroom[] => {
    return [{ classroomCd: '', classroomNm: '' }, ..._state.classroomList]
  },

  classroomGradeForDisplay: (_state: State): Classroom => {
    return _state.classroom
  },
  personalNumber: (_state: State): string => {
    return _state.personalNumber.value
  },
  personalNumberError: (_state: State): string => {
    return _state.personalNumber.error
  },
  attendID: (_state: State): string => {
    return _state.attendID.value
  },
  attendIDError: (_state: State): string => {
    return _state.attendID.error
  },
  fullNameKatakana: (_state: State): string => {
    return _state.fullNameKatakana.value
  },
  fullNameKatakanaError: (_state: State): string => {
    return _state.fullNameKatakana.error
  },
  fullName: (_state: State): string => {
    return _state.fullName.value
  },
  fullNameError: (_state: State): string => {
    return _state.fullName.error
  },
  searchResultTableForDisplay: (_state: State): StudentInformation[] => {
    return _state.searchResultTable
  },
}

interface StoreContent {
  commit: Commit
  state: State
  dispatch: Dispatch
}

const actions: { [type: string]: ActionHandler<State, any> } = {
  [SET_GRADE_ATTEND_FISCAL_YEAR]: (
    { commit }: StoreContent,
    year: string
  ): void => {
    commit(SET_GRADE_ATTEND_FISCAL_YEAR, year)
  },

  [SET_GRADE_CERTIFICATION_LIST]: (
    { commit }: StoreContent,
    list: Certification[]
  ) => {
    commit(SET_GRADE_CERTIFICATION_LIST, list)
  },

  [SET_GRADE_CERTIFICATION]: (
    { commit, state }: StoreContent,
    name: string
  ) => {
    const target: Certification = state.certificationList.find(
      (item: Certification): boolean => item.name === name
    ) ?? { name: '', kbn: '' }

    commit(SET_GRADE_CERTIFICATION, target)
  },

  [SET_GRADE_COURSE_GROUP_LIST]: async ({
    commit,
    state,
  }: StoreContent): Promise<void> => {
    const list: CourseGroup[] = await queryCourseGroupList(
      state.attendFiscalYear,
      state.certification.kbn ?? ''
    )
    commit(SET_GRADE_COURSE_GROUP_LIST, list)
  },

  [SET_GRADE_COURSE_GROUP]: async (
    { commit, state, dispatch }: StoreContent,
    name: CourseGroup
  ): Promise<void> => {
    // 選択済みの支店をリセット
    await dispatch(SET_GRADE_BRANCH_STORE, '')

    // 選択したコースグループをリストから探し出す
    const target: CourseGroup = state.courseGroupList.find(
      (item: CourseGroup): boolean => item.courseGroupNm === name
    ) ?? { courseGroupCd: '', courseGroupNm: '' }

    // mutation を呼出して state を更新する
    commit(SET_GRADE_COURSE_GROUP, target)

    // 選択済みのコースグループが存在する時にのみ支店の選択肢を再取得
    if (target.courseGroupNm) {
      await dispatch(SET_GRADE_BRANCH_STORE_LIST)
    }
  },

  [SET_GRADE_BRANCH_STORE_LIST]: async ({
    commit,
  }: StoreContent): Promise<void> => {
    const token: string = getAccessTokenFromSession()
    const userType = getRoleIDFromAccessToken(token)
    const userID: string = getUserIDFromAccessToken(token)

    const list: BranchStore[] = await queryBranchStoreList(userType, userID)
    commit(SET_GRADE_BRANCH_STORE_LIST, list)
  },

  [SET_GRADE_BRANCH_STORE]: async (
    { commit, state, dispatch }: StoreContent,
    name: BranchStore
  ): Promise<void> => {
    dispatch(SET_GRADE_CLASSROOM, '')

    const target: BranchStore = state.branchStoreList.find(
      (item: BranchStore): boolean => item.branchNm === name
    ) ?? { branchCd: '', branchNm: '' }

    commit(SET_GRADE_BRANCH_STORE, target)

    if (target.branchNm) {
      await dispatch(SET_GRADE_CLASSROOM_LIST)
    }
  },

  [SET_GRADE_CLASSROOM_LIST]: async ({
    commit,
    state,
  }: StoreContent): Promise<void> => {
    const list: Classroom[] = await queryClassroomList({
      attendFiscalYear: state.attendFiscalYear,
      certificationCode: state.certification.kbn ?? '',
      courseGroupCode: state.courseGroup.courseGroupCd ?? '',
      branchStoreCode: state.branchStore.branchCd ?? '',
    })

    commit(SET_GRADE_CLASSROOM_LIST, list)
  },

  [SET_GRADE_CLASSROOM]: (
    { commit, state }: StoreContent,
    name: Classroom
  ): void => {
    const target: Classroom = state.classroomList.find(
      (item: Classroom): boolean => item.classroomNm === name
    ) ?? { classroomCd: '', classroomNm: '' }

    commit(SET_GRADE_CLASSROOM, target)
  },

  [SET_GRADE_PERSONAL_NUMBER]: (
    { commit }: StoreContent,
    input: InputItem
  ): void => {
    commit(SET_GRADE_PERSONAL_NUMBER, input)
  },

  [SET_GRADE_ATTEND_ID]: ({ commit }: StoreContent, input: InputItem): void => {
    commit(SET_GRADE_ATTEND_ID, input)
  },

  [SET_GRADE_FULL_NAME_KATAKANA]: (
    { commit }: StoreContent,
    input: InputItem
  ): void => {
    commit(SET_GRADE_FULL_NAME_KATAKANA, input)
  },

  [SET_GRADE_FULL_NAME]: ({ commit }: StoreContent, input: InputItem): void => {
    commit(SET_GRADE_FULL_NAME, input)
  },

  [SET_GRADE_SEARCH_RESULT_TABLE]: (
    { commit }: StoreContent,
    result: StudentInformation[]
  ): void => {
    commit(SET_GRADE_SEARCH_RESULT_TABLE, result)
  },
}

const mutations: { [type: string]: Mutation<State> } = {
  [SET_GRADE_ATTEND_FISCAL_YEAR]: (_state: State, year: string): void => {
    _state.attendFiscalYear = year
  },

  [SET_GRADE_CERTIFICATION_LIST]: (
    _state: State,
    list: Certification[]
  ): void => {
    _state.certificationList = [...list]
  },

  [SET_GRADE_CERTIFICATION]: (
    _state: State,
    certification: Certification
  ): void => {
    _state.certification = { ...certification }
  },

  [SET_GRADE_COURSE_GROUP_LIST]: (
    _state: State,
    list: CourseGroup[]
  ): void => {
    _state.courseGroupList = [...list]
  },

  [SET_GRADE_COURSE_GROUP]: (
    _state: State,
    courseGroup: CourseGroup
  ): void => {
    _state.courseGroup = { ...courseGroup }
  },

  [SET_GRADE_BRANCH_STORE_LIST]: (
    _state: State,
    list: BranchStore[]
  ): void => {
    _state.branchStoreList = [...list]
  },

  [SET_GRADE_BRANCH_STORE]: (
    _state: State,
    branchStore: BranchStore
  ): void => {
    _state.branchStore = { ...branchStore }
  },

  [SET_GRADE_CLASSROOM_LIST]: (_state: State, list: Classroom[]): void => {
    _state.classroomList = [...list]
  },

  [SET_GRADE_CLASSROOM]: (_state: State, classroom: Classroom): void => {
    _state.classroom = { ...classroom }
  },

  [SET_GRADE_PERSONAL_NUMBER]: (_state: State, input: InputItem): void => {
    _state.personalNumber = { ...input }
  },

  [SET_GRADE_ATTEND_ID]: (_state: State, input: InputItem): void => {
    _state.attendID = { ...input }
  },

  [SET_GRADE_FULL_NAME_KATAKANA]: (
    _state: State,
    input: InputItem
  ): void => {
    _state.fullNameKatakana = { ...input }
  },

  [SET_GRADE_FULL_NAME]: (_state: State, input: InputItem): void => {
    _state.fullName = { ...input }
  },

  [SET_GRADE_SEARCH_RESULT_TABLE]: (
    _state: State,
    result: StudentInformation[]
  ): void => {
    _state.searchResultTable = [...result]
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
