
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SwitchDetailButton',

  props: {
    displayLeftButton: {
      type: Boolean,
      default: true,
    },

    displayRightButton: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const handleClickLeft = (): void => emit('clickLeft')
    const handleClickRight = (): void => emit('clickRight')

    return { handleClickLeft, handleClickRight }
  },
})
