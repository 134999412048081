
// Components
import NavigationButton from '@/components/common/NavigationButton.vue'
import Logout from '@/components/common/Logout.vue'
// Icons
import IconHome from '@/components/icons/IconHome.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import IconLibrary from '@/components/icons/IconLibrary.vue'
import IconLive from '@/components/icons/IconLive02.vue'
import IconDiary from '@/components/icons/IconDiary.vue'
import IconMyPage from '@/components/icons/IconMyPage.vue'
import IconGrade from '@/components/icons/IconGrade.vue'
import IconPassDiary from '@/components/icons/IconPassDiary.vue'
import IconHomePresent from '@/components/icons/IconHomePresent.vue'
import IconInformationPresent from '@/components/icons/IconInformationPresent.vue'
import IconLivePresent from '@/components/icons/IconLivePresent.vue'
import IconMyPagePresent from '@/components/icons/IconMyPagePresent.vue'
import IconGradePresent from '@/components/icons/IconGradePresent.vue'

import { setGlobalParams, EXAMNATION_DATE } from '@/services/GlobleParames'
// Dependencies
import {
  defineComponent,
  watch,
  computed,
  reactive,
  toRefs,
  onBeforeMount,
} from 'vue'
import { queryExaminationDay } from '@/request/api'
import { countUnreadMessage } from '@/request/api/information-message/message'
import { useStore } from '@/store'
import { useRouter } from '@/router'
import { useGlobalError, UseOnDemand, useOnDemand } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { logout } from '@/services/ControlLogin'
import {
  FORM_TARGET,
  HTTP_METHOD,
  ONE_DAY_MILLISECOND,
  ON_DEMAND_PARAMETERS_KEY_SEND,
} from '@/config'
import { NavigationButtonInterface } from '@/typings'
import { transferInSynchronous, removeSelectTabAndPullDownState } from '@/Utils'
import { titles } from './common'


// Vue インスタンス
export default defineComponent({
  components: {
    NavigationButton,
    Logout,
    IconHome,
    IconInformation,
    IconLibrary,
    IconLive,
    IconDiary,
    IconMyPage,
    IconGrade,
    IconPassDiary,
  },
  props: {
    refreshNotReadNumber: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      userID: '',
      score: 0,
      days: '-' as string | number,
      notReadNumber: 0,
      refreshButtons: 0,
    })
    const handleTransferScreen = (button: NavigationButtonInterface): void => {
      setPresentTab(button.title)

      // 外部サイトへ遷移
      if (button.toOtherSite) {
        button.transferMethod?.()
        return
      }
      if (button.routePath === '/GradeData') {
        removeSelectTabAndPullDownState()
      }
      // 内部で遷移
      router.push(button.routePath as string, () => {
        // 遷移後に何もしない
      })
    }
    const presentTab = computed<string>(() => {
      return `${!store.state.presentTab ? '' : store.state.presentTab}`
    })

    const setPresentTab = (title: string) =>
      store?.commit('setPresentTab', title)

    const transferOnDemand = (): void => {
      const ssoParameters = store.state.onDemand.ssoParameters

      setOnDemandParametersToStorage({
        id: ssoParameters.id,
        timestamp: ssoParameters.ts,
      })

      const data = new Map([
        [ON_DEMAND_PARAMETERS_KEY_SEND.ID, ssoParameters.id],
        [ON_DEMAND_PARAMETERS_KEY_SEND.TIMESTAMP, ssoParameters.ts],
      ])

      transferInSynchronous({
        data,
        path: process.env.VUE_APP_ON_DEMAND_DOMAIN,
        method: HTTP_METHOD.POST,
        target: FORM_TARGET.BLANK,
      })
    }

    const navigationList = computed<NavigationButtonInterface[]>(() => {
      return [
        {
          component:
            presentTab.value === titles.home ? IconHomePresent : IconHome,
          title: titles.home,
          routePath: '/',
        },
        {
          component:
            presentTab.value === titles.information
              ? IconInformationPresent
              : IconInformation,
          title: titles.information,
          routePath: '/InformationMessageList',
          notReadNumber: state.notReadNumber,
        },
        {
          component:
            presentTab.value === titles.grade ? IconGradePresent : IconGrade,
          title: titles.grade,
          routePath: '/GradeData',
        },

        {
          component:
            presentTab.value === titles.eLearning ? IconLivePresent : IconLive,
          title: titles.eLearning,
          toOtherSite: true,
          transferMethod: transferOnDemand,
          isToNewTab: true,
        },

        {
          component:
            presentTab.value === titles.myPage ? IconMyPagePresent : IconMyPage,
          title: titles.myPage,
          routePath: '/AccountInformationRegist',
        },
      ]
    })

    const router = useRouter()
    const store = useStore()
    const { setGlobalError } = useGlobalError()

    const {
      setOnDemandSSOParameters,
      setOnDemandParametersToStorage,
    }: UseOnDemand = useOnDemand()

    // Computed
    const headerRefreshmentSwitch = computed(
      () => store.state.headerRefreshmentSwitch
    )

    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    // Lifecycle
    onBeforeMount(async () => {
      state.userID = getUserID()
      await setExaminationDate(state.userID)
      await getNotRead()
      await setOnDemandSSOParameters(state.userID)
      emit('handleheaderMounted')
    })

    // Watch
    watch(
      () => headerRefreshmentSwitch.value,

      async () => {
        state.userID = getUserID()
        await setExaminationDate(state.userID)
      }
    )

    // Methods
    const getUserID = (): string => {
      return getUserIDFromAccessToken(getAccessTokenFromSession())
    }

    const setExaminationDate = async (userID: string): Promise<void> => {
      try {
        const { examDate } = await queryExaminationDay(userID)

        if (!examDate) {
          return
        }
        setGlobalParams(EXAMNATION_DATE, examDate)
        const today = new Date()
        const examinationDateMillisecond = new Date(examDate).getTime()
        const todayMillisecond = today.getTime()
        const leftDays = examinationDateMillisecond - todayMillisecond

        if (leftDays > 0) {
          state.days = Math.ceil(leftDays / ONE_DAY_MILLISECOND)
        }
      } catch (error) {
        setGlobalError(error as Error)
      }
    }
    const getNotRead = async (): Promise<void> => {
      try {
        const count: number = await countUnreadMessage(state.userID)
        state.notReadNumber = count ?? 0
        state.refreshButtons++
      } catch (error) {
        setGlobalError(error as Error)
      }
    }
    return {
      ...toRefs(state),
      navigationList,
      isUserFromAPP,
      logout,
      handleTransferScreen,
    }
  },
})
