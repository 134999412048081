
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseTextBoxWithLabel',

  props: {
    placeholder: String,
    textBoxID: String,
    value: String,
    inputStyle: Object,
    error: String,
    errorStyle: Object,
  },

  setup(props, { emit }) {
    const handleInput = (event: InputEvent): void => {
      emit('input', (event.target as HTMLInputElement).value)
    }

    return { handleInput }
  },
})
