
import SelectionBox from '@/components/views/GradeDataSearch/SelectionBox.vue'
import { useStore } from '@/store'
import {
  SET_BRANCH_STORE,
  SET_CLASSROOM,
  SET_COURSE_GROUP,
  SET_COURSE_GROUP_LIST,
} from '@/store/modules/search-condition/actionTypes'
import { BranchStore, Certification, Classroom } from '@/typings'

import { computed, defineComponent, watch } from 'vue'
import { Store } from 'vuex'

export default defineComponent({
  name: 'CourseGroupSelector',

  setup() {
    const store: Store<any> = useStore()

    // コースグループ
    const courseGroupActived = computed<boolean>(
      (): boolean => store.getters.courseGroupActived
    )

    const courseGroupList = computed<string[]>(
      (): string[] => store.getters.courseGroupListForDisplay
    )

    const setCourseGroupList = async (): Promise<void> => {
      await store.dispatch(SET_COURSE_GROUP_LIST)
    }

    const courseGroup = computed<string>(
      (): string => store.getters.courseGroupForDisplay
    )

    const setCourseGropu = async (name: string): Promise<void> => {
      await store.dispatch(SET_COURSE_GROUP, name)
    }

    const attendFiscalYear = computed<string>(
      (): string => store.state.searchCondition.attendFiscalYear
    )

    const certification = computed<Certification>(
      (): Certification => store.state.searchCondition.certification
    )

    // 受講年度、受講種別両方選択済みの際にコースグループの選択肢を取得
    watch<string[]>(
      () => [attendFiscalYear.value, certification.value.name as string],

      async ([newYear, newName]): Promise<void> => {
        await setCourseGropu('')

        if (newYear && newName) {
          await setCourseGroupList()
        }
      }
    )

    // 支店
    const branchStoreActived = computed<boolean>(
      (): boolean => store.getters.branchStoreActived
    )

    const branchStoreList = computed<BranchStore[]>(
      (): BranchStore[] => store.getters.branchStoreListForDisplay
    )

    const branchStore = computed<string>(
      (): string => store.getters.branchStoreForDisplay
    )

    const setBranchStore = async (name: string): Promise<void> => {
      await store.dispatch(SET_BRANCH_STORE, name)
    }

    // 教室
    const classroomActived = computed<boolean>(
      (): boolean => store.getters.classroomActived
    )

    const classroomList = computed<Classroom[]>(
      (): Classroom[] => store.getters.classroomListForDisplay
    )

    const classroom = computed<Classroom>(
      (): Classroom => store.getters.classroomForDisplay
    )

    const setClassroom = (name: string) => {
      store.dispatch(SET_CLASSROOM, name)
    }

    return {
      courseGroupActived,
      courseGroupList,
      courseGroup,
      setCourseGropu,
      branchStoreActived,
      branchStoreList,
      branchStore,
      setBranchStore,
      classroomActived,
      classroomList,
      classroom,
      setClassroom,
    }
  },

  components: { SelectionBox },
})
