
/**
 *  メッセージ受信一覧画面 - メッセージカード
 */
// Components
import IconBubble from '@/components/icons/IconBubble.vue'
import IconMail from '@/components/icons/IconMail.vue'
import IconClip from '@/components/icons/IconClip.vue'
import { useRouter } from '@/router'

// Dependencies
import { RecievedMessageModel } from '@/request/api/information-message/model/message'
import { computed, defineComponent, PropType } from 'vue'
import { setInformationMessageItemToStorage } from '@/services/InformationMessage'

export default defineComponent({
  components: { IconBubble, IconClip, IconMail },
  name: 'MessageCard',

  props: {
    message: {
      type: Object as PropType<RecievedMessageModel>,
      required: true,
    },
  },

  setup(props) {
    const timestamp = computed<string>(() => {
      return props?.message?.sendTimestamp
    })
    console.log("message:" + JSON.stringify(props.message))
    const router = useRouter()

    const handleMoveToDetailPage = (): void => {
      setInformationMessageItemToStorage(props.message)
      router.push({ name: 'InformationMessageDetail' })
    }

    return { timestamp, handleMoveToDetailPage }
  },
})
