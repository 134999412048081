/**
 * インフォメーションメッセージ - 受講生情報
 */
// Dependencies
import { StudentInformation } from '@/typings'

// 受講生情報のセッションストレージでのキー名
const STUDENT_INFORMATION_LIST_KEY = 'studentInformationList'

/**
 * 受講生情報をセッションストレージに保存
 * @param data 受講生情報リスト
 */
export const setStudentInformationListToStorage = (
  data: StudentInformation[]
): void => {
  sessionStorage.setItem(STUDENT_INFORMATION_LIST_KEY, JSON.stringify(data))
}

/**
 * セッションストレージから受講生情報を取得
 * @returns 受講生情報リスト
 */
export const getStudentInformationListFromStorage = (): StudentInformation[] => {
  const jsonData = sessionStorage.getItem(STUDENT_INFORMATION_LIST_KEY)

  return JSON.parse(jsonData ?? '[]')
}

/**
 * セッションストレージから受講生情報を削除
 */
export const removeStudentInformationListFromStorage = (): void => {
  sessionStorage.removeItem(STUDENT_INFORMATION_LIST_KEY)
}
