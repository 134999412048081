import { requestBFF } from '@/request'
import { getMessage } from '@/Utils'
import {
  PassDiaryScheduleSummaryModel,
  QueryPassDiaryScheduleSummaryParameters,
} from './models/summaryInformation'
import { useGlobalError } from '@/services/Hooks'
const { setGlobalError, clearGlobalError } = useGlobalError()

/**
 * @description 合格ダイアリースタッフ用集計データを取得
 * @param parameters
 * @returns
 */
export const queryPassDiaryScheduleSummary = async (
  parameters: QueryPassDiaryScheduleSummaryParameters
): Promise<PassDiaryScheduleSummaryModel> => {
  try {
    clearGlobalError()

    const query = `
    query {
      getScheduleSummaryForStaff(
        input: {
          jukoFiscalYear: "${parameters.jukoFiscalYear}",
          jukoClassCd: "${parameters.jukoClassCd}",
          branchCd: "${parameters.branchCd}",
          mainClassroomCd: ${JSON.stringify(parameters.mainClassroomCd)},
          startDate: "${parameters.startDate}",
          endDate: "${parameters.endDate}",
          label: "${parameters.label}"
        }
      ) {
        mainClassroomCd
        mainClassroomName
        users
        max
        min
        avg
      }
    } 
  `

    const { data } = await requestBFF(query)

    if (!data.data.getScheduleSummaryForStaff?.length) {
      return Promise.reject(new Error(getMessage('EFR0017')))
    } else {
      return data.data.getScheduleSummaryForStaff
    }
  } catch (error) {
    setGlobalError(error)
    return []
  }
}
