
/**
 * 支店一覧画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import BranchListTable from '@/components/views/InformationBranchList/BranchListTable.vue'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { InformationBranchItem, CountMessage } from '@/typings'
import BaseSelector from '@/components/common/BaseSelector.vue'
import { mainContainerStyle } from './style'
import { queryInformationBranchList, queryBranchStoreList } from '@/request/api'
import { useRouter } from '@/router'
import { getRoleIdFromJwt } from '@/Utils'
import { BranchStore } from '@/typings/index'
import {
  setGlobalParams,
  BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS,
} from '@/services/GlobleParames'
// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'

interface State {
  userID: string
  branchList: CountMessage[]
  branchCdList: string[]
  branchListShow: InformationBranchItem[]
  selectedDate: string
  yearMonthList: string[]
  refreshBranchList: number
}

export default defineComponent({
  name: 'BranchList',
  setup() {
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      branchList: [],
      branchCdList: [],
      selectedDate: '',
      branchListShow: [],
      yearMonthList: [],
      refreshBranchList: 0,
    })

    onBeforeMount(async () => {
      getYearMonthList()
      //BranchList取得
      getBranchListForShow()
    })
    const getYearMonthList = () => {
      var day = new Date()
      for (var i = 0; i < 6; i++) {
        const month =
          day.getMonth() - i < 0
            ? day.getMonth() - i + 13
            : day.getMonth() - i + 1
        const months = month < 10 ? '0' + month : month
        var year =
          day.getMonth() - i < 0 ? day.getFullYear() - 1 : day.getFullYear()
        var ym = year.toString() + '-' + months.toString()
        state.yearMonthList = [...state.yearMonthList, ym]
      }
      state.selectedDate = state.yearMonthList[0]
    }

    const getBranchListForShow = async () => {
      const accessToken = getAccessTokenFromSession()
      const userType = getRoleIdFromJwt(accessToken) ?? ''
      const userID = getUserIDFromAccessToken(accessToken)
      const allBranchs =
        (await queryBranchStoreList(userType, userID)) ?? ([] as BranchStore[])
      var branchCdList = [] as string[]
      allBranchs.forEach((item) => {
        branchCdList = [...branchCdList, item.branchCd as string]
      })
      const branchList = await queryInformationBranchList(
        branchCdList,
        state.selectedDate + '-01'
      )
      state.branchListShow = []
      branchList.forEach((item) => {
        const informationBranchItem = {} as InformationBranchItem
        informationBranchItem.branchCd = item.userId
        informationBranchItem.messageNumberThisMonth = item.count
        informationBranchItem.branchName = allBranchs.find(
          (branch) => item.userId === branch.branchCd
        )?.branchNm as string
        state.branchListShow = [...state.branchListShow, informationBranchItem]
      })
      state.refreshBranchList++
    }
    const handleSelectDate = (item: number) => {
      state.selectedDate = state.yearMonthList[item]
      getBranchListForShow()
      state.refreshBranchList++
    }
    const router = useRouter()
    const handleTransferToDetail = (branch: InformationBranchItem) => {
      const params = {
        branch: JSON.stringify(branch),
        yearMonth: state.selectedDate,
      }
      setGlobalParams(BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS, params)
      router.push({
        name: 'BranchSentMessageHistoryList',
        params: params,
      })
    }
    const yearMonthSelectBarStyle = {
      background: '#f3f3f3',
      border: 0,
      'max-width': '600px',
      margin: '1rem auto 0 auto',
    }
    return {
      ...toRefs(state),
      mainContainerStyle,
      yearMonthSelectBarStyle,
      handleTransferToDetail,
      handleSelectDate,
    }
  },

  components: { ManagePageContainer, BranchListTable, BaseSelector },
})
