
/**
 * インフォメーション - 送信履歴詳細部品
 */

// Components
import MessageWrapper from './MessageDetailItemWrapper.vue'
import LabelAfterCheckbox from '@/components/common/checkbox/LabelAfterCheckbox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import { PDFJS_CONFIG } from '@/config/pdfjsConfig'
// Dependencies
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  computed,
  watch,
} from 'vue'
import { MessageSentHistoryItemModel } from '@/request/api/information-message/model/sentHistory'
import {
  VIEW_LIST_OF_NOT_READ,
  VIEW_LIST_OF_READ,
  VIEW_REPLY_LIST,
  VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM,
} from '@/router/routesName'
import { useRouter } from '@/router'
import {
  setGlobalParams,
  RECEIVE_LIST_PARAMS,
  REPLY_LIST_PARAMS,
  HISTORY_MESSAGE_DETAIL,
  DELETE_SENT_MESSAGE,
  getGlobalParams,
} from '@/services/GlobleParames'
import { downloadFile } from '@/request/api'
import { stringify } from 'crypto-js/enc-base64'
import {
  getMessage,
} from '@/Utils'
import { useStore } from '@/store'
import { CURRENT_MESSAGE_INFORMATION } from '@/views/ReplyList/index.vue'
const SENT_MESSAGE_DETAIL_DELETE_CONFIRM_KEY = 'SentMessageDetailDeleteConfirm'

export default defineComponent({
  name: 'SentMessageDetail',

  props: {
    messageDetail: {
      type: Object as PropType<MessageSentHistoryItemModel>,
      require: true,
    },

    readRate: {
      type: String,
      required: true,
    },

    destinationList: {
      type: Array as PropType<string[]>,
      required: true,
    },

    fileIDList: {
      type: Array as PropType<string[]>,
    },
  },

  setup(props) {
    const state = reactive({
      message: props.messageDetail,
      attachmentList: [] as string[],
      refreshPdf: [] as boolean[],
      isDeleteMessageButton: false,
      imgWidth: '100%' as string,
      sizeValue: '100' as string,
      uploadFileError: "",
    })
    const store = useStore()
    onBeforeMount(() => {
      if (state.message) {
        setGlobalParams(HISTORY_MESSAGE_DETAIL, state.message)
      } else {
        state.message = getGlobalParams(HISTORY_MESSAGE_DETAIL)
      }
      state.isDeleteMessageButton =
        getGlobalParams(DELETE_SENT_MESSAGE) ?? false

      state.attachmentList = state.message?.attachmentList ?? []
    })

    const router = useRouter()
    const resetKey = ref(0)
    const reset = (): void => {
      resetKey.value++
    }

    watch(
      () => state.imgWidth,
      () => reset()
    )

    const goToUnreadList = (): void => {
      const parms = {
        sender: state.message?.sender as string,
        messageNo: state.message?.messageNo as string,
      }

      setGlobalParams(RECEIVE_LIST_PARAMS, parms)
      router.push({ name: VIEW_LIST_OF_NOT_READ, params: parms })
    }

    const goToReadList = (): void => {
      const parms = {
        sender: state.message?.sender as string,
        messageNo: state.message?.messageNo as string,
      }

      setGlobalParams(RECEIVE_LIST_PARAMS, parms)
      router.push({ name: VIEW_LIST_OF_READ, params: parms })
    }

    const goToAnswerList = (): void => {
      const parms = {
        sender: state.message?.sender as string,
        messageNo: state.message?.messageNo as string,
      }

      sessionStorage.setItem(CURRENT_MESSAGE_INFORMATION, JSON.stringify(parms))
      setGlobalParams(REPLY_LIST_PARAMS, parms)
      router.push({ name: VIEW_REPLY_LIST, params: parms })
    }

    const confirm = (): void => {
      router.push({ name: VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM })
    }

    // 宛先
    const computedDestinationList = computed<string>(
      (): string => props.destinationList.join(', ') ?? ''
    )

    const destinationTotal = computed<number>(() => {
      return props.destinationList.length
    })

    // 添付ファイル
    const fileList = ref<string[]>([])

    onBeforeMount(async () => {
      if (
        !props.fileIDList?.length ||
        typeof props.fileIDList === 'undefined'
      ) {
        return
      }
      var i = 0
      store?.commit('setIsLoadingWithText', true)
      store?.commit('setIsLoadingText', "ダウンロード中...")
      for await (let fileID of props.fileIDList) {
        const itemurl = await downloadFile(fileID)
        if (!itemurl) {
          store?.commit('setIsLoadingWithText', false)
          state.uploadFileError = getMessage('EFR0041')
          throw new Error(getMessage('EFR0041'))
        }
        fileList.value = [...fileList.value, itemurl]
        state.refreshPdf[i] = !state.refreshPdf[i]
        i++
      }
      store?.commit('setIsLoadingWithText', false)
    })

    const autoLink = (text: string): string => {
      return text
        ? text.replace(
          /(https?:\/\/[^\s]*)/g,
          "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>"
        )
        : ''
    }

    const changeImgSize = (size: string): void => {
      state.imgWidth = size + '%'
    }

    const sizeDown = (): void => {
      const number = Number(state.sizeValue)
      state.sizeValue = String(number - 10)
      changeImgSize(state.sizeValue)
    }

    const sizeUp = (): void => {
      const number = Number(state.sizeValue)
      state.sizeValue = String(number + 10)
      changeImgSize(state.sizeValue)
    }

    return {
      ...toRefs(state),
      goToUnreadList,
      goToReadList,
      goToAnswerList,
      fileList,
      computedDestinationList,
      destinationTotal,
      confirm,
      PDFJS_CONFIG,
      autoLink,
      changeImgSize,
      resetKey,
      sizeDown,
      sizeUp,
    }
  },

  components: { MessageWrapper, LabelAfterCheckbox, BaseButton, VuePdfApp },
})
