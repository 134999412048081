
import { PassDiaryScheduleSummaryModel } from '@/request/api/pass-diary/models/summaryInformation'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ClassroomDetail',

  props: {
    list: {
      type: Array as PropType<PassDiaryScheduleSummaryModel>,
      required: true,
    },
  },

  methods: {
    timeResultCalc: function (num: number) {
      return Math.floor((num / 60) * Math.pow(10, 2)) / Math.pow(10, 2)
    },
  },
})
