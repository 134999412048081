
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'

const REM_LENGTH = 16 // 1rem あたりの px 数
const PADDING_WIDTH = REM_LENGTH * 2 // padding の幅
const WRAP_TAG = '<br />' // 改行のタグ

export default defineComponent({
  setup() {
    const store: Store<any> = useStore()
    const clientWidth = ref<number>(document.body.clientWidth)
    const error = ref<string>('')
    const globalError = computed<string>(() => store.state.globalError)
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    onMounted(() => {
      window.onresize = () => (clientWidth.value = document.body.clientWidth)
      handleError(globalError.value)
    })

    watch(
      () => clientWidth.value,

      (newValue) => {
        if (!error.value) {
          return
        }

        // メッセージの長さが画面の幅を超えた時に改行する
        const isErrorLonger: boolean =
          error.value.length * REM_LENGTH + PADDING_WIDTH > newValue

        const isSettedNewLine: boolean = error.value.includes(WRAP_TAG)

        if (isErrorLonger && !isSettedNewLine) {
          error.value = globalError.value.replace(/。/, `。${WRAP_TAG}`)
        }

        if (!isErrorLonger && isSettedNewLine) {
          error.value = globalError.value.replace(/<br \/>/, '')
        }
      }
    )

    watch(
      () => globalError.value,
      (newValue) => handleError(newValue)
    )

    const handleError = (message: string): void => {
      error.value = message
    }

    return { isUserFromAPP, error, clientWidth }
  },
})
