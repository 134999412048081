
import { defineComponent, computed } from 'vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'

export default defineComponent({
  name: 'PeriodMonth',
  components: { IconArrowLeft, IconArrowRight },
  props: {
    targetDate: { type: Date, required: true },
  },

  setup(props, { emit }) {
    const targetYear = computed(() => {
      return props.targetDate.getFullYear() + ''
    })

    const targetMonth = computed(() => {
      return props.targetDate.getMonth() + 1 + ''
    })

    const handlePrev = (): void => {
      emit('prev')
    }
    const handleNext = (): void => {
      emit('next')
    }
    return {
      handlePrev,
      handleNext,
      targetYear,
      targetMonth,
    }
  },
})
