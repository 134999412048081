
/**
 * インフォメーション - 送信先リスト削除確認画面
 */
// Components
import MessageWrapper from '../../components/common/InformationMessage/MessageDetailItemWrapper.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ManagePageTitle from '@/components/common/ManagePageTitle.vue'

// Dependencies
import {
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  computed,
  ref,
} from 'vue'
import { useRouter } from '@/router'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'
import {
  queryDestinationList,
  deleteDestinationList,
} from '@/request/api/information-message/destinationList'
import {
  DeleteDestinationListParameters,
  QueryDestinationListModel,
} from '@/request/api/information-message/model/destinationListModel'
import { getMessage } from '@/Utils'

interface State {
  name: string
  userIDList: string[]
}

export default defineComponent({
  name: 'DestinationListSelectDeleteConfirm',

  props: {
    destinationListName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter()

    const { setGlobalError }: UseGlobalError = useGlobalError()

    const state = reactive<State>({
      name: '',
      userIDList: [] as string[],
    })

    const isClickSendData = ref<boolean>(false)

    onBeforeMount(async () => {
      if (props.destinationListName) {
        state.name = props.destinationListName.slice(1, -1)
        const destinationList: QueryDestinationListModel =
          await queryDestinationList(state.name)
        state.userIDList = destinationList[0]?.userIdList ?? []
      }
    })

    // 宛先
    const computedDestinationList = computed<string>(
      (): string => state.userIDList.join(', ') ?? ''
    )

    const destinationTotal = computed<number>(() => {
      return state.userIDList.length
    })

    const backToDestinationListPage = (): void => {
      router.push({ name: 'DestinationListSelect' })
    }

    const handleDeleteDestinationList = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      if (!props.destinationListName) {
        setGlobalError(getMessage('EFR0037', ['送信先リスト']))
        return
      }
      const params: DeleteDestinationListParameters = {
        destinationListName: state.name,
        userIDList: state.userIDList,
      }
      const isDeleteDestinationList: boolean = await deleteDestinationList(
        params
      )
      if (!isDeleteDestinationList) {
        return
      }

      // 削除に成功した場合は完了画面へ遷移
      router.push({
        name: 'ManageNormalCompleted',
        params: { msg: getMessage('EFR0026', ['送信先リストの削除']) },
      })
    }

    return {
      ...toRefs(state),
      computedDestinationList,
      destinationTotal,
      backToDestinationListPage,
      handleDeleteDestinationList,
    }
  },

  components: {
    MessageWrapper,
    ManagePageContainer,
    ManagePageTitle,
    BaseButton,
  },
})
