
/**
 * アイコン - 閉じる03
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconClose03',

  props: {
    fill: {
      type: String,
      default: '#fff',
    },
  },
})
