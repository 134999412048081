
import BaseTextBox from '@/components/common/BaseTextBox.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ComponentDestinationListInput',
  props: { selectedValue: String, error: String },

  setup(props, { emit }) {
    const handleInput = (value: string): void => {
      emit('input', value)
    }

    return { handleInput }
  },

  components: { BaseTextBox },
})
