
/**
 * 合格ダイアリ―コピー期間選択画面
 */
import IconClose04 from '@/components/icons/IconClose04.vue'

import { DailyScheduleModel } from '@/typings/PassDiary'
import { ApplicationInformation } from '@/typings'
import { defineComponent, PropType, toRefs, reactive } from 'vue'
import { getDailySchedule } from '@/request/api'
import { addDailySchedule } from '@/request/api/pass-diary/dailySchedule'
import PassDiaryInputPageButton from '@/components/views/PassDiary/PassDiaryInputPageButton.vue'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useGlobalError } from '@/services/Hooks'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'

export default defineComponent({
  name: 'CopySelectScheduleDaily',

  props: {
    dailySchedule: {
      type: Array as PropType<DailyScheduleModel[]>,
      required: true,
    },
    applicationInformation: {
      type: Object as PropType<ApplicationInformation>,
      required: true,
    },
  },

  setup(props, { emit }) {
    // State
    const state = reactive({
      userCode:
        props.applicationInformation.userId ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationNo: props.applicationInformation.applicationNo,
      selectedDay: props.dailySchedule[0].date,
      selectedSchedules: [] as string[],
      toCopySchedules: [] as DailyScheduleModel[],
    })

    const { setGlobalError } = useGlobalError()

    const handleCloseInputWrapper = (): void => {
      emit('closeInputWrapper')
    }

    const copyScheduleDialy = async (): Promise<void> => {
      for (const date of state.selectedSchedules) {
        await getToCopySchedules(date)
      }
      props.dailySchedule.forEach((schedule: DailyScheduleModel) => {
        // 予定のみコピーする
        if (schedule.yojitsuFlg) {
          state.selectedSchedules.forEach((day: string) => {
            if (isNotExistSchedule(schedule, day)) {
              registerMultipleSchedule(schedule, day)
            }
          })
        }
      })

      emit('closeInputWrapper')
    }

    // 選択したスケジュールの予定を取得
    const getToCopySchedules = async (date: string): Promise<void> => {
      try {
        const dailyScheduleList: DailyScheduleModel[] = await getDailySchedule({
          userCode: state.userCode ?? '',
          applicationNo: state.applicationNo ?? '',
          date: date,
        })
        dailyScheduleList.forEach((item: DailyScheduleModel) => {
          state.toCopySchedules = [...state.toCopySchedules, item]
        })
      } catch (error) {
        handleError(error as Error)
      }
    }

    // コピー元とコピー先で同じスケジュールがないか確認
    const isNotExistSchedule = (
      schedule: DailyScheduleModel,
      day: string
    ): boolean => {
      let retValue = true
      state.toCopySchedules.forEach((item: DailyScheduleModel) => {
        if (
          item.date === day &&
          item.yojitsuFlg === schedule.yojitsuFlg &&
          item.startHour === schedule.startHour &&
          item.startMinute === schedule.startMinute &&
          item.endHour === schedule.endHour &&
          item.endMinute === schedule.endMinute &&
          item.label === schedule.label
        ) {
          retValue = false
        }
      })

      return retValue
    }

    const handleError = (error: Error | unknown | string): void => {
      console.log(error)
      setGlobalError(error as Error)
    }

    const registerMultipleSchedule = async (
      schedule: DailyScheduleModel,
      day: string
    ): Promise<void> => {
      await addDailySchedule({
        userCode: state.userCode ?? '',
        applicationNo: state.applicationNo ?? '',
        dailySchedule: getCopySchedule(schedule, day),
      })
    }

    const getCopySchedule = (
      schedule: DailyScheduleModel,
      day: string
    ): DailyScheduleModel => {
      return {
        yojitsuFlg: schedule.yojitsuFlg,
        date: day,
        startHour: schedule.startHour,
        startMinute: schedule.startMinute,
        endHour: schedule.endHour,
        endMinute: schedule.endMinute,
        label: schedule.label,
        memo: '',
      }
    }

    const disabledDate = (date: Date): boolean => {
      const selectDate = new Date(new Date(state.selectedDay).setHours(0))
      return date.getTime() == selectDate.getTime()
    }

    return {
      ...toRefs(state),
      handleCloseInputWrapper,
      copyScheduleDialy,
      disabledDate,
    }
  },

  components: {
    IconClose04,
    PassDiaryInputPageButton,
    DatePicker,
  },
})
