
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'LoadingShowText',
  props: {
    isLoading: {
      type: Boolean,
    },
    text:{
      type: String,
    },
  },

})
