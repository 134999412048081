import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'

const STORAGE_KEY_DESTINATION_LIST_ARRAY = 'destinationListArray'

/**
 * @description 送信先リストの配列をセッションストレージに保存
 * @param list 送信先リストの配列
 */
export const setDestinationListArrayToStorage = (
  list: DestinationListModel[]
): void => {
  sessionStorage.setItem(
    STORAGE_KEY_DESTINATION_LIST_ARRAY,
    JSON.stringify(list)
  )
}

/**
 * @description セッションストレージから送信先リストの配列を取得
 * @returns 送信先リストの配列
 */
export const getDestinationListArrayFromStorage = (): DestinationListModel[] => {
  const stringTypeList: string | null = sessionStorage.getItem(
    STORAGE_KEY_DESTINATION_LIST_ARRAY
  )

  return JSON.parse(stringTypeList ?? '[]')
}

/**
 * @description セッションストレージから送信先リストの配列を削除
 */
export const removeDestinationListArrayFromStorage = (): void => {
  sessionStorage.removeItem(STORAGE_KEY_DESTINATION_LIST_ARRAY)
}
