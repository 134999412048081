
import AttendInformationSelector from '@/components/common/SearchInput/AttendInformationSelector.vue'
import CourseGroupSelector from '@/components/common/SearchInput/CourseGroupSelector.vue'
import PersonalInformationInput from '@/components/common/SearchInput/PersonalInformationInput.vue'
import SummaryInformationSelector from '@/components/common/SearchInput/SummaryInformationSelector.vue'

import { defineComponent } from 'vue'
import SearchButtonGroup from './SearchButtonGroup.vue'

export default defineComponent({
  name: 'SummaryConditionInput',

  components: {
    AttendInformationSelector,
    CourseGroupSelector,
    PersonalInformationInput,
    SummaryInformationSelector,
    SearchButtonGroup,
  },
})
