
import { useStore } from '@/store'
import {
  SET_CLASSROOM_SUMMARY,
  SET_STUDENT_SUMMARY,
  SET_USAGE_SCHEDULE_DIARY,
} from '@/store/modules/pass-diary/actionTypes'
import { ACTIVE_TABLE } from '@/store/modules/search-condition'
import {
  SET_ACTIVE_TABLE,
  SET_ATTEND_ID,
  SET_FULL_NAME,
  SET_FULL_NAME_KATAKANA,
  SET_PERSONAL_NUMBER,
} from '@/store/modules/search-condition/actionTypes'
import { getMessage } from '@/Utils'
import { computed, defineComponent, ref } from 'vue'
import { Store } from 'vuex'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'
import { StudentInformation } from '@/typings'

export default defineComponent({
  name: 'SearchButtonGroup',

  setup() {
    const store: Store<any> = useStore()

    const setActiveTable = (name: string): void => {
      store.dispatch(SET_ACTIVE_TABLE, name)
    }
    const { setGlobalError }: UseGlobalError = useGlobalError()
    // 教室検索
    const searchClassroomActived = computed<boolean>(
      (): boolean =>
        store.getters.searchClassroomButtonActived &&
        !isClassRoomSearching.value
    )

    const studentSummary = computed<StudentInformation[]>(
      (): StudentInformation[] => store.state.passDiary.studentSummary
    )

    const setClassroomSummary = async (): Promise<void> => {
      isClassRoomSearching.value = true
      setActiveTable(ACTIVE_TABLE.CLASSROOM_SUMMARY)
      if (validate()) {
        return
      }
      await store.dispatch(SET_CLASSROOM_SUMMARY)
      isClassRoomSearching.value = false
      if (store.state.passDiary.classroomSummaryError) {
        setGlobalError(store.state.passDiary.classroomSummaryError)
      }
    }
    // 教室検索中
    const isClassRoomSearching = ref<boolean>(false)
    // 受講生検索中
    const isStudentSearching = ref<boolean>(false)
    // 受講生検索
    const searchStudentActived = computed<boolean>(
      (): boolean =>
        store.getters.searchStudentButtonActived && !isStudentSearching.value
    )

    const setStudentSummary = async (): Promise<void> => {
      isStudentSearching.value = true
      setActiveTable(ACTIVE_TABLE.STUDENT_SUMMARY)

      if (validate()) {
        return
      }
      await store.dispatch(SET_STUDENT_SUMMARY)
      isStudentSearching.value = false

      const list: string[] = []
      studentSummary.value.forEach(function (item, index) {
        list[index] = item.jukoId as string
      })

      await store.dispatch(SET_USAGE_SCHEDULE_DIARY, list)

      if (store.state.passDiary.studentSummaryError) {
        setGlobalError(store.state.passDiary.studentSummaryError)
      }
    }

    // フォーム検証
    const searchCondition = store.state.searchCondition

    const setPersonalNumberError = (error: string): void => {
      store.dispatch(SET_PERSONAL_NUMBER, {
        value: searchCondition.personalNumber.value,
        error,
      })
    }

    const setAttendIDError = (error: string): void => {
      store.dispatch(SET_ATTEND_ID, {
        value: searchCondition.attendID.value,
        error,
      })
    }

    const setFullNameError = (error: string): void => {
      store.dispatch(SET_FULL_NAME, {
        value: searchCondition.fullName.value,
        error,
      })
    }

    const setFullNameKatakanaError = (error: string): void => {
      store.dispatch(SET_FULL_NAME_KATAKANA, {
        value: searchCondition.fullNameKatakana.value,
        error,
      })
    }

    const validate = (): boolean => {
      let isErrorExist: boolean = false

      if (
        searchCondition.personalNumber.value &&
        !/^[0-9]{1,8}$/.test(searchCondition.personalNumber.value)
      ) {
        const error: string = getMessage('EFR0006', ['1 ~ 8 桁の半角数字'])
        setPersonalNumberError(error)
        isErrorExist = true
      }

      if (
        searchCondition.attendID.value &&
        !/^[0-9a-zA-Z]{7}$/.test(searchCondition.attendID.value)
      ) {
        const error: string = getMessage('EFR0006', ['7 桁の半角英数字'])
        setAttendIDError(error)
        isErrorExist = true
      }

      if (
        searchCondition.fullName.value &&
        searchCondition.fullName.value.length > 50
      ) {
        const error: string = getMessage('EFR0006', ['50 桁以内の文字'])
        setFullNameError(error)
        isErrorExist = true
      }

      if (
        searchCondition.fullNameKatakana.value &&
        searchCondition.fullNameKatakana.value.length > 50
      ) {
        const error: string = getMessage('EFR0006', ['50 桁以内の文字'])
        setFullNameKatakanaError(error)
        isErrorExist = true
      }

      if (searchCondition.fullName.value.includes('"')) {
        const error: string = getMessage('EFR0018', ['「"」'])
        setFullNameError(error)
        isErrorExist = true
      }

      if (searchCondition.fullNameKatakana.value.includes('"')) {
        const error: string = getMessage('EFR0018', ['「"」'])
        setFullNameKatakanaError(error)
        isErrorExist = true
      }

      return isErrorExist
    }

    return {
      searchClassroomActived,
      setClassroomSummary,
      searchStudentActived,
      setStudentSummary,
    }
  },
})
