
// Dependencies
import { computed, defineComponent, ref } from 'vue'

// Icons
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'

export default defineComponent({
  name: 'TheCard',
  components: { IconArrowDownTriangle },

  props: {
    title: String,
    options: Array,

    selectedItem: {
      type: String,
      require: true,
    },
  },

  setup(props, { emit }) {
    // Data
    const selected = ref<string>('')
    const valueToDisplay = computed(() => props.selectedItem ?? selected.value)
    const menu = ref<HTMLElement>()

    // Methods
    const handleControlSelector = (event: Event): void => {
      const target = event.target as HTMLElement
      const menuStyles = window.getComputedStyle(menu.value as HTMLElement)
      Number(menuStyles.opacity) === 1 && target.blur()
    }

    const handleSelect = (index: number): void => {
      emit('click', index)
      selected.value = props.options?.[index] as string
    }

    return {
      valueToDisplay,
      menu,
      handleControlSelector,
      handleSelect,
    }
  },
})
