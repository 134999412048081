
/**
 * アイコン - インフォメーション
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconInformationPresent',
  props: {
    stroke: {
      type: String,
      default: '#fff',
    },
  },
})
