
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import { defineComponent, computed } from 'vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'

export default defineComponent({
  name: 'PeriodYear',
  components: { IconArrowLeft, IconArrowRight },
  props: {
    targetDate: { type: Date, required: true },
  },

  setup(props, { emit }) {
    const targetYear = computed(() => {
      return props.targetDate.getFullYear() + ''
    })
    const handleNextYear = (): void => {
      emit('next')
    }
    const handlePrevYear = (): void => {
      emit('prev')
    }
    return {
      handlePrevYear,
      handleNextYear,
      targetYear,
    }
  },
})
