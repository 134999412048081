
// Components
import NavigationButton from '@/components/common/NavigationButton.vue'
// Icons
import IconQR from '@/components/icons/IconQR.vue'
import IconMobileLogout from '@/components/icons/IconMobileLogout.vue'
import { setGlobalParams, EXAMNATION_DATE } from '@/services/GlobleParames'
// Dependencies
import {
  defineComponent,
  watch,
  computed,
  reactive,
  toRefs,
  onBeforeMount,
} from 'vue'
import { queryExaminationDay } from '@/request/api'
import { useStore } from '@/store'
import { useRouter } from '@/router'
import { useGlobalError } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { ONE_DAY_MILLISECOND } from '@/config'
import { NavigationButtonInterface } from '@/typings'

// Vue インスタンス
export default defineComponent({
  name: 'BaseMobileHeader',
  components: {
    NavigationButton,
    IconQR,
    IconMobileLogout,
  },

  setup() {
    const handleTransferScreen = (button: NavigationButtonInterface): void => {
      router.push(button.routePath as string, () => {
        // 遷移後に何もしない
      })
    }

    const navigationList: NavigationButtonInterface[] = [
      { component: IconQR, title: '', routePath: '/QrAttendCert' },
    ]

    const router = useRouter()
    const store = useStore()
    const { setGlobalError } = useGlobalError()

    const state = reactive({
      userID: '',
      score: 0,
      days: '-' as string | number,
    })

    // Computed
    const headerRefreshmentSwitch = computed(
      () => store.state.headerRefreshmentSwitch
    )

    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    // Lifecycle
    onBeforeMount(async () => {
      state.userID = getUserID()
      await setExamDate(state.userID)
    })

    // Watch
    watch(
      () => headerRefreshmentSwitch.value,

      async () => {
        state.userID = getUserID()
        await setExamDate(state.userID)
      }
    )

    // Methods
    const getUserID = (): string => {
      return getUserIDFromAccessToken(getAccessTokenFromSession())
    }

    const setExamDate = async (userID: string): Promise<void> => {
      try {
        const { examDate } = await queryExaminationDay(userID)

        if (!examDate) {
          return
        }
        setGlobalParams(EXAMNATION_DATE, examDate)
        const leftDays = new Date(examDate).getTime() - new Date().getTime()

        if (leftDays > 0) {
          state.days = Math.ceil(leftDays / ONE_DAY_MILLISECOND)
        }
      } catch (error) {
        setGlobalError(error as Error)
      }
    }

    return {
      ...toRefs(state),
      navigationList,
      isUserFromAPP,
      handleTransferScreen,
    }
  },
})
