
import { defineComponent, reactive, onBeforeMount, toRefs } from 'vue'
import MarkButton from '@/components/common/MarkButton.vue'
export default defineComponent({
  name: 'AnswerItem',

  props: {
    sentakuSu: {
      type: Number,
      require: true,
    },
    selectedNo: {
      type: String,
      require: true,
    },
    isVertical: {
      type: Boolean,
      require: true,
    },
    questionIdx: {
      type: Number,
      require: true,
    },
    menjoFlg: {
      type: Boolean,
      require: true,
    },
    pageNum: {
      type: Number,
      require: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      buttonArray: [] as string[],
    })

    onBeforeMount(() => {
      state.buttonArray = []
      for (var j = 0; j < 10; j++) {
        state.buttonArray = [
          ...state.buttonArray,
          buttonType(
            j + 1,
            props.sentakuSu as number,
            props.selectedNo as string,
            props.menjoFlg
          ),
        ]
      }
    })

    const buttonType = (
      num: number,
      sentakuSu: number,
      selectedNo: string,
      menjoFlg: boolean
    ) => {
      if (num - 1 > sentakuSu || (num - 1 == sentakuSu && !menjoFlg)) {
        return '3' //disable
      }
      if (menjoFlg && num - 1 == sentakuSu && selectedNo.indexOf('免') > -1) {
        return '2' //mark
      }
      if (menjoFlg && num - 1 == sentakuSu && selectedNo.indexOf('免') == -1) {
        return '1' //unmark
      }
      if (num == 10 && selectedNo.indexOf('A' + '') > -1) {
        return '2' //mark
      }
      if (num == 10 && selectedNo.indexOf('A' + '') == -1) {
        return '1' //unmark
      }
      if (selectedNo.indexOf(num + '') > -1) {
        return '2' //mark
      } else {
        return '1' //unmark
      }
    }

    const handleInputAnswer = (questionIdx: number) => {
      emit('emitting', questionIdx)
    }
    return { ...toRefs(state), handleInputAnswer }
  },
  components: { MarkButton },
})
