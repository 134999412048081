export const SET_SCHEDULE_MEMO = 'SET_SCHEDULE_MEMO'
export const SET_TRANSFER_MEMO_STATUS = 'SET_TRANSFER_MEMO_STATUS'
export const SET_IS_UPDATE_ACTIVE = 'SET_IS_UPDATE_ACTIVE'
export const SET_SCHEDULE_LIST = 'SET_SCHEDULE_LIST'
export const SET_DEFAULT_SCHEDULE = 'SET_DEFAULT_SCHEDULE'
export const SET_MODIFIED_SCHEDULE = 'SET_MODIFIED_SCHEDULE'
export const SET_LABEL_CLASSIFICATION_LIST = 'SET_LABEL_CLASSIFICATION_LIST'
export const SET_CLASSROOM_SUMMARY = 'SET_CLASSROOM_SUMMARY'
export const SET_STUDENT_SUMMARY = 'SET_STUDENT_SUMMARY'
export const SET_USAGE_SCHEDULE_DIARY = 'SET_USAGE_SCHEDULE_DIARY'
export const SET_CLASSROOM_SUMMARY_ERROR = 'SET_CLASSROOM_SUMMARY_ERROR'
export const SET_STUDENT_SUMMARY_ERROR = 'SET_STUDENT_SUMMARY_ERROR'
