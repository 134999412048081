
// Components
import SelectionBox from '@/components/views/GradeDataSearch/SelectionBox.vue'
import TextBox from '@/components/views/GradeDataSearch/TextBox.vue'

// Dependencies
import {
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  computed,
  watch,
  onMounted,
  ref,
} from 'vue'
import {
  getMessage,
  getRoleIdFromJwt,
  setSuitStringValue,
  setSuitObjectValue,
  setSuitArrayValue,
} from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { USER_TYPE } from '@/config'
import {
  queryBranchStoreList,
  queryCertificationList,
  queryClassroomList,
  queryCourseGroupList,
  queryStudentInformationList,
} from '@/request/api'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  BranchStore,
  Certification,
  Classroom,
  CourseGroup,
  StudentInformation,
  Teacher,
} from '@/typings'
import { StudentInformationCondition } from '@/typings/GradeSearch'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_GRADE_ATTEND_FISCAL_YEAR,
  SET_GRADE_CERTIFICATION_LIST,
  SET_GRADE_CERTIFICATION,
  SET_GRADE_COURSE_GROUP_LIST,
  SET_GRADE_COURSE_GROUP,
  SET_GRADE_BRANCH_STORE,
  SET_GRADE_CLASSROOM,
  SET_GRADE_PERSONAL_NUMBER,
  SET_GRADE_ATTEND_ID,
  SET_GRADE_FULL_NAME_KATAKANA,
  SET_GRADE_FULL_NAME,
  SET_GRADE_SEARCH_RESULT_TABLE,
} from '@/store/modules/grade-data-search-condition/actionTypes'

interface State {
  userType: string // ユーザーのロール ID
  userID: string // ユーザーのログイン ID
  attendFiscalYear: string // 受講年度
  certification: Certification // 受講種別
  courseGroup: CourseGroup
  branchStore: BranchStore
  classroom: Classroom
  teacher: Teacher
  personalNumber: string
  attendID: string // 受講 ID
  fullName: string
  fullNameKatakana: string
}

interface Option {
  attendFiscalYearList: string[]
  certificationList: Certification[]
  courseGroupList: CourseGroup[]
  branchStoreList: BranchStore[]
  classroomList: Classroom[]
}

interface Caution {
  personalNumberError: string
  attendIDError: string // 受講 ID
  fullNameError: string
  fullNameKatakanaError: string
}

export default defineComponent({
  name: 'GradeDataSearchInput',
  components: { SelectionBox, TextBox },

  setup(props, { emit }) {
    // Data
    const styles = {
      groupItemDisplayAreaStyle: {
        border: 'solid 1px #869fb3',
      },

      groupItemTitleStyle: {
        fontWeight: 400,
        margin: '0 0 10px',
      },
    }

    const { setGlobalError, clearGlobalError }: UseGlobalError =
      useGlobalError()

    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store?.state?.isUserFromAPP)
    const thisYear = new Date().getFullYear()

    // 受講年度
    const attendFiscalYear = computed<string>(
      (): string => store.state.gradeDataSearchCondition.attendFiscalYear
    )

    const setAttendFiscalYear = (year: string): void => {
      store.dispatch(SET_GRADE_ATTEND_FISCAL_YEAR, year)
    }

    // 受講種別リスト
    const certificationList_ = computed<Certification[]>(
      (): Certification[] => store.getters.certificationListGradeForDisplay
    )

    const setCertificationList = async (
      list: Certification[]
    ): Promise<void> => {
      store.dispatch(SET_GRADE_CERTIFICATION_LIST, list)
    }

    // 受講種別
    const certification = computed<Certification>(
      (): Certification => store.getters.certificationGradeForDisplay
    )

    const setCertification = (name: string): void => {
      store.dispatch(SET_GRADE_CERTIFICATION, name)
    }

    // コースグループ
    const courseGroupList_ = computed<CourseGroup[]>(
      (): CourseGroup[] => store.getters.courseGroupListGradeForDisplay
    )

    const setCourseGroupList = async (): Promise<void> => {
      await store.dispatch(SET_GRADE_COURSE_GROUP_LIST)
    }

    const courseGroup = computed<CourseGroup>(
      (): CourseGroup => store.getters.courseGroupGradeForDisplay
    )

    const setCourseGroup = async (name: string): Promise<void> => {
      await store.dispatch(SET_GRADE_COURSE_GROUP, name)
    }

    // 支店
    const branchStoreList_ = computed<BranchStore[]>(
      (): BranchStore[] => store.getters.branchStoreListGradeForDisplay
    )

    const branchStore = computed<BranchStore>(
      (): BranchStore => store.getters.branchStoreGradeForDisplay
    )

    const setBranchStore = async (list: BranchStore): Promise<void> => {
      await store.dispatch(SET_GRADE_BRANCH_STORE, list.branchNm)
    }

    // 教室
    const classroomList_ = computed<Classroom[]>(
      (): Classroom[] => store.getters.classroomListGradeForDisplay
    )

    const classroom = computed<Classroom>(
      (): Classroom => store.getters.classroomGradeForDisplay
    )

    const setClassroom = async (list: Classroom): Promise<void> => {
      await store.dispatch(SET_GRADE_CLASSROOM, list.classroomNm)
    }

    // 個人番号
    const personalNumber = computed<string>(
      (): string => store.state.gradeDataSearchCondition.personalNumber.value as string
    )

    const setPersonalNumber = (value: string) => {
      store.dispatch(SET_GRADE_PERSONAL_NUMBER, { value, error: '' })
    }

    const personalNumberError = computed<string>(
      (): string => store.state.gradeDataSearchCondition.personalNumber.error as string
    )

    const setPersonalNumberError = (error: string): void => {
      store.dispatch(SET_GRADE_PERSONAL_NUMBER, {
        value: personalNumber.value,
        error,
      })
    }

    // 受講 ID
    const attendID = computed<string>((): string => store.state.gradeDataSearchCondition.attendID.value as string)

    const setAttendID = (value: string) => {
      store.dispatch(SET_GRADE_ATTEND_ID, { value, error: '' })
    }

    const attendIDError = computed<string>(
      (): string => store.state.gradeDataSearchCondition.attendID.error as string
    )

    const setAttendIDError = (error: string): void => {
      store.dispatch(SET_GRADE_ATTEND_ID, {
        value: attendID.value,
        error,
      })
    }

    // 氏名カナ
    const fullNameKatakana = computed<string>(
      (): string => store.state.gradeDataSearchCondition.fullNameKatakana.value as string
    )

    const setFullNameKatakana = (value: string) => {
      store.dispatch(SET_GRADE_FULL_NAME_KATAKANA, { value, error: '' })
    }

    const fullNameKatakanaError = computed<string>(
      (): string => store.state.gradeDataSearchCondition.fullNameKatakana.error as string
    )

    const setFullNameKatakanaError = (error: string): void => {
      store.dispatch(SET_GRADE_FULL_NAME_KATAKANA, {
        value: fullNameKatakana.value,
        error,
      })
    }

    // 氏名
    const fullName = computed<string>((): string => store.state.gradeDataSearchCondition.fullName.value as string)

    const setFullName = (value: string) => {
      store.dispatch(SET_GRADE_FULL_NAME, { value, error: '' })
    }

    const fullNameError = computed<string>(
      (): string => store.state.gradeDataSearchCondition.fullName.error as string
    )

    const setFullNameError = (error: string): void => {
      store.dispatch(SET_GRADE_FULL_NAME, {
        value: fullName.value,
        error,
      })
    }

    const setSearchResultTable = async (
      result: StudentInformation[]
    ): Promise<void> => {
      await store.dispatch(SET_GRADE_SEARCH_RESULT_TABLE, result)
    }

    // 検索条件を入力した際、gradeSearchConditionsを設定する。
    const state = reactive<State>({
      userType: '',
      userID: '',
      attendFiscalYear: setSuitStringValue(attendFiscalYear.value),
      certification: certification.value ? setSuitObjectValue(certification.value) : { name: '', kbn: '' },
      courseGroup: courseGroup.value ? setSuitObjectValue(courseGroup.value) : { courseGroupCd: '', courseGroupNm: '' },
      branchStore: branchStore.value ? setSuitObjectValue(branchStore.value) : { branchCd: '', branchNm: '' },
      classroom: classroom.value ? setSuitObjectValue(classroom.value) : { classroomCd: '', classroomNm: '' },
      teacher: { koshiId: '', koshiNm: '' },
      personalNumber: setSuitStringValue(personalNumber.value),
      attendID: setSuitStringValue(attendID.value),
      fullName: setSuitStringValue(fullName.value),
      fullNameKatakana: setSuitStringValue(fullNameKatakana.value),
    })

    const option = reactive<Option>({
      attendFiscalYearList: [
        '',
        (thisYear - 1).toString(),
        thisYear.toString(),
        (thisYear + 1).toString(),
      ],
      certificationList: setSuitArrayValue(
        certificationList_.value
      ) as Certification[],
      courseGroupList: setSuitArrayValue(
        courseGroupList_.value
      ) as CourseGroup[],
      branchStoreList: setSuitArrayValue(
        branchStoreList_.value
      ) as BranchStore[],
      classroomList: setSuitArrayValue(classroomList_.value) as Classroom[],
    })

    const caution = reactive<Caution>({
      personalNumberError: setSuitStringValue(personalNumberError.value),
      attendIDError: setSuitStringValue(attendIDError.value),
      fullNameError: setSuitStringValue(fullNameError.value),
      fullNameKatakanaError: setSuitStringValue(fullNameKatakanaError.value),
    })

    const isSearchDone = ref<boolean>(true)

    // Computed
    const isManagementUser = computed<boolean>(
      () => state.userType === USER_TYPE.MANAGEMENT
    )

    const isEducationStaff = computed<boolean>(
      () => state.userType === USER_TYPE.EDUCATION_STAFF
    )

    const isBusinessUser = computed<boolean>(
      () => state.userType === USER_TYPE.BUSINESS
    )

    const isPermitCourseGroup = computed<boolean>({
      get: () => {
        return state.attendFiscalYear &&
          state.certification.kbn &&
          option.courseGroupList.length
          ? true
          : false
      },

      set: (isPermit) => {
        if (!isPermit) {
          state.courseGroup = {}
          state.classroom = {}
        }
      },
    })

    const isPermitBranchStore = computed<boolean>(() =>
      isPermitCourseGroup.value && option.branchStoreList.length ? true : false
    )

    const isPermitClassroom = computed<boolean>({
      get: () => {
        return state.courseGroup.courseGroupCd &&
          state.branchStore.branchCd &&
          option.classroomList.length
          ? true
          : false
      },

      set: (isPermit) => {
        if (!isPermit) {
          state.classroom = {}
        }
      },
    })

    const isPermitTextBox = computed<boolean>(() => isPermitCourseGroup.value)

    // 検索ボタンの錠
    const isPermitSearch = computed<boolean>(() => {
      // エラー存在時は施錠
      if (
        caution.personalNumberError.length > 0 ||
        caution.attendIDError.length > 0 ||
        caution.fullNameKatakanaError.length > 0 ||
        caution.fullNameError.length > 0
      ) {
        return false
      }

      // データ請求時は施錠
      if (!isSearchDone.value) {
        return false
      }

      // 受講年度、受講種別未入力時は施錠
      if (!isPermitCourseGroup.value) {
        return false
      }

      // 受講情報入力済みで営業ユーザーの場合は開錠
      if (isBusinessUser.value) {
        return true
      }

      // 教務スタッフ・管理者ユーザー且つグループ検索入力済みの場合は開錠
      if (
        state.courseGroup.courseGroupCd &&
        state.branchStore.branchCd &&
        state.classroom.classroomCd
      ) {
        return true
      }

      // 教務スタッフ・管理者ユーザー且つテキストボックス入力済みの場合は開錠
      return (
        state.personalNumber.length > 0 ||
        state.attendID.length > 0 ||
        state.fullNameKatakana.length > 0 ||
        state.fullName.length > 0
      )
    })

    // LifeCycle
    onBeforeMount(() => {
      const accessToken = getAccessTokenFromSession()
      state.userType = getRoleIdFromJwt(accessToken) ?? ''
      state.userID = getUserIDFromAccessToken(accessToken)
    })

    onMounted(async () => {
      try {
        const certificationLst = await queryCertificationList()

        option.certificationList = [{ name: '', kbn: '' }, ...certificationLst]
        setCertificationList(option.certificationList)

        const branchStoreLst = await queryBranchStoreList(
          state.userType,
          state.userID
        )

        option.branchStoreList = [
          { branchCd: '', branchNm: '' },
          ...branchStoreLst,
        ]
      } catch (error) {
        console.log(error)
        setGlobalError(error as Error)
      }
    })

    // Watch
    watch(
      () => ({ ...state }),
      () => clearGlobalError()
    )

    watch(
      () => [state.attendFiscalYear, state.certification.kbn],

      async (newValues) => {
        try {
          if (newValues.some((item) => !item)) {
            return
          }

          const courseGroupLst = await queryCourseGroupList(
            state.attendFiscalYear,
            state.certification.kbn as string
          )

          option.courseGroupList = [
            { courseGroupCd: '', courseGroupNm: '' },
            ...courseGroupLst,
          ]
          setCourseGroupList()
        } catch (error) {
          console.log(error)
          setGlobalError(error as Error)
        }
      }
    )

    watch(
      () => [state.courseGroup.courseGroupCd, state.branchStore.branchCd],

      async ([newCourseGroup, newBranchStore]) => {
        try {
          if (!newCourseGroup || !newBranchStore) {
            return
          }

          const classroomList = await queryClassroomList({
            attendFiscalYear: state.attendFiscalYear,
            certificationCode: state.certification.kbn as string,
            courseGroupCode: state.courseGroup.courseGroupCd as string,
            branchStoreCode: state.branchStore.branchCd as string,
          })

          option.classroomList = [
            { classroomCd: '', classroomNm: '' },
            ...classroomList,
          ]
        } catch (error) {
          console.log(error)
          setGlobalError(error as Error)
        }
      }
    )

    // Methods
    const validate = (): boolean => {
      let isErrorExist: boolean = false

      if (state.personalNumber && !/^[0-9]{1,8}$/.test(state.personalNumber)) {
        caution.personalNumberError = getMessage('EFR0006', [
          '1 ~ 8 桁の半角数字',
        ])
        setPersonalNumberError(caution.personalNumberError)

        isErrorExist = true
      }

      if (state.attendID && !/^[0-9a-zA-Z]{7}$/.test(state.attendID)) {
        caution.attendIDError = getMessage('EFR0006', ['7 桁の半角英数字'])
        setAttendIDError(caution.attendIDError)
        isErrorExist = true
      }

      if (state.fullName && state.fullName.length > 50) {
        caution.fullNameError = getMessage('EFR0006', ['50 桁以内の文字'])
        setFullNameError(caution.fullNameError)
        isErrorExist = true
      }

      if (state.fullNameKatakana && state.fullNameKatakana.length > 50) {
        caution.fullNameKatakanaError = getMessage('EFR0006', [
          '50 桁以内の文字',
        ])
        setFullNameKatakanaError(caution.fullNameKatakanaError)

        isErrorExist = true
      }

      if (state.fullName.includes('"')) {
        caution.fullNameError = getMessage('EFR0018', ['「"」'])
        setFullNameError(caution.fullNameError)
        isErrorExist = true
      }

      if (state.fullNameKatakana.includes('"')) {
        caution.fullNameKatakanaError = getMessage('EFR0018', ['「"」'])
        setFullNameKatakanaError(caution.fullNameKatakanaError)
        isErrorExist = true
      }

      return isErrorExist
    }

    const handleSetAttendYear = (value: number | string): void => {
      isPermitCourseGroup.value = false
      state.attendFiscalYear = value.toString()
      setAttendFiscalYear(state.attendFiscalYear)
    }

    const handleSetCertification = (value: string): void => {
      isPermitCourseGroup.value = false

      // 選択肢から受け取った受講種別名を有するアイテムを探し出す
      state.certification = option.certificationList.find(
        (item) => item.name === value
      ) as Certification
      setCertification(value)
    }

    const handleSetCourseGroup = (value: string): void => {
      isPermitClassroom.value = false

      // 選択肢から受け取ったコースグループ名を有するアイテムを探し出す
      state.courseGroup = option.courseGroupList.find(
        (item) => item.courseGroupNm === value
      ) as CourseGroup
      setCourseGroup(value)
    }

    const handleSetBranchStore = (value: string): void => {
      isPermitClassroom.value = false

      // 選択肢から受け取った支店名を有するアイテムを探し出す
      state.branchStore = option.branchStoreList.find(
        (item) => item.branchNm === value
      ) as BranchStore
      setBranchStore(state.branchStore)
    }

    const handleSetClassroom = (value: string): void => {

      // 選択肢から受け取った教室名を有するアイテムを探し出す
      state.classroom = option.classroomList.find(
        (item) => item.classroomNm === value
      ) as Classroom
      setClassroom(state.classroom)
    }

    // 検索ボタンを押した際、このメッソドを呼び出す。
    const search = async (): Promise<void> => {
      try {
        if (!isPermitSearch.value || validate()) {
          return
        }

        isSearchDone.value = false

        //検索する
        const studentInformationCondition: StudentInformationCondition = {
          attendFiscalYear: state.attendFiscalYear,
          certificationCode: state.certification.kbn ?? '',
          courseGroupCode: state.courseGroup.courseGroupCd ?? '',
          branchStoreCode: state.branchStore.branchCd ?? '',
          classroomCode: state.classroom.classroomCd ?? '',
          teacherID: state.teacher.koshiId ?? '',
          personalNumber: state.personalNumber ?? '',
          attendID: state.attendID ?? '',
          fullName: state.fullName ?? '',
          fullNameKatakana: state.fullNameKatakana ?? '',
          userType: state.userType ?? '',
          userID: state.userID ?? '',
        }

        const response: StudentInformation[] =
          await queryStudentInformationList(studentInformationCondition)

        setSearchResultTable(
          response.filter(duplicateStudentInformationListFilter)
        )
        isSearchDone.value = true
        emit('emitting', response.filter(duplicateStudentInformationListFilter))
      } catch (error) {
        console.log(error)
        isSearchDone.value = true
        setGlobalError(error as Error)
        emit('emitting', [])
      }
    }

    const duplicateStudentInformationListFilter = (
      information: StudentInformation,
      index: number,
      list: StudentInformation[]
    ): boolean => {
      const firstIndex: number = list.findIndex(
        (item: StudentInformation): boolean =>
          information.applicationNo === item.applicationNo
      )

      return index === firstIndex
    }

    const certificationListDisplay = computed<string[]>(
      (): string[] => option.certificationList.map((item) => item.name ?? '')
    )
    const courseGroupListDisplay = computed<string[]>(
      (): string[] => option.courseGroupList.map((item) => item.courseGroupNm ?? '')
    )
    const branchStoreListDisplay = computed<string[]>(
      (): string[] => option.branchStoreList.map((item) => item.branchNm ?? '')
    )
    const classroomListDisplay = computed<string[]>(
      (): string[] => option.classroomList.map((item) => item.classroomNm ?? '')
    )

    return {
      ...toRefs(state),
      ...toRefs(option),
      ...toRefs(caution),
      ...styles,
      isUserFromAPP,
      isManagementUser,
      isEducationStaff,
      isBusinessUser,
      isPermitCourseGroup,
      isPermitBranchStore,
      isPermitClassroom,
      isPermitTextBox,
      isPermitSearch,
      handleSetAttendYear,
      handleSetCertification,
      handleSetCourseGroup,
      handleSetBranchStore,
      handleSetClassroom,
      search,
      setPersonalNumber,
      setAttendID,
      setFullNameKatakana,
      setFullName,
      certificationListDisplay,
      courseGroupListDisplay,
      branchStoreListDisplay,
      classroomListDisplay,
    }
  },
})
