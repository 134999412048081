
import BaseTextBox from '@/components/common/BaseTextBox.vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MemoInput',
  props: { value: String, error: String },

  setup(props, { emit }) {
    const memo = ref<string>('')

    const handleInput = (event: InputEvent): void => {
      const value = (event.target as HTMLInputElement).value
      memo.value = value.trim()
      emit('input', memo.value)
    }

    return { handleInput, memo }
  },

  components: { BaseTextBox },
})
