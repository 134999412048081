
/**
 * インフォメーション - 送信履歴詳細画面
 */
// Components
import BaseButton from '@/components/common/BaseButton.vue'
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ManagePageTitle from '@/components/common/ManagePageTitle.vue'
import MessageDetail from '@/components/common/InformationMessage/SentMessageDetail.vue'

// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { SendMessageReadStatusItem, SentMessageItem } from '@/typings'
import {
  getGlobalParams,
  SENT_MESSAGE_DETAIL,
  setGlobalParams,
  MESSAGE_COMPLETE_PAGE,
  DELETE_SENT_MESSAGE,
} from '@/services/GlobleParames'
import { querySendMessageReadStatus } from '@/request/api'
import { STUDENT_USER_CODE_LENGTH } from '@/config'

interface State {
  messageDetail: SentMessageItem
  readRate: string
  destinationList: SendMessageReadStatusItem[]
  refresh: number
}

export default defineComponent({
  name: 'PageSentMessageDetail',

  props: {
    message: {
      type: String,
      require: true,
    },
  },

  setup(props) {
    const state = reactive<State>({
      messageDetail: {} as SentMessageItem,
      readRate: '',
      destinationList: [],
      refresh: 0,
    })

    onBeforeMount(async () => {
      if (
        getGlobalParams(MESSAGE_COMPLETE_PAGE) &&
        getGlobalParams(DELETE_SENT_MESSAGE)
      ) {
        return
      } else if (props.message) {
        state.messageDetail = JSON.parse(props.message ?? '{}')
        setGlobalParams(SENT_MESSAGE_DETAIL, state.messageDetail)
      } else {
        state.messageDetail = getGlobalParams(SENT_MESSAGE_DETAIL)
      }
      state.messageDetail.attachmentList =
        state.messageDetail?.['attachmentList'] ?? []
      state.refresh++
      await setDestinationList()
      setReadRate(state.destinationList)
    })

    const setDestinationList = async (): Promise<any> => {
      const response: SendMessageReadStatusItem[] =
        await querySendMessageReadStatus(
          state.messageDetail.sender,
          state.messageDetail.messageNo
        )

      state.destinationList = response.filter(
        (item: SendMessageReadStatusItem) =>
          item.receiver.length === STUDENT_USER_CODE_LENGTH
      )
    }

    const setReadRate = (
      destinationList: SendMessageReadStatusItem[]
    ): void => {
      const denominator = destinationList.length

      const numerator = destinationList.filter(
        (item: SendMessageReadStatusItem) => item.read
      ).length

      // 分母が無ければレートが計算できない
      if (!denominator) {
        state.readRate = '-'
        return
      }

      state.readRate = `${Math.round((numerator / denominator) * 10000) / 100}%`
    }

    return { ...toRefs(state) }
  },

  components: {
    ManagePageContainer,
    ManagePageTitle,
    BaseButton,
    MessageDetail,
  },
})
