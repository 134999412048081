export interface State {
  headerRefreshmentSwitch: boolean
  isUserFromAPP: boolean
  isLoadingWithText: boolean
  isLoadingText: string
  globalError: string
  studentUserID: string
  applicationNumber: string
  presentTab: string
  access_token: string
  refresh_token: string
}

const state: State = {
  headerRefreshmentSwitch: false,
  isUserFromAPP: false,
  isLoadingWithText: false,
  isLoadingText: '',
  globalError: '',
  studentUserID: '',
  applicationNumber: '',
  presentTab: '',
  access_token: '',
  refresh_token: '',
}

export default state
