
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { formatTime } from '@/Utils'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
export interface InterfaceScheduleDate {
  year: string
  month: string
  day: string
  weekday: string
}

export default defineComponent({
  name: 'ScheduleDate',
  components: { IconArrowLeft, IconArrowRight },
  props: {
    date: { type: Date, required: true },
    isDatePicker: { type: Boolean, required: true },
  },

  setup(props, { emit }) {
    const state = reactive<InterfaceScheduleDate>({
      year: props.date.getFullYear() + '',
      month: formatTime(props.date.getMonth() + 1),
      day: formatTime(props.date.getDate()),
      weekday: ['日', '月', '火', '水', '木', '金', '土'][props.date.getDay()],
    })
    const isDatePicker = ref(props.isDatePicker)
    const resetIsDatePicker = (): void => {
      isDatePicker.value = !isDatePicker.value
      emit('isDisplayDatePicker', isDatePicker.value)
    }

    return {
      ...toRefs(state),
      resetIsDatePicker,
    }
  },
})
