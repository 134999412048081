
/**
 * 合格ダイアリ―予定入力(ラベル)画面
 */
import LabelInput from '@/components/views/PassDiary/LabelInput.vue'
import IconClose04 from '@/components/icons/IconClose04.vue'

import { LabelClassification } from '@/typings/index'
import { defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'
import { SET_IS_UPDATE_ACTIVE } from '@/store/modules/pass-diary/actionTypes'

export default defineComponent({
  name: 'ScheduleLabelInput',

  props: {
    label: {
      type: Array as PropType<LabelClassification[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store: Store<any> = useStore()

    const setUpdateActivateToStore = (isValue: boolean): Promise<void> =>
      store.dispatch(SET_IS_UPDATE_ACTIVE, isValue)

    const handleCloseInputWrapper = (): void => {
      emit('closeInputWrapper')
    }

    const handleTransferToScheduleAchievement = (
      _label: LabelClassification
    ): void => {
      setUpdateActivateToStore(false)
      emit('handleTransferToScheduleAchievement', _label)
    }

    const isLabelShow = (label: LabelClassification): boolean =>
      label.name3 === '1'

    return {
      LabelInput,
      handleCloseInputWrapper,
      handleTransferToScheduleAchievement,
      isLabelShow,
    }
  },

  components: {
    LabelInput,
    IconClose04,
  },
})
