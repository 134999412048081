
/**
 * 合格ダイアリー - 実績レポート画面 - 月間グラフ
 */
import { computed, defineComponent, PropType, ref, watch, onMounted } from 'vue'
import {
  SelectedLabelItem,
  isLabelChecked,
  getColor,
} from '@/components/views/PassDiary/Common'
import { UserScheduleSummary } from '@/typings/PassDiary'

type CanvasContextType = CanvasRenderingContext2D | null | undefined

const CANVAS_WIDTH: number = 375
const CANVAS_CONTEXT_ID = '2d'

export default defineComponent({
  name: 'MonthReportGraph',

  props: {
    selectedItemArray: {
      type: Array as PropType<SelectedLabelItem[]>,
      required: true,
    },

    userScheduleSummary: {
      type: Array as PropType<UserScheduleSummary[]>,
      required: true,
    },
    targetDate: {
      type: Date,
      required: true,
    },
    monthReportGraphHeight: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    onMounted(() => {
      paintReportGraph()
    })

    watch<SelectedLabelItem[]>(
      () => props.selectedItemArray,

      () => paintReportGraph()
    )
    watch<UserScheduleSummary[]>(
      () => props.userScheduleSummary,

      () => paintReportGraph()
    )
    const paintReportGraph = (): void => {
      resetCanvas()
      const context = barGraphContext.value

      if (!context) {
        return
      }
      //canvas background
      context.fillStyle = '#ececec'
      context.fillRect(0, 0, 375, props.monthReportGraphHeight)
      //graph background
      context.fillStyle = '#ffffff'
      context.fillRect(60, 0, 315, props.monthReportGraphHeight - 68)
      //縦目盛
      context.fillStyle = 'rgba(0,0,0,0.5)'
      context.font = '400 16px Noto Sans JP'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.strokeStyle = 'rgba(0,0,0,0.5)'
      context.lineWidth = 1

      let rectH = 0
      const gradationsCount = (props.monthReportGraphHeight - 84) / 72
      for (var i = 0; i < gradationsCount; i++) {
        context.beginPath()
        context.fillText(gradationsCount - i + '0h', 36, 16 + 72 * i)
        context.moveTo(60, 16 + 72 * i)
        context.lineTo(65, 16 + 72 * i)
        context.stroke()
      }

      //横目盛
      let d = ''
      let w = ''
      context.font = '400 12px Noto Sans JP'
      for (var k = 0; k < 5; k++) {
        context.beginPath()
        context.fillText(
          '第' + (k + 1) + '週',
          100 + k * 50,
          props.monthReportGraphHeight - 48
        )
      }
      //週間サマリー

      for (var h = 0; h < 5; h++) {
        if (!props.userScheduleSummary[h]) {
          break
        }

        var dataArr = props.userScheduleSummary[h].actual
        const weeklyGraphWidth = 20
        let weeklyGraphHeight = 0
        let weeklyGraphEnd = props.monthReportGraphHeight - 68
        let idx = 0
        for (var j = 0; j < dataArr.length; j++) {
          idx = dataArr.length - j - 1
          if (isLabelChecked(dataArr[idx].label, props.selectedItemArray)) {
            context.fillStyle = `${
              getColor(dataArr[idx].label, props.selectedItemArray).achieveColor
            }`
            weeklyGraphHeight = Math.ceil(dataArr[idx].value * 0.12)
            context.fillRect(
              90 + h * 50,
              weeklyGraphEnd - weeklyGraphHeight,
              weeklyGraphWidth,
              weeklyGraphHeight
            )
            weeklyGraphEnd = weeklyGraphEnd - weeklyGraphHeight
          }
        }
      }
    }

    const barGraphCanvas = ref<HTMLCanvasElement>()

    const barGraphContext = computed<CanvasContextType>(() =>
      barGraphCanvas.value?.getContext(CANVAS_CONTEXT_ID)
    )

    const resetCanvas = (): void => {
      if (!barGraphContext.value) {
        return
      }
      barGraphContext.value.clearRect(
        0,
        0,
        CANVAS_WIDTH,
        props.monthReportGraphHeight
      )
    }

    return {
      CANVAS_WIDTH,
      barGraphCanvas,
    }
  },
})
