
// Components
import Logout from '@/components/common/Logout.vue'
import IconUser from '@/components/icons/IconUser.vue'
import IconLogo01 from '@/components/icons/IconLogo01.vue'

// Dependencies
import { computed, defineComponent, ref } from 'vue'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIdFromJwt } from '@/Utils'
import { logout } from '@/services/ControlLogin'
import { useRouter } from '@/router'
import { useStore } from '@/store'

export default defineComponent({
  components: { Logout, IconUser, IconLogo01 },

  setup() {
    const router = useRouter()
    const store = useStore()
    const username = ref(getUserIdFromJwt(getAccessTokenFromSession()) ?? '')
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    const handleTransfer = (): void => {
      router.push({ name: 'ManageTop' }, () => {
        // 遷移後何もしない
      })
    }

    return { username, isUserFromAPP, logout, handleTransfer }
  },
})
