
/**
 * 合格ダイアリー - 予実確認（TOP）画面 - 円盤
 */
import { computed, defineComponent, PropType, ref, watch, onMounted } from 'vue'
import { DailyScheduleModel } from '@/typings/PassDiary'
import { LabelClassification } from '@/typings/index'
import {
  drawBackground,
  drawGraduation,
  drawControlPanel,
  Color,
  Scale,
} from './Common'
interface FanShapeOptions {
  startAngle: number
  endAngle: number
  fillStyle: string
}

type CanvasContextType = CanvasRenderingContext2D | null | undefined

export default defineComponent({
  name: 'DiaryGraph',

  props: {
    achievementList: {
      type: Array as PropType<DailyScheduleModel[]>,
      required: true,
    },

    yoteiList: {
      type: Array as PropType<DailyScheduleModel[]>,
      required: true,
    },

    rabelClassification: {
      type: Array as PropType<LabelClassification[]>,
      required: true,
    },
    scale: {
      type: Object as PropType<Scale>,
      required: true,
    },
  },

  setup(props) {
    const CANVAS_HEIGHT: number = 320 * props.scale.y
    const CANVAS_WIDTH: number = 320 * props.scale.x
    const CANVAS_CONTEXT_ID = '2d'
    const CIRCLE_CENTER_X = 160
    const CIRCLE_CENTER_Y = 160
    onMounted(() => {
      paintCircleClock()
    })
    watch<DailyScheduleModel[]>(
      () => props.achievementList,

      () => paintCircleClock()
    )
    watch<DailyScheduleModel[]>(
      () => props.yoteiList,

      () => paintCircleClock()
    )
    const paintCircleClock = (): void => {
      clockContext.value?.scale(props.scale.x, props.scale.y)
      resetCanvas()
      drawBackground(
        clockContext.value,
        {
          circleCenterX: CIRCLE_CENTER_X,
          circleCenterY: CIRCLE_CENTER_Y,
          radius: 130,
        },
        0,
        Math.PI * 2,
        '#fff',
        20,
        '#e9eaea'
      )
      drawGraduation(
        clockContext.value,
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        'rgba(155,155,155,1)',
        '400 12px Noto Sans JP',
        'rgba(155,155,155,0.5)'
      )
      props.achievementList.forEach((item: any) => {
        paintFanShapeAchieve({
          startAngle: getAngle(
            item.startHour as number,
            item.startMinute as number
          ),
          endAngle: getAngle(item.endHour as number, item.endMinute as number),
          fillStyle: getColor(item.label).achieveColor,
        })
      })

      clearFanShapeScheduleOfCenter()

      props.yoteiList.forEach((item: any) => {
        paintFanShapeSchedule({
          startAngle: getAngle(
            item.startHour as number,
            item.startMinute as number
          ),
          endAngle: getAngle(item.endHour as number, item.endMinute as number),
          fillStyle: getColor(item.label).scheduleColor,
        })
      })

      drawControlPanel(
        clockContext.value,
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        60,
        0,
        Math.PI * 2,
        '#e9eaea'
      )
    }

    const clockCanvas = ref<HTMLCanvasElement>()

    const clockContext = computed<CanvasContextType>(() =>
      clockCanvas.value?.getContext(CANVAS_CONTEXT_ID)
    )
    const getColor = (name: string): Color => {
      let defaultRet = { scheduleColor: '#ffffff', achieveColor: '#ffffff' }
      if (
        name &&
        props.rabelClassification &&
        props.rabelClassification.length > 0
      ) {
        return {
          scheduleColor:
            '#' +
            props.rabelClassification.find((item: any) => {
              return item.kbn === name
            })?.name1,
          achieveColor:
            '#' +
            props.rabelClassification.find((item: any) => {
              return item.kbn === name
            })?.name2,
        }
      }

      return defaultRet
    }
    const resetCanvas = (): void => {
      if (!clockContext.value) {
        return
      }
      clockContext.value.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
      clockContext.value.fillStyle = '#fff'
    }

    const getAngle = (hour: number, minute: number): number => {
      if (hour < 0 || minute < 0 || minute > 59) {
        return -10
      }
      const formattedTime = hour + minute / 100

      const temporary =
        formattedTime <= 18 ? formattedTime - 6 : formattedTime - 30

      const timeDegreeRate = 24 / 360
      const degree = temporary / timeDegreeRate

      return degree * (Math.PI / 180)
    }

    const paintFanShapeAchieve = ({
      startAngle,
      endAngle,
      fillStyle,
    }: FanShapeOptions): void => {
      const context = clockContext.value

      if (!context) {
        return
      }
      context.beginPath()
      context.arc(
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        140,
        startAngle,
        endAngle,
        false
      )
      context.fillStyle = fillStyle
      context.lineTo(CIRCLE_CENTER_X, CIRCLE_CENTER_Y)
      context.fill()
    }
    const paintFanShapeSchedule = ({
      startAngle,
      endAngle,
      fillStyle,
    }: FanShapeOptions): void => {
      const context = clockContext.value

      if (!context) {
        return
      }
      context.beginPath()
      context.arc(
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        120,
        startAngle,
        endAngle,
        false
      )

      context.fillStyle = fillStyle
      context.lineTo(CIRCLE_CENTER_X, CIRCLE_CENTER_Y)
      context.fill()
    }

    const clearFanShapeScheduleOfCenter = (): void => {
      const context = clockContext.value

      if (!context) {
        return
      }
      context.beginPath()
      context.arc(CIRCLE_CENTER_X, CIRCLE_CENTER_Y, 120, 0, Math.PI * 2, false)
      context.fillStyle = 'white'
      context.lineTo(CIRCLE_CENTER_X, CIRCLE_CENTER_Y)
      context.fill()
    }

    return {
      CANVAS_HEIGHT,
      CANVAS_WIDTH,
      clockCanvas,
    }
  },
})
