
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { formatTime } from '@/Utils'

export interface InterfaceScheduleDate {
  y: string
  m: string
  d: string
  w: string
}

export default defineComponent({
  name: 'ScheduleDateTopPage',

  props: {
    date: { type: Date, required: true },
    isDatePicker: { type: Boolean, required: true },
  },

  setup(props, { emit }) {
    const state = reactive<InterfaceScheduleDate>({
      y: props.date.getFullYear() + '',
      m: formatTime(props.date.getMonth() + 1),
      d: formatTime(props.date.getDate()),
      w: ['日', '月', '火', '水', '木', '金', '土'][props.date.getDay()],
    })
    const isDatePicker = ref(props.isDatePicker)
    const resetIsDatePicker = (): void => {
      isDatePicker.value = !isDatePicker.value
      emit('isDisplayDatePicker', isDatePicker.value)
    }

    return {
      ...toRefs(state),
      resetIsDatePicker,
    }
  },
})
