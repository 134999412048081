export const SET_GRADE_ATTEND_FISCAL_YEAR = 'SET_GRADE_ATTEND_FISCAL_YEAR'
export const SET_GRADE_CERTIFICATION_LIST = 'SET_GRADE_CERTIFICATION_LIST'
export const SET_GRADE_CERTIFICATION = 'SET_GRADE_CERTIFICATION'
export const SET_GRADE_COURSE_GROUP_LIST = 'SET_GRADE_COURSE_GROUP_LIST'
export const SET_GRADE_COURSE_GROUP = 'SET_GRADE_COURSE_GROUP'
export const SET_GRADE_BRANCH_STORE_LIST = 'SET_GRADE_BRANCH_STORE_LIST'
export const SET_GRADE_BRANCH_STORE = 'SET_GRADE_BRANCH_STORE'
export const SET_GRADE_CLASSROOM_LIST = 'SET_GRADE_CLASSROOM_LIST'
export const SET_GRADE_CLASSROOM = 'SET_GRADE_CLASSROOM'
export const SET_GRADE_PERSONAL_NUMBER = 'SET_GRADE_PERSONAL_NUMBER'
export const SET_GRADE_ATTEND_ID = 'SET_GRADE_ATTEND_ID'
export const SET_GRADE_FULL_NAME_KATAKANA = 'SET_GRADE_FULL_NAME_KATAKANA'
export const SET_GRADE_FULL_NAME = 'SET_GRADE_FULL_NAME'
export const SET_GRADE_SEARCH_RESULT_TABLE = 'SET_GRADE_SEARCH_RESULT_TABLE'
