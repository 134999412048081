
import BaseTextBox from '@/components/common/BaseTextBox.vue'
import { defineComponent, ref, onBeforeMount } from 'vue'

export default defineComponent({
  name: 'DestinationNameInput',

  props: {
    error: String,
    value: String,
    disabled: Boolean,
  },

  setup(props, { emit }) {
    const destinationName = ref<string>('')

    onBeforeMount(() => {
      destinationName.value = props.value ?? ''
    })

    const handleInput = (value: string): void => {
      destinationName.value = value
      emit('input', value)
    }

    return { destinationName, handleInput }
  },

  components: { BaseTextBox },
})
