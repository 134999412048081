import {
  CLIENT_ID,
  CLIENT_SECRET,
  ATTRIBUTES_KEYS,
  GRANT_TYPES,
  DOMAINS,
} from '@/config'
import { generateCodeVerifier, getMessage } from '@/Utils'
import {
  instanceBFF,
  instanceSignOn,
  requestUploadBFF,
  requestDownloadBFF,
} from '@/request'
import { getRefreshTokenFromSession } from '@/services/ControlToken'
import {
  ApplicationInformation,
  ExaminationDate,
  PrefectureClassification,
  SendMessageReadStatusItem,
  CountMessage,
  ReceiveReplyMessage,
} from '@/typings'
import {
  BranchStore,
  Certification,
  Classroom,
  CourseGroup,
  StudentInformation,
  Teacher,
  LabelClassification,
} from '@/typings/index'
import {
  ClassroomCondition,
  StudentInformationCondition,
  TeacherCondition,
} from '@/typings/GradeSearch'
import {
  TrainingDay,
  GetTestInformationArguments,
  TestInformation,
  AddAnswerArguments,
} from '@/typings/TrainingDay'
import { TestClass } from '@/typings/TestClass'
import { Subject } from '@/typings/Subject'
import { GradeBySubject } from '@/typings/GradeBySubject'
import {
  MogiShikenDetailList,
  MogiShikenList,
  MogiShikenSeisekiList,
} from '@/typings/MogiShiken'
import { AccountRegistInitializeData } from '@/typings/AccountInformationRegist'
import { useGlobalError } from '@/services/Hooks'
import { MessageSendArguments } from '@/typings/InformationMessage'
import {
  AttendCertificationInformation,
  AttendCertificationInformationUpdateArguments,
  PersonalInformation,
  PersonalInformationStatus,
  JukoUserPersonalInformation,
} from '@/typings/PersonalInformationConfig'
import {
  DailyScheduleModel,
  getDailyScheduleParameters,
  getUserScheduleSummaryParameters,
  UserScheduleSummary,
  ResultList,
} from '@/typings/PassDiary'
import { GradeDetailByTestClass } from '@/typings/GradeByTestClass'
import {
  setAccessTokenToSession,
  setRefrehTokenToSession,
} from '@/services/ControlToken'

declare const Buffer: any
const { setGlobalError, clearGlobalError } = useGlobalError()

/**
 * トークンを取得する。
 * @returns
 */
export const queryToken = async (code: string): Promise<any> => {
  // リクエストのパラメーターを作成。
  const parameters = new URLSearchParams()

  parameters.append(
    ATTRIBUTES_KEYS.GRANT_TYPE,
    GRANT_TYPES.GET_TOKEN_FIRST_TIME
  )

  parameters.append(ATTRIBUTES_KEYS.CODE, code)
  parameters.append(ATTRIBUTES_KEYS.REDIRECT_URI, DOMAINS.WEB_SERVER ?? '')
  parameters.append(ATTRIBUTES_KEYS.CLIENT_ID, CLIENT_ID)
  parameters.append(
    ATTRIBUTES_KEYS.CODE_VERIFIER,
    sessionStorage.getItem(ATTRIBUTES_KEYS.CODE_VERIFIER) ?? ''
  )

  let encodedClientIdAndSecret: string = Buffer.from(
    `${CLIENT_ID}:${CLIENT_SECRET}`
  ).toString('base64')

  const response = await instanceSignOn.post('', parameters, {
    headers: {
      // btoa(): Base64 にエンコード、デコードは atob()
      Authorization: `Basic ${encodedClientIdAndSecret}`,
    },
  })

  const { access_token, refresh_token } = response.data?.data

  return [access_token, refresh_token]
}

/**
 * トークンを更新する。
 */
export const updateToken = async () => {
  // リクエストのパラメーターを作成。
  const parameters = new URLSearchParams()
  parameters.append(ATTRIBUTES_KEYS.GRANT_TYPE, GRANT_TYPES.REFRESH_TOKEN)

  parameters.append(
    process.env.VUE_APP_REFRESH_TOKEN,
    getRefreshTokenFromSession()
  )

  parameters.append(ATTRIBUTES_KEYS.CLIENT_ID, CLIENT_ID)
  parameters.append(ATTRIBUTES_KEYS.CLIENT_SECRET, CLIENT_SECRET)
  parameters.append(ATTRIBUTES_KEYS.CODE_VERIFIER, generateCodeVerifier())

  let encodedClientIdAndSecret: string = Buffer.from(
    `${CLIENT_ID}:${CLIENT_SECRET}`
  ).toString('base64')

  // 認証サーバーにトークンを請求する。
  const response = await instanceSignOn.post('', parameters, {
    headers: {
      // btoa(): Base64 にエンコード、デコードは atob()
      Authorization: `Basic ${encodedClientIdAndSecret}`,
    },
  })

  const { access_token, refresh_token } = response.data?.data

  // 更新されたトークンを保存する。
  setAccessTokenToSession(access_token)
  setRefrehTokenToSession(refresh_token)
}

/**
 * 共通 - 区分マスタ（受講種別）取得 API
 * @returns
 */
export const queryCertificationList = async (): Promise<Certification[]> => {
  try {
    const query = `
    query {
      findKbnMaster(input: { systemId: "ST", kbnCd: "0006" }) {
        kbn
        kana
        name
        name1
        name2
        name3
        name4
        name5
        sort
      }
    }
  `

    const { data } = await instanceBFF.post('', query)

    return data.data.findKbnMaster ?? []
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 共通 - 都道府県を取得
 * @returns
 */
export const queryPrefectureList = async (): Promise<
  PrefectureClassification[]
> => {
  try {
    const query = `
    query {
      findKbnMaster(input: { systemId: "ST", kbnCd: "0003" }) {
        kbn
        kana
        name
        name1
        name2
        name3
        name4
        name5
        sort
      }
    }
  `

    const { data } = await instanceBFF.post('', query)

    return data.data.findKbnMaster ?? []
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 共通 - 申込情報一覧を請求
 * @param userID 受講 ID
 * @returns
 */
export const queryApplicationInformationList = async (
  userID: string
): Promise<ApplicationInformation[]> => {
  try {
    clearGlobalError()
    const query = `
    query {
      findApplicationListByUser(userId: "${userID ?? ''}") {
        applicationNo
        jukoFiscalYear
        jukoClassCd
        jukoClassName
        courseName
      }
    }
  `

    const {
      findApplicationListByUser,
    }: { [property: string]: ApplicationInformation[] } = (
      await instanceBFF.post('', query)
    ).data.data

    if (!findApplicationListByUser?.length) {
      return Promise.reject(new Error(getMessage('EFR0036')))
    } else {
      return findApplicationListByUser
    }
  } catch (error) {
    setGlobalError(error)
    return []
  }
}

/**
 * 共通 - 申込情報を請求
 * @param userID 受講 ID
 * @returns
 */
export const queryApplicationInformation = async (
  userID: string
): Promise<ApplicationInformation> => {
  try {
    const query = `
    query {
      findScheduledApplication(userId: "${userID}") {
          applicationNo
          jukoFiscalYear
          userId
          jukoClassCd
          jukoClassName
          departmentDraftingKbn
          courseCd
          courseName
          branchCd
          classroomCd
          personalNo
      }
    }
  `

    const { findScheduledApplication } = await (
      await instanceBFF.post('', query)
    ).data.data

    return findScheduledApplication
  } catch (error) {
    setGlobalError(error)
    return {}
  }
}

/**
 * 共通 - 申込情報を登録
 * @param userID 受講 ID
 * @param applicationNo 予定申込情報
 * @returns
 */
export const setApplicationInformation = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<boolean> => {
  const mutation = `
    mutation {
      addScheduledApplication(
        userId: "${userID}",
        applicationNo: "${applicationNo}"
      )
    }
  `

  return (await instanceBFF.post('', mutation)).data.data
    .addScheduledApplication
}

/**
 * 共通 - 登録済み試験日を取得
 * @param userID 受講 ID
 * @returns
 */
export const queryExaminationDay = async (
  userID: string
): Promise<ExaminationDate> => {
  try {
    const query = `
    query {
      findScheduledExaminationDate(userId: "${userID}") {
        jukoClassCd
        kbnName
        examDate
        decision
      }
    }
  `

    return (await instanceBFF.post('', query)).data.data
      .findScheduledExaminationDate
  } catch (error) {
    setGlobalError(error as Error)
    return {} as ExaminationDate
  }
}

/**
 * 共通 - 試験日を登録
 * @param userID 受講 ID
 * @param examinationDay 試験日
 * @returns
 */
export const setExaminationDay = async ({
  userID,
  examinationDay,
}: {
  userID: string
  examinationDay: ExaminationDate
}): Promise<boolean> => {
  const mutation = `
      mutation {
        addScheduledExaminationDate(
          userId: "${userID}",
          input:{
            jukoClassCd: "${examinationDay?.jukoClassCd}",
            kbnName: "${examinationDay?.kbnName}",
            examDate: "${examinationDay?.examDate}"
            decision: ${examinationDay?.decision}
          }
        )
      }
    `

  return (await instanceBFF.post('', mutation)).data.data
    .addScheduledExaminationDate
}

/**
 * 共通 - 担当者コースグループ情報取得 API
 * @param attendFiscalYear 受講年度
 * @param certificationCode 受講種別区分
 * @returns
 */
export const queryCourseGroupList = async (
  attendFiscalYear: string | number,
  certificationCode: string
): Promise<CourseGroup[]> => {
  const query = `
    query {
      getCourseGroupList(
        jukoFiscalYear: "${attendFiscalYear}",
        jukoClassKbn: "${certificationCode}"
      ) {
        courseGroupCd
        courseGroupNm
      }
    }
  `

  const { getCourseGroupList } = (await instanceBFF.post('', query)).data.data

  return getCourseGroupList ?? Promise.resolve([])
}

/**
 * 共通 - 担当者支店情報取得 API
 * @param userType ユーザーのロール ID
 * @param userID 社員 ID（ユーザーのログイン ID）
 * @returns
 */
export const queryBranchStoreList = async (
  userType: string,
  userID: string
): Promise<BranchStore[]> => {
  const query = `
    query {
      getShitenList(
        userKbn: "0${userType}",
        userKbnId: "${userID}"
      ) {
        branchCd
        branchNm
      }
    }
  `

  const { getShitenList } = (await instanceBFF.post('', query)).data.data

  return getShitenList ?? Promise.resolve([])
}

/**
 * 共通 - 担当者教室情報取得 API
 * @param condition { 受講年度, 受講種別区分, コースグループコード, 支店コード }
 * @returns
 */
export const queryClassroomList = async ({
  attendFiscalYear,
  certificationCode,
  courseGroupCode,
  branchStoreCode,
}: ClassroomCondition): Promise<Classroom[]> => {
  const query = `
    query {
      getKyoshitsuList(
        jukoFiscalYear: "${attendFiscalYear}",
        jukoClassKbn: "${certificationCode}",
        courseGroupCd: "${courseGroupCode}",
        branchCd: "${branchStoreCode}"
      ) {
        classroomCd
        classroomNm
      }
    }
  `

  const { getKyoshitsuList } = (await instanceBFF.post('', query)).data.data

  return getKyoshitsuList ?? Promise.resolve([])
}

/**
 * 共通 - 担当者講師情報取得 API
 * @param condition { 受講年度, 受講種別区分, コースグループコード, 支店コード, 教室コード }
 * @returns
 */
export const queryTeacherList = async ({
  attendFiscalYear,
  certificationCode,
  branchStoreCode,
  classroomCode,
}: TeacherCondition): Promise<Teacher[]> => {
  const query = `
    query {
      getKoshiList(
        jukoFiscalYear: "${attendFiscalYear}",
        jukoClassKbn: "${certificationCode}",
        branchCd: "${branchStoreCode}",
        classroomCd: "${classroomCode}"
      ) {
        koshiId
        koshiNm
      }
    }
  `

  const { getKoshiList } = (await instanceBFF.post('', query)).data.data

  return getKoshiList ?? Promise.resolve([])
}

/**
 * 共通 - 受講生情報取得 API
 * @param condition {
 *  受講年度,
 *  受講種別区分,
 *  コースグループCD,
 *  支店コード,
 *  教室コード,
 *  講師 ID,
 *  個人番号,
 *  受講 ID,
 *  氏名,
 *  氏名カナ,
 *  ユーザーロール ID,
 *  ユーザーログイン ID
 * }
 * @returns
 */
export const queryStudentInformationList = async ({
  attendFiscalYear,
  certificationCode,
  courseGroupCode,
  branchStoreCode,
  classroomCode,
  teacherID,
  personalNumber,
  attendID,
  fullName,
  fullNameKatakana,
  userType,
  userID,
}: StudentInformationCondition): Promise<StudentInformation[]> => {
  try {
    clearGlobalError()
    const query = `
    query {
      getJukoseiList(
        jukoFiscalYear: "${attendFiscalYear}",
        jukoClassKbn: "${certificationCode}",
        courseGroupCd: "${courseGroupCode ?? ''}",
        branchCd: "${branchStoreCode ?? ''}",
        classroomCd: "${classroomCode ?? ''}",
        koshiId: "${teacherID ?? ''}",
        personalNo: "${personalNumber ?? ''}",
        jukoId: "${attendID ?? ''}",
        name: "${fullName ?? ''}",
        kana: "${fullNameKatakana ?? ''}",
        userKbn: "0${userType}",
        userId: "${userID}"
      ) {
        personalNo
        jukoId
        kana
        name
        courseCd
        sogoFlg
        branchNm
        classroomNm
        applicationNo
      }
    }
  `

    const { getJukoseiList }: { [property: string]: StudentInformation[] } = (
      await instanceBFF.post('', query)
    ).data.data

    if (!getJukoseiList?.length) {
      return Promise.reject(new Error(getMessage('EFR0017')))
    } else if (getJukoseiList.length > 300) {
      return Promise.reject(new Error(getMessage('EFR0023')))
    } else {
      return getJukoseiList
    }
  } catch (error) {
    setGlobalError(error)
    return []
  }
}

/**
 * 試験日マスタ登録画面 - 初期化データを請求
 * @returns
 */
export const queryExamDateMaintenanceInitializeData = async () => {
  const query = `
    query{
      findKbnMaster(input:{systemId:"ST",kbnCd:"0006"}){
        kbn
        kana
        name
        sort
      }

      findExaminationDate {
        jukoClassCd
        kbnName
        examDate
        decision
      }
    }
  `

  return (await instanceBFF.post('', query)).data.data
}

/**
 * 試験日マスタ登録画面 - 試験日を新規作成
 * @returns
 */
export const createExaminationDate = async ({
  jukoClassCd,
  kbnName,
  examDate,
  decision,
}: {
  [property: string]: any
}) => {
  const mutation = `
    mutation {
      addExaminationDate(
        input: {
          jukoClassCd: "${jukoClassCd}",
          kbnName: "${kbnName}",
          examDate: "${examDate}",
          decision: ${decision}
        }
      )
    }
  `

  return instanceBFF.post('', mutation)
}

/**
 * 試験日マスタ登録画面 - 試験日を削除
 * @returns
 */
export const deleteExaminationDate = async ({
  jukoClassCd,
  kbnName,
  examDate,
  decision,
}: {
  [property: string]: any
}) => {
  const mutation = `
          mutation {
            deleteExaminationDate(
              input: {
                jukoClassCd: "${jukoClassCd}",
                kbnName: "${kbnName}",
                examDate: "${examDate.replace(/\//g, '-')}",
                decision: ${decision}
              }
            )
          }
        `

  return instanceBFF.post('', mutation)
}

/**
 * 試験日マスタ登録画面 - 試験日を変更
 * @returns
 */
export const modifyExaminationDate = async ({
  targetExaminationDate,
  jukoClassCd,
  kbnName,
  examDate,
  decision,
}: {
  [property: string]: any
}) => {
  const mutation = `
    mutation {
      updateExaminationDate(
        key: {
          jukoClassCd: "${targetExaminationDate.jukoClassCd}",
          kbnName: "${targetExaminationDate.kbnName}",
          examDate: "${targetExaminationDate.examDate}",
          decision: ${targetExaminationDate.decision}
        }
        input: {
          jukoClassCd: "${jukoClassCd}",
          kbnName: "${kbnName}",
          examDate: "${examDate}",
          decision: ${decision}
        }
      )
    }
  `

  return instanceBFF.post('', mutation)
}

/**
 * 試験日マスタ登録画面 - 試験日マスタデータを請求
 * @returns
 */
export const queryExaminationDates = async () => {
  const query = `
    query{
      findExaminationDate {
        jukoClassCd
        kbnName
        examDate
        decision
      }
    }
  `

  return instanceBFF.post('', query)
}

/**
 * アカウント情報登録画面 - 初期化データを請求
 * @param userID 受講 ID
 * @returns
 */
export const queryAccountRegistInitializeData = async (
  userID: string
): Promise<AccountRegistInitializeData> => {
  // 区分マスターを取得する条件
  const queryKbnMasterArguments = {
    systemId: 'ST',
    kbnCd: '0006',
    kbn: '',
  }

  /**
   * - findApplicationListByUser: ユーザの予定申込情報の取得
   * - findScheduledApplication: 予定申込情報の登録
   * - findExaminationDateByUser: ユーザの試験日一覧
   * - findScheduledExaminationDate: ユーザの予定試験日
   * - findKbnMaster: 区分マスター
   */
  const query = `
      query {
        findApplicationListByUser(userId: "${userID}") {
          applicationNo
          jukoFiscalYear
          jukoClassName
          courseName
        }

        findScheduledApplication(userId: "${userID}") {
          applicationNo
          jukoFiscalYear
          jukoClassName
          courseName
        }

        findExaminationDateByUser(userId: "${userID}") {
          jukoClassCd
          kbnName
          examDate
          decision
        }

        findScheduledExaminationDate(userId: "${userID}") {
          jukoClassCd
          kbnName
          examDate
          decision
        }

        findKbnMaster(
          input: {
            systemId: "${queryKbnMasterArguments.systemId}",
            kbnCd: "${queryKbnMasterArguments.kbnCd}"
            kbn: "${queryKbnMasterArguments.kbn}"
          }
        ) {
          kbn
          kana
          name
        }
      }
    `

  const {
    findKbnMaster,
    findApplicationListByUser,
    findScheduledApplication,
    findExaminationDateByUser,
    findScheduledExaminationDate,
  } = (await instanceBFF?.post('', query)).data.data

  return {
    certificationList: findKbnMaster,
    applicationInformation: findScheduledApplication,
    applicationInformationList: findApplicationListByUser,
    userExamination: findScheduledExaminationDate,
    examinationList: findExaminationDateByUser,
  }
}

/**
 * 成績参照画面（講習日別） - 講習日初期化 API
 * @param userID 受講 ID
 * @param applicationNo 申込番号
 * @returns
 */
export const queryTraningDayInitializeData = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<TrainingDay> => {
  const query = `
    query {
      getKoshuDateList(
        userId: "${userID ?? ''}", 
        applicationNo: "${applicationNo ?? ''}"
      ) {
        koshuChokkinDt
        koshuChokkinSu
        koshuDateInfoLst {
          koshuDt
          koshuSu
          ampmNm
        }
      }
    }
  `

  return (await instanceBFF.post('', query)).data.data.getKoshuDateList
}

// 成績参照画面（科目回別） - 科目回初期化 API
export const querySubjectInitializeData = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<Subject> => {
  const data = `
    query {
      getKamokuList(
        userId: "${userID}", 
        applicationNo: "${applicationNo}"
      ) {
        chokkinSeqNo
        kamokuInfoLst {
          kamokuNm
          seqNo
          sortNo
        }
      }
    }
  `

  return (await instanceBFF.post('', data)).data.data.getKamokuList
}

// 成績参照画面（科目回別） - 科目回別成績データ初期化 API
export const queryGradeBySubject = async ({
  userID,
  applicationNo,
  seqNo,
}: {
  userID: string
  applicationNo: string
  seqNo: string
}): Promise<GradeBySubject> => {
  const data = `
    query {
      getSeisekiByKamoku(
        userId: "${userID}", 
        applicationNo: "${applicationNo}",
        seqNo: "${seqNo}"
      ) {
        kamokuNm
        kogiKomokuNm
        kamokuSeisekiInfoLst {
          testClassKbn
          testClassNm
          tokutenSu
          mantenSu
          teishutsuFlg
          testCd
          testKamokuCd
          classId
          shussekiFlg
          testNm
        }
      }
    }
  `
  const { getSeisekiByKamoku } = (await instanceBFF.post('', data)).data.data
  return getSeisekiByKamoku
}

// 成績参照画面（模擬試験） - 模擬試験リスト初期化 API
export const queryMogiShikenInitializeData = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<MogiShikenList> => {
  const query = `
    query {
      getMogiShikenList(
        userId: "${userID}", 
        applicationNo: "${applicationNo}"
      ) {
        testChokkinCd
        list {
          testCd
          testNm
        }
      }
    }
  `

  const { getMogiShikenList } = (await instanceBFF.post('', query)).data.data

  return getMogiShikenList
}

// 成績参照画面（模擬試験） - 模擬試験成績リスト初期化 API
export const queryGradeOfMogiShiken = async ({
  userID,
  applicationNo,
  testCd,
}: {
  userID: string
  applicationNo: string
  testCd: string
}): Promise<MogiShikenSeisekiList> => {
  const query = `
    query {
      getMogiShiken(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          testCd: "${testCd}"
        }
      ) {
        testNm
        list {
          testCd
          testKamokuCd
          testNm
          hyojiFlg
          tokutenSu
          mantenSu
          hensachiSu
          juniSu
          jukenshaSu
          heikinSu
          kijuntenSu
          classId
          shussekiFlg
        }
      }
    }
  `

  return (await instanceBFF.post('', query)).data.data.getMogiShiken
}

// 成績参照画面（模擬試験） - 模擬試験成績詳細リスト初期化 API
export const queryMogiShikenDetail = async ({
  userID,
  applicationNo,
  testCd,
  testKamokuCd,
}: {
  userID: string
  applicationNo: string
  testCd: string
  testKamokuCd: string
}): Promise<MogiShikenDetailList> => {
  const query = `
    query {
      getMogiShikenDetail(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          testCd: "${testCd}",
          testKamokuCd: "${testKamokuCd}"
        }
      ) {
        testNm
        list {
          mondaiNo
          seigoFlg
          kaitoNm
          seitoNm
          typeMondaiNm
          typeBunruiNm
          typeKomokuNm
        }
      }
    }
  `

  return (await instanceBFF.post('', query)).data.data.getMogiShikenDetail
}

/**
 * 成績参照画面（その他） - 種別初期化 API
 * @param userID 受講 ID
 * @param applicationNo 申込番号
 * @returns
 */
export const queryOtherTestClassInitializeData = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<TestClass> => {
  const query = `
    query {
      getOtherTestClassList(
        userId: "${userID ?? ''}", 
        applicationNo: "${applicationNo ?? ''}"
      ) {
        testClassKbn
        testClassInfoLst {
          testClassKbn
          testClassNm
          sortNo
        }
      }
    }
  `
  return (await instanceBFF.post('', query)).data.data.getOtherTestClassList
}

// 成績参照画面（その他） - 種別成績データ初期化 API
export const queryOtherGradeByTestClass = async ({
  userID,
  applicationNo,
  testClassKbn,
}: {
  userID: string
  applicationNo: string
  testClassKbn: string
}): Promise<GradeDetailByTestClass[]> => {
  const data = `
    query {
      queryOtherSeisekiByTestClass(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          testClassKbn: "${testClassKbn}"
        }
      ) {
        kamokuNm
        testClassKbn
        testClassNm
        tokutenSu
        mantenSu
        teishutsuFlg
        testCd
        testKamokuCd
        classId
        shussekiFlg
        testNm
        sortNo
      }
    }
  `
  const { queryOtherSeisekiByTestClass } = (await instanceBFF.post('', data))
    .data.data
  return queryOtherSeisekiByTestClass
}

/**
 * 成績参照画面（テスト種別） - 種別初期化 API
 * @param userID 受講 ID
 * @param applicationNo 申込番号
 * @returns
 */
export const queryTestClassInitializeData = async ({
  userID,
  applicationNo,
}: {
  userID: string
  applicationNo: string
}): Promise<TestClass> => {
  const query = `
    query {
      getTestClassList(
        userId: "${userID ?? ''}", 
        applicationNo: "${applicationNo ?? ''}"
      ) {
        testClassKbn
        testClassInfoLst {
          testClassKbn
          testClassNm
          sortNo
        }
      }
    }
  `
  return (await instanceBFF.post('', query)).data.data.getTestClassList
}

// 成績参照画面（テスト種別） - 種別成績データ初期化 API
export const queryGradeByTestClass = async ({
  userID,
  applicationNo,
  testClassKbn,
}: {
  userID: string
  applicationNo: string
  testClassKbn: string
}): Promise<GradeDetailByTestClass[]> => {
  const data = `
    query {
      querySeisekiByTestClass(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          testClassKbn: "${testClassKbn}"
        }
      ) {
        kamokuNm
        testClassKbn
        testClassNm
        tokutenSu
        mantenSu
        teishutsuFlg
        testCd
        testKamokuCd
        classId
        shussekiFlg
        testNm
      }
    }
  `
  const { querySeisekiByTestClass } = (await instanceBFF.post('', data)).data
    .data
  return querySeisekiByTestClass
}
/**
 * 個人情報変更画面 - 個人情報ステータスを取得
 * @param attendID
 * @returns
 */
export const queryPersonalInformationStatus = async (
  attendID: string
): Promise<PersonalInformationStatus> => {
  try {
    const query = `
      query {
        getPersonalInfoStatus(jukoId: "${attendID}") {
          status
          message
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.getPersonalInfoStatus
  } catch (error) {
    setGlobalError(error as Error)
    return {} as PersonalInformationStatus
  }
}

/**
 * 個人情報変更画面 - 個人受験情報を取得
 * @param attendID
 * @returns
 */
export const queryAttendCertificationInformationList = async (
  attendID: string
): Promise<AttendCertificationInformation[]> => {
  try {
    const query = `
      query {
        getJukenInfo(jukoId: "${attendID}") {
          jukoClassKbn
          jukoClassNm
          jukenNo
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.getJukenInfo
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 個人情報変更画面 - 個人受験情報を更新
 * @param input
 * @returns
 */
export const updateAttendCertificationInformation = async (
  input: AttendCertificationInformationUpdateArguments
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        updateJukenNo(
          jukoId: "${input.attendID}"
          jukoClassKbn: "${input.certificationCode}"
          jukenNo: "${input.examNumber}"
        )
      }
    `

    const { data } = await instanceBFF.post('', mutation)

    return data.data.updateJukenNo
  } catch (error) {
    setGlobalError(error as Error)
    return false
  }
}

/**
 * 個人情報変更画面 - 個人情報を取得
 * @param attendID 受講 ID（ユーザー ID）
 * @returns
 */
export const queryPersonalInformation = async (
  attendID: string
): Promise<PersonalInformation> => {
  try {
    const query = `
      query {
        getPersonalInfo(jukoId: "${attendID}") {
          yubinNo
          todofukenNm
          address1
          address2
          address3
          tellphone
          email
        }
      }
    `

    const { data } = await instanceBFF.post('', query)
    const result = data.data.getPersonalInfo

    return {
      postCode: result.yubinNo,
      prefecture: result.todofukenNm,
      address1: result.address1,
      address2: result.address2,
      address3: result.address3,
      phoneNumber: result.tellphone,
      mail: result.email,
    }
  } catch (error) {
    setGlobalError(error as Error)
    return {} as PersonalInformation
  }
}

/**
 * 個人情報変更画面 - 個人情報を登録
 * @param input
 * @returns
 */
export const updatePersonalInformation = async (
  input: PersonalInformation
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        updatePersonalInfo(
          input: {
            jukoId: "${input.userID}",
            yubinNo: "${input.postCode}",
            todofukenNm: "${input.prefecture}",
            address1: "${input.address1}",
            address2: "${input.address2}",
            address3: "${input.address3}",
            tellphone: "${input.phoneNumber}",
            email: "${input.mail}",
          }
        )
      }
    `

    const response = await instanceBFF.post('', mutation)

    return response.data.data.updatePersonalInfo
  } catch (error) {
    console.log(error as Error)
    return false
  }
}

/**
 * メッセージ確認画面 - 添付ファイルを送信
 */
export const sendFilesOfMessage = async (
  formData: FormData
): Promise<string[]> => {
  try {
    const response = await requestUploadBFF(formData)
    return response.data.idList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * メッセージ詳細画面 - 添付ファイルをダウンロード
 */
export const downloadFile = async (id: string): Promise<any> => {
  try {
    const response = await requestDownloadBFF(id)
    if (
      response?.data.hasOwnProperty('is_success') &&
      !response?.data.is_success
    ) {
      return ''
    }
    if (id.slice(-3).toLowerCase() === 'pdf') {
      return 'data:application/pdf;base64,' + response.data
    } else {
      return 'data:image/png;base64,' + response.data
    }
  } catch (error) {
    setGlobalError(error as Error)
    return ''
  }
}

/**
 * メッセージ確認画面 - メッセージを送信
 */
export const sendInformationMessage = async (
  input: MessageSendArguments
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        sendMessage(input: {
          sender: "${input.userID ?? ''}",
          sendTo: ${JSON.stringify(input.destinationList)},
          subject: "${encodeURIComponent(input.subject) ?? ''}"
          body: "${encodeURIComponent(input.body) ?? ''}"
          attachmentList: ${JSON.stringify(input.attachmentKeyList)}
          enableReply: ${input.enableReply}
          range: ${JSON.stringify(input.range)}
          branchCd: "${input.branchCd ?? ''}",
        })
      } 
    `

    const response = await instanceBFF.post('', mutation)

    return response.data.data.sendMessage
  } catch (error) {
    setGlobalError(error as Error)
    return false
  }
}

/**
 * 利用支店一覧画面 - 各支店でのインフォメーション利用状況を取得
 * @param userIds ユーザId配列
 * @param date　対応年月（日は使っていない）
 */
export const queryInformationBranchList = async (
  userIds: string[],
  date: string
): Promise<CountMessage[]> => {
  try {
    const query = `
      query {
        countMessageByMonthly(
          userIds: ${JSON.stringify(userIds)},
          date:${JSON.stringify(date)},
        ) {
          userId
          count
        }
      }
    `
    const response = await instanceBFF.post('', query)
    return response.data.data.countMessageByMonthly
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 未読者一覧画面 - 送信メッセージの既読ステータス取得
 * @param sender 送信者
 * @param messageNo　メッセージ番号
 */
export const querySendMessageReadStatus = async (
  sender: string,
  messageNo: string
): Promise<SendMessageReadStatusItem[]> => {
  try {
    const query = `
      query {
        sentMessageReadStatusList(
          userId: "${sender ?? ''}",
          messageNo: "${messageNo ?? ''}",
        ) {
          receiver
          readTimestamp
          read
        }
      }
    `
    const response = await instanceBFF.post('', query)
    return response.data.data.sentMessageReadStatusList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 回答一覧画面 - 送信メッセージの回答情報取得
 * @param sender 送信者
 * @param messageNo　メッセージ番号
 */
export const queryReceiveReply = async (
  sender: string,
  messageNo: string
): Promise<ReceiveReplyMessage[]> => {
  try {
    const query = `
      query {
        receiveReplyList(
          userId: "${sender ?? ''}",
          messageNo: "${messageNo ?? ''}",
        ) {
          receiver
          message { sender, subject, body, sendTimestamp, messageNo, replyNo, enableReply, replyed}
        }
      }
    `
    const response = await instanceBFF.post('', query)
    return response.data.data.receiveReplyList
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 解答登録（マークシート）画面 - テスト情報取得API
 */
export const getTestInformation = async (
  input: GetTestInformationArguments
): Promise<TestInformation> => {
  try {
    const query = `
    query {
      getTestInformation(
        input: {
          testCd: "${input.testCd}", 
          testKamokuCd: "${input.testKamokuCd}",
          jukoFiscalYear: "${input.jukoFiscalYear}"
          jukoClassKbn: "${input.jukoClassKbn}"
        }
      ) {
        testCd
        testKamokuCd
        testNm
        mondaiSu
        sentakuSu
        teishutsuFlg
        menjoFlg
        chuiJikoNm
      }
    }
  `

    const response = await instanceBFF.post('', query)
    return response.data.data.getTestInformation
  } catch (error) {
    setGlobalError(error as Error)
    return {}
  }
}
/**
 * 解答登録（マークシート）画面 - 解答入力登録API
 */
export const addAnswer = async (
  input: AddAnswerArguments
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        addAnswer(input: {
          jukoFiscalYear: "${input.jukoFiscalYear ?? ''}",
          jukoClassKbn: ${JSON.stringify(input.jukoClassKbn)},
          testCd: "${input.testCd ?? ''}"
          testKamokuCd: "${input.testKamokuCd ?? ''}"
          jukoId: ${JSON.stringify(input.jukoId)}
          kaitoNmList: ${JSON.stringify(input.kaitoNmList)}
          applicationNo: ${JSON.stringify(input.applicationNo)}
          
        })
      } 
    `
    const response = await instanceBFF.post('', mutation)
    return response.data.data.addAnswer
  } catch (error) {
    setGlobalError(error as Error)
    return false
  }
}

/**
 * 予実確認（TOP）画面 - 日次スケジュールの取得API
 */
export const getDailySchedule = async (
  input: getDailyScheduleParameters
): Promise<DailyScheduleModel[]> => {
  try {
    const query = `
    query {
      getDailySchedule(
        userId: "${input.userCode}", 
        applicationNo: "${input.applicationNo}",
        date: "${input.date}"
      ) {
        yojitsuFlg
        date
        startHour
        startMinute
        endHour
        endMinute
        label
        memo
      }
    }
  `
    const response = await instanceBFF.post('', query)
    return response.data.data.getDailySchedule
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 共通 - 区分マスタ取得 API(ラベル)
 * @returns
 */
export const getLabelOfKbnMaster = async (): Promise<LabelClassification[]> => {
  // 区分マスターを取得する条件
  const queryKbnMasterArguments = {
    systemId: 'SS',
    kbnCd: '0190',
    kbn: '',
  }

  try {
    const query = `
    query {
      findKbnMaster(
        input: {
          systemId: "${queryKbnMasterArguments.systemId}",
          kbnCd: "${queryKbnMasterArguments.kbnCd}"
          kbn: "${queryKbnMasterArguments.kbn}"
        }
      ) {
        kbn
        kana
        name
        name1
        name2
        name3
        name4
      }
    }
  `

    const { data } = await instanceBFF.post('', query)

    return data.data.findKbnMaster ?? []
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 実績レポート画面 - 日次スケジュールの個人集計API
 */
export const getUserScheduleSummary = async (
  input: getUserScheduleSummaryParameters
): Promise<UserScheduleSummary[]> => {
  try {
    const query = `
    query {
      getUserScheduleSummary(
        userId: "${input.userCode ?? ''}", 
        applicationNo: "${input.applicationNo ?? ''}",
        startDate: "${input.startDate ?? ''}",
        kbn: ${input.kbn ?? ''},
        yojitsuFlg: ${input.yojitsuFlg}
      ) {
        actual {
          label
          value
        }
      }
    }
  `
    const response = await instanceBFF.post('', query)
    return response.data.data.getUserScheduleSummary
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 回答一覧画面 - 氏名を取得
 * @param attendID 受講 ID（ユーザー ID）
 * @returns
 */
export const queryPersonalInformationName = async (
  attendID: string[]
): Promise<JukoUserPersonalInformation[]> => {
  try {
    const query = `
      query {
        getJukoUserInfo(jukoId: ${JSON.stringify(attendID)}) {
          status
          message
          kana
          name
          birthday
          email
        }
      }
    `

    const response = await instanceBFF.post('', query)
    return response.data.data.getJukoUserInfo
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

/**
 * 合格ダイアリ―検索画面 - 日次スケジュールの利用状況取得API
 */
export const getUsageDailySchedule = async (
  userCdList: string[]
): Promise<ResultList[]> => {
  try {
    const query = `
    query {
      getUsageDailySchedule(
        userCdList: ${JSON.stringify(userCdList)},
      ) {
        userCd
        result
      }
    }
  `
    const response = await instanceBFF.post('', query)
    return response.data.data.getUsageDailySchedule
  } catch (error) {
    setGlobalError(error as Error)
    return []
  }
}

// 成績参照画面（その他） - 解答正誤リスト初期化 API
export const queryKaiToSeiGo = async ({
  userID,
  applicationNo,
  testCd,
  testKamokuCd,
}: {
  userID: string
  applicationNo: string
  testCd: string
  testKamokuCd: string
}): Promise<MogiShikenDetailList> => {
  const query = `
    query {
      getKaiToSeiGo(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          testCd: "${testCd}",
          testKamokuCd: "${testKamokuCd}"
        }
      ) {
        testNm
        list {
          mondaiNo
          seigoFlg
          kaitoNm
          seitoNm
          typeMondaiNm
          typeBunruiNm
          typeKomokuNm
        }
      }
    }
  `

  return (await instanceBFF.post('', query)).data.data.getKaiToSeiGo
}
