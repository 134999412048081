
/**
 * インフォメーション - メッセージ確認画面
 */
// Components
import BaseButton from '@/components/common/BaseButton.vue'
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ManagePageTitle from '@/components/common/ManagePageTitle.vue'
import MessageDetail from '../../components/common/InformationMessage/MessageDetailConfirm.vue'
// Dependencies
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { MessageDetailInterface } from '@/typings/InformationMessage'
import { useGlobalError } from '@/services/Hooks'
import { getMessage, getRoleIdFromJwt } from '@/Utils'
import { useRouter } from '@/router'
import { sendInformationMessage, downloadFile } from '@/request/api'
import { queryBranchStoreList } from '@/request/api/index'
import {
  queryDestinationList,
  registerDestinationList,
} from '@/request/api/information-message/destinationList'
import {
  getGlobalParams,
  setGlobalParams,
  MESSAGE_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import { State } from '@/views/InformationCreateMessage/index.vue'
import { SelectedDestinationItem } from '@/typings'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'
import { BranchStore } from '@/typings/index'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { FileData } from '@/typings/InformationMessage'
import { useStore } from '@/store'

const MESSAGE_STATE_STORAGE_KEY_NAME = 'createMessegeState'

interface UserInfo {
  userType: string
  userID: string
}

export interface ConfirmState extends MessageDetailInterface {
  // ユーザー ID
  userID: string
  // 宛先をリストに登録するかどうか
  isAddDestinationToList: boolean
  // 宛先リスト名
  destinationListName: string
  // 表示用閲覧範囲
  selectedBranchList: BranchStore[]
  // 送信用閲覧範囲
  range: string[]
  // アップロードファイルS3 ID
  uploadFileIdList: string[]
  // 送信者の所属支店CD
  branchCd: string
}

interface Option {
  branchStoreList: BranchStore[]
}

export default defineComponent({
  name: 'InformationMessageConfirm',

  setup() {
    const { setGlobalError, clearGlobalError } = useGlobalError()
    const store = useStore()
    const userInfo = reactive<UserInfo>({
      userType: '',
      userID: '',
    })

    const messageDetail = reactive<ConfirmState>({
      userID: '',
      destinationList: [],
      isAddDestinationToList: false,
      destinationListName: '',
      subject: '',
      body: '',
      updatedFileList: [],
      enableReply: false,
      selectedBranchList: [],
      range: [],
      uploadFileIdList: [],
      branchCd: '',
    })

    const option = reactive<Option>({
      branchStoreList: [],
    })
    // コンポネート再描画
    const refreshCom = ref<number>(0)
    onBeforeMount(async () => {
      const messageDetailFromSessionStorage =
        await getMessageDetailFromSessionStorage()
      if (Object.keys(messageDetailFromSessionStorage).length) {
        setMessageDetail(messageDetailFromSessionStorage)
        const accessToken = getAccessTokenFromSession()
        userInfo.userType = getRoleIdFromJwt(accessToken) ?? ''
        userInfo.userID = getUserIDFromAccessToken(accessToken)

        option.branchStoreList = await queryBranchStoreList(
          userInfo.userType,
          userInfo.userID
        )
        messageDetail.branchCd = option.branchStoreList[0].branchCd as string
        addDestinationTotal(messageDetailFromSessionStorage)
      }
      refreshCom.value++;
    })

    const destinationTotal = ref<number>(0)
    const isClickSendData = ref<boolean>(false)
    const uploadFileError = ref<string>("")
      
    const getMessageDetailFromSessionStorage = async (): Promise<State> => {
      try {
        const data: State = JSON.parse(
          sessionStorage.getItem(MESSAGE_STATE_STORAGE_KEY_NAME) ?? '{}'
        )
        if (!Object.keys(data).length) {
          throw new Error(getMessage('EFR0013', ['メッセージ詳細']))
        }
        clearGlobalError()
        const fileList = data.updatedFileList
        data.updatedFileList = []

        let fileIndex = 0;
        store?.commit('setIsLoadingWithText',true)
        store?.commit('setIsLoadingText',"ダウンロード中...")
        for await (let item of fileList) {
          item.url = await downloadFile(data.uploadFileIdList[fileIndex])
          if(!item.url){
              uploadFileError.value = getMessage('EFR0041')
              refreshCom.value++
              store?.commit('setIsLoadingWithText',false)
              throw new Error(getMessage('EFR0041'))
          }
          data.updatedFileList = [...data.updatedFileList, item]
          fileIndex++
        }
        store?.commit('setIsLoadingWithText',false)
        return data
      } catch (error) {
        console.log(error)
        setGlobalError(getMessage('EFR0010'))
        return {} as State
      }
    }

    const setMessageDetail = (item: State): void => {
      messageDetail.userID = item.userID
      messageDetail.destinationList = [...item.selectedDestinationList]
      messageDetail.isAddDestinationToList = item.isAddToDestiList
      messageDetail.destinationListName = item.listName
      messageDetail.subject = item.subject
      messageDetail.body = item.body
      messageDetail.updatedFileList = [...item.updatedFileList]
      messageDetail.enableReply = item.enableReply
      messageDetail.selectedBranchList = [...item.selectedBranchList]
      messageDetail.uploadFileIdList = [...item.uploadFileIdList]
    }

    const router = useRouter()

    const backToCreatePage = (): void => {
      router.push({
        name: 'CreateMessage',
        params: { transitionSource: 'Confirm' },
      })
    }

    const handleSendMessage = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      // ブラウザバックで再送信した場合
      if (getGlobalParams(MESSAGE_COMPLETE_PAGE)) {
        setGlobalError(getMessage('EFR0039', ['メッセージ']))
        return
      }
      // データを送信
      if (messageDetail.isAddDestinationToList) {
        const isDestinationListAdded: boolean = await doAddDestinationList()

        if (!isDestinationListAdded) {
          return
        }
      }

      const isMessageSent: boolean = await doSendInformationMessage()

      if (!isMessageSent) {
        return
      }

      setGlobalParams(MESSAGE_COMPLETE_PAGE, true)

      // 送信に成功した場合は完了画面へ遷移
      router.push({
        name: 'ManageNormalCompleted',
        params: { msg: getMessage('EFR0026', ['メッセージの送信']) },
      })
    }

    const doAddDestinationList = async (): Promise<boolean> => {
      return registerDestinationList({
        destinationListName: messageDetail.destinationListName,

        userIDList: messageDetail.destinationList
          .map<string[]>(destinationListFormatter)
          .flat(),
      })
    }

    const doSendInformationMessage = async (): Promise<boolean> => {
      messageDetail.selectedBranchList.forEach((item) => {
        messageDetail.range = [...messageDetail.range, item.branchCd as string]
      })

      messageDetail.range = messageDetail.range.filter(
        (index, item, self) => self.indexOf(index) === item
      )
      messageDetail.range = [...new Set(messageDetail.range)]

      return sendInformationMessage({
        userID: messageDetail.userID,

        destinationList: messageDetail.destinationList
          .map<string[]>(destinationListFormatter)
          .flat(),

        subject: messageDetail.subject,
        body: messageDetail.body,
        attachmentKeyList: messageDetail.uploadFileIdList,
        enableReply: messageDetail.enableReply,
        range: messageDetail.range,
        branchCd: messageDetail.branchCd,
      })
    }

    const destinationListFormatter = (
      item: SelectedDestinationItem | DestinationListModel
    ): string[] =>
      item.hasOwnProperty('createDate')
        ? [(item as DestinationListModel).destinationListName]
        : (item as SelectedDestinationItem).userCd

    const addDestinationTotal = (element: State): void => {
      destinationTotal.value = 0

      element.selectedDestinationList.forEach(
        (item: SelectedDestinationItem | DestinationListModel) => {
          item.hasOwnProperty('createDate')
            ? setSelectedDestinationListCount(item as DestinationListModel)
            : destinationTotal.value++
        }
      )
    }

    const setSelectedDestinationListCount = async (
      item: DestinationListModel
    ): Promise<void> => {
      const retDestinationList = await queryDestinationList(
        item.destinationListName
      )
      const userIDList: string[] = retDestinationList[0]?.userIdList ?? []
      destinationTotal.value += userIDList.length
    }

    return {
      messageDetail,
      backToCreatePage,
      handleSendMessage,
      destinationTotal,
      refreshCom,
      uploadFileError,
    }
  },

  components: {
    ManagePageContainer,
    ManagePageTitle,
    BaseButton,
    MessageDetail,
  },
})
