
// Components
import TheCard from '@/components/views/AccountInformationRegist/TheCard.vue'
import TheInformation from '@/components/views/AccountInformationRegist/TheInformation.vue'

// Icons
import IconIDCard from '@/components/icons/IconIDCard.vue'

// Dependencies
import {
  defineComponent,
  ref,
  Ref,
  computed,
  onBeforeMount,
  reactive,
  toRefs,
} from 'vue'

import {
  queryAccountRegistInitializeData,
  queryApplicationInformation,
  setApplicationInformation,
  queryExaminationDay,
  setExaminationDay,
} from '@/request/api'

import { useRouter } from '@/router'
import { useStore } from '@/store'
import { getMessage } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getCurriculumTypeName } from '@/services/ExaminationDate'
import { useGlobalError } from '@/services/Hooks'
import {
  ApplicationInformation,
  Certification,
  ExaminationDate,
} from '@/typings'
import { AccountRegistInitializeData } from '@/typings/AccountInformationRegist'
import { logout } from '@/services/ControlLogin'
export default defineComponent({
  name: 'AccountInformationRegist',

  components: {
    IconIDCard,
    TheCard,
    TheInformation,
  },

  setup() {
    const router = useRouter()
    const store = useStore()
    const { setGlobalError } = useGlobalError()

    // State
    const state = reactive({ userID: '' })
    const applicationInformationList: Ref<ApplicationInformation[]> = ref([]) // コース
    const examinationDateList: Ref<ExaminationDate[]> = ref([]) // 試験日の属性付きの空オプション
    const certificationList: Ref<Certification[]> = ref([]) // 区分マスター

    const selectedApplicationInformation: Ref<ApplicationInformation> = ref({
      applicationNo: '',
      jukoClassName: '',
      courseName: '',
      jukoFiscalYear: '',
    })

    const selectedExaminationDate: Ref<ExaminationDate> = ref({})
    const errors = reactive({ course: '', examinationDate: '' })

    // Computed
    const formattedCourseList = computed(() => {
      return applicationInformationList?.value?.map((item) => {
        return `${item.jukoFiscalYear}　${item.courseName}`
      })
    })

    const userCourse = computed(() => {
      if (selectedApplicationInformation.value?.applicationNo) {
        return `${selectedApplicationInformation.value?.jukoFiscalYear}　${selectedApplicationInformation.value?.courseName}`
      }

      return ''
    })

    const formattedExaminationDateList = computed(() => {
      return examinationDateList.value?.map((item) => {
        if (item.examDate === '') return
        const jukoName = getCurriculumTypeName(item, certificationList.value)

        if (!jukoName) return

        return `${formatDate(item.examDate as string)}　${jukoName}　${
          item.kbnName
        }`
      })
    })

    const userExaminationDate = computed(() => {
      const schedule = selectedExaminationDate?.value

      if (schedule?.kbnName) {
        const jukoName = getCurriculumTypeName(
          schedule,
          certificationList.value
        )

        return `${formatDate(schedule.examDate as string)}　${jukoName}　${
          schedule.kbnName
        }`
      }

      return ''
    })

    const isUserFromAPP = computed(() => store.state.isUserFromAPP)

    // Lifecycle
    onBeforeMount(async () => {
      state.userID = getUserIDFromAccessToken(getAccessTokenFromSession())
      await initializeData()
    })

    // Methods
    const refreshHeader = () => store?.commit('refreshHeader')

    // データを初期化
    const initializeData = async (): Promise<void> => {
      try {
        const {
          applicationInformationList: _applicationInformationList,
          applicationInformation,
          examinationList,
          userExamination,
          certificationList: _certificationList,
        }: AccountRegistInitializeData = await queryAccountRegistInitializeData(
          state.userID ?? ''
        )

        applicationInformationList.value = _applicationInformationList
        selectedApplicationInformation.value = applicationInformation
        examinationDateList.value = examinationList
        selectedExaminationDate.value = userExamination
        certificationList.value = _certificationList

        if (!applicationInformationList.value?.length) {
          setGlobalError(new Error(getMessage('EFR0036')))
          selectedApplicationInformation.value = {}
          examinationDateList.value = []
          selectedExaminationDate.value = {}
          certificationList.value = []
        }
      } catch (error) {
        setGlobalError((error as Error).message ?? error)
      }
    }

    const selectCourse = async (index: number): Promise<void> => {
      try {
        const target = applicationInformationList.value?.[index]

        const isSuccessed = await setApplicationInformation({
          userID: state.userID ?? '',
          applicationNo: target?.applicationNo ?? '',
        })

        errors.course = isSuccessed ? '' : getMessage('EFR0008', ['コース'])
        selectedApplicationInformation.value =
          await queryApplicationInformation(state.userID ?? '')
      } catch (error) {
        setGlobalError((error as Error).message ?? error)
      }
    }

    const selectSchedule = async (index: number): Promise<void> => {
      try {
        const examinationDay = examinationDateList.value?.[index]

        const isSuccessed = await setExaminationDay({
          userID: state.userID ?? '',
          examinationDay,
        })

        if (!isSuccessed) {
          errors.examinationDate = getMessage('EFR0008', [
            'カウントダウン試験日',
          ])

          return
        }

        errors.examinationDate = ''
        refreshHeader()

        selectedExaminationDate.value = await queryExaminationDay(
          state.userID ?? ''
        )
      } catch (error) {
        setGlobalError((error as Error).message ?? error)
      }
    }

    const handleSelectCourse = async (index: number): Promise<void> => {
      await selectCourse(index)
    }

    const handleSelectSchedule = async (index: number): Promise<void> => {
      await selectSchedule(index)
    }

    const formatDate = (date: string | Date): string => {
      const packagedDate = new Date(date)

      return `${packagedDate.getFullYear()}年${
        packagedDate.getMonth() + 1
      }月${packagedDate.getDate()}日`
    }

    return {
      ...toRefs(state),
      router,
      formattedCourseList,
      certificationList,
      formattedExaminationDateList,
      selectedApplicationInformation,
      userCourse,
      selectedExaminationDate,
      userExaminationDate,
      examinationDateList,
      errors,
      isUserFromAPP,
      refreshHeader,
      handleSelectCourse,
      handleSelectSchedule,
      logout,
    }
  },
})
