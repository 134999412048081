
/**
 * ライブラリ アイコン
 */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: '#FEFEFE',
    },
  },
})
