
/**
 * 受講情報選択機
 */
import SelectionBox from '@/components/views/GradeDataSearch/SelectionBox.vue'

import { computed, defineComponent, onBeforeMount } from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_ATTEND_FISCAL_YEAR,
  SET_CERTIFICATION,
  SET_CERTIFICATION_LIST,
} from '@/store/modules/search-condition/actionTypes'

export default defineComponent({
  name: 'AttendInformationSelector',

  setup() {
    const store: Store<any> = useStore()

    // 受講年度
    const thisYear = new Date().getFullYear()

    const attendFiscalYearList = computed<string[]>((): string[] => [
      '',
      (thisYear - 1).toString(),
      thisYear.toString(),
      (thisYear + 1).toString(),
    ])

    const attendFiscalYear = computed<string>(
      (): string => store.state.searchCondition.attendFiscalYear
    )

    const setAttendFiscalYear = (year: string): void => {
      store.dispatch(SET_ATTEND_FISCAL_YEAR, year)
    }

    // 受講種別
    const certificationList = computed<string[]>(
      (): string[] => store.getters.certificationListForDisplay
    )

    const setCertificationList = async (): Promise<void> => {
      store.dispatch(SET_CERTIFICATION_LIST)
    }

    onBeforeMount(async (): Promise<void> => setCertificationList())

    const certification = computed<string>(
      (): string => store.getters.certificationForDisplay
    )

    const setCertification = (name: string): void => {
      store.dispatch(SET_CERTIFICATION, name)
    }

    return {
      attendFiscalYearList,
      attendFiscalYear,
      setAttendFiscalYear,
      certificationList,
      certification,
      setCertification,
    }
  },

  components: { SelectionBox },
})
