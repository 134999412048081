
// Components
import AllRoleContainer from '@/components/common/AllRoleContainer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import TabList from '@/components/views/GradeData/TabList.vue'
import PageListByTrainingDay from '@/views/GradeData/SubPages/PageListByTrainingDay.vue'
import PageListBySubject from '@/views/GradeData/SubPages/PageListBySubject.vue'
import PageSimulation from '@/views/GradeData/SubPages/PageSimulation.vue'
import PageListByOther from '@/views/GradeData/SubPages/PageListByOther.vue'
import PageListByTestClass from '@/views/GradeData/SubPages/PageListByTestClass.vue'
import { getMessage, CONST_ACTIVE_TAB } from '@/Utils'
// Dependencies
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  computed,
} from 'vue'
import VueRouter from 'vue-router'
import { useRouter } from '@/router'
import { GRADE_DATA_SUBPAGES, DEFAULT_ACTIVE_TAB } from '@/config'
import {
  queryApplicationInformation,
  queryApplicationInformationList,
} from '@/request/api'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useGlobalError, useOnDemand } from '@/services/Hooks'
import { ApplicationInformation } from '@/typings'
import { mainContainerStyle, manageMainContainerStyle } from './style'
import { useStore } from '@/store'
import { Store } from 'vuex'

interface State {
  activeTab: number
  loginUserId: string
  userID: string
  applicationNo: string
  // 受講種別区分
  //certificationCode: string
  windowWidth: number
  isShowMenu: boolean
  windowHeight: number
  isYokoAPP: boolean

}

export default defineComponent({
  components: {
    AllRoleContainer,
    SubHeader,
    TabList,
    PageListByTrainingDay,
    PageListBySubject,
    PageSimulation,
    PageListByOther,
    PageListByTestClass,
  },

  props: {
    userIDProps: String,
    applicationNumber: String,
  },

  setup(props) {
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    // State
    const state = reactive<State>({
      activeTab: DEFAULT_ACTIVE_TAB,
      loginUserId: getUserIDFromAccessToken(getAccessTokenFromSession()),
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationNo: props.applicationNumber ?? '',
      //certificationCode: '',
      windowWidth: window.innerWidth,
      isShowMenu: true,
      windowHeight: window.innerHeight,
      isYokoAPP: false
    })

    const tabList = ['講習日別', '科目回別', '種別', '模擬試験', 'その他']
    const router = useRouter()
    const { setGlobalError } = useGlobalError()
    const { setOnDemandSSOParameters } = useOnDemand()

    // Lifecycle
    onBeforeMount(async () => {
      try {
        !state.applicationNo && (await setApplicationNumber())
        await setCertificationCode()
        if (sessionStorage.getItem(CONST_ACTIVE_TAB)) {
          state.activeTab = Number(sessionStorage.getItem(CONST_ACTIVE_TAB))
        } else {
          state.activeTab = getActiveTabIndex(router)
          sessionStorage.setItem(CONST_ACTIVE_TAB, state.activeTab.toString())
        }
        await setOnDemandSSOParameters(state.loginUserId)
      } catch (error) {
        console.log(error)
        setGlobalError(error as Error)
      }
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
      state.isYokoAPP = (isUserFromAPP.value || state.windowWidth < 800) && state.windowWidth > state.windowHeight
      state.isShowMenu = state.isYokoAPP ? false : true
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
      state.windowHeight = window.innerHeight
      state.isYokoAPP = (isUserFromAPP.value || state.windowWidth < 800) && state.windowWidth > state.windowHeight
      state.isShowMenu = state.isYokoAPP ? false : true
    }

    const manageStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return {}
      } else {
        return manageMainContainerStyle
      }
    })
    // Methods
    const setApplicationNumber = async (): Promise<void> => {
      const { applicationNo } = await queryApplicationInformation(state.userID)
      state.applicationNo = applicationNo ?? ''
    }

    const setCertificationCode = async (): Promise<void> => {
      const applicationInfoData: ApplicationInformation[] =
        await queryApplicationInformationList(state.userID)
      if (!applicationInfoData?.length) {
        setGlobalError(new Error(getMessage('EFR0036')))
      }
      if (state.applicationNo && applicationInfoData?.length) {
        const targetApplicationInfo = applicationInfoData.find(
          (item: ApplicationInformation): boolean => {
            return state.applicationNo === item.applicationNo
          }
        ) as ApplicationInformation
      }
    }

    const handleSelectTab = (index: number) => {
      state.activeTab = index
      sessionStorage.setItem(CONST_ACTIVE_TAB, state.activeTab.toString())
    }

    const getActiveTabIndex = (routerInstance: VueRouter) => {
      switch (
      routerInstance?.currentRoute?.params?.tab
        ?.toString()
        .replace(/\-/g, '')
        .toLowerCase()
      ) {
        case GRADE_DATA_SUBPAGES.LIST_BY_TRAINING_DAY:
          return 0
        case GRADE_DATA_SUBPAGES.LIST_BY_SUBJECT:
          return 1
        case GRADE_DATA_SUBPAGES.LIST_BY_TESTCLASS:
          return 2
        case GRADE_DATA_SUBPAGES.SIMULATION:
          return 3
        case GRADE_DATA_SUBPAGES.OTHER:
          return 4
        default:
          return DEFAULT_ACTIVE_TAB
      }
    }

    return {
      tabList,
      ...toRefs(state),
      handleSelectTab,
      mainContainerStyle,
      manageStyle,
    }
  },
})
