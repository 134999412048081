import { requestBFF } from '@/request'
import { GradeModel } from './models/grade-data'

// 成績参照画面（講習日別） - 講習日別成績データ初期化 API
export const queryGradeByTraningDay = async ({
  userID,
  applicationNo,
  koshuDt,
  koshuSu,
}: {
  userID: string
  applicationNo: string
  koshuDt: string
  koshuSu: string
}): Promise<GradeModel[]> => {
  const query = `
    query {
      queryKoshuSeisekiByDate(
        input: {
          userId: "${userID}", 
          applicationNo: "${applicationNo}",
          koshuDt: "${koshuDt}",
          koshuSu: "${koshuSu}"
        }
      ) {
        kamokuNm
        testClassKbn
        testClassNm
        tokutenSu
        mantenSu
        teishutsuFlg
        testCd
        testKamokuCd
        classId
        shussekiFlg
        testNm
      }
    }
  `
  const { queryKoshuSeisekiByDate } = (await requestBFF(query)).data.data

  return queryKoshuSeisekiByDate
}
