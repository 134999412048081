
/**
 * アイコン - 新しいタブで開く
 */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: '#000',
    },
  },
})
