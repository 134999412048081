
/**
 * インフォメーションメニュー画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import BaseButton from '@/components/common/BaseButton.vue'

// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import {
  InformationMenuButton,
  InformationMenuButtonList,
  State,
} from '@/typings/InformationMenu'
import { getRoleIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { USER_TYPE } from '@/config'
import { useRouter } from '@/router'
// セッションストレージでのメッセージ作成状態のキー名
export const SENT_MESSAGE_HISTORY_KEY = "sentMessegeHistoryState";

export default defineComponent({
  name: 'InformationMenu',

  setup() {
    // ボタンリストを定義
    const buttons: InformationMenuButtonList = {
      linkToMessageSend: {
        id: 'LinkToMessageSend',
        title: 'メッセージ送信',
        destinationName: 'CreateMessage',
        parameter: { transitionSource: 'InformationMenu' },
      },

      linkToSendHistory: {
        id: 'LinkToSendHistory',
        title: '送信履歴確認',
        destinationName: 'SentMessageHistoryList',
      },

      linkToUsedDetail: {
        id: 'LinkToUsedDetail',
        title: '利用状況確認',
        destinationName: 'BranchList',
      },

      linkToDestinationListBulkImport: {
        id: 'LinkToDestinationListBulkImport',
        title: '送信先リスト一括取込',
        destinationName: 'DestinationListBulkImport',
      },
    }

    // ユーザータイプに応じてボタンを振り分ける
    const buttonMap = new Map<string, InformationMenuButton[]>()

    buttonMap.set(USER_TYPE.EDUCATION_STAFF, [
      buttons.linkToMessageSend,
      buttons.linkToSendHistory,
      buttons.linkToDestinationListBulkImport,
    ])

    buttonMap.set(USER_TYPE.BUSINESS, [buttons.linkToSendHistory])

    buttonMap.set(USER_TYPE.MANAGEMENT, [
      buttons.linkToMessageSend,
      buttons.linkToSendHistory,
      buttons.linkToUsedDetail,
      buttons.linkToDestinationListBulkImport,
    ])

    const getButtonListByUserID = (roleID: string): InformationMenuButton[] => {
      return buttonMap.get(roleID) as InformationMenuButton[]
    }

    // 初期化
    const state = reactive<State>({
      userRoleID: '',
      buttonList: [],
    })

    onBeforeMount(() => {
      state.userRoleID = getRoleIDFromAccessToken(getAccessTokenFromSession())
      state.buttonList = getButtonListByUserID(state.userRoleID)
    })

    // 画面遷移
    const router = useRouter()

    const handleTransfer = (item: InformationMenuButton): void => {
      if (item.id === 'LinkToSendHistory') { sessionStorage.removeItem(SENT_MESSAGE_HISTORY_KEY) }

      router.push(
        item.hasOwnProperty('parameter')
          ? { name: item.destinationName, params: item.parameter }
          : item.destinationName
      )
    }

    return { ...toRefs(state), handleTransfer }
  },

  components: {
    ManagePageContainer,
    BaseButton,
  },
})
