import { getTestInformation } from '@/request/api'
import { GetTestInformationArguments } from '@/typings/TrainingDay'
import {
  setGlobalParams,
  ANSWER_INPUT_LIST_PARAMS,
  ANSWER_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import VueRouter from 'vue-router'

export const transferToInputMarkList = async (
  testCd: string,
  testKamokuCd: string,
  jukoFiscalYear: string,
  jukoClassCd: string,
  userID: string,
  applicationNo: string,
  router: VueRouter
) => {
  const getTestInfoParams = {
    testCd: testCd,
    testKamokuCd: testKamokuCd,
    jukoFiscalYear: jukoFiscalYear.substring(0, 4),
    jukoClassKbn: jukoClassCd,
  } as GetTestInformationArguments
  const testInfo = (await getTestInformation(getTestInfoParams)) ?? {}

  const params = {
    jukoId: userID,
    testCd: testCd,
    testKamokuCd: testKamokuCd,
    jukoFiscalYear: jukoFiscalYear.substring(0, 4),
    jukoClassKbn: jukoClassCd,
    applicationNo: applicationNo,
    kaitoNmList: [] as string[],
    testInfo: testInfo,
  }
  setGlobalParams(ANSWER_INPUT_LIST_PARAMS, params)
  setGlobalParams(ANSWER_COMPLETE_PAGE, false)
  router.push({ name: 'AnswerInput' })
}

export const transferTestClassKbn = (testClassKbn: string): string => {
  let testClassMap = new Map()
  testClassMap.set('01', '1')
  testClassMap.set('02', '2')
  testClassMap.set('03', '3')
  testClassMap.set('04', '4')
  testClassMap.set('05', '5')
  testClassMap.set('06', '6')
  testClassMap.set('07', '7')
  testClassMap.set('08', '8')
  testClassMap.set('09', '9')
  testClassMap.set('10', 'K')
  testClassMap.set('11', 'A')
  testClassMap.set('12', 'B')
  testClassMap.set('13', 'C')
  testClassMap.set('14', 'D')
  testClassMap.set('15', 'E')
  testClassMap.set('16', 'F')
  testClassMap.set('17', 'G')
  testClassMap.set('18', 'H')
  testClassMap.set('19', 'I')
  testClassMap.set('20', 'J')
  testClassMap.set('21', '')
  testClassMap.set('22', 'L')
  testClassMap.set('23', 'M')
  testClassMap.set('24', 'N')
  testClassMap.set('25', 'O')
  testClassMap.set('26', 'P')
  testClassMap.set('27', 'Q')
  testClassMap.set('28', 'R')
  testClassMap.set('29', 'S')
  testClassMap.set('30', 'T')
  testClassMap.set('31', 'U')
  testClassMap.set('32', 'V')
  testClassMap.set('33', 'W')
  testClassMap.set('34', 'X')
  testClassMap.set('35', 'Y')
  testClassMap.set('36', 'Z')
  return testClassMap.get(testClassKbn) ? testClassMap.get(testClassKbn) : ''
}
export const SEISEKI_TYPE_KOSHUBI = 'KOSHUBI'
export const SEISEKI_TYPE_KAMOKU = 'KAMOKU'
export const SEISEKI_TYPE_MOGISHIKEN = 'MOGISHIKEN'
export const SEISEKI_TYPE_OTHER = 'OTHER'
export const SEISEKI_TYPE_TESTCLASS = 'TESTCLASS'
