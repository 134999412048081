
/**
 * 合格ダイアリー - 学習ダイアリー画面
 */
import AllRoleContainer from '@/components/common/AllRoleContainer.vue'
import BaseSelector from '@/components/common/BaseSelector.vue'
import DiaryAchievementItem from '@/components/views/PassDiary/DiaryAchievementItem.vue'
import DateSelectorHideOfStaffInput from '@/components/common/selector/DateSelectorHideOfStaffInput.vue'
import DiaryGraph from '@/components/views/PassDiary/DiaryGraph.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
import { useRouter } from '@/router'
// Dependencies
import {
  queryApplicationInformation,
  queryApplicationInformationList,
  getDailySchedule,
  getLabelOfKbnMaster,
} from '@/request/api'
import { LabelClassification } from '@/typings/index'
import { useGlobalError } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getDate } from '@/Utils'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onBeforeMount,
  toRefs,
  watch,
  onMounted,
  onUnmounted,
} from 'vue'
import { mainContainerStyle, manageMainContainerStyle } from './style'
import { ApplicationInformation } from '@/typings'
import { DailyScheduleModel } from '@/typings/PassDiary'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_LABEL_CLASSIFICATION_LIST,
  SET_SCHEDULE_LIST,
} from '@/store/modules/pass-diary/actionTypes'

export default defineComponent({
  name: 'StudyDiary',

  props: {
    userIDProps: String,
    applicationNo: String,
  },

  setup(props) {
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    const studentUserID = computed<string>(
      (): string => store.state.studentUserID
    )
    const applicationNumber = computed<string>(
      (): string => store.state.applicationNumber
    )
    const setScheduleListToStore = (
      list: DailyScheduleModel[]
    ): Promise<void> => store.dispatch(SET_SCHEDULE_LIST, list)

    const setLabelClassificationListToStore = (
      list: LabelClassification[]
    ): Promise<void> => store.dispatch(SET_LABEL_CLASSIFICATION_LIST, list)

    const { setGlobalError, clearGlobalError } = useGlobalError()

    // State
    const state = reactive({
      userID:
        (studentUserID.value || props.userIDProps) ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationInformationList: [] as ApplicationInformation[],
      applicationInformation: {} as ApplicationInformation,
      achievementList: [] as DailyScheduleModel[],
      dailySchedule: [] as DailyScheduleModel[],
      yoteiList: [] as DailyScheduleModel[],
      targetDate: new Date(),
      formattedTargetDate: getDate(''),
      rabelClassification: [] as LabelClassification[],
      refreshList: false,
      refreshGraph: 0,
      windowWidth: window.innerWidth,
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        clearGlobalError()

        await setApplicationInformation()
        state.rabelClassification = await getLabelOfKbnMaster()
        setLabelClassificationListToStore([...state.rabelClassification])
        await handleGetDailySchedule()
      } catch (error) {
        handleError(error as Error)
      }
    })

    // Computed
    const formattedCourse = computed(() => {
      return `${state.applicationInformation?.courseName ?? ''}`
    })

    const formattedCourseList = computed(() =>
      state.applicationInformationList.map((item) => {
        return `${item.courseName ?? ''}`
      })
    )

    watch<Date>(
      () => state.targetDate as Date,
      () => (state.formattedTargetDate = getDate(state.targetDate))
    )

    // Methods
    const setApplicationInformation = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInformationList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.applicationInformationList = applicationInformationList ?? []
        let settedApplicationStudent = {} as ApplicationInformation
        if (!props.applicationNo && !applicationNumber.value) {
          settedApplicationStudent = await queryApplicationInformation(
            state.userID
          )
        }
        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInformationList?.find(
          (item) =>
            (item.applicationNo === applicationNumber.value ||
              item.applicationNo === props.applicationNo) ??
            settedApplicationStudent.applicationNo
        )
        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.applicationInformation =
          settedApplicationInformation ?? applicationInformationList?.[0] ?? {}
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    const handleGetDailySchedule = async (): Promise<void> => {
      try {
        if (state.applicationInformation.applicationNo === undefined) {
          return
        }
        const dailyScheduleList: DailyScheduleModel[] = await getDailySchedule({
          userCode: state.userID,
          applicationNo: state.applicationInformation.applicationNo,
          date: getDate(state.targetDate),
        })

        state.dailySchedule = dailyScheduleList ?? []
        await setScheduleListToStore([...dailyScheduleList])
        state.achievementList = []
        state.yoteiList = []

        state.dailySchedule.forEach((item) => {
          if (item.yojitsuFlg && getLabel(item.label)?.name3 === '1') {
            //予定
            state.yoteiList = [...state.yoteiList, item]
          } else if (!item.yojitsuFlg && getLabel(item.label)?.name3 === '1') {
            //実績
            state.achievementList = [...state.achievementList, item]
          }
        })

        state.refreshList = !state.refreshList
        state.refreshGraph = state.refreshGraph + 1
      } catch (error) {
        handleError(error as Error)
      }
    }
    const getLabel = (label: string): LabelClassification | null => {
      let retValue = null
      if (state.rabelClassification && state.rabelClassification.length > 0) {
        retValue = state.rabelClassification.find(
          (item) => item.kbn === label
        ) as LabelClassification
      }
      return retValue
    }

    const handlePrev = async (date: Date) => {
      state.targetDate = new Date(date.getTime() - 24 * 60 * 60 * 1000)
      await handleGetDailySchedule()
    }

    const handleNext = async (date: Date) => {
      state.targetDate = new Date(date.getTime() + 24 * 60 * 60 * 1000)
      await handleGetDailySchedule()
    }

    const handleChangeDate = async (date: Date) => {
      state.targetDate = date
      await handleGetDailySchedule()
    }

    const handleSelectCourse = async (index: number) => {
      state.applicationInformation = state.applicationInformationList[index]
      await handleGetDailySchedule()
    }

    const router = useRouter()

    const handleToReport = () => {
      try {
        const applicationNo =
          props.applicationNo ??
          (state.applicationInformation.applicationNo as string)

        if (!applicationNo || applicationNo === undefined) {
          throw new Error('コース存在しません。')
        }

        router.push({
          name: 'PassDiary/StudyReport',

          params: {
            userIDProps: props.userIDProps ?? state.userID,
            applicationNo: applicationNo,
          },
        })
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleError = (error: Error | unknown | string): void => {
      console.log(error)
      setGlobalError(error as Error)
    }

    // Style
    const courseSelectBarStyle = {
      fontSize: '16px',
    }

    const activeScheduleList = ref<DailyScheduleModel[]>([])

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
      state.refreshGraph++
    }

    const scale = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { x: 0.875, y: 0.875 }
      } else {
        return { x: 1, y: 1 }
      }
    })
    const graghAchieveStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { display: 'block' }
      } else {
        return { display: 'flex' }
      }
    })

    const graghStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { width: '100%' }
      } else {
        return { width: '50%' }
      }
    })
    return {
      ...toRefs(state),
      mainContainerStyle,
      manageMainContainerStyle,
      courseSelectBarStyle,
      scale,
      formattedCourse,
      formattedCourseList,
      isUserFromAPP,
      activeScheduleList,
      handleSelectCourse,
      handleToReport,
      handlePrev,
      handleNext,
      handleChangeDate,
      getLabel,
      handleGetDailySchedule,
      graghStyle,
      graghAchieveStyle,
    }
  },

  components: {
    AllRoleContainer,
    BaseSelector,
    DiaryAchievementItem,
    DateSelectorHideOfStaffInput,
    DiaryGraph,
    IconArrowRight,
  },
})
