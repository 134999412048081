
import { defineComponent } from 'vue'

// Icons
import IconProfile from '@/components/icons/IconProfile.vue'
import IconPadlock from '@/components/icons/IconPadlock.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
import { useRouter } from '@/router'

interface TransferArgument {
  isToExternalServer: boolean
  path: string
}

export default defineComponent({
  components: { IconProfile, IconPadlock, IconArrowRight },
  setup() {
    const router = useRouter()

    // Methods
    const handleTransferScreen = ({
      isToExternalServer,
      path,
    }: TransferArgument) => {
      isToExternalServer ? location.assign(path) : router.push(path)
    }

    const informations = [
      {
        icon: 'IconPadlock',
        title: 'パスワード変更',
        isToExternalServer: true,
        path: process.env.VUE_APP_PATH_PASSWORD_CHANGE,
      },

      {
        icon: 'IconProfile',
        title: 'プロフィール変更',
        isToExternalServer: false,
        path: '/PersonalInformationConfig',
      },
    ]

    return { handleTransferScreen, informations }
  },
})
