
/**
 * 添付ファイルありのアイコン
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconClip',

  props: {
    fill: {
      type: String,
      default: '#869FB3',
    },

    height: {
      type: String,
      default: '24',
    },

    width: {
      type: String,
      default: '24',
    },
  },
})
