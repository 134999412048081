
// Dependencies
import { defineComponent, onBeforeMount, computed } from 'vue'
import { refreshActiveTime } from '@/services/ControlLogin'
import { useStore } from '@/store'
import { checkIsFromAPP } from '@/Utils'
import LoadingShowText from "@/components/common/LoadingShowText.vue"

export default defineComponent({
  name: 'App',
  components: {
    LoadingShowText
  },
  setup() {
    // Data
    const store = useStore()
    const isLoadingWithText = computed(() => store.state.isLoadingWithText)
    const isLoadingText = computed(() => store.state.isLoadingText)
    // Lifecycle
    onBeforeMount(() => checkUserFrom())

    // Methods
    const checkUserFrom = () =>
      store?.commit('checkUserFrom', checkIsFromAPP(window))
    const isfromapp = checkIsFromAPP(window)
    return { refreshActiveTime, isfromapp, isLoadingWithText, isLoadingText }
  },
})
