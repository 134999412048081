
// アイコン - 成績
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconGradePresent',

  props: {
    fill: {
      type: String,
      default: '#fff',
    },
  },
})
