
// Components
import Header from '@/components/common/ManageHeader.vue'
import Footer from '@/components/common/ManageFooter.vue'
import BaseErrorBox from '@/components/common/BaseErrorBox.vue'

// Dependencies
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManagePageContainer',
  components: { Header, Footer, BaseErrorBox },

  props: {
    mainContainerStyle: {
      type: Object,
    },
  },
})
