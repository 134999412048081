
// Dependencies
import { defineComponent, PropType } from 'vue'

interface FormatedDestination {
  key: string
  showValue: string
}

interface State {
  formatedDestinationList: FormatedDestination[]
}

export default defineComponent({
  name: 'SelectedDestination',

  props: {
    destinations: [] as PropType<FormatedDestination>,
    errorID: String,
    error: String,
    errorStyle: Object,
  },

  methods: {
    deleteMe: function (key: string) {
      this.$emit('emitting', key)
    },
  },
})
