
/**
 * 合格ダイアリー - 予実入力画面 - 時刻入力部品
 */
import { computed, defineComponent, PropType } from 'vue'

export interface InterfaceScheduleTime {
  hour1: string
  hour2: string
  minute1: string
  minute2: string
}

export interface SelectTypeScheduleTime {
  hour: string
  minutes: string
}

export default defineComponent({
  name: 'ScheduleTime',

  props: {
    label: { type: String, required: true },

    timeProp: {
      type: Object as PropType<InterfaceScheduleTime>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const hourList = [...Array(props.label === 'GOAL' ? 48 : 24)].map<string>(
      (_, index: number): string =>
        index < 10 ? '0' + index : index.toString()
    )

    const minutesList = [...Array(6)].map<string>((_, index: number): string =>
      index === 0 ? '00' : index * 10 + ''
    )

    const time = computed<SelectTypeScheduleTime>(() => ({
      hour: props.timeProp.hour1 + props.timeProp.hour2,
      minutes: props.timeProp.minute1 + props.timeProp.minute2,
    }))

    const handleSelectHour = (event: Event): void => {
      const node = event.target as HTMLSelectElement
      emit('setHour', node.value)
    }

    const handleSelectMinutes = (event: Event): void => {
      const node = event.target as HTMLSelectElement
      emit('setMinutes', node.value)
    }

    return {
      time,
      hourList,
      minutesList,
      handleSelectHour,
      handleSelectMinutes,
    }
  },
})
