
import { defineComponent, reactive, toRefs } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'
import { getRoleIdFromJwt } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import ScheduleDateTopPage from '@/components/views/PassDiary/ScheduleDateTopPage.vue'
import ScheduleDate from '@/components/views/PassDiary/ScheduleDate.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'

export default defineComponent({
  name: 'DateSelectorHideOfStaffInput',
  components: {
    DatePicker,
    ScheduleDateTopPage,
    ScheduleDate,
    IconArrowLeft,
    IconArrowRight,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      dateValue: props.date,
      isDatePicker: false,
      userType: getRoleIdFromJwt(getAccessTokenFromSession()) ?? '',
    })
    const handleChange = (): void => {
      emit('changeDate', state.dateValue)
    }

    const toPrev = (): void => {
      emit('prev', state.dateValue)
    }
    const toNext = (): void => {
      emit('next', state.dateValue)
    }

    const handleDatePicker = (isDisplay: boolean): void => {
      state.isDatePicker = isDisplay
    }

    return {
      ...toRefs(state),
      handleChange,
      toPrev,
      toNext,
      handleDatePicker,
    }
  },
})
