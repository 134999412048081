
import TransferOnDemandButton from './TransferOnDemandButton.vue'

import { defineComponent } from 'vue'
import { toThousands } from '@/Utils'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'

export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true,
    },

    hyojiFlg: {
      type: Boolean,
      required: true,
    },
    isLoadingGradeList: Boolean,
  },

  methods: {
    toThousands,
    kamokuclick: function (testNm: string, testKamokuCd: string) {
      this.$emit('emitting', testNm, testKamokuCd)
    },
  },

  components: {
    OverlayScrollbarsComponent,
    TransferOnDemandButton,
  },
})
