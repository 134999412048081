
import { defineComponent, computed } from 'vue'
import { formatTime } from '@/Utils'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
export interface InterfaceScheduleDate {
  year: string
  month: string
  day: string
  weekday: string
}

export default defineComponent({
  name: 'PeriodWeek',
  components: { IconArrowLeft, IconArrowRight },
  props: {
    targetDate: { type: Date, required: true },
  },

  setup(props, { emit }) {
    const from = computed(() => {
      const baseDate = new Date(
        props.targetDate.getFullYear(),
        props.targetDate.getMonth(),
        props.targetDate.getDate() - 7,
        0,
        0,
        0,
        0
      )
      return {
        year: baseDate.getFullYear() + '',
        month: formatTime(baseDate.getMonth() + 1),
        day: formatTime(baseDate.getDate()),
        weekday: ['日', '月', '火', '水', '木', '金', '土'][baseDate.getDay()],
      } as InterfaceScheduleDate
    })
    const to = computed(() => {
      const baseDate = new Date(
        props.targetDate.getFullYear(),
        props.targetDate.getMonth(),
        props.targetDate.getDate() - 1,
        0,
        0,
        0,
        0
      )
      return {
        year: baseDate.getFullYear() + '',
        month: formatTime(baseDate.getMonth() + 1),
        day: formatTime(baseDate.getDate()),
        weekday: ['日', '月', '火', '水', '木', '金', '土'][baseDate.getDay()],
      } as InterfaceScheduleDate
    })

    const handlePrev = (): void => {
      emit('prev')
    }
    const handleNext = (): void => {
      emit('next')
    }
    return {
      handlePrev,
      handleNext,
      from,
      to,
    }
  },
})
