
/**
 * 合格ダイアリー - 予実入力画面
 */
import ScheduleAchievementClock from '@/components/views/PassDiary/ScheduleAchievementClock.vue'
import InputWrapperTitle from '@/components/views/PassDiary/InputWrapperTitle.vue'
import ScheduleTime, {
  InterfaceScheduleTime,
} from '@/components/views/PassDiary/ScheduleTime.vue'
import PassDiaryInputPageButton from '@/components/views/PassDiary/PassDiaryInputPageButton.vue'
import IconClose03 from '@/components/icons/IconClose03.vue'
import IconPen from '@/components/icons/IconPen02.vue'

import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
  toRefs,
} from 'vue'
import {
  addDailySchedule,
  updateDailySchedule,
  deleteDailyScheduleByDate,
} from '@/request/api/pass-diary/dailySchedule'
import { ApplicationInformation } from '@/typings'
import { LabelClassification } from '@/typings/index'
import { DailyScheduleModel } from '@/request/api/pass-diary/models/dailySchedule'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { formatTime, getDate } from '@/Utils'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_DEFAULT_SCHEDULE,
  SET_MODIFIED_SCHEDULE,
  SET_TRANSFER_MEMO_STATUS,
  SET_IS_UPDATE_ACTIVE,
  SET_SCHEDULE_LIST,
} from '@/store/modules/pass-diary/actionTypes'

interface InterfaceDate {
  _date: Date
  year: number
  month: number
  day: number
  dayOfWeek: string
}

export interface PlanTime {
  start: string
  goal: string
}

const MEMO = {
  DEFAULT_TEXT: '256文字',
  ACTIVED_TEXT: 'メモ',
}

export default defineComponent({
  name: 'ScheduleAchievementInput',

  props: {
    expectationSchedule: {
      type: Object as PropType<DailyScheduleModel>,
      required: true,
    },

    achievementSchedule: {
      type: Object as PropType<DailyScheduleModel>,
      required: true,
    },

    applicationInformation: {
      type: Object as PropType<ApplicationInformation>,
      required: true,
    },

    label: {
      type: Object as PropType<LabelClassification>,
      required: true,
    },
    yojitsuFlg: Boolean,
  },

  setup(props, { emit }) {
    const store: Store<any> = useStore()

    const transferFromMemo = computed<boolean>(
      (): boolean => store.state.passDiary.transferFromMemo
    )

    const setTransferFromMemoStatsu = (status: boolean): Promise<void> =>
      store.dispatch(SET_TRANSFER_MEMO_STATUS, status)

    const defaultSchedule = computed<DailyScheduleModel>(
      (): DailyScheduleModel => store.state.passDiary.defaultSchedule
    )

    const setDefaultScheduleToStore = (
      schedule: DailyScheduleModel
    ): Promise<void> => store.dispatch(SET_DEFAULT_SCHEDULE, schedule)

    const modifiedSchedule = computed<DailyScheduleModel>(
      (): DailyScheduleModel => store.state.passDiary.modifiedSchedule
    )

    const setScheduleToStore = (schedule: DailyScheduleModel): Promise<void> =>
      store.dispatch(SET_MODIFIED_SCHEDULE, schedule)

    const setScheduleListToStore = (
      list: DailyScheduleModel[]
    ): Promise<void> => store.dispatch(SET_SCHEDULE_LIST, list)

    const storeMemo = computed<string>((): string => store.state.passDiary.memo)

    const isUpdateActivate = computed<boolean>(
      (): boolean => store.state.passDiary.isUpdateActivated
    )

    const setUpdateActivateToStore = (isValue: boolean): Promise<void> =>
      store.dispatch(SET_IS_UPDATE_ACTIVE, isValue)

    onMounted(async (): Promise<void> => {
      if (transferFromMemo.value) {
        const schedule: DailyScheduleModel = modifiedSchedule.value
        schedule.memo = storeMemo.value
        await setScheduleToStore(schedule)
      }
    })

    const goBackWithSetData = async (): Promise<void> => {
      await sendData()
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)
      registAllowed.value && emit('goBack')
    }

    const closeWithSetData = async (): Promise<void> => {
      await sendData()
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)
      registAllowed.value && emit('closeInputWrapper')
    }

    const handleGoBack = async (): Promise<void> => {
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)
      emit('goBack')
    }

    const handleCloseInputWrapper = async (): Promise<void> => {
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)
      emit('closeInputWrapper')
    }

    const achievementActived = computed<boolean>(() => {
      if (props.yojitsuFlg !== undefined && !props.yojitsuFlg) {
        return true
      }
      return false
    })

    const activeSchedule = computed<DailyScheduleModel>(() => {
      if (transferFromMemo.value) {
        return { ...modifiedSchedule.value }
      }

      setTransferFromMemoStatsu(false)

      if (achievementActived.value) {
        return { ...props.achievementSchedule }
      }

      return { ...props.expectationSchedule }
    })

    // 日付
    const dateDetail = computed<InterfaceDate>(() => {
      const _date: Date = new Date(activeSchedule.value.date)
      const year: number = _date.getFullYear()
      const month: number = _date.getMonth() + 1
      const day: number = _date.getDate()
      const week = ['日', '月', '火', '水', '木', '金', '土']
      const dayOfWeek = week[_date.getDay()]

      return { _date, year, month, day, dayOfWeek }
    })

    /** 時刻 */
    // 予定、実績時刻
    const scheduleTime = reactive<PlanTime>({
      start: '',
      goal: '',
    })

    const achievementTime = reactive<PlanTime>({
      start: '',
      goal: '',
    })

    onMounted((): void => {
      if (
        !transferFromMemo.value &&
        isUpdateActivate.value &&
        props.yojitsuFlg
      ) {
        setDefaultScheduleToStore(props.expectationSchedule)
      }
      if (
        !transferFromMemo.value &&
        isUpdateActivate.value &&
        props.yojitsuFlg === false
      ) {
        setDefaultScheduleToStore(props.achievementSchedule)
      }
      const expectation: DailyScheduleModel =
        transferFromMemo.value && props.yojitsuFlg
          ? modifiedSchedule.value
          : props.expectationSchedule

      scheduleTime.start = `${formatTime(
        expectation.startHour as number
      )}:${formatTime(expectation.startMinute as number)}`

      scheduleTime.goal = `${formatTime(
        expectation.endHour as number
      )}:${formatTime(expectation.endMinute as number)}`

      const achievement: DailyScheduleModel =
        transferFromMemo.value && !props.yojitsuFlg
          ? modifiedSchedule.value
          : props.achievementSchedule

      achievementTime.start = `${formatTime(
        achievement.startHour as number
      )}:${formatTime(achievement.startMinute as number)}`

      achievementTime.goal = `${formatTime(
        achievement.endHour as number
      )}:${formatTime(achievement.endMinute as number)}`
    })

    // 表示時刻
    const startTime = reactive<InterfaceScheduleTime>({
      hour1: '',
      hour2: '',
      minute1: '',
      minute2: '',
    })

    const goalTime = reactive<InterfaceScheduleTime>({
      hour1: '',
      hour2: '',
      minute1: '',
      minute2: '',
    })

    const oldStartTime = reactive<InterfaceScheduleTime>({
      hour1: '',
      hour2: '',
      minute1: '',
      minute2: '',
    })

    const oldGoalTime = reactive<InterfaceScheduleTime>({
      hour1: '',
      hour2: '',
      minute1: '',
      minute2: '',
    })

    onMounted((): void => {
      const startHour: string = formatTime(
        activeSchedule.value.startHour as number
      )

      const startMinute: string = formatTime(
        activeSchedule.value.startMinute as number
      )

      const endHour: string = formatTime(activeSchedule.value.endHour as number)

      const endMinute: string = formatTime(
        activeSchedule.value.endMinute as number
      )

      startTime.hour1 = startHour.substr(0, 1)
      startTime.hour2 = startHour.substr(1, 1)
      startTime.minute1 = startMinute.substr(0, 1)
      startTime.minute2 = startMinute.substr(1, 1)

      goalTime.hour1 = endHour.substr(0, 1)
      goalTime.hour2 = endHour.substr(1, 1)
      goalTime.minute1 = endMinute.substr(0, 1)
      goalTime.minute2 = endMinute.substr(1, 1)

      oldStartTime.hour1 = startHour.substr(0, 1)
      oldStartTime.hour2 = startHour.substr(1, 1)
      oldStartTime.minute1 = startMinute.substr(0, 1)
      oldStartTime.minute2 = startMinute.substr(1, 1)

      oldGoalTime.hour1 = endHour.substr(0, 1)
      oldGoalTime.hour2 = endHour.substr(1, 1)
      oldGoalTime.minute1 = endMinute.substr(0, 1)
      oldGoalTime.minute2 = endMinute.substr(1, 1)
    })

    const copyObject = (target: any, base: any): void => {
      Object.keys(base).forEach((key: string) => {
        target[key] = base[key]
      })
    }

    const setStartTime = (time: string): void => {
      setTimeFromString(startTime, time)

      if (achievementActived.value) {
        achievementTime.start = time
        achievementTime.goal = ''
      } else {
        scheduleTime.start = time
        scheduleTime.goal = ''
      }

      goalTime.hour1 = ''
      goalTime.hour2 = ''
      goalTime.minute1 = ''
      goalTime.minute2 = ''
    }

    const setGoalTime = (time: string): void => {
      const start = Object.keys(startTime)
        .map((key: string, index, list): string => {
          const _key = key as keyof InterfaceScheduleTime
          return index === 1 ? `${startTime[_key]}:` : startTime[_key]
        })
        .reduce((previous, next) => previous + next)

      const nextDayTime = getNextDayTime(time)
      const result = time < start ? nextDayTime : time

      setTimeFromString(goalTime, result)

      if (achievementActived.value) {
        achievementTime.goal = result
      } else {
        scheduleTime.goal = result
      }
    }

    const getNextDayTime = (time: string): string => {
      const timeArray = time.split(':')
      const hour = timeArray[0]
      const minute = timeArray[1]

      return `${Number(hour) + 24}:${minute}`
    }

    // スイッチの切り替え
    watch<boolean>(achievementActived, (newStatus) => {
      resetTime(startTime)
      resetTime(goalTime)

      if (newStatus) {
        setTimeFromString(startTime, achievementTime.start)
        setTimeFromString(goalTime, achievementTime.goal)
      } else {
        setTimeFromString(startTime, scheduleTime.start)
        setTimeFromString(goalTime, scheduleTime.goal)
      }

      copyObject(oldStartTime, startTime)
      copyObject(oldGoalTime, goalTime)
    })

    watch<string | undefined>(
      (): string | undefined => props.label.name,

      (): void => {
        const schedule: DailyScheduleModel = activeSchedule.value

        // 予定と実績をリセット
        scheduleTime.start = ''
        scheduleTime.goal = ''
        achievementTime.start = ''
        achievementTime.goal = ''

        // props で時刻を設定
        setTime(startTime, {
          hour: schedule.startHour as number,
          minutes: schedule.startMinute as number,
        })

        setTime(goalTime, {
          hour: schedule.endHour as number,
          minutes: schedule.endMinute as number,
        })
      }
    )

    const setTime = (
      time: InterfaceScheduleTime,
      { hour, minutes }: { hour: number; minutes: number }
    ): void => {
      time.hour1 = formatTime(hour).substr(0, 1)
      time.hour2 = formatTime(hour).substr(1, 1)
      time.minute1 = formatTime(minutes).substr(0, 1)
      time.minute2 = formatTime(minutes).substr(1, 1)
    }

    const handleSetStartHour = (hour: string): void => {
      startTime.hour1 = hour.substr(0, 1)
      startTime.hour2 = hour.substr(1, 1)

      if (achievementActived.value) {
        achievementTime.start = `${hour}:${startTime.minute1}${startTime.minute2}`
      } else {
        scheduleTime.start = `${hour}:${startTime.minute1}${startTime.minute2}`
      }
    }

    const handleSetStartMinutes = (minutes: string): void => {
      startTime.minute1 = minutes.substr(0, 1)
      startTime.minute2 = minutes.substr(1, 1)

      if (achievementActived.value) {
        achievementTime.start = `${startTime.hour1}${startTime.hour2}:${minutes}`
      } else {
        scheduleTime.start = `${startTime.hour1}${startTime.hour2}:${minutes}`
      }
    }

    const handleSetGoalHour = (hour: string): void => {
      // 翌日であるかを判断
      let isUseNextDay: boolean = false

      if (startTime.hour1 && hour) {
        const begin: string = startTime.hour1 + startTime.hour2
        isUseNextDay = hour < begin
      }

      isUseNextDay && (hour = Number(hour) + 24 + '')

      // 終了時間の時数をセット
      goalTime.hour1 = hour.substr(0, 1)
      goalTime.hour2 = hour.substr(1, 1)

      // 予定か実績かを判断
      if (achievementActived.value) {
        achievementTime.goal = `${hour}:${goalTime.minute1}${goalTime.minute2}`
      } else {
        scheduleTime.goal = `${hour}:${goalTime.minute1}${goalTime.minute2}`
      }
    }

    const handleSetGoalMinutes = (minutes: string): void => {
      goalTime.minute1 = minutes.substr(0, 1)
      goalTime.minute2 = minutes.substr(1, 1)

      if (achievementActived.value) {
        achievementTime.goal = `${goalTime.hour1}${goalTime.hour2}:${minutes}`
      } else {
        scheduleTime.goal = `${goalTime.hour1}${goalTime.hour2}:${minutes}`
      }
    }

    const handleMoveEnd = async (): Promise<void> => {
      moveActived.value = false
    }

    const state = reactive({
      userCode:
        props.applicationInformation.userId ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationNo: props.applicationInformation.applicationNo,
      refreshClockGraph: 0,
    })

    const moveActived = ref<boolean>(false)
    const registAllowed = ref<boolean>(false)

    const sendData = async (): Promise<void> => {
      if (isTimeHasEmpty(startTime) || isTimeHasEmpty(goalTime)) {
        registAllowed.value = false
        return
      }

      const formattedStart: string = `${startTime.hour1}${startTime.hour2}:${startTime.minute1}${startTime.minute2}`
      const formattedGoal: string = `${goalTime.hour1}${goalTime.hour2}:${goalTime.minute1}${goalTime.minute2}`

      if (formattedStart === formattedGoal) {
        registAllowed.value = false
        return
      }

      registAllowed.value = true

      const currentSchedule: DailyScheduleModel = getDailySchedule({
        start: startTime,
        goal: goalTime,
      })

      await setScheduleToStore(currentSchedule)

      if (isUpdateActivate.value) {
        console.log('update')

        await updateDailySchedule({
          userCode: state.userCode ?? '',
          applicationNo: state.applicationNo ?? '',
          key: defaultSchedule.value,
          value: currentSchedule,
        })

        copyObject(oldStartTime, startTime)
        copyObject(oldGoalTime, goalTime)

        return
      }

      console.log('add')

      await addDailySchedule({
        userCode: state.userCode ?? '',
        applicationNo: state.applicationNo ?? '',
        dailySchedule: currentSchedule,
      })

      copyObject(oldStartTime, startTime)
      copyObject(oldGoalTime, goalTime)
    }

    const getDailySchedule = ({
      start,
      goal,
      memo,
    }: {
      start: InterfaceScheduleTime
      goal: InterfaceScheduleTime
      memo?: string
    }): DailyScheduleModel => {
      return {
        yojitsuFlg: !achievementActived.value,
        date: getDate(activeSchedule.value.date),
        startHour: Number(start.hour1) * 10 + Number(start.hour2),
        startMinute: Number(start.minute1) * 10 + Number(start.minute2),
        endHour: Number(goal.hour1) * 10 + Number(goal.hour2),
        endMinute: Number(goal.minute1) * 10 + Number(goal.minute2),
        label: props.label.kbn as string,
        memo: memo ?? activeSchedule.value.memo,
      }
    }

    const setTimeFromString = (
      target: InterfaceScheduleTime,
      time: string
    ): void => {
      if (time.length !== 5) {
        return
      }

      target.hour1 = time.substr(0, 1)
      target.hour2 = time.substr(1, 1)
      target.minute1 = time.substr(3, 1)
      target.minute2 = time.substr(4, 1)
    }

    // リセットボタン
    const resetActived = computed<boolean>(
      (): boolean =>
        isTimeHasValue(startTime) ||
        isTimeHasValue(goalTime) ||
        (store.state.passDiary.scheduleList &&
          store.state.passDiary.scheduleList.length > 0)
    )

    function isTimeHasValue(target: InterfaceScheduleTime): boolean {
      return Object.keys(target).some(
        (key: string): boolean =>
          target[key as keyof InterfaceScheduleTime] !== ''
      )
    }

    function isTimeHasEmpty(target: InterfaceScheduleTime): boolean {
      return Object.keys(target).some(
        (key: string): boolean =>
          target[key as keyof InterfaceScheduleTime] === ''
      )
    }

    const timeHasReset = ref<number>(0)

    watch<number>(
      timeHasReset,

      async (): Promise<void> => {
        const confirmRet = confirm(
          '該当日付のスケジュールを全て削除しますがよろしいですか？'
        )
        if (!confirmRet) {
          return
        }
        await deleteDailyScheduleByDate({
          userCode: state.userCode ?? '',
          applicationNo: state.applicationNo ?? '',
          targetDate: getDate(dateDetail.value._date),
        })
        setScheduleListToStore([])
        setUpdateActivateToStore(false)

        resetTime(startTime)
        resetTime(goalTime)
        state.refreshClockGraph++
        if (achievementActived.value) {
          achievementTime.start = ''
          achievementTime.goal = ''
        } else {
          scheduleTime.start = ''
          scheduleTime.goal = ''
        }

        await setTransferFromMemoStatsu(false)
        emit('closeInputWrapper')
      }
    )

    const resetTime = (target: InterfaceScheduleTime): void => {
      Object.keys(target).forEach(
        (key: string) => (target[key as keyof InterfaceScheduleTime] = '')
      )
    }

    // メモ
    const memoActived = computed<boolean>(() => {
      return isTimeFully(startTime) && isTimeFully(goalTime)
    })

    const memoLabel = computed<string>(() =>
      memoActived.value ? MEMO.ACTIVED_TEXT : MEMO.DEFAULT_TEXT
    )

    const isTimeFully = (time: InterfaceScheduleTime): boolean => {
      return Object.keys(time).every(
        (key: string): boolean =>
          time[key as keyof InterfaceScheduleTime] !== ''
      )
    }

    const transferToMemoPage = async (): Promise<void> => {
      const scheduleMemo = transferFromMemo.value
        ? modifiedSchedule.value.memo
        : defaultSchedule.value ? defaultSchedule.value.memo : ''
      const memo = isUpdateActivate.value ? scheduleMemo : ''
      const currentSchedule: DailyScheduleModel = getDailySchedule({
        start: startTime,
        goal: goalTime,
        memo: memo,
      })
      await setScheduleToStore(currentSchedule)
      emit('dailyScheduleMemoPage', memo)
    }
    const dateStyle = computed(() => {
      if (window.innerHeight < 648) {
        return { margin: '0 0' }
      } else {
        return { margin: '2rem 0 0' }
      }
    })
    const timeStyle = computed(() => {
      if (window.innerHeight < 648) {
        return { margin: '0 0' }
      } else {
        return { margin: '1rem 0 0' }
      }
    })
    return {
      ...toRefs(state),
      handleGoBack,
      handleCloseInputWrapper,
      goBackWithSetData,
      closeWithSetData,
      dateDetail,
      achievementActived,
      scheduleTime,
      achievementTime,
      setStartTime,
      setGoalTime,
      startTime,
      handleSetStartHour,
      handleSetStartMinutes,
      goalTime,
      handleSetGoalHour,
      handleSetGoalMinutes,
      handleMoveEnd,
      resetActived,
      timeHasReset,
      memoLabel,
      memoActived,
      transferToMemoPage,
      moveActived,
      dateStyle,
      timeStyle,
    }
  },

  components: {
    ScheduleAchievementClock,
    InputWrapperTitle,
    ScheduleTime,
    IconClose03,
    IconPen,
    PassDiaryInputPageButton,
  },
})
