var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'content-page_box',
    _vm.isUserFromAPP && 'app-layout_content-page_box',
  ],style:(_vm.height && { height: _vm.height }),attrs:{"id":"ContentPageContainer"}},[_c('BaseHeader',{attrs:{"refreshNotReadNumber":_vm.refreshNotReadNumber},on:{"handleheaderMounted":_vm.handleheaderMounted}}),(_vm.isIOSApp)?_c('BaseMobileHeader',{class:['header-footer-display', _vm.isShowMenu && 'show-menu'],attrs:{"value":_vm.isShowMenu}}):_c('BaseMobileHeader',{class:[!_vm.isShowMenu && 'hidden'],attrs:{"value":_vm.isShowMenu}}),_c('BaseErrorBox'),(_vm.isIOSApp)?_c('div',{class:[
      'content-page-main-container',
      _vm.isUserFromAPP && 'app-layout_main-container',
      _vm.isUserFromAPP && !_vm.isShowMenu && 'no-footer',
      _vm.studyDialyReportSize && 'content-page-daily-report',
    ],style:(_vm.mainContainerStyle)},[(_vm.headerMonted)?_vm._t("default"):_vm._e()],2):_c('div',{class:[
      'content-page-main-container',
      _vm.isUserFromAPP && 'app-layout_main-container',
      !_vm.isShowMenu && 'no-footer',
      _vm.studyDialyReportSize && 'content-page-daily-report',
    ],style:(_vm.mainContainerStyle),attrs:{"value":_vm.isShowMenu}},[(_vm.headerMonted)?_vm._t("default"):_vm._e()],2),_c('BaseFooter'),(_vm.isIOSApp)?_c('img',{class:[_vm.isYokoAPP && 'fixed_btn_ios', !_vm.isYokoAPP && 'hidden'],attrs:{"src":require("@/assets/img/menu-svgrepo-com.svg"),"alt":"#"},on:{"click":() => _vm.menuSwitchClickIOS()}}):_c('img',{class:[_vm.isYokoAPP && 'fixed_btn', !_vm.isYokoAPP && 'hidden'],attrs:{"src":require("@/assets/img/menu-svgrepo-com.svg"),"alt":"#"},on:{"click":() => _vm.menuSwitchClick()}}),_c('div',[(_vm.isIOSApp)?_c('BaseMobileFooter',{class:[
        _vm.isUserFromAPP && 'header-footer-display',
        _vm.isUserFromAPP && _vm.isShowMenu && 'show-menu',
      ],attrs:{"value":_vm.isShowMenu,"refreshNotReadNumber":_vm.refreshNotReadNumber},on:{"handleMobileFooterMounted":_vm.handleheaderMounted}}):_c('BaseMobileFooter',{class:[!_vm.isShowMenu && 'hidden'],attrs:{"value":_vm.isShowMenu,"refreshNotReadNumber":_vm.refreshNotReadNumber},on:{"handleMobileFooterMounted":_vm.handleheaderMounted}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }