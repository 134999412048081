
// Components
import BaseHeader from '@/components/common/BaseHeader.vue'
import BaseMobileHeader from '@/components/common/BaseMobileHeader.vue'
import BaseFooter from '@/components/common/BaseFooter.vue'
import BaseMobileFooter from '@/components/common/BaseMobileFooter.vue'
import BaseErrorBox from '@/components/common/BaseErrorBox.vue'

// Dependencies
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  onMounted,
  onUnmounted,
} from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ContentPageContainer',
  components: {
    BaseHeader,
    BaseMobileHeader,
    BaseFooter,
    BaseMobileFooter,
    BaseErrorBox,
  },

  props: {
    height: {
      type: String,
    },

    mainContainerStyle: {
      type: Object,
    },

    useMobileTerminalMenu: {
      type: Boolean,
      default: true,
    },
    refreshNotReadNumber: {
      type: Number,
    },
    studyDialyReportSize: {
      type: Boolean,
      default: false,
    },
    isIOSApp: {
      type: Boolean,
    },
  },

  setup() {
    const state = reactive({
      headerMonted: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isShowMenu: true,
      isYokoAPP: false,
      menuButtonClickTimes: 0,
    })
    const store = useStore()

    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const handleheaderMounted = () => {
      state.headerMonted = true
    }
    const menuSwitchClick = (): void => {
      state.isShowMenu = !state.isShowMenu
    }
    const menuSwitchClickIOS = (): void => {
      if (state.menuButtonClickTimes % 2 === 0) {
        state.isShowMenu = true
      } else {
        state.isShowMenu = false
      }
      state.menuButtonClickTimes++
    }
    const handleResize = () => {
      state.isYokoAPP =
        (isUserFromAPP.value || window.innerWidth < 800) && window.innerWidth > window.innerHeight
      state.isShowMenu = state.isYokoAPP ? false : true
    }
    onBeforeMount(() => {
      handleResize()
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
      screen.orientation.addEventListener('change', handleResize)
      state.isYokoAPP =
        (isUserFromAPP.value || state.windowWidth < 800) &&
        state.windowWidth > state.windowHeight
      state.isShowMenu = state.isYokoAPP ? false : true
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
      screen.orientation.removeEventListener('change', handleResize)
    })

    return {
      ...toRefs(state),
      isUserFromAPP,
      handleheaderMounted,
      menuSwitchClick,
      menuSwitchClickIOS,
    }
  },
})
