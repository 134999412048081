import 'overlayscrollbars/css/OverlayScrollbars.css'
import Vue from 'vue'
import '@/assets/styles/index.css'
import '@/assets/styles/bootstrap.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './config/Validate'
import OverlayScrollbars from 'overlayscrollbars'
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'

/* �������� */

import $ from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/js/bootstrap.js'




import vueTouch from 'kim-vue-touch'

Vue.use(vueTouch)

/* �����܂� */
Vue.config.productionTip = false

Vue.prototype.$ = $


Vue.use(OverlayScrollbarsPlugin)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

OverlayScrollbars(document.body, {
  nativeScrollbarsOverlaid: {
    initialize: false,
  },
})
