
import { defineComponent, ref, onBeforeMount } from 'vue'

export default defineComponent({
  name: 'BodyInput',
  props: { value: String, error: String },

  setup(props, { emit }) {
    const body = ref<string>('')
    onBeforeMount(() => {
      body.value = props.value ?? ''
    })
    const handleInput = (event: Event): void => {
      const value = (event.target as HTMLInputElement).value
      body.value = value
      emit('input', value)
    }

    return { handleInput, body }
  },

  components: {},
})
