
import TransferOnDemandButton from './TransferOnDemandButton.vue'

import { GradeModel } from '@/request/api/grade/models/grade-data'
import { computed, defineComponent, PropType } from 'vue'
import { getRoleIdFromJwt } from '@/Utils'
import { USER_TYPE } from '@/config'
import { getAccessTokenFromSession } from '@/services/ControlToken'

export default defineComponent({
  props: {
    columnTitleList: {
      type: Array as PropType<string[]>,
      required: true,
    },

    list: {
      type: Array as PropType<GradeModel[]>,
      required: true,
    },
    isLoadingGradeList : Boolean,
  },

  methods: {
    handleInputAnswer: function (
      testCd: string | undefined,
      testKamokuCd: string | undefined
    ) {
      this.$emit('emitting', testCd, testKamokuCd)
    },
    testNmClick: function (
      testNm: string | undefined,
      testKamokuCd: string | undefined,
      testCd: string | undefined
    ) {
      this.$emit('emitDetail', testNm, testKamokuCd, testCd)
    },
  },

  setup(props) {
    const accessToken = getAccessTokenFromSession()
    const userType = getRoleIdFromJwt(accessToken) ?? ''
    const computedList = computed<GradeModel[]>(() => {
      let tmpList = props.list as GradeModel[]
      if(!tmpList){
        return tmpList;
      }
      tmpList.forEach(e1 => {
        e1.countOFSameTestCd =  tmpList.filter((e2) => e2.testCd === e1.testCd)?.length
      });
      return tmpList
    })

    const isStudent = computed<boolean>(() => userType === USER_TYPE.STUDENT)

    return { computedList, isStudent }
  },

  components: { TransferOnDemandButton },
})
