import { render, staticRenderFns } from "./TextBox.vue?vue&type=template&id=134023ec&scoped=true"
import script from "./TextBox.vue?vue&type=script&lang=ts"
export * from "./TextBox.vue?vue&type=script&lang=ts"
import style0 from "./TextBox.vue?vue&type=style&index=0&id=134023ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134023ec",
  null
  
)

export default component.exports