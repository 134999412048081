
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import GradeDataSearchInput from '@/views/GradeDataSearch/SubPages/GradeDataSearchInput.vue'
import InformationSearchTable from './InformationSearchTable.vue'
import BaseButton from '@/components/common/BaseButton.vue'

// Dependencies
import { reactive, defineComponent, toRefs, computed } from 'vue'
import { StudentInformation } from '@/typings'
import { useStore } from '@/store'
import { Store } from 'vuex'
import { useRouter } from '@/router'
import { setStudentInformationListToStorage } from '@/services/InformationMessage/StudentInformation'

interface State {
  gradeDataDetailList: StudentInformation[]
  selectedStudentInformation: StudentInformation[]
}

interface DomClass {
  inputContent: string
  tableWrapper: string
  table: string
}

export default defineComponent({
  setup() {
    // Data
    const store: Store<any> = useStore()

    const state = reactive<State>({
      gradeDataDetailList: [],
      selectedStudentInformation: [],
    })

    const isUserFromAPP = computed<boolean>(() => store?.state?.isUserFromAPP)

    const domClass = reactive<DomClass>({
      inputContent: `input_content ${
        isUserFromAPP.value && 'app-layout_width'
      }`,

      tableWrapper: `table_wrapper ${
        isUserFromAPP.value && 'app-layout_table_wrapper'
      }`,

      table: `table ${isUserFromAPP.value && 'app-layout_table-width'}`,
    })

    // Methods
    const showDetails = async (gradeDataDetailList: StudentInformation[]) => {
      //受験生明細データを設定し、明細部をレンダリングする
      state.gradeDataDetailList = gradeDataDetailList.filter(
        duplicateGradeDataFilter
      )
    }

    const duplicateGradeDataFilter = (
      item: StudentInformation,
      index: number,
      list: StudentInformation[]
    ): boolean => {
      const firstIndex: number = list.findIndex(
        (item02: StudentInformation) => item.jukoId === item02.jukoId
      )

      return index === firstIndex
    }

    const router = useRouter()

    const handleSubmit = (): void => {
      setStudentInformationListToStorage(state.selectedStudentInformation)

      router.push({
        name: 'CreateMessage',
        params: { transitionSource: 'SelectJukoSei' },
      })
    }

    return {
      ...toRefs(state),
      ...toRefs(domClass),
      showDetails,
      handleSubmit,
    }
  },

  components: {
    ManagePageContainer,
    InformationSearchTable,
    GradeDataSearchInput,
    BaseButton,
  },
})
