
import { defineComponent, PropType } from 'vue'
import { InformationBranchItem } from '@/typings'
export default defineComponent({
  props: {
    list: {
      type: Array as PropType<InformationBranchItem[]>,
      required: true,
    },
  },

  methods: {
    handleItemClick: function (branch: InformationBranchItem) {
      this.$emit('emitting', branch)
    },
  },
})
