
/**
 * インフォメーション - 送信履歴詳細削除確認部品
 */

// Components
import MessageWrapper from './MessageDetailItemWrapper.vue'
import LabelAfterCheckbox from '@/components/common/checkbox/LabelAfterCheckbox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import { PDFJS_CONFIG } from '@/config/pdfjsConfig'
// Dependencies
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  computed,
} from 'vue'
import { MessageSentHistoryItemModel } from '@/request/api/information-message/model/sentHistory'
import { SENT_MESSAGE_DETAIL, getGlobalParams } from '@/services/GlobleParames'
import { downloadFile } from '@/request/api'
import { getMessage } from '@/Utils'
import { useStore } from '@/store'

export default defineComponent({
  name: 'MessageDetailDeleteConfirm',

  props: {
    messageDetail: {
      type: Object as PropType<MessageSentHistoryItemModel>,
      require: true,
    },

    destinationList: {
      type: Array as PropType<string[]>,
      required: true,
    },

    fileIDList: {
      type: Array as PropType<string[]>,
    },
  },

  setup(props) {
    const store = useStore()
    const state = reactive({
      message: props.messageDetail,
      attachmentList: [] as string[],
      refreshPdf: [] as boolean[],
      uploadFileError:"",
    })

    onBeforeMount(() => {
      state.message = getGlobalParams(SENT_MESSAGE_DETAIL)
      state.attachmentList = state.message?.attachmentList ?? []
    })

    // 宛先
    const computedDestinationList = computed<string>(
      (): string => props.destinationList.join(', ') ?? ''
    )

    const destinationTotal = computed<number>(() => {
      return props.destinationList.length
    })

    // 添付ファイル
    const fileList = ref<string[]>([])

    onBeforeMount(async () => {
      if (!props.fileIDList?.length) {
        return
      }
      var i = 0

      store?.commit('setIsLoadingWithText',true)
      store?.commit('setIsLoadingText',"ダウンロード中...")
      for await (let fileID of props.fileIDList) {
        const itemurl = await downloadFile(fileID)
        if(!itemurl){
          state.uploadFileError = getMessage('EFR0041')
          store?.commit('setIsLoadingWithText',false)
          throw new Error(getMessage('EFR0041'))
        }
        fileList.value = [...fileList.value, itemurl]
        state.refreshPdf[i] = !state.refreshPdf[i]
        i++
      }
      store?.commit('setIsLoadingWithText',false)
    })

    return {
      ...toRefs(state),
      fileList,
      computedDestinationList,
      destinationTotal,
      PDFJS_CONFIG,

    }
  },

  components: { MessageWrapper, LabelAfterCheckbox, BaseButton, VuePdfApp },
})
