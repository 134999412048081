
// Dependencies
import { computed, defineComponent, PropType, ref, onBeforeMount } from 'vue'
import { useStore } from '@/store'
import { countUnreplyMessage } from '@/request/api/information-message/message'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useGlobalError } from '@/services/Hooks'
import { Component } from 'vue/types/umd'

const { setGlobalError } = useGlobalError()

// TheCardコンポーネントの定義
export default defineComponent({
  name: 'TheCard',
  props: {
    icon: {
      type: Object as PropType<Component>, // アイコンコンポーネント
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },



  setup(props) {
    const store = useStore()
    const isUserFromAPP = computed(() => store.state.isUserFromAPP)
    const unreplyMessageCount = ref<number>(0)

    // ユーザーIDを取得する関数
    const getUserID = (): string => {
      return getUserIDFromAccessToken(getAccessTokenFromSession())
    }

    // 未回答メッセージ数を取得する非同期関数
    onBeforeMount(async () => {
      const userID = getUserID();
      try {
        if (props.title === 'お知らせ') { // 「お知らせ」の場合のみAPI呼び出しを行う
          const count = await countUnreplyMessage(userID);
          unreplyMessageCount.value = count;
        }
      } catch (error) {
        setGlobalError(error as Error);
      }
    })

    return { isUserFromAPP, unreplyMessageCount }
  },
})

