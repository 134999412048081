
// Dependencies
import { defineComponent, reactive, toRefs } from 'vue'

// Components
import TermsPreamble from '@/components/common/Terms/TermsPreamble.vue'
import TermsParagraph from '@/components/common/Terms/TermsParagraph.vue'
import TermsBackMatter from '@/components/common/Terms/TermsBackMatter.vue'
import TermsList from '@/components/common/Terms/TermsList.vue'

export default defineComponent({
  components: { TermsPreamble, TermsParagraph, TermsBackMatter, TermsList },

  setup() {
    const state = reactive({
      title: 'プライバシーポリシー（個人情報の取り扱いについて）',
      signature: 'SHIKAKU APP', // 右上の署名
      telephone: '0120-375-159',
      mailTo: 'Mailto:customer@shikaku.co.jp',
      timestamp: '2021 年 10 月 15 日 現在',
    })

    const sendMail = () => window.open(state.mailTo)

    return {
      ...toRefs(state),
      sendMail,
    }
  },
})
