
/**
 * 回答ありのアイコン
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconMail',

  props: {
    fill: {
      type: String,
      default: '#869fb3',
    },

    height: {
      type: String,
      default: '24',
    },

    width: {
      type: String,
      default: '24',
    },
  },
})
