
// Dependencies
import { defineComponent } from 'vue'

// Icons
import IconBlank from '@/components/icons/IconBlank.vue'
import IconLogo02 from '@/components/icons/IconLogo02.vue'
import { OFFICIAL_SITE } from '@/config'

export default defineComponent({
  components: { IconBlank, IconLogo02 },

  setup() {
    // Methods
    const handleTransferPage = (): void => location.assign(OFFICIAL_SITE)

    return {
      handleTransferPage,
    }
  },
})
