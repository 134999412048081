
import { defineComponent, computed, reactive, toRefs, PropType } from 'vue'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import BaseSelector from '@/components/common/BaseSelector.vue'
import { MogiShikenDetailLst } from '@/typings/MogiShiken'
import { SEISEKI_TYPE_MOGISHIKEN, SEISEKI_TYPE_KOSHUBI, SEISEKI_TYPE_KAMOKU, SEISEKI_TYPE_OTHER, SEISEKI_TYPE_TESTCLASS } from '@/views/GradeData/SubPages/common'
import { GradeModel } from '@/request/api/grade/models/grade-data'
import { GradeDetailBySubject } from '@/typings/GradeBySubject'
import { GradeDetailByTestClass } from '@/typings/GradeByTestClass'
import { useStore } from '@/store'

const OPTION = { ALL: 'すべて', RIGHT: '〇', WRONG: '✕' }

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<MogiShikenDetailLst[]>,
      required: true,
    },

    kamokuNm: {
      type: String,
      required: true,
    },
    seiSekiType: {
      type: String,
      required: true,
    },
    grade: {
      type: Object
    }
  },

  components: {
    OverlayScrollbarsComponent,
    BaseSelector,
  },

  setup(props) {
    const state = reactive({
      bunrui: OPTION.ALL,
      hantei: OPTION.ALL,
      mondaiType: OPTION.ALL,
    })
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const filteredSimulationDetailList = computed<MogiShikenDetailLst[]>(() => {
      let list: MogiShikenDetailLst[] = props.list ?? []

      const mapWrongRightList = new Map<string, string>([
        [OPTION.RIGHT, '1'],
        [OPTION.WRONG, '0'],
      ])

      if (state.hantei !== OPTION.ALL) {
        list = list.filter(
          (item: MogiShikenDetailLst): boolean =>
            item.seigoFlg === mapWrongRightList.get(state.hantei)
        )
      }

      if (state.bunrui !== OPTION.ALL) {
        list = list.filter(
          (item: MogiShikenDetailLst): boolean =>
            item.typeBunruiNm === state.bunrui
        )
      }

      if (state.mondaiType !== OPTION.ALL) {
        list = list.filter(
          (item: MogiShikenDetailLst): boolean =>
            item.typeMondaiNm === state.mondaiType
        )
      }

      return list
    })
    const isShowHanTei = computed<boolean>(()=> {
      if(!props.seiSekiType || !props.grade){
        return true
      }
      if(props.seiSekiType === SEISEKI_TYPE_KOSHUBI){
        if(Number((props.grade as GradeModel)?.mantenSu) > 0){
          return true
        }else{
          return false
        }
      }
      if(props.seiSekiType === SEISEKI_TYPE_KAMOKU){
        if(Number((props.grade as GradeDetailBySubject)?.mantenSu) > 0){
          return true
        }else{
          return false
        }
      }
      if(props.seiSekiType === SEISEKI_TYPE_OTHER || props.seiSekiType === SEISEKI_TYPE_TESTCLASS ){
        if(Number((props.grade as GradeDetailByTestClass)?.mantenSu) > 0){
          return true
        }else{
          return false
        }
      }
      return true
    })
    // 判定
    const hanteiList = computed<string[]>(() => {
      let list: string[] = []
      props.list.forEach((item: any, index: any) => {
        if (item.seigoFlg === '1' && list.indexOf(OPTION.RIGHT) == -1) {
          list.push(OPTION.RIGHT)
        } else if (item.seigoFlg === '0' && list.indexOf(OPTION.WRONG) == -1) {
          list.push(OPTION.WRONG)
        }
      })

      return [OPTION.ALL, ...new Set(list)]
    })

    const handleSelectHantei = (index: number): void => {
      state.hantei = hanteiList.value[index]
    }

    // 問題タイプ
    const mondaiTypeList = computed<unknown[]>(() => {
      const list = props.list.map<string>(
        (item: MogiShikenDetailLst) => item.typeMondaiNm as string
      )
      list.sort()

      return [OPTION.ALL, ...new Set(list)]
    })

    const handleSelectMondaiType = (index: number): void => {
      state.mondaiType = mondaiTypeList.value[index] as string
    }

    // 分類
    const bunruiList = computed<unknown[]>(() => {
      const list = props.list.map<string>(
        (item: MogiShikenDetailLst) => item.typeBunruiNm as string
      )
      list.sort()

      return [OPTION.ALL, ...new Set(list)]
    })

    const handleSelectBunrui = (index: number): void => {
      state.bunrui = bunruiList.value[index] as string
    }

    const bunruiSelectBarStyle = {
      background: '#000000',
      border: 0,
      padding: '1px',
      color: '#FFF',
      'white-space': 'nowrap',
      display: 'inline-table',
      height: '15px',
    }

    const selectorFontStyle = {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      color: '#C0C0C0',
      'font-weight': 'bold',
      width: 'auto',
    }

    const selectorFontHanteiMondaiTypeStyle = {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      color: '#C0C0C0',
      'font-weight': 'bold',
      width: 'auto',
      'padding-right': '15px',
      'padding-left': '10px',
      'text-align': 'center',
    }

    const iconArrowDownTriangleTypeStyle = {
      right: '1px',
    }

    const bunruiOptionStyle = {
      padding: '1px',
      'text-align': 'left',
      color: '#333',
      'white-space': 'nowrap',
    }

    const optionCenterStyle = {
      padding: '1px',
      'text-align': 'center',
      color: '#333',
      'white-space': 'nowrap',
    }

    return {
      ...toRefs(state),
      filteredSimulationDetailList,
      bunruiList,
      handleSelectBunrui,
      bunruiSelectBarStyle,
      selectorFontStyle,
      selectorFontHanteiMondaiTypeStyle,
      bunruiOptionStyle,
      optionCenterStyle,
      iconArrowDownTriangleTypeStyle,
      hanteiList,
      handleSelectHantei,
      mondaiTypeList,
      handleSelectMondaiType,
      SEISEKI_TYPE_MOGISHIKEN,
      isShowHanTei,
      isUserFromAPP,
    }
  },
})
