
/**
 * インフォメーション - 送信先リスト選択画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'

// Dependencies
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'

interface State {
  userID: string
  applicationNo: string
}

export default defineComponent({
  components: {
    ManagePageContainer,
  },

  props: {
    msg: {
      type: String,
      default: '処理が完了しました。',
    },
  },
  setup() {
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    return { isUserFromAPP }
  },
})
