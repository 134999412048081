
/**
 * メッセージ受信一覧画面
 */
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import MessageCard from '@/components/views/InformationMessageList/MessageCard.vue'
import { INFORMATION_MESSAGE_NUMBER } from '@/config'
import {
  queryRecievedMessageList,
  deleteMessageByPeriod,
} from '@/request/api/information-message/message'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { RecievedMessageModel } from '@/request/api/information-message/model/message'
import {
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  computed,
  onMounted,
  onUnmounted,
} from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  mainContainerStyle,
  mainContainerStyleAPP,
  scrollContainerStyle,
  scrollContainerStyleAPP,
} from './styles'

interface State {
  userID: string
  messageList: RecievedMessageModel[]
  windowWidth: number
  lockScroll: boolean
}

export default defineComponent({
  name: 'InformationMessageList',

  setup() {
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      messageList: [],
      windowWidth: window.innerWidth,
      lockScroll: false,
    })
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store?.state?.isUserFromAPP)
    onBeforeMount(async () => {
      if (!sessionStorage.getItem("lastDateOfDeletePeriodMessage") || Number(getCurrentDate()) > Number(sessionStorage.getItem("lastDateOfDeletePeriodMessage"))) {
        await deleteMessageByPeriod()
        sessionStorage.setItem("lastDateOfDeletePeriodMessage", getCurrentDate())
      }
      const messageList: RecievedMessageModel[] =
        await queryRecievedMessageList({
          userID: state.userID,
          max: INFORMATION_MESSAGE_NUMBER,
        })

      // 返信ではないメッセージを表示
      state.messageList = [...filterNotReplyMessage(messageList)]

      // 更新順（返信も含む）でソート
      state.messageList = [...state.messageList.sort(messageListSortCallback)]
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
    }

    const getCurrentDate = () => {
      const _time: Date = new Date()
      const year: number = _time.getFullYear()
      const month: number = _time.getMonth() + 1
      const dayOfMonth: number = _time.getDate()
      return year.toString() + (month > 9 ? month.toString() : "0" + month.toString()) + (dayOfMonth > 9 ? dayOfMonth.toString() : "0" + dayOfMonth.toString())
    }

    const mainStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return mainContainerStyleAPP
      } else {
        return mainContainerStyle
      }
    })
    const scrollStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return scrollContainerStyleAPP
      } else {
        return scrollContainerStyle
      }
    })
    const filterNotReplyMessage = (
      messageList: RecievedMessageModel[]
    ): RecievedMessageModel[] => {

      let tempMessageList = [] as RecievedMessageModel[]
      messageList.forEach((item) => {
        item.tempReplyNo = item.replyNo
        if (!item.replyNo) {
          item.replyNo = item.messageNo
        }

      })

      messageList.sort(messageListSortFilterNotReplyCallback)
      let preMessage = {} as RecievedMessageModel
      messageList.forEach((item) => {
        if (item.replyNo !== preMessage.replyNo) {
          tempMessageList = [...tempMessageList, item]
        }
        preMessage = { ...item }
      })

      return tempMessageList;

    }

    const formatTimeStamp = (timestamp: string) => {
      //input sample: "2024年03月21日 木 10:13:44"
      return (
        timestamp.substring(0, 4) +
        '/' +
        timestamp.substring(5, 7) +
        '/' +
        timestamp.substring(8, 10) +
        ' ' +
        timestamp.substring(14, 22)
      )
    }

    const messageListSortCallback = (
      previous: RecievedMessageModel,
      next: RecievedMessageModel
    ): number => {
      const preUpdateDate = new Date(formatTimeStamp(previous.updatedTimestamp))
      const preSendDate = new Date(formatTimeStamp(previous.sendTimestamp))
      const nextUpdateDate = new Date(formatTimeStamp(next.updatedTimestamp))
      const nextSendDate = new Date(formatTimeStamp(next.sendTimestamp))
      const previousTimestamp = preUpdateDate > preSendDate ? preUpdateDate : preSendDate
      const nextTimestamp = nextUpdateDate > nextSendDate ? nextUpdateDate : nextSendDate
      return nextTimestamp.getTime() - previousTimestamp.getTime()
    }

    const messageListSortFilterNotReplyCallback = (
      previous: RecievedMessageModel,
      next: RecievedMessageModel
    ): number => {

      if (previous.replyNo === next.replyNo) {
        const previousTimestamp = new Date(
          formatTimeStamp(previous.sendTimestamp)
        )
        const nextTimestamp = new Date(formatTimeStamp(next.sendTimestamp))
        return previousTimestamp.getTime() - nextTimestamp.getTime()
      } else {
        return Number(next.messageNo) - Number(previous.messageNo)
      }
    }

    const handleScroll = async (event: Event): Promise<void> => {
      if (state.lockScroll) return;
      const container: HTMLDivElement = event.target as HTMLDivElement

      const computedScrollHeight: number = Math.ceil(
        container.scrollTop + container.clientHeight
      )

      const scrollHeight: number = container.scrollHeight

      // スクロールした分 ＋ 表示分がスクロール全体の高度以上であれば列の最後に達したと認定
      if (computedScrollHeight >= scrollHeight) {
        state.lockScroll = true
        let list = await queryRecievedMessageList({
          userID: state.userID,
          lastMessageNumber:
            state.messageList[state.messageList.length - 1].messageNo,
          max: INFORMATION_MESSAGE_NUMBER,
        })

        list = [...filterNotReplyMessage(list)]
        list = [...list.sort(messageListSortCallback)]
        state.messageList = [...state.messageList, ...list]

        setTimeout(() => {
          state.lockScroll = false
        }, 1000)
      }
    }
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return {
      ...toRefs(state),
      handleScroll,
      mainStyle,
      scrollStyle,
      isFromIOSAPP,
      isFromAndroidAPP,
      isUserFromAPP,
    }
  },

  components: { ContentPageContainer, MessageCard, SubHeader },
})
