
/**
 * 合格ダイアリー - デフォルトスケジュール選択画面
 */
import ScheduleAchievementClock from '@/components/views/PassDiary/ScheduleAchievementClock.vue'
import DiaryGraph from '@/components/views/PassDiary/DiaryGraph.vue'
import InputWrapperTitle from '@/components/views/PassDiary/InputWrapperTitle.vue'
import ScheduleTime from '@/components/views/PassDiary/ScheduleTime.vue'
import PassDiaryInputPageButton from '@/components/views/PassDiary/PassDiaryInputPageButton.vue'
import IconClose03 from '@/components/icons/IconClose03.vue'
import IconPen from '@/components/icons/IconPen02.vue'

import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  onUnmounted,
} from 'vue'

import { getDailySchedule } from '@/request/api'

import {
  addDailySchedule,
  deleteDailyScheduleByDate,
} from '@/request/api/pass-diary/dailySchedule'
import { ApplicationInformation } from '@/typings'
import { LabelClassification } from '@/typings/index'
import { DefaultDailyScheduleModelList } from '@/typings/PassDiary'
import { DailyScheduleModel } from '@/request/api/pass-diary/models/dailySchedule'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_MODIFIED_SCHEDULE,
  SET_TRANSFER_MEMO_STATUS,
} from '@/store/modules/pass-diary/actionTypes'

interface InterfaceDate {
  _date: Date
  year: number
  month: number
  day: number
  dayOfWeek: string
}

export interface PlanTime {
  start: string
  goal: string
}

export default defineComponent({
  name: 'SelectDefaultSchedule',

  props: {
    applicationInformation: {
      type: Object as PropType<ApplicationInformation>,
      required: true,
    },

    label: {
      type: Array as PropType<LabelClassification[]>,
      required: true,
    },
    defaultDailyScheduleModelList: {
      type: Array as PropType<DefaultDailyScheduleModelList[]>,
      required: true,
    },
    targetDate: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    const setTransferFromMemoStatsu = (status: boolean): Promise<void> =>
      store.dispatch(SET_TRANSFER_MEMO_STATUS, status)

    const setScheduleToStore = (schedule: DailyScheduleModel): Promise<void> =>
      store.dispatch(SET_MODIFIED_SCHEDULE, schedule)

    const left = () => {
      $('.right')[0].click()
    }
    const right = () => {
      $('.left')[0].click()
    }

    //OKボタン押下
    const closeWithSetData = async (): Promise<void> => {
      var index = 0
      var box = Array.from(document.getElementsByClassName('item'))
      Array.prototype.forEach.call(box, function (element) {
        if (element.className == 'item active') {
          index = box.indexOf(element)
        }
      })
      
      console.log(index)
      await sendData(index)
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)

      registAllowed.value && emit('closeInputWrapper')
    }
    //キャンセルボタン押下
    const handleCloseInputWrapper = async (): Promise<void> => {
      await setTransferFromMemoStatsu(false)
      await setScheduleToStore({} as DailyScheduleModel)
      emit('closeInputWrapper')
    }
    //初期化
    const state = reactive({
      userCode:
        props.applicationInformation.userId ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationNo: props.applicationInformation.applicationNo,
      title: 'このスケジュールを挿入しますか？',
      scheduleList: [] as DailyScheduleModel[],
      achieveList: [] as DailyScheduleModel[],
      windowWidth: window.innerWidth,
      isUserFromAPP: store.state.isUserFromAPP,
      refreshGraph: 0,
      dailyScheduleList: [] as DailyScheduleModel[],
    })

    const registAllowed = ref<boolean>(false)

    const sendData = async (index: number): Promise<void> => {
      registAllowed.value = true
      await deleteDailyScheduleByDate({
        userCode: state.userCode ?? '',
        applicationNo: state.applicationNo ?? '',
        targetDate: props.targetDate,
      })
      props.defaultDailyScheduleModelList[
        index
      ].defaultDailyScheduleModel.forEach(
        async (defaultDailyScheduleModel: any) => {
          await setScheduleToStore(defaultDailyScheduleModel)
          console.log('add')
          await addDailySchedule({
            userCode: state.userCode ?? '',
            applicationNo: state.applicationNo ?? '',
            dailySchedule: defaultDailyScheduleModel,
          })
        }
      )
    }
    onMounted(() => {
      window.addEventListener('resize', handleResize)
      setDailySchedule()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    const setDailySchedule = async (): Promise<void> => {
      const dailyScheduleList: DailyScheduleModel[] = await getDailySchedule({
        userCode:
          props.applicationInformation.userId ??
          getUserIDFromAccessToken(getAccessTokenFromSession()),
        applicationNo: props.applicationInformation.applicationNo,
        date:
          props.targetDate ??
          props.defaultDailyScheduleModelList[0].defaultDailyScheduleModel[0]
            .date,
      })
      state.dailyScheduleList = dailyScheduleList ?? []
    }
    const handleResize = () => {
      state.windowWidth = window.innerWidth
      state.refreshGraph++
    }
    //円盤サイズ
    const scale = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { x: 0.875, y: 0.875 }
      } else {
        return { x: 1.4, y: 1.4 }
      }
    })
    //ラベルサイズ
    const labelStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { margin: '0 0', size: 20 }
      } else {
        return { margin: '2rem 0 0', size: 40 }
      }
    })

    return {
      ...toRefs(state),
      handleCloseInputWrapper,
      closeWithSetData,
      left,
      right,
      scale,
      labelStyle,
    }
  },

  components: {
    ScheduleAchievementClock,
    DiaryGraph,
    InputWrapperTitle,
    ScheduleTime,
    IconClose03,
    IconPen,
    PassDiaryInputPageButton,
  },
})
