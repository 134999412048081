
// Components
import { LabelClassification } from '@/typings/index'
// Dependencies
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'LabelInput',
  props: {
    label: {
      type: Object as PropType<LabelClassification>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const labelColor = 'border-color: #' + props.label.name2 + ';'

    const itemClick = (): void => {
      emit('emitting', props.label)
    }

    return { labelColor, itemClick }
  },
})
