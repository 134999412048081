import { render, staticRenderFns } from "./ServiceTerms.vue?vue&type=template&id=63ec1288&scoped=true"
import script from "./ServiceTerms.vue?vue&type=script&lang=ts"
export * from "./ServiceTerms.vue?vue&type=script&lang=ts"
import style0 from "./ServiceTerms.vue?vue&type=style&index=0&id=63ec1288&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ec1288",
  null
  
)

export default component.exports