var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"grade-table",attrs:{"id":"GradeTable"}},[_c('div',{staticClass:"column-title_container",style:({
      gridTemplateColumns: `${
        _vm.columnTitleList[0] === '科目' ? '2fr' : ''
      } 2fr 1.5fr 1fr 1fr`,
    })},_vm._l((_vm.columnTitleList),function(title){return _c('div',{key:title,staticClass:"column-title_item"},[_c('span',[_vm._v(_vm._s(title))])])}),0),(_vm.isLoadingGradeList)?_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"20px"}},[_vm._v("読み込み中…")]):(_vm.computedList.length > 0)?_c('div',{staticClass:"grade-data"},_vm._l((_vm.computedList),function(item,index){return _c('div',{key:index,staticClass:"grade_Container",style:([
        {
          background: index % 2 === 0 ? '' : '#f4f4f4',

          gridTemplateColumns: `${
            _vm.columnTitleList[0] === '科目' ? '2fr' : ''
          } 2fr 1.5fr 1fr 1fr`,
        },
        ,
      ])},[(_vm.columnTitleList.find((item) => item === '科目'))?_c('span',{staticClass:"grade_subject"},[_vm._v(" "+_vm._s(item.kamokuNm)+" ")]):_vm._e(),(item.countOFSameTestCd && item.countOFSameTestCd === 1)?_c('span',[_c('span',{staticStyle:{"font-size":"x-small"}},[_vm._v(_vm._s(item.testCdDisplay))]),_c('br'),_c('span',[_c('a',{staticClass:"acls",attrs:{"hlef":"#"},on:{"click":function($event){return _vm.testNmClick(item.testNm, item.testKamokuCd, item.testCd)}}},[_vm._v(_vm._s(item.testNm))])])]):(item.testKamokuCd === '0')?_c('span',[_c('span',{staticStyle:{"font-size":"x-small"}},[_vm._v(_vm._s(item.testCdDisplay))]),_c('br'),_c('span',[_vm._v(_vm._s(item.testNm))])]):_c('span',[_c('a',{staticClass:"acls",attrs:{"hlef":"#"},on:{"click":function($event){return _vm.testNmClick(item.testNm, item.testKamokuCd, item.testCd)}}},[_vm._v(_vm._s(item.testNm))])]),_c('span',{staticClass:"grade_score"},[(!item.teishutsuFlg)?_c('span',{staticStyle:{"color":"#0d3f67","justify-self":"center"}},[(item.enableInputAnswerFlg && _vm.isStudent)?_c('a',{staticClass:"acls",attrs:{"hlef":"#"},on:{"click":function($event){return _vm.handleInputAnswer(item.testCd, item.testKamokuCd)}}},[_vm._v(" 未 ")]):_c('label',[_vm._v("未")])]):(
            Number(item.mantenSu) === -9999 &&
            Number(item.tokutenSu) === -9999
          )?_c('span',{staticStyle:{"color":"#9d9d9d","justify-self":"center"}}):(Number(item.mantenSu) <= 0)?_c('span',{staticStyle:{"color":"#9d9d9d","justify-self":"center"}},[_vm._v(" 済 ")]):(Number(item.mantenSu) > 0)?_c('span',{staticClass:"grade_score_exist"},[_c('span',[_vm._v(_vm._s(item.tokutenSu))]),_c('span',{staticClass:"grade_score_right-number"},[_vm._v(" /"+_vm._s(item.mantenSu)+" ")])]):_c('span',{staticStyle:{"color":"#0d3f67","justify-self":"center"}},[(item.enableInputAnswerFlg)?_c('a',{staticClass:"acls",attrs:{"hlef":"#"},on:{"click":function($event){return _vm.handleInputAnswer(item.testCd, item.testKamokuCd)}}},[_vm._v(" 未 ")]):_c('label',[_vm._v("未")])])]),_c('span',{staticClass:"grade_score-late",style:({
          opacity:
            item.teishutsuFlg &&
            Number(item.mantenSu || 0) &&
            Number(item.tokutenSu || 0)
              ? 1
              : 0,

          pointerEvents:
            item.teishutsuFlg &&
            Number(item.mantenSu || 0) &&
            Number(item.tokutenSu || 0)
              ? 'auto'
              : 'none',
        })},[_vm._v(" "+_vm._s(item.mantenSu != -9999 && ((item.tokutenSu / item.mantenSu) * 100).toFixed() >= 0 ? `${((item.tokutenSu / item.mantenSu) * 100).toFixed()}%` : '')+" ")]),(item.shussekiFlg)?_c('TransferOnDemandButton',{attrs:{"id":'Link' + item.testClassKbn,"grade":item}}):_vm._e()],1)}),0):_c('div',{staticClass:"text-center no-data-message"},[_vm._v("表示するデータはありません。")])])
}
var staticRenderFns = []

export { render, staticRenderFns }