
// Components
import BaseSelector from '@/components/common/BaseSelector.vue'
import GradeTable from '@/components/views/GradeData/GradeTable.vue'

// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  watch,
} from 'vue'
import {
  queryApplicationInformationList,
  queryTestClassInitializeData,
  getTestInformation,
  queryGradeByTestClass,
  queryKaiToSeiGo,
} from '@/request/api'
import { useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { ApplicationInformation } from '@/typings'
import { GradeDetailByTestClass } from '@/typings/GradeByTestClass'
import {
  GetTestInformationArguments,
  TestInformation,
} from '@/typings/TrainingDay'
import { TestClassDetail } from '@/typings/TestClass'
import {
  transferToInputMarkList,
  transferTestClassKbn,
  SEISEKI_TYPE_TESTCLASS,
} from './common'
import { useRouter } from '@/router'
import SwitchDetailButton from '@/components/views/GradeData/SwitchDetailButton.vue'
import MogishikenDetailTable from '@/components/views/GradeData/MogishikenDetailTable.vue'
import { MogiShikenDetailLst } from '@/typings/MogiShiken'
import { CONST_SELECTED_TESTCLASS } from '@/Utils'

interface Flag {
  leftButtonVisible: boolean
  rightButtonVisible: boolean
}
interface CompareFunction {
  (previous: GradeDetailByTestClass, next: GradeDetailByTestClass): number
}
export default defineComponent({
  components: {
    BaseSelector,
    GradeTable,
    SwitchDetailButton,
    MogishikenDetailTable,
  },

  props: {
    userIDProps: String,
    applicationNo: String,
    isShowMenu: Boolean,
  },

  setup(props) {
    const { setGlobalError } = useGlobalError()
    const router = useRouter()

    // State
    const state = reactive({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      testClassApplicationInfoList: [] as ApplicationInformation[],
      testClassApplicationInfo: {} as ApplicationInformation,
      testClassList: [] as TestClassDetail[],
      testClass: {} as TestClassDetail,
      testClassGradeList: [] as GradeDetailByTestClass[],
      jukoClassCode: '',
      refreshGradeTable: 0,
      testKamokuCd: '',
      testCd: '',
      testNmDetail: '',
      testClassSeisekiPage: true,
      testClassDetailLst: [] as MogiShikenDetailLst[],
      testClassGradeOfDetailToShow: {} as GradeDetailByTestClass,
      isLoadingGradeList:false
    })

    // Computed
    const testClassFormattedCourse = computed(() => {
      return `${state.testClassApplicationInfo?.jukoFiscalYear ?? ''}　${state.testClassApplicationInfo?.courseName ?? ''}`
    })

    const testClassFormattedCourseList = computed(() =>
      state.testClassApplicationInfoList.map((item) => {
        return `${item.jukoFiscalYear}　${item.courseName ?? ''}`
      })
    )

    const formattedTestClass = computed(() => {
      return state.testClass?.hasOwnProperty('testClassNm')
        ? `${state.testClass.testClassNm ?? ''}`
        : `${state.testClassList?.[0]?.testClassNm}`
    })

    const formattedTestClassList = computed(() => {
      return state.testClassList.map((item) => {
        return `${item.testClassNm ?? ''}`
      })
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        if (props.applicationNo) {
          state.isLoadingGradeList = true
          // 申込情報リスト、設定済み申込情報を初期化
          await setTestClassApplicationInfo()
          // 種別リスト初期化
          await setTestClassList()
          // テスト種別成績初期化
          await setTestClassGradeList()
          state.isLoadingGradeList = false
        }
      } catch (error) {
        handleError(error as Error)
      }
    })

    watch(
      () => [props.applicationNo],
      async () => {
        try {
          state.isLoadingGradeList = true
          await setTestClassApplicationInfo()
          await setTestClassList()
          await setTestClassGradeList()
          state.isLoadingGradeList = false
        } catch (error) {
          handleError(error as Error)
        }
      }
    )

    // Methods
    const handleError = (error: Error | unknown | string): void => {
      state.isLoadingGradeList = false
      state.testClassGradeList = []
      console.log(error)
      setGlobalError(error as Error)
    }

    const setTestClassApplicationInfo = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInformationList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.testClassApplicationInfoList = applicationInformationList ?? []

        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInformationList?.find(
          (item) => item.applicationNo === props.applicationNo
        )

        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.testClassApplicationInfo =
          settedApplicationInformation ?? applicationInformationList?.[0] ?? {}

        state.jukoClassCode = state.testClassApplicationInfo.jukoClassCd ?? ''
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setTestClassList = async () => {
      try {
        const getTestClassList = await queryTestClassInitializeData({
          userID: state.userID,

          applicationNo:
            state.testClassApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',
        })

        state.testClassList =
          getTestClassList?.testClassInfoLst?.sort(
            (ela, elb) =>
              parseInt(ela.sortNo ?? '99') - parseInt(elb.sortNo ?? '99')
          ) ?? []
        if(sessionStorage.getItem(CONST_SELECTED_TESTCLASS)){
          state.testClass = JSON.parse(sessionStorage.getItem(CONST_SELECTED_TESTCLASS) as string) 
        }else{
          state.testClass = state.testClassList?.[0] ?? {}
          sessionStorage.setItem(CONST_SELECTED_TESTCLASS,JSON.stringify(state.testClass))
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setTestClassGradeList = async () => {
      try {
        if (!state.testClassList.length) {
          return
        }
        if (!state.testClassApplicationInfoList?.length) {
          return
        }
        if (
          !state.testClass?.testClassKbn &&
          !state.testClassList?.[0]?.testClassKbn
        ) {
          return
        }
        const localTestClassgradeList = await queryGradeByTestClass({
          userID: state.userID,

          applicationNo:
            state.testClassApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          testClassKbn:
            state.testClass?.testClassKbn ??
            state.testClassList?.[0]?.testClassKbn ??
            '',
        })

        if (!localTestClassgradeList.length) {
          return
        }

        for (let item of localTestClassgradeList) {
          if (!item.teishutsuFlg) {
            const jukoDay = state.testClassApplicationInfo
              .jukoFiscalYear as string

            const params: GetTestInformationArguments = {
              testCd: item.testCd,
              testKamokuCd: item.testKamokuCd,
              jukoFiscalYear: jukoDay.substring(0, 4),
              jukoClassKbn: state.jukoClassCode,
            }
            const testInfo: TestInformation = await getTestInformation(params)
            item.enableInputAnswerFlg = testInfo.teishutsuFlg
          }
          item.testCdDisplay =
            transferTestClassKbn(
              state.testClassApplicationInfo.jukoClassCd ?? ''
            ) + item.testCd
        }
        state.testClassGradeList = localTestClassgradeList ?? []
        state.testClassSeisekiPage = true
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const handleSelectTestClass = async (index: number) => {
      try {
        state.isLoadingGradeList = true
        state.testClass = state.testClassList[index]
        sessionStorage.setItem(CONST_SELECTED_TESTCLASS,JSON.stringify(state.testClass))
        await setTestClassGradeList()
        state.isLoadingGradeList = false
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleTransferToInputMarkList = async (
      testCd: string,
      testKamokuCd: string
    ) => {
      transferToInputMarkList(
        testCd,
        testKamokuCd,
        state.testClassApplicationInfo.jukoFiscalYear as string,
        state.jukoClassCode,
        state.userID,
        state.testClassApplicationInfo.applicationNo as string,
        router
      )
    }
    const flag = reactive<Flag>({
      leftButtonVisible: true,
      rightButtonVisible: true,
    })
    const resetFlag = (): void => {
      flag.leftButtonVisible = true
      flag.rightButtonVisible = true
    }

    const setDetailList = async (
      testKamokuCd: string,
      testCd: string
    ): Promise<void> => {
      try {
        if (!testKamokuCd || !testCd) {
          return
        }

        const mogiShikenDetailList = await queryKaiToSeiGo({
          userID: state.userID,

          applicationNo:
            state.testClassApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: testCd ?? '',
          testKamokuCd: testKamokuCd ?? '',
        })

        state.testClassDetailLst = mogiShikenDetailList?.list ?? []
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleTransferToDetail = async (
      testNm: string,
      testKamokuCd: string,
      testCd: string
    ): Promise<void> => {
      state.testKamokuCd = testKamokuCd
      state.testCd = testCd
      resetFlag()

      const currentDetailIndex = state.testClassGradeList.findIndex(
        (item) => item.testKamokuCd === testKamokuCd && item.testCd === testCd
      )
      state.testClassGradeOfDetailToShow = state.testClassGradeList[currentDetailIndex]
      currentDetailIndex - 1 <= 0 && (flag.leftButtonVisible = false)

      currentDetailIndex + 1 >= state.testClassGradeList.length &&
        (flag.rightButtonVisible = false)

      await setDetailList(testKamokuCd, testCd)
      state.testNmDetail = testNm
      state.testClassSeisekiPage = false
    }

    const handlePrevious = async (): Promise<void> => {
      try {
        resetFlag()
        // 一個前の科目を取得
        const previousDetailIndex =
          state.testClassGradeList.findIndex(
            (item) =>
              item.testKamokuCd === state.testKamokuCd &&
              item.testCd === state.testCd
          ) - 1

        if (previousDetailIndex - 1 <= 0) {
          flag.leftButtonVisible = false
        }

        const previousDetail = state.testClassGradeList[previousDetailIndex]
        state.testClassGradeOfDetailToShow = previousDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(
          previousDetail.testKamokuCd as string,
          previousDetail.testCd as string
        )

        state.testKamokuCd = previousDetail.testKamokuCd as string
        state.testCd = previousDetail.testCd as string
        state.testNmDetail = previousDetail.testNm
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleNext = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const nextDetailIndex =
          state.testClassGradeList.findIndex(
            (item) =>
              item.testKamokuCd === state.testKamokuCd &&
              item.testCd === state.testCd
          ) + 1

        if (nextDetailIndex + 1 >= state.testClassGradeList.length) {
          flag.rightButtonVisible = false
        }

        const nextDetail = state.testClassGradeList[nextDetailIndex]
        state.testClassGradeOfDetailToShow = nextDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(
          nextDetail.testKamokuCd as string,
          nextDetail.testCd as string
        )

        state.testKamokuCd = nextDetail.testKamokuCd as string
        state.testCd = nextDetail.testCd as string
        state.testNmDetail = nextDetail.testNm
      } catch (error) {
        handleError(error as Error)
      }
    }
    // Styles
    const courseSelectBarStyle = {
      background: '#f3f3f3',
      border: 'none',
    }

    const testClassSelectBarStyle = {
      border: 'solid 1px #979797',
      borderRadius: '5px',
    }

    return {
      ...toRefs(state),
      ...toRefs(flag),
      handleSelectTestClass,
      handleTransferToInputMarkList,
      handleTransferToDetail,
      handlePrevious,
      handleNext,
      courseSelectBarStyle,
      testClassSelectBarStyle,
      testClassFormattedCourse,
      testClassFormattedCourseList,
      formattedTestClass,
      formattedTestClassList,
      SEISEKI_TYPE_TESTCLASS,
    }
  },
})
