
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ReplyContent',
  props: {
    replyBody: String,
    rePlyTime: String,
  },

})
