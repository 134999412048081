import { render, staticRenderFns } from "./TabList.vue?vue&type=template&id=e9131fce&scoped=true"
import script from "./TabList.vue?vue&type=script&lang=ts"
export * from "./TabList.vue?vue&type=script&lang=ts"
import style0 from "./TabList.vue?vue&type=style&index=0&id=e9131fce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9131fce",
  null
  
)

export default component.exports