
import { defineComponent, PropType, computed } from 'vue'
import { DailyScheduleModel } from '@/typings/PassDiary'
import { formatTime, getRoleIdFromJwt } from '@/Utils'
import { LabelClassification } from '@/typings/index'
import { USER_TYPE } from '@/config'
import { getAccessTokenFromSession } from '@/services/ControlToken'

export default defineComponent({
  name: 'DiaryAchievementItem',

  props: {
    item: {
      type: Object as PropType<DailyScheduleModel>,
      required: true,
    },

    label: {
      type: Object as PropType<LabelClassification>,
      required: true,
    },
  },

  setup(props) {
    const accessToken = getAccessTokenFromSession()
    const userType = getRoleIdFromJwt(accessToken) ?? ''

    const start = computed<string>((): string => {
      const hour: string = getStringTime(props.item.startHour)
      const minute: string = getStringTime(props.item.startMinute)

      return `${hour}:${minute}`
    })

    const end = computed<string>((): string => {
      const hour: string = getStringTime(props.item.endHour)
      const minute: string = getStringTime(props.item.endMinute)

      return `${hour}:${minute}`
    })

    const getStringTime = (numberTime: number | undefined): string => {
      return typeof numberTime === 'undefined' ? '' : formatTime(numberTime)
    }

    const isStudent = computed<boolean>(() => userType === USER_TYPE.STUDENT)

    return { start, end, isStudent }
  },
})
