export const mainContainerStyle = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: "'Noto Sans JP', 'sans-serif'",
  margin: '0 auto',
  padding: '2.25rem 0 5rem 0',
  width: '70%',
  height: '100vh',
}
