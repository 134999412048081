
import { defineComponent, reactive, onBeforeMount, toRefs } from 'vue'
import MarkButton from '@/components/common/MarkButton.vue'
import BaseButton from '@/components/common/BaseButton.vue'
export default defineComponent({
  name: 'AnswerInputCard',

  props: {
    questionIdx: {
      type: Number,
      require: true,
    },
    selectedNo: {
      type: String,
      require: true,
    },
    sentakuSu: {
      type: Number,
      require: true,
    },
    menjoFlg: {
      type: Boolean,
      require: true,
    },
    chuiJikoNm: {
      type: String,
      require: true,
    },
    mondaiSu: {
      type: Number,
      require: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      buttonTypeArray: [] as string[],
    })

    onBeforeMount(() => {
      state.buttonTypeArray = []
      for (var i = 0; i < 10; i++) {
        state.buttonTypeArray = [
          ...state.buttonTypeArray,
          buttonType(
            i + 1,
            props.sentakuSu as number,
            props.selectedNo as string,
            props.menjoFlg
          ),
        ]
      }
    })

    const handleToList = () => {
      emit('emitting')
    }
    const buttonType = (
      num: number,
      sentakuSu: number,
      selectedNo: string,
      menjoFlg: boolean
    ) => {
      if (num - 1 > sentakuSu || (num - 1 == sentakuSu && !menjoFlg)) {
        return '3' //disable
      }
      if (menjoFlg && num - 1 == sentakuSu && selectedNo.indexOf('免') > -1) {
        return '2' //mark
      }
      if (menjoFlg && num - 1 == sentakuSu && selectedNo.indexOf('免') == -1) {
        return '1' //unmark
      }
      if (num == 10 && selectedNo.indexOf('A' + '') > -1) {
        return '2' //mark
      }
      if (num == 10 && selectedNo.indexOf('A' + '') == -1) {
        return '1' //unmark
      }
      if (selectedNo.indexOf(num + '') > -1) {
        return '2' //mark
      } else {
        return '1' //unmark
      }
    }
    const clickMarkButton = (num: number) => {
      emit(
        'handleMarkButtonClick',
        props.questionIdx,
        answerAfterClick(
          props.questionIdx as number,
          num,
          props.selectedNo as string
        )
      )
    }

    const answerAfterClick = (
      questionIdx: number,
      num: number,
      selectedNo: string
    ) => {
      const kaito = selectedNo
      let ret = ''
      if (num == 10 && !props.menjoFlg) {
        ret = getNewKaitoOfA(kaito)
      } else if (num == (props.sentakuSu as number) + 1) {
        ret = getNewKaiToOfmenjo(kaito)
      } else {
        if (kaito.indexOf(num + '') > -1) {
          ret = kaito.replace(num + '', '')
        } else {
          ret = insertNewKaito(kaito, num)
        }
      }
      return ret
    }

    const getNewKaiToOfmenjo = (kaito: string) => {
      if (kaito.indexOf('免') == -1) {
        return kaito + '免'
      } else {
        return kaito.replace('免', '')
      }
    }

    const getNewKaitoOfA = (kaito: string) => {
      if (kaito.indexOf('A') == -1) {
        return kaito + 'A'
      } else {
        return kaito.replace('A', '')
      }
    }

    const insertNewKaito = (kaito: string, num: number): string => {
      for (var i = 0; i < kaito.length; i++) {
        if (num + '' < kaito.charAt(i)) {
          return (
            (i == 0 ? '' : kaito.substring(0, i)) + num + kaito.substring(i)
          )
        }
      }
      return kaito + num
    }

    const handleToPrev = () => {
      emit('handleToPrev', props.questionIdx)
    }
    const handleToNext = (num: number) => {
      emit('handleToNext', props.questionIdx)
    }
    const prevButtonStyle = {
      width: '35px',
      height: '35px',
      'border-radius': '17px',
      background: '#0d3f67',
    }
    const toListButtonStyle = {
      width: '105px',
      height: '45px',
      'border-radius': '23.5px',
      background: '#ffffff',
      color: '#333333',
    }
    return {
      ...toRefs(state),
      handleToList,
      clickMarkButton,
      prevButtonStyle,
      toListButtonStyle,
      handleToPrev,
      handleToNext,
      answerAfterClick,
      insertNewKaito,
    }
  },
  components: { MarkButton, BaseButton },
})
