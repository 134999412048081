
// Components
import IconBubble from '@/components/icons/IconBubble.vue'
import IconClip from '@/components/icons/IconClip.vue'
import { MessageSentHistoryItemModel } from '@/request/api/information-message/model/sentHistory'

// Dependencies
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  components: { IconBubble, IconClip },
  name: 'MessageHistoryCard',

  props: {
    message: {
      type: Object as PropType<MessageSentHistoryItemModel>,
      required: true,
    },
    isMessageMaxLength: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const timestamp = computed<string>(() => {
      return props.message.sendTimestamp
    })

    const itemClick = (): void => {
      emit('emitting', props.message)
    }

    return { timestamp, itemClick }
  },
})
