
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { getUserIdFromJwt, getRoleIdFromJwt } from '@/Utils'
import { defineComponent, computed, onBeforeMount } from 'vue'
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useStore } from '@/store'
import { useRouter } from '@/router'

export default defineComponent({
  name: 'QrAttendCert',
  components: {
    VueQrcode,
    ContentPageContainer,
  },
  props: {
    qrcode: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const loginUserRoleId = computed(() => {
      return getRoleIdFromJwt(getAccessTokenFromSession())
    })
    // Lifecycle
    onBeforeMount(() => {
      if (!getAccessTokenFromSession()) {
        router.push({ name: 'HomePage' })
      }
    })
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    const targetText = computed(() => {
      if (props.qrcode) {
        return props.qrcode
      } else {
        return getUserIdFromJwt(getAccessTokenFromSession())
      }
    })
    const option = {
      errorCorrectionLevel: 'H',
      maskPattern: 0,
      margin: 4,
      scale: 2,
      width: 176,
      color: {
        dark: '#000000FF',
        light: '#FFFFFFFF',
      },
    }
    return {
      loginUserRoleId,
      isFromAndroidAPP,
      isFromIOSAPP,
      targetText,
      option,
    }
  },
})
