
// Components
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import AccountInformationRegistContent from '@/views/AccountInformationRegist/SubPages/AccountInformationRegistContent.vue'

// Dependencies
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
export default defineComponent({
  name: 'AccountInformationRegist',

  components: {
    ContentPageContainer,
    AccountInformationRegistContent,
  },
  setup() {
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return { isFromAndroidAPP, isFromIOSAPP }
  },
})
