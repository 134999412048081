
/**
 * カスタマイズしたアイコンを確認する画面
 */
// Dependencies
import { defineComponent } from 'vue'

// Icons
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
import IconBlank from '@/components/icons/IconBlank.vue'
import IconBubble from '@/components/icons/IconBubble.vue'
import IconClip from '@/components/icons/IconClip.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconClose03 from '@/components/icons/IconClose03.vue'
import IconClose04 from '@/components/icons/IconClose04.vue'
import IconDiary from '@/components/icons/IconDiary.vue'
import IconExamRegister from '@/components/icons/IconExamRegister.vue'
import IconGrade from '@/components/icons/IconGrade.vue'
import IconHome from '@/components/icons/IconHome.vue'
import IconHomework from '@/components/icons/IconHomework.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import IconLecture from '@/components/icons/IconLecture.vue'
import IconLibrary from '@/components/icons/IconLibrary.vue'
import IconBlank02 from '@/components/icons/IconBlank02.vue'
import IconLive02 from '@/components/icons/IconLive02.vue'
import IconLogo01 from '@/components/icons/IconLogo01.vue'
import IconLogo02 from '@/components/icons/IconLogo02.vue'
import IconLogo03 from '@/components/icons/IconLogo03.vue'
import IconMobileTopBack from '@/components/icons/IconMobileTopBack.vue'
import IconMyPage from '@/components/icons/IconMyPage.vue'
import IconPadlock from '@/components/icons/IconPadlock.vue'
import IconPen from '@/components/icons/IconPen02.vue'
import IconPlayOnDemand from '@/components/icons/IconPlayOnDemand.vue'
import IconPlus from '@/components/icons/IconPlus.vue'
import IconProfile from '@/components/icons/IconProfile.vue'
import IconTest from '@/components/icons/IconTest.vue'
import IconUser from '@/components/icons/IconUser.vue'

export default defineComponent({
  components: {
    IconArrowDownTriangle,
    IconArrowLeft,
    IconArrowRight,
    IconBlank,
    IconBubble,
    IconClip,
    IconClose,
    IconClose03,
    IconClose04,
    IconDiary,
    IconExamRegister,
    IconGrade,
    IconHome,
    IconHomework,
    IconInformation,
    IconLecture,
    IconLibrary,
    IconBlank02,
    IconLive02,
    IconLogo01,
    IconLogo02,
    IconLogo03,
    IconMobileTopBack,
    IconMyPage,
    IconPadlock,
    IconPen,
    IconPlayOnDemand,
    IconPlus,
    IconProfile,
    IconTest,
    IconUser,
  },
})
