
/**
 * 未読者一覧画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ReceiverListOfNotReadComp from '@/components/views/ReceiverListOfNotReadComp/ReceiverListOfNotReadComp.vue'

// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import { STUDENT_USER_CODE_LENGTH } from '@/config'
import { SendMessageReadStatusItem } from '@/typings'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import {
  getGlobalParams,
  RECEIVE_LIST_PARAMS,
  MESSAGE_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { mainContainerStyle } from './style'
import {
  querySendMessageReadStatus,
  queryPersonalInformationName,
} from '@/request/api'
import { JukoUserPersonalInformation } from '@/typings/PersonalInformationConfig'

interface State {
  userID: string
  notReadList: string[]
  notReadListDefault: string[]
}
interface PropsParams {
  sender: string
  messageNo: string
}
export default defineComponent({
  name: 'ReceiverListOfNotRead',
  props: {
    sender: {
      type: String,
    },
    messageNo: {
      type: String,
    },
  },
  setup(props) {
    const resetKey = ref(0)
    const reset = () => {
      resetKey.value++
    }
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      notReadList: [],
      notReadListDefault: [] as string[],
    })

    onBeforeMount(async () => {
      if (getGlobalParams(MESSAGE_COMPLETE_PAGE)) {
        return
      }
      const receiverList = await querySendMessageReadStatus(
        props.sender ??
          (getGlobalParams(RECEIVE_LIST_PARAMS) as PropsParams['sender']),
        props.messageNo ??
          (getGlobalParams(RECEIVE_LIST_PARAMS) as PropsParams['messageNo'])
      )

      const notReadStudents = receiverList
        .filter(
          (item: SendMessageReadStatusItem) =>
            item.receiver.length === STUDENT_USER_CODE_LENGTH && !item.read
        )
        .map((item: SendMessageReadStatusItem) => item.receiver)

      state.notReadList = [...state.notReadList, ...notReadStudents]
      state.notReadListDefault = [
        ...state.notReadListDefault,
        ...notReadStudents,
      ]
    })

    const getReceiverName = async (
      receiver: string[],
      index: number
    ): Promise<void> => {
      if (state.notReadList[index] === state.notReadListDefault[index]) {
        const jukoUserInfo: JukoUserPersonalInformation[] =
          await queryPersonalInformationName(receiver)
        state.notReadList[index] = jukoUserInfo[0].name
      } else {
        state.notReadList[index] = state.notReadListDefault[index]
      }
      reset()
    }

    return { ...toRefs(state), mainContainerStyle, getReceiverName, resetKey }
  },

  components: { ManagePageContainer, ReceiverListOfNotReadComp },
})
