import { INVALID_ARGUMENT } from '@/config'

/**
 * アプリケーション例外（業務エラー）であるかをチェック
 * @param error
 * @returns
 */
export const isApplicationException = (error: Error | string): boolean => {
  const message: string = (error as Error)?.message ?? error
  return message.includes(INVALID_ARGUMENT)
}
