
/**
 * 合格ダイアリー - 学習ダイアリーView
 */
import BaseSelector from '@/components/common/BaseSelector.vue'
import ScheduleLabelInput from '@/views/PassDiary/StudyDiary/ScheduleLabelInput.vue'
import AddDialyScheduleMemo from '@/views/PassDiary/StudyDiary/AddDialyScheduleMemo.vue'
import SelectDefaultSchedule from '@/views/PassDiary/StudyDiary/SelectDefaultSchedule.vue'
import ScheduleAchievementInput from '@/views/PassDiary/StudyDiary/ScheduleAchievementInput.vue'
import CopySelectScheduleDaily from '@/views/PassDiary/StudyDiary/CopySelectScheduleDaily.vue'
import DiaryAchievementItem from '@/components/views/PassDiary/DiaryAchievementItem.vue'
import DateSelectorHideInput from '@/components/common/selector/DateSelectorHideInput.vue'
import DiaryGraph from '@/components/views/PassDiary/DiaryGraph.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'
import IconAchieveReport from '@/components/icons/IconAchieveReport.vue'
import IconScheduleCopy from '@/components/icons/IconScheduleCopy.vue'
import BaseButton from '@/components/common/BaseButton.vue'

import {
  queryApplicationInformation,
  queryApplicationInformationList,
  getDailySchedule,
  getLabelOfKbnMaster,
} from '@/request/api'

// Dependencies
import { useRouter } from '@/router'
import { LabelClassification } from '@/typings/index'
import { useGlobalError } from '@/services/Hooks'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getRoleIdFromJwt, getDate, isValueSetted } from '@/Utils'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  watch,
} from 'vue'
import { ApplicationInformation } from '@/typings'
import {
  DailyScheduleModel,
  DefaultDailyScheduleModelList,
} from '@/typings/PassDiary'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_LABEL_CLASSIFICATION_LIST,
  SET_SCHEDULE_LIST,
  SET_IS_UPDATE_ACTIVE,
} from '@/store/modules/pass-diary/actionTypes'
import axios from 'axios'

export default defineComponent({
  name: 'StudyDiaryView',

  setup() {
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    const applicationNumber = computed<string>(
      (): string => store.state.applicationNumber ?? ''
    )
    const d3 = require('d3-dsv')
    const setScheduleListToStore = (
      list: DailyScheduleModel[]
    ): Promise<void> => store.dispatch(SET_SCHEDULE_LIST, list)

    const setLabelClassificationListToStore = (
      list: LabelClassification[]
    ): Promise<void> => store.dispatch(SET_LABEL_CLASSIFICATION_LIST, list)

    const setUpdateActivateToStore = (isValue: boolean): Promise<void> =>
      store.dispatch(SET_IS_UPDATE_ACTIVE, isValue)

    const { setGlobalError, clearGlobalError } = useGlobalError()

    // State
    const state = reactive({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      applicationInforList: [] as ApplicationInformation[],
      applicationInfor: {} as ApplicationInformation,
      achieveList: [] as DailyScheduleModel[],
      dailyScheduleList: [] as DailyScheduleModel[],
      scheduleList: [] as DailyScheduleModel[],

      defaultScheduleList: [] as DailyScheduleModel[],
      defaultDailyScheduleModelList: [] as DefaultDailyScheduleModelList[],

      defaultScheduleNameList: [] as string[],

      targetDate: new Date(),
      formatedTargetDate: getDate(''),
      labelClassification: [] as LabelClassification[],
      refreshList: false,
      refreshGraph: 0,
      windowWidth: window.innerWidth,
      scheduleAchieveTab: 0,
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        clearGlobalError()

        await setApplicationInformation()
        state.labelClassification = await getLabelOfKbnMaster()
        setLabelClassificationListToStore([...state.labelClassification])
        await setDailySchedule()
      } catch (error) {
        handleError(error as Error)
      }
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
      state.refreshGraph++
    }
    const inputWrapperOpened = ref<boolean>(false)

    const activedPage = ref<string>(ScheduleLabelInput.name)
    const loginUserRoleId = computed(() => {
      return getRoleIdFromJwt(getAccessTokenFromSession())
    })

    // Computed
    const formattedCourse = computed(() => {
      return `${state.applicationInfor?.courseName ?? ''}`
    })

    const formattedCourseList = computed(() =>
      state.applicationInforList.map((item) => {
        return `${item.courseName ?? ''}`
      })
    )

    watch<Date>(
      () => state.targetDate as Date,
      () => (state.formatedTargetDate = getDate(state.targetDate))
    )

    watch<boolean>(
      inputWrapperOpened,
      async (): Promise<void> => setDailySchedule()
    )

    // Methods
    const setApplicationInformation = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInformationList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.applicationInforList = applicationInformationList ?? []
        let settedApplicationStudent = {} as ApplicationInformation

        if (!applicationNumber.value) {
          settedApplicationStudent = await queryApplicationInformation(
            state.userID
          )
        }
        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInformationList?.find(
          (item) =>
            item.applicationNo ===
            (isValueSetted(applicationNumber.value)
              ? applicationNumber.value
              : settedApplicationStudent.applicationNo)
        )
        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.applicationInfor =
          settedApplicationInformation ?? applicationInformationList?.[0] ?? {}
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    const setDailySchedule = async (): Promise<void> => {
      try {
        const dailyScheduleList: DailyScheduleModel[] = await getDailySchedule({
          userCode: state.userID,
          applicationNo: state.applicationInfor.applicationNo,
          date: getDate(state.targetDate),
        })

        state.dailyScheduleList = dailyScheduleList ?? []
        await setScheduleListToStore([...dailyScheduleList])
        state.achieveList = []
        state.scheduleList = []

        state.dailyScheduleList.forEach((item) => {
          if (item.yojitsuFlg && getLabel(item.label)?.name3 === '1') {
            //予定
            state.scheduleList = [...state.scheduleList, item]
          } else if (!item.yojitsuFlg && getLabel(item.label)?.name3 === '1') {
            //実績
            state.achieveList = [...state.achieveList, item]
          }
        })

        state.refreshList = !state.refreshList
        state.refreshGraph = state.refreshGraph + 1
      } catch (error) {
        handleError(error as Error)
      }
    }
    const getLabel = (label: string): LabelClassification | null => {
      let retValue = null
      if (state.labelClassification && state.labelClassification.length > 0) {
        retValue = state.labelClassification.find(
          (item) => item.kbn === label
        ) as LabelClassification
      }
      return retValue
    }

    const handlePrev = async (date: Date) => {
      state.targetDate = new Date(date.getTime() - 24 * 60 * 60 * 1000)
      await setDailySchedule()
    }

    const handleNext = async (date: Date) => {
      state.targetDate = new Date(date.getTime() + 24 * 60 * 60 * 1000)
      await setDailySchedule()
    }
    const handleChangeDate = async (date: Date) => {
      state.targetDate = date
      await setDailySchedule()
    }

    const handleSelectCourse = async (index: number) => {
      state.applicationInfor = state.applicationInforList[index]
      await setDailySchedule()
    }

    const router = useRouter()

    const handleToSearch = async (): Promise<void> => {
      axios.get('./csv/defaultSchedulel.csv').then((res) => {
        var ret = d3.csvParse(res.data)
        let listIndex = 0
        var varMemo = ""
        state.defaultDailyScheduleModelList[0] = {
          defaultDailyScheduleModel: [],
          defaultSchedulelabel: '',
        }
        for (let i = 0; i < ret.length; i++) {
          if(i == 0){
            varMemo = "AI用" + ret[i]['デフォルト分類名称']
          }else{
            varMemo = ""
          }
          if (
            i > 0 &&
            ret[i - 1]['デフォルト分類名称'] != ret[i]['デフォルト分類名称']

          ) {
            listIndex++
            state.defaultDailyScheduleModelList[listIndex] = {
              defaultDailyScheduleModel: [],
              defaultSchedulelabel: '',
            }
            varMemo = "AI用" + ret[i]['デフォルト分類名称']
          }
          state.defaultDailyScheduleModelList[listIndex].defaultSchedulelabel =
            ret[i]['デフォルト分類名称']
          state.defaultDailyScheduleModelList[
            listIndex
          ].defaultDailyScheduleModel.push({
            yojitsuFlg: true,
            date: getDate(state.targetDate),
            startHour: Number(ret[i]['開始時']),
            startMinute: Number(ret[i]['開始分']),
            endHour: Number(ret[i]['終了時']),
            endMinute: Number(ret[i]['終了分']),
            label: ret[i]['ラベル番号'],
            memo: varMemo,
          })
        }
      })

      setTimeout(() => {
        inputWrapperOpened.value = true
        activedPage.value = SelectDefaultSchedule.name
      }, 300)
    }
    const handleToReport = () => {
      router.push({
        name: 'PassDiary/StudyReport',

        params: {
          userIDProps: state.userID,
          applicationNo: state.applicationInfor.applicationNo as string,
        },
      })
    }

    const handleError = (error: Error | unknown | string): void => {
      console.log(error)
      setGlobalError(error as Error)
    }

    // Style
    const courseSelectBarStyle = {
      border: 'none',
      fontSize: '16px',
      'background-color': '#EAEAEA',
    }
    const scale = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { x: 0.875, y: 0.875 }
      } else {
        return { x: 1.4, y: 1.4 }
      }
    })
    const graghAchieveStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { display: 'block' }
      } else {
        return { display: 'flex' }
      }
    })

    const graghStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return { width: '100%' }
      } else {
        return { width: '50%' }
      }
    })
    const courseDisplayAreaStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return {
          fontSize: '16px',
          fontWeight: '400',
          color: '#333333',
          'text-align': 'center',
        }
      } else {
        return {
          height: '88px',
          fontSize: '16px',
          fontWeight: '400',
          color: '#333333',
          'text-align': 'center',
        }
      }
    })

    const scheduleTabStyle = computed(() => {
      if (state.scheduleAchieveTab === 0) {
        return {
          color: '#0d3f67',
          'border-bottom-color': '#0d3f67',
          'font-weight': 'bold',
        }
      } else {
        return {
          color: '#760d3f67',
          'border-bottom-color': '#760d3f67',
          'font-weight': 'normal',
        }
      }
    })
    const achieveTabStyle = computed(() => {
      if (state.scheduleAchieveTab === 0) {
        return {
          color: '#760d3f67',
          'border-bottom-color': '#760d3f67',
          'font-weight': 'normal',
        }
      } else {
        return {
          color: '#0d3f67',
          'border-bottom-color': '#0d3f67',
          'font-weight': 'bold',
        }
      }
    })
    // ラベル選択画面
    const selectedLabel = reactive<LabelClassification>({
      kbn: '',
      name: '',
      name1: '',
      name2: '',
      name3: '',
      name4: '',
      name5: '',
    })

    const handleSelectLabel = (value: LabelClassification): void => {
      Object.keys(value).forEach((key: string): void => {
        const _key = key as keyof LabelClassification
        selectedLabel[_key] = value[_key]
      })

      activeScheduleList.value = []

      activedPage.value = ScheduleAchievementInput.name
    }

    // 予実入力画面
    const defaultSchedule = computed<DailyScheduleModel>(() => ({
      yojitsuFlg: true,
      date: state.formatedTargetDate,
      label: selectedLabel.kbn ?? '',
      startHour: undefined,
      startMinute: undefined,
      endHour: undefined,
      endMinute: undefined,
      memo: '',
    }))

    const activeScheduleList = ref<DailyScheduleModel[]>([])

    const handleScheduleAchievementInputGoBack = async (): Promise<void> => {
      await setDailySchedule()
      activedPage.value = ScheduleLabelInput.name
    }

    const handleClose = async (): Promise<void> => {
      await setDailySchedule()
      inputWrapperOpened.value = false
      activedPage.value = ScheduleLabelInput.name
    }

    // メモ入力画面
    const memo = ref<string>('')

    const handleOpenMemoPage = (value: string): void => {
      activedPage.value = AddDialyScheduleMemo.name
      memo.value = value
    }

    const handleCloseMemoPage = async (value: string): Promise<void> => {
      activedPage.value = ScheduleAchievementInput.name
      memo.value = value
    }
    const handleClickGraph = (): void => {
      setUpdateActivateToStore(false)
      inputWrapperOpened.value = true
    }
    const handleSelectTab = (idx: number): void => {
      state.scheduleAchieveTab = idx
    }
    const handleUpdateDiaryAchieve = (target: DailyScheduleModel): void => {
      setUpdateActivateToStore(true)
      handleSelectLabel(getLabel(target.label) as LabelClassification)

      activeScheduleList.value = state.dailyScheduleList.filter(
        (item: DailyScheduleModel): boolean => {
          return (
            item.label === target.label &&
            item.date === state.formatedTargetDate &&
            item.startHour === target.startHour &&
            item.startMinute === target.startMinute &&
            item.endHour === target.endHour &&
            item.endMinute === target.endMinute
          )
        }
      )

      inputWrapperOpened.value = true
      activedPage.value = ScheduleAchievementInput.name
    }

    // 予定コピー画面
    const isDisabledCopy = (): boolean => {
      let retValue = true
      state.dailyScheduleList.forEach((item) => {
        if (item.yojitsuFlg) {
          retValue = false
        }
      })
      return retValue
    }

    const handleToCopy = (): void => {
      if (!isDisabledCopy()) {
        inputWrapperOpened.value = true
        activedPage.value = CopySelectScheduleDaily.name
      }
    }

    return {
      ...toRefs(state),
      courseSelectBarStyle,
      courseDisplayAreaStyle,
      scheduleTabStyle,
      achieveTabStyle,
      scale,
      graghAchieveStyle,
      graghStyle,
      inputWrapperOpened,
      selectedLabel,
      loginUserRoleId,
      formattedCourse,
      formattedCourseList,
      isUserFromAPP,
      defaultSchedule,
      activeScheduleList,
      activedPage,
      handleSelectCourse,
      handleToSearch,
      handleToReport,
      handlePrev,
      handleNext,
      handleChangeDate,
      getLabel,
      handleSelectLabel,
      setDailySchedule,
      AddDialyScheduleMemo,
      ScheduleLabelInput,
      ScheduleAchievementInput,
      SelectDefaultSchedule,
      CopySelectScheduleDaily,
      memo,
      handleClickGraph,
      handleCloseMemoPage,
      handleOpenMemoPage,
      handleScheduleAchievementInputGoBack,
      handleUpdateDiaryAchieve,
      handleSelectTab,
      handleClose,
      handleToCopy,
      isDisabledCopy,
    }
  },

  components: {
    BaseSelector,
    DiaryAchievementItem,
    DateSelectorHideInput,
    DiaryGraph,
    IconArrowRight,
    IconAchieveReport,
    IconScheduleCopy,
    ScheduleLabelInput,
    ScheduleAchievementInput,
    SelectDefaultSchedule,
    AddDialyScheduleMemo,
    CopySelectScheduleDaily,
    BaseButton,
  },
})
