
// Dependencies
import { defineComponent, PropType, reactive, toRefs, onBeforeMount } from 'vue'
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

// Icons
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'
import { SelectedLabelItem } from '@/components/views/PassDiary/Common'
export default defineComponent({
  name: 'LabelSelector',
  components: { IconArrowDownTriangle },

  props: {
    selectedItemArray: {
      type: Array as PropType<SelectedLabelItem[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    // State
    const state = reactive({
      stateSelectedItemArray: [] as SelectedLabelItem[],
    })
    onBeforeMount(() => {
      state.stateSelectedItemArray = [...props.selectedItemArray]
    })
    // Methods
    const handleChangeAchieve = (index: number): void => {
      state.stateSelectedItemArray[index].isAchieveChecked =
        !state.stateSelectedItemArray[index].isAchieveChecked
      emit('changeSelect', state.stateSelectedItemArray)
    }
    return {
      ...toRefs(state),
      handleChangeAchieve,
    }
  },
})
window.addEventListener('DOMContentLoaded', () => {
  document.getElementById('.item-container') &&
    new SimpleBar(document.getElementById('.item-container') as HTMLElement, {
      autoHide: false,
    })
})
