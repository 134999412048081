
// Dependencies
import { defineComponent } from 'vue'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'

export default defineComponent({
  name: 'GradeSearchTextBox',

  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },

    value: {
      type: String,
      require: true,
      default: '',
    },

    actived: {
      type: Boolean,
      require: true,
      default: false,
    },

    required: {
      type: Boolean,
    },

    error: {
      type: String,
    },

    width: {
      type: String,
      default: '100%',
    },

    titleStyle: {
      type: Object,
    },

    inputEreaStyle: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { clearGlobalError }: UseGlobalError = useGlobalError()

    const handleInput = (event: InputEvent): void => {
      clearGlobalError()
      emit('input', (event.target as HTMLInputElement).value)
    }

    return { handleInput }
  },
})
