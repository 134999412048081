
import IconPlayOnDemand from '@/components/icons/IconPlayOnDemand.vue'

import { defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'
import { transferInSynchronous } from '@/Utils'
import { GradeModel } from '@/request/api/grade/models/grade-data'
import { MogiShikenSeisekiLst } from '@/typings/MogiShiken'
import {
  FORM_TARGET,
  HTTP_METHOD,
  ON_DEMAND_PARAMETERS_KEY_SEND,
} from '@/config'
import { OnDemandSSOParametersModel } from '@/request/api/on-demand/models'
import { UseOnDemand, useOnDemand } from '@/services/Hooks'

interface ClickParameters {
  testCode: string
  classID: string
}

export default defineComponent({
  props: {
    grade: {
      type: Object as PropType<GradeModel | MogiShikenSeisekiLst>,
      required: true,
    },
  },

  setup() {
    const store: Store<any> = useStore()

    const handleTransferToOnDemand = (parameters: ClickParameters): void => {
      const ssoParameters: OnDemandSSOParametersModel =
        store.state.onDemand.ssoParameters
      const courseKey: string = parameters?.classID ?? ''
      const chapterKey: string = getChapterKey(parameters)

      const { setOnDemandParametersToStorage }: UseOnDemand = useOnDemand()

      setOnDemandParametersToStorage({
        id: ssoParameters.id,
        timestamp: ssoParameters.ts,
        courseKey,
        chapterKey,
      })

      const data = new Map<string, string>([
        [ON_DEMAND_PARAMETERS_KEY_SEND.ID, ssoParameters.id],
        [ON_DEMAND_PARAMETERS_KEY_SEND.TIMESTAMP, ssoParameters.ts],
        [ON_DEMAND_PARAMETERS_KEY_SEND.COURSE_KEY, courseKey],
        [ON_DEMAND_PARAMETERS_KEY_SEND.CHAPTER_KEY, chapterKey],
      ])

      transferInSynchronous({
        data,
        path: process.env.VUE_APP_ON_DEMAND_DOMAIN,
        method: HTTP_METHOD.POST,

        target: store.state.isUserFromAPP
          ? FORM_TARGET.SELF
          : FORM_TARGET.BLANK,
      })
    }

    /**
     * @description chapter key を取得
     * @param parameters testCode, classID
     * @returns 受講種別ラスト 2 桁（クラス ID 5~6 桁） + テストコード 4 桁
     */
    const getChapterKey = (parameters: ClickParameters): string => {
      const certification: string = parameters?.classID.split('_')[0] ?? ''
      return certification.substring(4, 6) + parameters?.testCode ?? ''
    }

    return { handleTransferToOnDemand }
  },

  components: { IconPlayOnDemand },
})
