
/**
 * インフォメーション - 送信履歴詳細削除確認画面
 */
// Components
import BaseButton from '@/components/common/BaseButton.vue'
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import ManagePageTitle from '@/components/common/ManagePageTitle.vue'
import MessageDetail from '../../components/common/InformationMessage/MessageDetailDeleteConfirm.vue'

// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs, ref } from 'vue'
import {
  SendMessageReadStatusItem,
  SentMessageItem,
  BranchStore,
} from '@/typings'
import {
  getGlobalParams,
  SENT_MESSAGE_DETAIL,
  setGlobalParams,
  MESSAGE_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import { VIEW_SENT_MESSAGE_DETAIL } from '@/router/routesName'
import { useRouter } from '@/router'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'
import { querySendMessageReadStatus, queryBranchStoreList } from '@/request/api'
import { deleteMessageByMessageNo } from '@/request/api/information-message/message'
import { DeleteMessageByMessageNoParameters } from '@/request/api/information-message/model/message'
import { STUDENT_USER_CODE_LENGTH } from '@/config'
import { getMessage, getRoleIdFromJwt } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'

interface State {
  messageDetail: SentMessageItem
  destinationList: SendMessageReadStatusItem[]
  refresh: number
  userID: string
  userType: string
  range: string[]
}

interface Option {
  branchStoreList: BranchStore[]
}

const SENT_MESSAGE_DETAIL_DELETE_CONFIRM_KEY = 'SentMessageDetailDeleteConfirm'

export default defineComponent({
  name: 'SentMessageDetailDeleteConfirm',

  setup(props) {
    const router = useRouter()

    const { setGlobalError }: UseGlobalError = useGlobalError()

    const state = reactive<State>({
      messageDetail: {} as SentMessageItem,
      destinationList: [],
      refresh: 0,
      userID: '',
      userType: '',
      range: [],
    })

    const option = reactive<Option>({
      branchStoreList: [],
    })

    const isClickSendData = ref<boolean>(false)

    onBeforeMount(async () => {
      state.messageDetail = getGlobalParams(SENT_MESSAGE_DETAIL)

      if (state.messageDetail.hasOwnProperty('messageNo') === true) {
        state.messageDetail.attachmentList =
          state.messageDetail?.['attachmentList'] ?? []
        state.refresh++
        await setDestinationList()
      }
    })

    const setDestinationList = async (): Promise<any> => {
      const response: SendMessageReadStatusItem[] =
        await querySendMessageReadStatus(
          state.messageDetail.sender,
          state.messageDetail.messageNo
        )

      state.destinationList = response.filter(
        (item: SendMessageReadStatusItem) =>
          item.receiver.length === STUDENT_USER_CODE_LENGTH
      )
    }

    const backToSendDeteilPage = (): void => {
      router.push({ name: VIEW_SENT_MESSAGE_DETAIL })
    }

    const handleDeleteMessage = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      // ブラウザバックで再削除した場合
      if (getGlobalParams(MESSAGE_COMPLETE_PAGE)) {
        setGlobalError(getMessage('EFR0037', ['メッセージ']))
        return
      }
      const accessToken = getAccessTokenFromSession()
      state.userType = getRoleIdFromJwt(accessToken) ?? ''
      state.userID = getUserIDFromAccessToken(accessToken)
      option.branchStoreList = await queryBranchStoreList(
        state.userType,
        state.userID
      )
      option.branchStoreList.forEach(function (item, index) {
        state.range[index] = item.branchCd as string
      })

      const params: DeleteMessageByMessageNoParameters = {
        userId: state.userID,
        messageNo: state.messageDetail.messageNo,
        range: state.range,
      }
      const isDeleteMessage: boolean = await deleteMessageByMessageNo(params)
      if (!isDeleteMessage) {
        return
      }

      setGlobalParams(SENT_MESSAGE_DETAIL, {})
      setGlobalParams(MESSAGE_COMPLETE_PAGE, true)

      // 削除に成功した場合は完了画面へ遷移
      router.push({
        name: 'ManageNormalCompleted',
        params: { msg: getMessage('EFR0026', ['メッセージの削除']) },
      })
    }

    return {
      ...toRefs(state),
      backToSendDeteilPage,
      handleDeleteMessage,
    }
  },

  components: {
    ManagePageContainer,
    ManagePageTitle,
    BaseButton,
    MessageDetail,
  },
})
