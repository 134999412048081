
// Components
import IconBubble from '@/components/icons/IconBubble.vue'
import IconClip from '@/components/icons/IconClip.vue'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import { PDFJS_CONFIG } from '@/config/pdfjsConfig'
// Dependencies
import {
  computed,
  defineComponent,
  PropType,
  ref,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
} from 'vue'
import { RecievedMessageModel } from '@/request/api/information-message/model/message'
import { replyMessage } from '@/request/api/information-message/message'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useRouter } from '@/router'
import { getMessage } from '@/Utils'
import { MAX_REPLY_MESSAGE_LENGTH } from '@/config'
import { queryReceiveReply } from '@/request/api'
import { ReceiveReplyMessage } from '@/typings'
import ReplyContent from "@/components/views/InformationMessageDetail/ReplyContent.vue"

interface FormError {
  // リプライメッセージ文字数エラー
  answerError: string
}

export default defineComponent({
  components: { IconBubble, IconClip, VuePdfApp, ReplyContent },
  name: 'MessageDetail',

  props: {
    message: {
      type: Object as PropType<RecievedMessageModel>,
      required: true,
    },

    reply: {
      type: String,
      required: false,
    },

    imageSrc: { type: Array },
    uploadFileError: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const state = reactive({
      imgSrc: [] as string[],
      imgWidth: '100%' as string,
      sizeValue: '100' as string,
      replyList: [] as ReceiveReplyMessage[],
      isReplyed: true,
      enableReply: false,
    })
    // Lifecycle
    onBeforeMount(async () => {
      await setReplyList()

    })
    const resetKey = ref(0)
    const reset = (): void => {
      resetKey.value++
    }

    // フォーム検証
    const formError = reactive<FormError>({
      answerError: '',
    })

    const timestamp = computed<string>(() => {
      return props?.message?.sendTimestamp
    })

    // 回答欄
    const answer = ref<string>('')

    const isClickSendData = ref<boolean>(false)

    const handleInput = (event: Event): void => {
      answer.value = (event.target as HTMLInputElement).value
    }

    const mojiCount = ref<number>(0)
    const propsImageSrc = ref<string[]>(props.imageSrc as string[])

    const setReplyList = async (): Promise<void> => {
      const receiverList: ReceiveReplyMessage[] = await queryReceiveReply(
        props.message.sender + "," + getUserIDFromAccessToken(getAccessTokenFromSession()),
        props.message.messageNo
      )
      state.replyList = [
        ...receiverList.filter(
          (item: ReceiveReplyMessage): boolean =>
            item.message !== null && props.message.messageNo !== item.message?.messageNo && props.message.messageNo === item.message?.replyNo && (item.message.sender === getUserIDFromAccessToken(getAccessTokenFromSession()) && item.receiver === item.message.sender || item.message.sender.length === 6 && item.receiver === getUserIDFromAccessToken(getAccessTokenFromSession()))),
      ]
      if (state.replyList.length > 0) {
        state.replyList.sort(messageListSortFilterNotReplyCallback)
        const lastItem = state.replyList[state.replyList.length - 1]
        state.isReplyed = (lastItem.message.sender === getUserIDFromAccessToken(getAccessTokenFromSession()))
        if (!state.isReplyed) {
          state.enableReply = lastItem.message.enableReply
        } else {
          state.enableReply = false
        }
      } else {
        state.isReplyed = false
        state.enableReply = props.message.enableReply
      }
      state.replyList = [...state.replyList.filter((item) => item.message.body !== "close")]
    }


    const formatTimeStamp = (timestamp: string) => {
      //input sample: "2024年03月21日 木 10:13:44"
      return (
        timestamp.substring(0, 4) +
        '/' +
        timestamp.substring(5, 7) +
        '/' +
        timestamp.substring(8, 10) +
        ' ' +
        timestamp.substring(14, 22)
      )
    }
    const messageListSortFilterNotReplyCallback = (
      previous: ReceiveReplyMessage,
      next: ReceiveReplyMessage
    ): number => {

      const previousTimestamp = new Date(
        formatTimeStamp(previous.message?.sendTimestamp)
      )
      const nextTimestamp = new Date(formatTimeStamp(next.message?.sendTimestamp))
      return previousTimestamp.getTime() - nextTimestamp.getTime()

    }

    watch(
      () => answer.value,
      () => {
        formError.answerError = ''
        mojiCount.value = answer.value.length
        isClickSendData.value = false
      }
    )

    watch(
      () => state.imgWidth,
      () => reset()
    )

    const router = useRouter()

    const sendAnswer = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      if (answer.value.length > MAX_REPLY_MESSAGE_LENGTH) {
        formError.answerError = getMessage('EFR0007', [
          MAX_REPLY_MESSAGE_LENGTH,
        ])
        return
      }
      const successed = await replyMessage({
        sender: getUserIDFromAccessToken(getAccessTokenFromSession()),
        replyNumber: props.message.messageNo,
        subject: '-',
        sendTo: [props.message.sender],
        body: answer.value,
      })

      if (successed) {
        router.push({
          name: 'NormalCompleted',
          params: { msg: getMessage('EFR0038', ['メッセージを送信']) },
        })
      }
    }

    const autoLink = (text: string): string => {
      return text
        ? text.replace(
          /(https?:\/\/[^\s]*)/g,
          "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>"
        )
        : ''
    }

    const changeImgSize = (size: string): void => {
      state.imgWidth = size + '%'
    }

    const sizeDown = (): void => {
      const number = Number(state.sizeValue)
      state.sizeValue = String(number - 10)
      changeImgSize(state.sizeValue)
    }

    const sizeUp = (): void => {
      const number = Number(state.sizeValue)
      state.sizeValue = String(number + 10)
      changeImgSize(state.sizeValue)
    }

    return {
      ...toRefs(state),
      ...toRefs(formError),
      answer,
      timestamp,
      sendAnswer,
      handleInput,
      mojiCount,
      PDFJS_CONFIG,
      autoLink,
      changeImgSize,
      resetKey,
      sizeDown,
      sizeUp,
      propsImageSrc,
    }
  },
})
