
/**
 * 合格ダイアリー - 予実入力画面 - 円盤
 */
import { useStore } from '@/store'
import { LabelClassification } from '@/typings'
import { DailyScheduleModel } from '@/typings/PassDiary'
import { formatTime } from '@/Utils'
import { PlanTime } from '@/views/PassDiary/StudyDiary/ScheduleAchievementInput.vue'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue'

interface Coordinate {
  x: number
  y: number
}

interface FanShapeOptions {
  startAngle: number
  endAngle: number
  strokeStyle: string
  fillStyle: string
}

interface TimeAngle {
  start: number
  end: number
}

interface ClockColor {
  expectation: string
  achievement: string
}

type CanvasContextType = CanvasRenderingContext2D | null | undefined

const CANVAS_HEIGHT = 320
const CANVAS_WIDTH = 320
const CANVAS_CONTEXT_ID = '2d'
const CIRCLE_CENTER_X = CANVAS_WIDTH / 2
const CIRCLE_CENTER_Y = CANVAS_HEIGHT / 2
const UNAVALIABLE_ANGLE = -10

export default defineComponent({
  name: 'ScheduleAchievementClock',

  props: {
    scheduleTime: {
      type: Object as PropType<PlanTime>,
      required: true,
    },

    achievementTime: {
      type: Object as PropType<PlanTime>,
      required: true,
    },

    achievementActived: {
      type: Boolean,
      required: true,
    },

    currentItem: {
      type: Object as PropType<{
        expectation: DailyScheduleModel
        acheievement: DailyScheduleModel
      }>,
    },

    scheduleColor: {
      type: Object as PropType<ClockColor>,
      required: true,
    },

    timeHasReset: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore()

    const scheduleList = computed<DailyScheduleModel[]>(
      (): DailyScheduleModel[] => store.state.passDiary.scheduleList
    )

    const labelClassificationList = computed<LabelClassification[]>(
      (): LabelClassification[] => store.state.passDiary.labelClassificationList
    )

    onMounted((): void => {
      resetCanvas()
      drawBackground()
      drawControlPanel()
      drawControlButton()
    })

    const clockCanvas = ref<HTMLCanvasElement>()

    const clockContext = computed<CanvasContextType>(() =>
      clockCanvas.value?.getContext(CANVAS_CONTEXT_ID)
    )

    const resetCanvas = (): void => {
      if (!clockContext.value) {
        return
      }

      clockContext.value.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)
    }

    const drawBackground = (): void => {
      const context = clockContext.value

      if (!context) {
        return
      }

      context.beginPath()
      context.arc(CIRCLE_CENTER_X, CIRCLE_CENTER_Y, 120, 0, Math.PI * 2, false)

      context.strokeStyle = '#e9eaea'
      context.lineWidth = 10
      context.fillStyle = '#fff'
      context.fill()
      context.stroke()

      drawGraduation()
      drawListItme()
    }

    const drawGraduation = (): void => {
      const context = clockContext.value

      if (!context) {
        return
      }

      context.fillStyle = 'rgba(255,255,255,0.8)'
      context.font = '700 12px Noto Sans JP'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.strokeStyle = 'rgba(255,255,255,0.5)'
      context.lineWidth = 1

      for (let i = 0; i < 24; i++) {
        context.beginPath()

        context.fillText(
          `${i + 1}`,
          CIRCLE_CENTER_X + Math.cos(Math.PI * 2 * ((i - 5) / 24)) * 150,
          CIRCLE_CENTER_Y + Math.sin(Math.PI * 2 * ((i - 5) / 24)) * 150
        )

        context.moveTo(
          CIRCLE_CENTER_X + Math.cos(Math.PI * 2 * ((i - 0.5) / 24)) * 155,
          CIRCLE_CENTER_Y + Math.sin(Math.PI * 2 * ((i - 0.5) / 24)) * 155
        )

        context.lineTo(
          CIRCLE_CENTER_X + Math.cos(Math.PI * 2 * ((i - 0.5) / 24)) * 145,
          CIRCLE_CENTER_Y + Math.sin(Math.PI * 2 * ((i - 0.5) / 24)) * 145
        )

        context.stroke()
      }
    }

    const _currentItem = computed(() => props.currentItem?.expectation)
    const _currentItemAchieve = computed(() => props.currentItem?.acheievement)
    const drawListItme = (): void => {
      let list = [] as DailyScheduleModel[]
      scheduleList.value.forEach((item: DailyScheduleModel) => {
        if (
          (item.yojitsuFlg &&
            item.startHour !== _currentItem.value?.startHour &&
            item.startMinute !== _currentItem.value?.startMinute &&
            item.endHour !== _currentItem.value?.endHour &&
            item.endMinute !== _currentItem.value?.endMinute) ||
          (!item.yojitsuFlg &&
            item.startHour !== _currentItemAchieve.value?.startHour &&
            item.startMinute !== _currentItemAchieve.value?.startMinute &&
            item.endHour !== _currentItemAchieve.value?.endHour &&
            item.endMinute !== _currentItemAchieve.value?.endMinute)
        ) {
          list = [...list, item]
        }
      })

      list.forEach((item: DailyScheduleModel): void => {
        const startAngle: number = getAngleWithPropsTime(
          formatLongTime({ hour: item.startHour, minute: item.startMinute })
        )

        const endAngle: number = getAngleWithPropsTime(
          formatLongTime({ hour: item.endHour, minute: item.endMinute })
        )

        const currentLabel = labelClassificationList.value.find(
          (label: LabelClassification): boolean => item.label === label.kbn
        ) as LabelClassification
        if (item.yojitsuFlg) {
          paintFanShape({
            startAngle,
            endAngle,
            fillStyle: `#${currentLabel.name1 as string}`,
            strokeStyle: `transparent`,
          })
        } else {
          paintFanShape({
            startAngle,
            endAngle,
            fillStyle: 'transparent',
            strokeStyle: `#${currentLabel.name2 as string}`,
          })
        }
      })
    }

    const formatLongTime = ({
      hour,
      minute,
    }: {
      hour: number | undefined
      minute: number | undefined
    }): string => {
      return `${formatTime(hour as number)}:${formatTime(minute as number)}`
    }

    const drawControlPanel = (): void => {
      const context = clockContext.value

      if (!context) {
        return
      }

      context.beginPath()
      context.arc(CIRCLE_CENTER_X, CIRCLE_CENTER_Y, 80, 0, Math.PI * 2, false)

      context.fillStyle = '#000'
      context.fill()
    }

    // 制御ボタンを描画
    const controlButtonCanvas = ref<HTMLCanvasElement>()

    const controlButtonContext = computed<CanvasContextType>(() =>
      controlButtonCanvas.value?.getContext(CANVAS_CONTEXT_ID)
    )

    const drawControlButton = (): void => {
      const context = controlButtonContext.value

      if (!context) {
        return
      }

      context.clearRect(0, 0, 50, 40)

      context.beginPath()
      context.moveTo(10, 20)
      context.lineTo(40, 20)
      context.lineWidth = 10
      context.lineCap = 'round'
      context.strokeStyle = '#fff'
      context.stroke()

      const x = props.achievementActived ? 40 : 10
      context.arc(x, 20, 10, 0, Math.PI * 2, true)

      context.fillStyle = props.achievementActived
        ? props.scheduleColor.achievement
        : props.scheduleColor.expectation

      context.fill()
    }

    const _achievementActived = computed<boolean>(() => {
      drawControlButton()
      paintCircleClock()
      return props.achievementActived
    })

    const handleControlControlButton = (): void => {
      emit('switchDisplayTime', !_achievementActived.value)
    }

    // 予定時刻弧長
    const timeScheduleAngle = reactive<TimeAngle>({
      start: UNAVALIABLE_ANGLE,
      end: UNAVALIABLE_ANGLE,
    })

    // 実績時刻弧長
    const timeAchievementAngle = reactive<TimeAngle>({
      start: UNAVALIABLE_ANGLE,
      end: UNAVALIABLE_ANGLE,
    })

    const getFormattedTime = (angle: number): string => {
      // 弧長から角度を取得
      const degree = angle / (Math.PI / 180)

      // 角度で時刻を取得
      const timeDegreeRate = 24 / 360
      let startTime = degree * timeDegreeRate

      // 時刻を 24 に合わせる
      startTime = startTime >= -6 ? startTime + 6 : startTime + 30

      // 十進数を時分に変換
      const startHour = Math.trunc(startTime)
      let startMinute = Math.trunc((startTime % 1) * 60)
      let temporary = startMinute
      startMinute = Math.trunc(temporary / 10) * 10

      // 時刻をフォーマットする
      return `${
        startHour.toString().length < 2 ? '0' + startHour : startHour
      }:${startMinute.toString().length < 2 ? '0' + startMinute : startMinute}`
    }

    const getTimeAngle = (coordinate: Coordinate): number => {
      return Math.atan2(
        coordinate.y - CIRCLE_CENTER_Y,
        coordinate.x - CIRCLE_CENTER_X
      )
    }

    watch<number>(
      () => props.timeHasReset,

      () => {
        resetCanvas()
        drawBackground()
        drawControlPanel()
      }
    )

    watch<string[]>(
      (): string[] => [props.scheduleTime.start, props.scheduleTime.goal],

      ([newStart, newGoal]): void => {
        handleSetTimeByProps(timeScheduleAngle, { newStart, newGoal })
        paintCircleClock()
      }
    )

    watch<string[]>(
      () => [props.achievementTime.start, props.achievementTime.goal],

      ([newStart, newGoal]) => {
        handleSetTimeByProps(timeAchievementAngle, { newStart, newGoal })
        paintCircleClock()
      }
    )

    const handleSetTimeByProps = (
      target: TimeAngle,
      { newStart, newGoal }: { newStart: string; newGoal: string }
    ): void => {
      target.start =
        newStart.length === 5
          ? getAngleWithPropsTime(newStart)
          : UNAVALIABLE_ANGLE

      target.end =
        newGoal.length === 5
          ? getAngleWithPropsTime(newGoal)
          : UNAVALIABLE_ANGLE

      if (
        target.start !== UNAVALIABLE_ANGLE &&
        target.end !== UNAVALIABLE_ANGLE
      ) {
        paintCircleClock()
      }
    }

    const getAngleWithPropsTime = (time: string): number => {
      const timeArray: string[] = time.split(':')
      const hour = Number(timeArray[0])
      const minute = Number(timeArray[1])
      const formattedTime = hour + minute / 100

      const temporary =
        formattedTime <= 18 ? formattedTime - 6 : formattedTime - 30

      const timeDegreeRate = 24 / 360
      const degree = temporary / timeDegreeRate

      return degree * (Math.PI / 180)
    }

    const paintCircleClock = (): void => {
      resetCanvas()
      drawBackground()

      paintFanShape({
        startAngle: timeScheduleAngle.start,

        endAngle:
          timeScheduleAngle.end >= 24
            ? timeScheduleAngle.end - 24
            : timeScheduleAngle.end,

        strokeStyle: 'transparent',
        fillStyle: props.scheduleColor.expectation,
      })

      paintFanShape({
        startAngle: timeAchievementAngle.start,

        endAngle:
          timeAchievementAngle.end >= 24
            ? timeAchievementAngle.end - 24
            : timeAchievementAngle.end,

        strokeStyle: props.scheduleColor.achievement,
        fillStyle: 'transparent',
      })

      drawControlPanel()
    }

    const paintFanShape = ({
      startAngle,
      endAngle,
      strokeStyle,
      fillStyle,
    }: FanShapeOptions): void => {
      if (startAngle === UNAVALIABLE_ANGLE || endAngle === UNAVALIABLE_ANGLE) {
        return
      }

      const context = clockContext.value

      if (!context) {
        return
      }

      context.beginPath()

      context.arc(
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        115,
        startAngle,
        endAngle,
        false
      )

      context.fillStyle = fillStyle
      context.lineTo(CIRCLE_CENTER_X, CIRCLE_CENTER_Y)
      context.fill()

      context.beginPath()

      context.arc(
        CIRCLE_CENTER_X,
        CIRCLE_CENTER_Y,
        125,
        startAngle,
        endAngle,
        false
      )
      context.strokeStyle = strokeStyle
      context.lineWidth = 20
      context.lineCap = 'round'
      context.stroke()
    }

    const getCoordinate = (clientX: number, clientY: number): Coordinate => {
      const coordinate: Coordinate = { x: 0, y: 0 }

      // 元素の大きさ及び画面に相対する位置を取得
      const canvasSize = clockCanvas.value?.getBoundingClientRect() as DOMRect

      coordinate.x = clientX - canvasSize.left
      coordinate.y = clientY - canvasSize.top

      // 円盤から離れたかを判断
      const distance: number = computeDistanceBetweenTwoCoordinates(
        { x: 160, y: 160 },
        { x: coordinate.x, y: coordinate.y }
      )

      if (distance > 125) {
        throw new Error('over the range')
      }

      return coordinate
    }

    const computeDistanceBetweenTwoCoordinates = (
      point1: Coordinate,
      point2: Coordinate
    ): number => {
      const sum: number =
        Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)

      return Math.sqrt(sum)
    }

    const setTime = (type: 'start' | 'goal', coordinate: Coordinate): void => {
      try {
        const { x, y }: Coordinate = getCoordinate(coordinate.x, coordinate.y)
        emit(`set-${type}-time`, getFormattedTime(getTimeAngle({ x, y })))
      } catch (error) {
        // 円盤の外に操作しています。
      }
    }

    // タッチで円盤を操作
    const handleTouchStart = (event: TouchEvent): void => {
      const touch: Touch = event.touches[0]
      setTime('start', { x: touch.clientX, y: touch.clientY })
    }

    const handleTouchMovePreparation = (event: TouchEvent): void => {
      const touch: Touch = event.touches[0]
      setTime('goal', { x: touch.clientX, y: touch.clientY })
      emit('move')
    }

    const handleTouchEnd = (): void => {
      emit('moveEnd')
    }

    // マウスで操作
    const mouseMoveLocked = ref<boolean>(true)

    const handleMouseDown = (event: MouseEvent): void => {
      setTime('start', { x: event.clientX, y: event.clientY })
      mouseMoveLocked.value = false
    }

    const handleMouseMove = (event: MouseEvent): void => {
      if (mouseMoveLocked.value) {
        return
      }

      setTime('goal', { x: event.clientX, y: event.clientY })
      emit('move')
    }

    const handleMouseUp = (): void => {
      mouseMoveLocked.value = true
      emit('moveEnd')
    }

    return {
      CANVAS_HEIGHT,
      CANVAS_WIDTH,
      clockCanvas,
      controlButtonCanvas,
      _achievementActived,
      handleControlControlButton,
      handleTouchStart,
      handleTouchMovePreparation,
      handleTouchEnd,
      mouseMoveLocked,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
    }
  },
})
