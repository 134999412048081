// 画面：
// アイコンレビュー画面
import IconPreview from '@/views/IconPreview.vue'
// トップ画面
import HomePage from '@/views/HomePage/index.vue'
// QR コード画面
import QrAttendCert from '@/views/QrAttendCert.vue'
// 試験日マスタ登録画面
import ExamDateMaintenance from '@/views/ExamDateMaintenance.vue'
// 管理者トップ画面
import ManageTop from '@/views/ManageTop.vue'
// アカウント情報登録（マイページ）画面
import AccountInformationRegist from '@/views/AccountInformationRegist/index.vue'
// 成績参照画面
import GradeData from '@/views/GradeData/index.vue'
// 成績参照検索画面
import GradeDataSearch from '@/views/GradeDataSearch/index.vue'
// 送信先リスト選択画面
import DestinationListSelect from '@/views/DestinationListSelect/DestinationListSelect.vue'
// 送信先リスト削除確認画面
import DestinationListSelectDeleteConfirm from '@/views/DestinationListSelectDeleteConfirm/index.vue'
// メッセージ送信完了画面
import NormalCompleted from '@/views/NormalCompleted.vue'
// メッセージ送信完了画面(管理)
import ManageNormalCompleted from '@/views/ManageNormalCompleted.vue'
// 個人情報変更画面
import PersonalInformationConfig from '@/views/PersonalInformationConfig/index.vue'
// インフォメーションメニュー画面
import InformationMenu from '@/views/InformationMenu.vue'
// インフォメーション（検索画面）
import InformationSearch from '@/views/InformationSearch/index.vue'
// メッセージ確認画面
import InformationMessageConfirm from '@/views/InformationMessageConfirm/index.vue'
// 送信先リスト一括取込画面
import DestinationListBulkImport from '@/views/DestinationListBulkImport/index.vue'
// メッセージ受信一覧画面
import InformationMessageList from '@/views/InformationMessageList/index.vue'
// メッセージ受信詳細画面
import InformationMessageDetail from '@/views/InformationMessageDetail/index.vue'
// 送信履歴一覧画面
import SentMessageHistoryList from '@/views/SentMessageHistoryList/index.vue'
// 送信履歴詳細画面
import SentMessageDetail from '@/views/SentMessageDetail/index.vue'
// 送信履歴詳細削除確認画面
import SentMessageDetailDeleteConfirm from '@/views/SentMessageDetailDeleteConfirm/index.vue'
// 支店一覧画面
import BranchList from '@/views/BranchList/index.vue'
// 支店送信履歴一覧画面
import BranchSentMessageHistoryList from '@/views/BranchSentMessageHistoryList/index.vue'
// メッセージ作成画面
import CreateMessage from '@/views/InformationCreateMessage/index.vue'
// メッセージ（エラー）画面
import Message from '@/views/Message.vue'
// ページが見つかりません画面
import PageNotFound from '@/views/PageNotFound.vue'
// プライバシーポリシー
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
// 利用規約
import ServiceTerms from '@/views/ServiceTerms.vue'
// 未読者一覧
import ListOfNotRead from '@/views/ListOfNotRead/index.vue'
// 既読者一覧
import ListOfRead from '@/views/ListOfRead/index.vue'
// 回答一覧
import ReplyList from '@/views/ReplyList/index.vue'
// 回答一覧
import ReplyDetail from '@/views/ReplyDetail/index.vue'
// 解答登録（マークシート）画面
import AnswerInput from '@/views/AnswerInput/index.vue'
// 学習ダイアリー画面
import StudyDiary from '@/views/PassDiary/StudyDiary/index.vue'
// 学習レポート画面
import StudyReport from '@/views/PassDiary/StudyReport/index.vue'
// スタッフ集計画面
import SummaryForStaff from '@/views/PassDiary/SummaryForStaff/index.vue'

import {
  ROUTE_APP_TO_ON_DEMAND,
  VIEW_ACCOUNT_INFORMATION_REGIST,
  VIEW_BRANCH_LIST,
  VIEW_BRANCH_SENT_MESSAGE_HISTORY_LIST,
  VIEW_CREATE_MESSAGE,
  VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM,
  VIEW_DESTINATION_LIST_BULK_IMPORT,
  VIEW_DESTINATION_LIST_SELECT,
  VIEW_ERROR_MESSAGE,
  VIEW_EXAM_DATE_MAINTENANCE,
  VIEW_GRADE_DATA,
  VIEW_GRADE_DATA_SEARCH,
  VIEW_HOME_PAGE,
  VIEW_ICON_PREVIEW,
  VIEW_INFORMATION_MENU,
  VIEW_INFORMATION_MESSAGE_CONFIRM,
  VIEW_INFORMATION_MESSAGE_DETAIL,
  VIEW_INFORMATION_MESSAGE_LIST,
  VIEW_INFORMATION_SEARCH,
  VIEW_LIST_OF_NOT_READ,
  VIEW_LIST_OF_READ,
  VIEW_MANAGE_NORMAL_COMPLETED,
  VIEW_MANAGE_TOP,
  VIEW_NORMAL_COMPLETED,
  VIEW_PAGE_NOT_FOUND,
  VIEW_PERSONAL_INFORMATION_CONFIG,
  VIEW_PRIVACY_POLICY,
  VIEW_QR_ATTEND_CERT,
  VIEW_REPLY_LIST,
  VIEW_REPLY_DETAIL,
  VIEW_SENT_MESSAGE_DETAIL,
  VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM,
  VIEW_SENT_MESSAGE_HISTORY_LIST,
  VIEW_SERVICE_TERMS,
  VIEW_ANSWER_INPUT,
  VIEW_STUDY_DIARY,
  VIEW_STUDY_REPORT,
  VIEW_SUMMARY_FOR_STAFF,
} from './routesName'

import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import {
  HTTP_METHOD,
  ON_DEMAND_PARAMETERS_KEY_SEND,
  ON_DEMAND_PARAMETERS_KEY_TOP_PAGE,
  USER_TYPE,
} from '@/config'
import Vue from 'vue'
import { getUrlParameter, transferInSynchronous } from '@/Utils'

const EMPLOYEE_USER = [
  USER_TYPE.TEACHER,
  USER_TYPE.BUSINESS,
  USER_TYPE.EDUCATION_STAFF,
  USER_TYPE.MANAGEMENT,
]

const routes: Array<RouteConfig> = [
  {
    path: `/${VIEW_ICON_PREVIEW}`,
    name: VIEW_ICON_PREVIEW,
    component: IconPreview,

    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      process.env.NODE_ENV === 'development'
        ? next()
        : next({ name: VIEW_PAGE_NOT_FOUND })
    },
  },

  {
    path: `/${ROUTE_APP_TO_ON_DEMAND}`,
    name: ROUTE_APP_TO_ON_DEMAND,

    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      const valueID: string | undefined = getUrlParameter(
        ON_DEMAND_PARAMETERS_KEY_TOP_PAGE.ID
      )

      const valueTimestamp: string | undefined = getUrlParameter(
        ON_DEMAND_PARAMETERS_KEY_TOP_PAGE.TIMESTAMP
      )

      const valueCourseKey: string | undefined = getUrlParameter(
        ON_DEMAND_PARAMETERS_KEY_TOP_PAGE.COURSE_KEY
      )

      const valueChapterKey: string | undefined = getUrlParameter(
        ON_DEMAND_PARAMETERS_KEY_TOP_PAGE.CHAPTER_KEY
      )

      if (valueID && valueTimestamp) {
        const data = new Map([
          [ON_DEMAND_PARAMETERS_KEY_SEND.ID, valueID],
          [ON_DEMAND_PARAMETERS_KEY_SEND.TIMESTAMP, valueTimestamp],
          [ON_DEMAND_PARAMETERS_KEY_SEND.COURSE_KEY, valueCourseKey ?? ''],
          [ON_DEMAND_PARAMETERS_KEY_SEND.CHAPTER_KEY, valueChapterKey ?? ''],
        ])

        transferInSynchronous({
          data,
          path: process.env.VUE_APP_ON_DEMAND_DOMAIN,
          method: HTTP_METHOD.POST,
        })
      } else {
        next({ name: VIEW_HOME_PAGE })
      }
    },
  },

  {
    path: '/',
    name: VIEW_HOME_PAGE,
    component: HomePage,
    meta: { requireAuth: true },
  },

  {
    path: `/${VIEW_MANAGE_TOP}`,
    name: VIEW_MANAGE_TOP,
    component: ManageTop,
    props: true,

    // meta: ルートメタフィールド。標記用のフィールド。
    meta: {
      // フィールド requireAuth を追加し、該当ルートは認証が必要だと標記する。
      requireAuth: true,
      allowableUser: EMPLOYEE_USER,
    },
  },

  {
    path: '*',
    name: VIEW_PAGE_NOT_FOUND,
    component: PageNotFound,
  },

  {
    path: `/${VIEW_ERROR_MESSAGE}`,
    name: VIEW_ERROR_MESSAGE,
    component: Message,
    props: true,
  },

  {
    path: `/${VIEW_PRIVACY_POLICY}`,
    name: VIEW_PRIVACY_POLICY,
    component: PrivacyPolicy,
  },

  {
    path: `/${VIEW_SERVICE_TERMS}`,
    name: VIEW_SERVICE_TERMS,
    component: ServiceTerms,
  },

  {
    path: `/${VIEW_QR_ATTEND_CERT}`,
    name: VIEW_QR_ATTEND_CERT,
    component: QrAttendCert,
    props: true,
    meta: { requireAuth: true },
  },

  {
    path: `/${VIEW_EXAM_DATE_MAINTENANCE}`,
    name: VIEW_EXAM_DATE_MAINTENANCE,
    component: ExamDateMaintenance,
    meta: {
      requireAuth: true,
      allowableUser: EMPLOYEE_USER,
    },
  },

  {
    path: `/${VIEW_ACCOUNT_INFORMATION_REGIST}`,
    name: VIEW_ACCOUNT_INFORMATION_REGIST,
    component: AccountInformationRegist,
    props: true,
    meta: { requireAuth: true, allowableUser: [USER_TYPE.STUDENT] },
  },

  {
    path: `/${VIEW_GRADE_DATA}/:tab`,
    name: VIEW_GRADE_DATA,
    component: GradeData,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.STUDENT, ...EMPLOYEE_USER],
    },
  },

  {
    path: `/${VIEW_GRADE_DATA}`,
    redirect: `/${VIEW_GRADE_DATA}/ByTrainingDay`,
  },

  {
    path: `/${VIEW_GRADE_DATA_SEARCH}`,
    name: VIEW_GRADE_DATA_SEARCH,
    component: GradeDataSearch,

    meta: {
      requireAuth: true,
      allowableUser: EMPLOYEE_USER,
    },
  },

  {
    path: `/${VIEW_DESTINATION_LIST_SELECT}`,
    name: VIEW_DESTINATION_LIST_SELECT,
    component: DestinationListSelect,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.EDUCATION_STAFF, USER_TYPE.MANAGEMENT],
    },
  },

  {
    path: `/${VIEW_CREATE_MESSAGE}`,
    name: VIEW_CREATE_MESSAGE,
    component: CreateMessage,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.EDUCATION_STAFF, USER_TYPE.MANAGEMENT],
    },
  },

  {
    path: `/${VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM}`,
    name: VIEW_DESTINATION_LIST_SELECT_DELETE_CONFIRM,
    component: DestinationListSelectDeleteConfirm,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.EDUCATION_STAFF, USER_TYPE.MANAGEMENT],
    },
  },

  {
    path: `/${VIEW_NORMAL_COMPLETED}`,
    name: VIEW_NORMAL_COMPLETED,
    component: NormalCompleted,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.STUDENT],
    },
  },

  {
    path: `/${VIEW_MANAGE_NORMAL_COMPLETED}`,
    name: VIEW_MANAGE_NORMAL_COMPLETED,
    component: ManageNormalCompleted,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_PERSONAL_INFORMATION_CONFIG}`,
    name: VIEW_PERSONAL_INFORMATION_CONFIG,
    component: PersonalInformationConfig,
    meta: { requireAuth: true, allowableUser: [USER_TYPE.STUDENT] },
  },

  {
    path: `/${VIEW_INFORMATION_MENU}`,
    name: VIEW_INFORMATION_MENU,
    component: InformationMenu,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_INFORMATION_MESSAGE_CONFIRM}`,
    name: VIEW_INFORMATION_MESSAGE_CONFIRM,
    component: InformationMessageConfirm,
    props: true,

    meta: {
      requireAuth: true,
      allowableUeser: [USER_TYPE.EDUCATION_STAFF, USER_TYPE.MANAGEMENT],
    },
  },

  {
    path: `/${VIEW_INFORMATION_SEARCH}`,
    name: VIEW_INFORMATION_SEARCH,
    component: InformationSearch,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.EDUCATION_STAFF, USER_TYPE.MANAGEMENT],
    },
  },

  {
    path: `/${VIEW_DESTINATION_LIST_BULK_IMPORT}`,
    name: VIEW_DESTINATION_LIST_BULK_IMPORT,
    component: DestinationListBulkImport,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.MANAGEMENT, USER_TYPE.EDUCATION_STAFF],
    },
  },

  {
    path: `/${VIEW_INFORMATION_MESSAGE_LIST}`,
    name: VIEW_INFORMATION_MESSAGE_LIST,
    component: InformationMessageList,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.STUDENT],
    },
  },

  {
    path: `/${VIEW_INFORMATION_MESSAGE_DETAIL}`,
    name: VIEW_INFORMATION_MESSAGE_DETAIL,
    component: InformationMessageDetail,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.STUDENT],
    },
  },

  {
    path: `/${VIEW_SENT_MESSAGE_HISTORY_LIST}`,
    name: VIEW_SENT_MESSAGE_HISTORY_LIST,
    component: SentMessageHistoryList,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_SENT_MESSAGE_DETAIL}`,
    name: VIEW_SENT_MESSAGE_DETAIL,
    component: SentMessageDetail,
    props: true,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM}`,
    name: VIEW_SENT_MESSAGE_DETAIL_DELETE_CONFIRM,
    component: SentMessageDetailDeleteConfirm,
    props: true,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_BRANCH_LIST}`,
    name: VIEW_BRANCH_LIST,
    component: BranchList,
    meta: { requireAuth: true, allowableUser: [USER_TYPE.MANAGEMENT] },
  },

  {
    path: `/${VIEW_BRANCH_SENT_MESSAGE_HISTORY_LIST}`,
    name: VIEW_BRANCH_SENT_MESSAGE_HISTORY_LIST,
    component: BranchSentMessageHistoryList,
    props: true,
    meta: { requireAuth: true, allowableUser: [USER_TYPE.MANAGEMENT] },
  },

  {
    path: `/${VIEW_LIST_OF_NOT_READ}`,
    name: VIEW_LIST_OF_NOT_READ,
    component: ListOfNotRead,
    props: true,
    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_LIST_OF_READ}`,
    name: VIEW_LIST_OF_READ,
    component: ListOfRead,
    props: true,
    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_REPLY_LIST}`,
    name: VIEW_REPLY_LIST,
    component: ReplyList,
    props: true,
    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_REPLY_DETAIL}`,
    name: VIEW_REPLY_DETAIL,
    component: ReplyDetail,
    props: true,
    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.BUSINESS,
        USER_TYPE.MANAGEMENT,
      ],
    },
  },

  {
    path: `/${VIEW_ANSWER_INPUT}`,
    name: VIEW_ANSWER_INPUT,
    component: AnswerInput,
    props: true,
    meta: {
      requireAuth: true,
      allowableUser: [USER_TYPE.STUDENT],
    },
  },

  {
    path: `/${VIEW_STUDY_DIARY}`,
    name: VIEW_STUDY_DIARY,
    component: StudyDiary,
    props: true,

    meta: {
      requireAuth: true,
      allowableUser: [
        USER_TYPE.STUDENT,
        USER_TYPE.BUSINESS,
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.MANAGEMENT,
        USER_TYPE.TEACHER,
      ],
    },
  },

  {
    path: `/${VIEW_STUDY_REPORT}`,
    name: VIEW_STUDY_REPORT,
    component: StudyReport,
    props: true,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.STUDENT,
        USER_TYPE.BUSINESS,
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.MANAGEMENT,
        USER_TYPE.TEACHER,
      ],
    },
  },

  {
    path: `/${VIEW_SUMMARY_FOR_STAFF}`,
    name: VIEW_SUMMARY_FOR_STAFF,
    component: SummaryForStaff,

    meta: {
      requireAuth: true,

      allowableUser: [
        USER_TYPE.BUSINESS,
        USER_TYPE.EDUCATION_STAFF,
        USER_TYPE.MANAGEMENT,
        USER_TYPE.TEACHER,
      ],
    },
  },
]

export default routes
