
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TermsPreamble',

  props: {
    title: {
      type: String,
      default: '此処に標題を入れてください',
    },

    signature: {
      type: String,
      default: '此処に署名を入れてください',
    },

    isTitleCenter: {
      type: Boolean,
      default: false,
    },
  },
})
