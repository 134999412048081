
import TextBox from '@/components/views/GradeDataSearch/TextBox.vue'
import { useStore } from '@/store'
import { InputItem } from '@/store/modules/search-condition'
import {
  SET_ATTEND_ID,
  SET_FULL_NAME,
  SET_FULL_NAME_KATAKANA,
  SET_PERSONAL_NUMBER,
} from '@/store/modules/search-condition/actionTypes'

import { computed, defineComponent } from 'vue'
import { Store } from 'vuex'

export default defineComponent({
  nmae: 'PersonalInformationInput',

  setup() {
    const store: Store<any> = useStore()
    const state = store.state.searchCondition

    // 個人番号
    const personalNumber = computed<InputItem>(
      (): InputItem => state.personalNumber
    )

    const setPersonalNumber = (value: string) => {
      store.dispatch(SET_PERSONAL_NUMBER, { value, error: '' })
    }

    // 受講 ID
    const attendID = computed<InputItem>((): InputItem => state.attendID)

    const setAttendID = (value: string) => {
      store.dispatch(SET_ATTEND_ID, { value, error: '' })
    }

    // 氏名カナ
    const fullNameKatakana = computed<InputItem>(
      (): InputItem => state.fullNameKatakana
    )

    const setFullNameKatakana = (value: string) => {
      store.dispatch(SET_FULL_NAME_KATAKANA, { value, error: '' })
    }

    // 氏名
    const fullName = computed<InputItem>((): InputItem => state.fullName)

    const setFullName = (value: string) => {
      store.dispatch(SET_FULL_NAME, { value, error: '' })
    }

    return {
      personalNumber,
      setPersonalNumber,
      attendID,
      setAttendID,
      fullNameKatakana,
      setFullNameKatakana,
      fullName,
      setFullName,
    }
  },

  components: { TextBox },
})
