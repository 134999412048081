import {
  getLabelOfKbnMaster,
  queryBranchStoreList,
  queryCertificationList,
  queryClassroomList,
  queryCourseGroupList,
} from '@/request/api'

import { getAccessTokenFromSession } from '@/services/ControlToken'
import {
  getRoleIDFromAccessToken,
  getUserIDFromAccessToken,
} from '@/services/UserInformation'
import {
  BranchStore,
  Certification,
  Classroom,
  CourseGroup,
  LabelClassification,
} from '@/typings'
import { ActionHandler, Commit, Dispatch, Getter, Mutation } from 'vuex'
import {
  SET_CERTIFICATION_LIST,
  SET_ATTEND_FISCAL_YEAR,
  SET_CERTIFICATION,
  SET_COURSE_GROUP_LIST,
  SET_COURSE_GROUP,
  SET_BRANCH_STORE_LIST,
  SET_BRANCH_STORE,
  SET_CLASSROOM,
  SET_CLASSROOM_LIST,
  SET_SUMMARY_PERIOD,
  SET_LABEL_LIST,
  SET_LABEL,
  SET_PERSONAL_NUMBER,
  SET_ATTEND_ID,
  SET_FULL_NAME_KATAKANA,
  SET_FULL_NAME,
  SET_ACTIVE_TABLE,
} from './actionTypes'

export interface SummaryPeriod {
  begin: string
  end: string
}

export interface InputItem {
  value: string
  error: string
}

export enum ACTIVE_TABLE {
  CLASSROOM_SUMMARY = 'CLASSROOM_SUMMARY',
  STUDENT_SUMMARY = 'STUDENT_SUMMARY',
}

export interface State {
  attendFiscalYear: string
  certificationList: Certification[]
  certification: Certification
  courseGroupList: CourseGroup[]
  courseGroup: CourseGroup
  branchStoreList: BranchStore[]
  branchStore: BranchStore
  classroomList: Classroom[]
  classroom: Classroom
  summaryPeriod: SummaryPeriod
  labelList: LabelClassification[]
  label: LabelClassification
  personalNumber: InputItem
  attendID: InputItem
  fullNameKatakana: InputItem
  fullName: InputItem
  activeTable: string
}

const state: State = {
  attendFiscalYear: '',
  certificationList: [],
  certification: { name: '', kbn: '' },
  courseGroupList: [],
  courseGroup: { courseGroupCd: '', courseGroupNm: '' },
  branchStoreList: [],
  branchStore: { branchCd: '', branchNm: '' },
  classroomList: [],
  classroom: { classroomCd: '', classroomNm: '' },
  summaryPeriod: { begin: '', end: '' },
  labelList: [],
  label: { name: '', kbn: '' },
  personalNumber: { value: '', error: '' },
  attendID: { value: '', error: '' },
  fullNameKatakana: { value: '', error: '' },
  fullName: { value: '', error: '' },
  activeTable: ACTIVE_TABLE.CLASSROOM_SUMMARY,
}

const getters: { [types: string]: Getter<State, any> } = {
  certificationListForDisplay: (_state: State): string[] => {
    const list = _state.certificationList.map<string>(
      (item: Certification): string => item.name as string
    )

    return ['', ...list]
  },

  certificationForDisplay: (_state: State): string => {
    return _state.certification.name as string
  },

  courseGroupActived: (_state: State): boolean => {
    return _state.attendFiscalYear !== '' && _state.certification?.name !== ''
  },

  courseGroupListForDisplay: (_state: State): string[] => {
    const list = _state.courseGroupList.map<string>(
      (item: CourseGroup): string => item.courseGroupNm as string
    )

    return ['', ...list]
  },

  courseGroupForDisplay: (_state: State): string => {
    return _state.courseGroup.courseGroupNm as string
  },

  branchStoreActived: (_state: State): boolean => {
    return _state.courseGroup.courseGroupNm !== ''
  },

  branchStoreListForDisplay: (_state: State): string[] => {
    const list = _state.branchStoreList.map<string>(
      (item: BranchStore): string => item.branchNm as string
    )

    return ['', ...list]
  },

  branchStoreForDisplay: (_state: State): string => {
    return _state.branchStore.branchNm as string
  },

  classroomActived: (_state: State): boolean => {
    return _state.branchStore.branchNm !== ''
  },

  classroomListForDisplay: (_state: State): string[] => {
    const list = _state.classroomList.map<string>(
      (item: Classroom): string => item.classroomNm as string
    )

    return ['', ...list]
  },

  classroomForDisplay: (_state: State): string => {
    return _state.classroom.classroomNm as string
  },

  labelListForDisplay: (_state: State): string[] => {
    const list = _state.labelList.map<string>(
      (item: LabelClassification): string => item.name as string
    )

    return ['', ...list]
  },

  labelForDisplay: (_state: State): string => {
    return _state.label.name as string
  },

  searchClassroomButtonActived: (_state: State): boolean => {
    const summaryPeriodSelected = Object.keys(_state.summaryPeriod).every(
      (key: string) => {
        const _key = key as keyof SummaryPeriod
        return _state.summaryPeriod[_key] !== ''
      }
    )

    return (
      _state.branchStore.branchNm !== '' &&
      _state.label.name !== '' &&
      summaryPeriodSelected
    )
  },

  searchStudentButtonActived: (_state: State): boolean => {
    return _state.classroom.classroomNm !== ''
  },
}

interface StoreContent {
  commit: Commit
  state: State
  dispatch: Dispatch
}

const actions: { [type: string]: ActionHandler<State, any> } = {
  [SET_ATTEND_FISCAL_YEAR]: ({ commit }: StoreContent, year: string): void => {
    commit(SET_ATTEND_FISCAL_YEAR, year)
  },

  [SET_CERTIFICATION_LIST]: async ({ commit }: StoreContent): Promise<void> => {
    const list: Certification[] = await queryCertificationList()
    commit(SET_CERTIFICATION_LIST, list)
  },

  [SET_CERTIFICATION]: ({ commit, state }: StoreContent, name: string) => {
    const target: Certification = state.certificationList.find(
      (item: Certification): boolean => item.name === name
    ) ?? { name: '', kbn: '' }

    commit(SET_CERTIFICATION, target)
  },

  [SET_COURSE_GROUP_LIST]: async ({
    commit,
    state,
  }: StoreContent): Promise<void> => {
    const list: CourseGroup[] = await queryCourseGroupList(
      state.attendFiscalYear,
      state.certification.kbn ?? ''
    )

    commit(SET_COURSE_GROUP_LIST, list)
  },

  [SET_COURSE_GROUP]: async (
    { commit, state, dispatch }: StoreContent,
    name: string
  ): Promise<void> => {
    // 選択済みの支店をリセット
    await dispatch(SET_BRANCH_STORE, '')

    // 選択したコースグループをリストから探し出す
    const target: CourseGroup = state.courseGroupList.find(
      (item: CourseGroup): boolean => item.courseGroupNm === name
    ) ?? { courseGroupCd: '', courseGroupNm: '' }

    // mutation を呼出して state を更新する
    commit(SET_COURSE_GROUP, target)

    // 選択済みのコースグループが存在する時にのみ支店の選択肢を再取得
    if (target.courseGroupNm) {
      await dispatch(SET_BRANCH_STORE_LIST)
    }
  },

  [SET_BRANCH_STORE_LIST]: async ({ commit }: StoreContent): Promise<void> => {
    const token: string = getAccessTokenFromSession()
    const userType = getRoleIDFromAccessToken(token)
    const userID: string = getUserIDFromAccessToken(token)

    const list: BranchStore[] = await queryBranchStoreList(userType, userID)
    commit(SET_BRANCH_STORE_LIST, list)
  },

  [SET_BRANCH_STORE]: async (
    { commit, state, dispatch }: StoreContent,
    name: string
  ): Promise<void> => {
    dispatch(SET_CLASSROOM, '')

    const target: BranchStore = state.branchStoreList.find(
      (item: BranchStore): boolean => item.branchNm === name
    ) ?? { branchCd: '', branchNm: '' }

    commit(SET_BRANCH_STORE, target)

    if (target.branchNm) {
      await dispatch(SET_CLASSROOM_LIST)
    }
  },

  [SET_CLASSROOM_LIST]: async ({
    commit,
    state,
  }: StoreContent): Promise<void> => {
    const list: Classroom[] = await queryClassroomList({
      attendFiscalYear: state.attendFiscalYear,
      certificationCode: state.certification.kbn ?? '',
      courseGroupCode: state.courseGroup.courseGroupCd ?? '',
      branchStoreCode: state.branchStore.branchCd ?? '',
    })

    commit(SET_CLASSROOM_LIST, list)
  },

  [SET_CLASSROOM]: ({ commit, state }: StoreContent, name: string): void => {
    const target: Classroom = state.classroomList.find(
      (item: Classroom): boolean => item.classroomNm === name
    ) ?? { classroomCd: '', classroomNm: '' }

    commit(SET_CLASSROOM, target)
  },

  [SET_SUMMARY_PERIOD]: (
    { commit }: StoreContent,
    date: SummaryPeriod
  ): void => {
    commit(SET_SUMMARY_PERIOD, date)
  },

  [SET_LABEL_LIST]: async ({ commit }: StoreContent): Promise<void> => {
    const list: LabelClassification[] = await getLabelOfKbnMaster()

    commit(SET_LABEL_LIST, list)
  },

  [SET_LABEL]: ({ commit, state }: StoreContent, name: string): void => {
    const target: LabelClassification = state.labelList.find(
      (item: LabelClassification): boolean => item.name === name
    ) ?? { kbn: '', name: '' }

    commit(SET_LABEL, target)
  },

  [SET_PERSONAL_NUMBER]: ({ commit }: StoreContent, input: InputItem): void => {
    commit(SET_PERSONAL_NUMBER, input)
  },

  [SET_ATTEND_ID]: ({ commit }: StoreContent, input: InputItem): void => {
    commit(SET_ATTEND_ID, input)
  },

  [SET_FULL_NAME_KATAKANA]: (
    { commit }: StoreContent,
    input: InputItem
  ): void => {
    commit(SET_FULL_NAME_KATAKANA, input)
  },

  [SET_FULL_NAME]: ({ commit }: StoreContent, input: InputItem): void => {
    commit(SET_FULL_NAME, input)
  },

  [SET_ACTIVE_TABLE]: ({ commit }: StoreContent, name: string): void => {
    commit(SET_ACTIVE_TABLE, name)
  },
}

const mutations: { [type: string]: Mutation<State> } = {
  [SET_ATTEND_FISCAL_YEAR]: (_state: State, year: string): void => {
    _state.attendFiscalYear = year
  },

  [SET_CERTIFICATION_LIST]: (_state: State, list: Certification[]): void => {
    _state.certificationList = [...list]
  },

  [SET_CERTIFICATION]: (_state: State, certification: Certification): void => {
    _state.certification = { ...certification }
  },

  [SET_COURSE_GROUP_LIST]: (_state: State, list: CourseGroup[]): void => {
    _state.courseGroupList = [...list]
  },

  [SET_COURSE_GROUP]: (_state: State, courseGroup: CourseGroup): void => {
    _state.courseGroup = { ...courseGroup }
  },

  [SET_BRANCH_STORE_LIST]: (_state: State, list: BranchStore[]): void => {
    _state.branchStoreList = [...list]
  },

  [SET_BRANCH_STORE]: (_state: State, branchStore: BranchStore): void => {
    _state.branchStore = { ...branchStore }
  },

  [SET_CLASSROOM_LIST]: (_state: State, list: Classroom[]): void => {
    _state.classroomList = [...list]
  },

  [SET_CLASSROOM]: (_state: State, classroom: Classroom): void => {
    _state.classroom = { ...classroom }
  },

  [SET_SUMMARY_PERIOD]: (_state: State, date: SummaryPeriod): void => {
    _state.summaryPeriod = { ...date }
  },

  [SET_LABEL_LIST]: (_state: State, list: LabelClassification[]): void => {
    _state.labelList = [...list]
  },

  [SET_LABEL]: (_state: State, label: LabelClassification): void => {
    _state.label = { ...label }
  },

  [SET_PERSONAL_NUMBER]: (_state: State, input: InputItem): void => {
    _state.personalNumber = { ...input }
  },

  [SET_ATTEND_ID]: (_state: State, input: InputItem): void => {
    _state.attendID = { ...input }
  },

  [SET_FULL_NAME_KATAKANA]: (_state: State, input: InputItem): void => {
    _state.fullNameKatakana = { ...input }
  },

  [SET_FULL_NAME]: (_state: State, input: InputItem): void => {
    _state.fullName = { ...input }
  },

  [SET_ACTIVE_TABLE]: (_state: State, name: string): void => {
    _state.activeTable = name
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
