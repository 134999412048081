
import { defineComponent, reactive, toRefs, PropType, watch } from 'vue'
import { StudentInformation } from '@/typings'

interface State {
  isSelectAll: boolean
  selectedStudentInformation: StudentInformation[]
}

export default defineComponent({
  props: {
    studentInformationList: {
      type: Array as PropType<StudentInformation[]>,
      require: true,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const state = reactive<State>({
      isSelectAll: false,
      selectedStudentInformation: [],
    })

    // 検索内容変更時に選択項目を初期化
    watch(
      () => props.studentInformationList,

      () => {
        state.selectedStudentInformation = []
        state.isSelectAll = false
      }
    )

    const handleSelectAll = (): void => {
      state.isSelectAll = !state.isSelectAll

      state.selectedStudentInformation = state.isSelectAll
        ? [...props.studentInformationList]
        : []

      emit('selectAll', state.selectedStudentInformation)
    }

    const handleSelectStudentInformation = (
      applicationNo: string | undefined
    ): void => {
      state.isSelectAll = false

      const isItemSelected = state.selectedStudentInformation.find(
        (item) => item.applicationNo === applicationNo
      )

      if (isItemSelected) {
        state.selectedStudentInformation =
          state.selectedStudentInformation.filter(
            (item) => item.applicationNo !== applicationNo
          )
      } else {
        const selectedItem = props.studentInformationList.find(
          (item: any) => item.applicationNo === applicationNo
        ) as StudentInformation

        state.selectedStudentInformation = [
          ...state.selectedStudentInformation,
          selectedItem,
        ]
      }

      emit('selectItem', state.selectedStudentInformation)
    }

    return {
      ...toRefs(state),
      handleSelectAll,
      handleSelectStudentInformation,
    }
  },
})
