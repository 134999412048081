export const mainContainerStyle = {
  minHeight: 'calc(100vh - 329px)',
  maxHeight: 'calc(100vh - 257px)',
}
export const mainContainerStyleAPP = {
  minHeight: 'calc(100vh - 129)',
  maxHeight: 'calc(100vh - 129)',
}
export const scrollContainerStyle = {
  'overflow-y': 'scroll',
  height: 'calc(100vh - 428px)',
}
export const scrollContainerStyleAPP = {
  'overflow-y': 'scroll',
  height: 'calc(100vh - 169px)',
}
