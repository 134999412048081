
// Dependencies
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue'

// Icons
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'

export default defineComponent({
  name: 'BaseSelector',
  components: { IconArrowDownTriangle },

  props: {
    list: {
      type: Array as PropType<string[]>,
      required: true,
    },

    selectedOption: String,
    placeholder: String,
    containerStyle: Object,
    displayAreaStyle: Object,
    optionStyle: Object,
    iconArrowDownTriangleStyle: Object,
    disabled: Boolean,
  },

  setup(props, { emit }) {
    // State
    const state = reactive({
      selectedValue: '',
    })

    const option = computed(() => {
      return props.selectedOption ?? state.selectedValue ?? props.placeholder
    })

    // Methods
    const handleSelect = (event: Event): void => {
      const targetIndex = Number((event.target as HTMLSelectElement).value)
      state.selectedValue = props.list[targetIndex]
      emit('select-option', targetIndex)
    }

    return {
      ...toRefs(state),
      option,
      handleSelect,
    }
  },
})
