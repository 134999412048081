import { Mutation } from 'vuex'
import {
  SET_APPLICATION_NUMBER,
  SET_GLOBAL_ERROR,
  SET_STUDENT_USER_ID,
} from './actionTypes'
import { State } from './state'

const mutations: { [type: string]: Mutation<State> } = {
  refreshHeader(state: State): void {
    state.headerRefreshmentSwitch = !state.headerRefreshmentSwitch
  },

  checkUserFrom(state: State, payload: any): void {
    state.isUserFromAPP = payload
  },

  setIsLoadingWithText(state: State, payload: any): void {
    state.isLoadingWithText = payload
  },

  setIsLoadingText(state: State, payload: any): void {
    state.isLoadingText = payload
  },

  [SET_GLOBAL_ERROR]: (state: State, error: string): void => {
    state.globalError = error
  },

  [SET_STUDENT_USER_ID]: (state: State, userID: string): void => {
    state.studentUserID = userID
  },

  [SET_APPLICATION_NUMBER]: (state: State, applicationNumber: string): void => {
    state.applicationNumber = applicationNumber
  },
  setPresentTab: (state: State, value: string): void => {
    state.presentTab = value
  },
}

export default mutations
