
/**
 * 新しいウィンドウ
 */
// Dependencies
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fill: {
      type: String,
      default: '#999',
    },
  },
})
