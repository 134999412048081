
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseTextBox',

  props: {
    textBoxID: String,
    errorID: String,
    placeholder: String,
    value: String,
    error: String,
    inputStyle: Object,
    errorStyle: Object,
    disabled: Boolean,
  },

  setup(props, { emit }) {
    const handleInput = (event: Event): void => {
      emit('input', (event.target as HTMLInputElement).value)
    }

    return { handleInput }
  },
})
