
import { defineComponent, reactive, toRefs } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'
import ScheduleDateTopPage from '@/components/views/PassDiary/ScheduleDateTopPage.vue'

export default defineComponent({
  name: 'DateSelectorHideInput',
  components: {
    DatePicker,
    ScheduleDateTopPage,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      dateValue: props.date,
      isDatePicker: false,
    })
    const handleChange = (): void => {
      emit('changeDate', state.dateValue)
    }

    const toPrev = (): void => {
      emit('prev', state.dateValue)
    }
    const toNext = (): void => {
      emit('next', state.dateValue)
    }
    const handleDatePicker = (isDisplay: boolean): void => {
      state.isDatePicker = isDisplay
    }
    return {
      ...toRefs(state),
      handleChange,
      toPrev,
      toNext,
      handleDatePicker,
    }
  },
})
