
/**
 * 送信先リスト一括取込画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import DestinationListInput from '@/components/views/DestinationListBulkImport/DestinationListInput/index.vue'
import AttendIDInput from '@/components/views/DestinationListBulkImport/AttendIDInput/index.vue'
import RegisterButtonGroup from '@/components/views/DestinationListBulkImport/RegisterButtonGroup.vue'
import DestinationListSelect from '@/components/views/DestinationListSelect/DestinationListSelectTable.vue'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'
import BaseButton from '@/components/common/BaseButton.vue'
import { useRouter } from '@/router'
// Dependencies
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { mainContainerStyle } from './style'
import { getMessage } from '@/Utils'
import { DestinationListState } from '@/typings'
import {
  queryDestinationList,
  registerDestinationList,
  updateDestinationList,
} from '@/request/api/information-message/destinationList'
import { useGlobalError } from '@/services/Hooks'
import {
  ATTEND_ID_MAX_LENGTH,
  DESTINATION_LIST_NAME_MAX_LENGTH,
  MAX_USER_ID_LIST_LENGTH,
} from '@/config'

interface SearchList {
  searchDestinationList: Array<DestinationListModel>
}

interface FormError {
  // 送信先リスト名エラー
  destinationListNameError: string
  // 送信先（受講 ID）リストエラー
  attendIDError: string
}

export default defineComponent({
  name: 'DestinationListBulkImport',

  setup() {
    const { setGlobalError, clearGlobalError } = useGlobalError()

    const inputWrapperOpened = ref<boolean>(false)

    // データ処理
    const state = reactive<DestinationListState>({
      destinationListName: '',
      userIDList: [],
    })

    const searchList = reactive<SearchList>({
      searchDestinationList: [] as DestinationListModel[],
    })

    const stringAttendIDList = ref<string>('')

    const handleSearchDestinationList = async (): Promise<void> => {
      const isPassed: boolean = validateDestinationListName()

      if (!isPassed) {
        state.userIDList = []
        stringAttendIDList.value = ''
        return
      }

      try {
        searchList.searchDestinationList = await queryDestinationList()
      } catch (error) {
        console.log(error)
        setGlobalError(error as Error)
      }

      searchList.searchDestinationList =
        searchList.searchDestinationList.filter((item) =>
          item.destinationListName.includes(state.destinationListName)
        )
      if (searchList.searchDestinationList.length == 0) {
        formError.destinationListNameError = getMessage('EFR0017')
        formError.attendIDError = ''
        state.userIDList = []
        stringAttendIDList.value = ''
        return
      }

      inputWrapperOpened.value = true
    }

    const router = useRouter()

    const handleUpdateDestinationList = async (): Promise<void> => {
      if (!validateAll()) {
        const response = await queryDestinationList(state.destinationListName)
        const userIDList: string[] = response[0]?.userIdList ?? []
        if (userIDList.length == 0) {
          clearGlobalError()
          formError.attendIDError = ''
          formError.destinationListNameError = getMessage('EFR0011')
          return
        }

        formatStringDestinationList()
        setUserIDList()

        const successed = await updateDestinationList(state)

        if (!successed) {
          clearGlobalError()
          formError.destinationListNameError = ''
          formError.attendIDError = getMessage('EFR0031', [
            '送信先リスト',
            '更新',
          ])
        } else {
          clearGlobalError()
          formError.destinationListNameError = ''
          formError.attendIDError = ''
          // 更新に成功した場合は完了画面へ遷移
          router.push({
            name: 'ManageNormalCompleted',
            params: { msg: getMessage('EFR0026', ['送信先リスト更新']) },
          })
        }
      }
    }

    const handleRegisterDestinationList = async (): Promise<void> => {
      if (!validateAll()) {
        const response = await queryDestinationList(state.destinationListName)
        const userIDList: string[] = response[0]?.userIdList ?? []

        if (userIDList.length > 0) {
          clearGlobalError()
          formError.attendIDError = ''
          formError.destinationListNameError = getMessage('EFR0012')
          return
        }

        formatStringDestinationList()
        setUserIDList()

        const successed = await registerDestinationList(state)

        if (!successed) {
          formError.attendIDError = getMessage('EFR0031', [
            '送信先リスト',
            '登録',
          ])
        } else {
          clearGlobalError()
          formError.destinationListNameError = ''
          formError.attendIDError = ''
          // 登録に成功した場合は完了画面へ遷移
          router.push({
            name: 'ManageNormalCompleted',
            params: { msg: getMessage('EFR0026', ['送信先リスト登録']) },
          })
        }
      }
    }

    const handleSearchSelectDestinationList = async (
      selectedDestinationList: DestinationListModel[]
    ): Promise<void> => {
      clearGlobalError()
      formError.destinationListNameError = ''
      formError.attendIDError = ''

      if (selectedDestinationList.length != 0) {
        try {
          searchList.searchDestinationList = await queryDestinationList(
            selectedDestinationList[0].destinationListName
          )
        } catch (error) {
          console.log(error)
          setGlobalError(error as Error)
        }

        state.userIDList = searchList.searchDestinationList[0].userIdList
        if (state.userIDList.length > MAX_USER_ID_LIST_LENGTH) {
          state.userIDList = []
          formError.attendIDError = getMessage('EFR0028', [
            MAX_USER_ID_LIST_LENGTH,
          ])
          formError.destinationListNameError = ''
        } else {
          state.destinationListName =
            searchList.searchDestinationList[0].destinationListName.slice(1, -1)
          stringAttendIDList.value = state.userIDList
            .toString()
            .replaceAll(',', '\n')
        }
      }

      inputWrapperOpened.value = false
    }

    const formatStringDestinationList = (): void => {
      // 半角空白、全角空白を改行に変更
      stringAttendIDList.value = stringAttendIDList.value.replace(
        /[\s|\u3000]+/g,
        '\n'
      )
    }

    const setUserIDList = (): void => {
      state.userIDList = stringAttendIDList.value.split('\n')
    }

    // フォーム検証
    const formError = reactive<FormError>({
      destinationListNameError: '',
      attendIDError: '',
    })

    const validateAll = (): boolean => {
      const validateList: boolean[] = [
        validateDestinationListName(),
        validateDestinationList(),
      ]

      return validateList.some((element: boolean) => !element)
    }

    const validateDestinationListName = (): boolean => {
      let isPassed = true

      if (!state.destinationListName) {
        formError.destinationListNameError = getMessage('EFR0005')
        isPassed = false
      }

      if (state.destinationListName.length > DESTINATION_LIST_NAME_MAX_LENGTH) {
        formError.destinationListNameError = getMessage('EFR0007', [
          ` ${DESTINATION_LIST_NAME_MAX_LENGTH} `,
        ])

        isPassed = false
      }

      return isPassed
    }

    watch(
      () => state.destinationListName,

      () => {
        clearGlobalError()
        formError.destinationListNameError = ''
      }
    )

    const validateDestinationList = (): boolean => {
      let isPassed = true

      const regex = /^[0-9a-zA-Z\n 　]*$/

      if (!regex.test(stringAttendIDList.value)) {
        formError.attendIDError = getMessage('EFR0024')
        isPassed = false
      }

      !validateAttendID() && (isPassed = false)

      return isPassed
    }

    const validateAttendID = (): boolean => {
      let isPassed = true

      const temporaryList = stringAttendIDList.value
        .split('\n')
        .filter((value: string): boolean => value !== '')

      temporaryList
        .filter((value: string) => !temporaryList.includes(value))
        .sort()

      const formatUserIdList = stringAttendIDList.value
        .replace(/[\s|\u3000]+/g, '\n')
        .split('\n')

      if (formatUserIdList.length > MAX_USER_ID_LIST_LENGTH) {
        formError.attendIDError = getMessage('EFR0028', [
          MAX_USER_ID_LIST_LENGTH,
        ])
        formError.destinationListNameError = ''
        isPassed = false
      }

      const isLengthOver = stringAttendIDList.value
        .split('\n')
        .some((attendID: string) => attendID.length > ATTEND_ID_MAX_LENGTH)

      if (isLengthOver) {
        formError.attendIDError = getMessage('EFR0025', [
          '受講 ID ',
          ` ${ATTEND_ID_MAX_LENGTH} `,
        ])

        isPassed = false
      }

      return isPassed
    }

    watch(
      () => stringAttendIDList.value,

      () => {
        clearGlobalError()
        formError.attendIDError = ''
      }
    )

    return {
      mainContainerStyle,
      ...toRefs(state),
      ...toRefs(formError),
      ...toRefs(searchList),
      stringAttendIDList,
      handleSearchDestinationList,
      handleUpdateDestinationList,
      handleRegisterDestinationList,
      handleSearchSelectDestinationList,
      inputWrapperOpened,
    }
  },

  components: {
    ManagePageContainer,
    DestinationListInput,
    AttendIDInput,
    RegisterButtonGroup,
    BaseButton,
    DestinationListSelect,
  },
})
