/**
 * インフォメーションメッセージ - メッセージストレージ
 */
import { RecievedMessageModel } from '@/request/api/information-message/model/message'

// セッションストレージに保存されるメッセージのキー
const KEY_INFORMATION_MESSAGE_ITEM = 'informationMessageItem'

/**
 * @description インフォメーションメッセージをセッションストレージに保存
 * @param message
 */
export const setInformationMessageItemToStorage = (
  message: RecievedMessageModel
): void => {
  sessionStorage.setItem(KEY_INFORMATION_MESSAGE_ITEM, JSON.stringify(message))
}

/**
 * @description セッションストレージからインフォメーションメッセージを取得
 * @returns
 */
export const getInformationMessageItemFromStorage = (): RecievedMessageModel => {
  const stringMessage = sessionStorage.getItem(KEY_INFORMATION_MESSAGE_ITEM)
  return JSON.parse(stringMessage ?? '{}')
}

/**
 * @description セッションストレージからインフォメーションメッセージを削除
 */
export const removeInformationMessageItemFromStorage = (): void => {
  sessionStorage.removeItem(KEY_INFORMATION_MESSAGE_ITEM)
}
