
// Components
import Header from '@/components/common/ManageHeader.vue'
import Footer from '@/components/common/ManageFooter.vue'
import TheCard from '@/components/views/ManageTop/TheCard.vue'

// Icons
import IconInformation from '@/components/icons/IconInformation.vue'
import IconGrade from '@/components/icons/IconGrade.vue'
import IconPadlock from '@/components/icons/IconPadlock.vue'
import IconExamRegister from '@/components/icons/IconExamRegister.vue'
import IconPassDiary from '@/components/icons/IconPassDiary.vue'
// Dependencies
import { computed, defineComponent, Component } from 'vue'
import { getRoleIdFromJwt } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useRouter } from '@/router'
import { USER_TYPE } from '@/config'
import { VIEW_SUMMARY_FOR_STAFF } from '@/router/routesName'

// 各種 URL
const URL = {
  // 「パスワード登録」画面
  CHANGE_PASSWORD: 'https://auth.dev.shikaku-app.jp/PasswordChange',
}

// ルーターのタイプ
const ROUTER_TYPE = {
  HTML: 'HTML', // HTML URL
  VUE: 'Vue', // Vue Router
}

interface Service {
  id?: number
  icon?: Component
  title?: string
  routerType?: string
  destination?: string
}

// Vue オブジェクト
export default defineComponent({
  name: 'ManageTop',

  components: {
    Header,
    Footer,
    TheCard,
    IconInformation,
    IconGrade,
    IconPadlock,
    IconExamRegister,
    IconPassDiary,
  },

  props: {
    roleId: String,
  },

  setup(props) {
    const router = useRouter()

    // 各ユーザーが使えるサービス
    const studentServices: Service[] = [
      {
        id: 1,
        icon: IconPadlock,
        title: 'パスワード変更',
        routerType: ROUTER_TYPE.HTML,
        destination: URL.CHANGE_PASSWORD,
      },
    ]

    const teacherServices: Service[] = [
      {
        id: 1,
        icon: IconPadlock,
        title: 'パスワード変更',
        routerType: ROUTER_TYPE.HTML,
        destination: URL.CHANGE_PASSWORD,
      },

      {
        id: 3,
        icon: IconPassDiary,
        title: 'ダイアリー',
        routerType: ROUTER_TYPE.VUE,
        destination: VIEW_SUMMARY_FOR_STAFF,
      },
    ]

    const educationStaffServices: Service[] = [
      {
        id: 1,
        icon: IconGrade,
        title: '成績参照',
        destination: 'GradeDataSearch',
      },

      {
        id: 2,
        icon: IconInformation,
        title: 'お知らせ',
        destination: 'InformationMenu',
      },

      {
        id: 3,
        icon: IconPassDiary,
        title: 'ダイアリー',
        routerType: ROUTER_TYPE.VUE,
        destination: VIEW_SUMMARY_FOR_STAFF,
      },
    ]

    const businessServices: Service[] = [
      {
        id: 1,
        icon: IconGrade,
        title: '成績参照',
        destination: 'GradeDataSearch',
      },

      {
        id: 2,
        icon: IconInformation,
        title: 'お知らせ',
        destination: 'InformationMenu',
      },

      {
        id: 3,
        icon: IconPassDiary,
        title: 'ダイアリー',
        routerType: ROUTER_TYPE.VUE,
        destination: VIEW_SUMMARY_FOR_STAFF,
      },
    ]

    const managementServices: Service[] = [
      {
        id: 1,
        icon: IconExamRegister,
        title: '試験マスタ登録',
        destination: 'ExamDateMaintenance',
      },

      {
        id: 2,
        icon: IconGrade,
        title: '成績参照',
        destination: 'GradeDataSearch',
      },

      {
        id: 3,
        icon: IconInformation,
        title: 'お知らせ',
        destination: 'InformationMenu',
      },

      {
        id: 4,
        icon: IconPassDiary,
        title: 'ダイアリー',
        routerType: ROUTER_TYPE.VUE,
        destination: VIEW_SUMMARY_FOR_STAFF,
      },
    ]

    const currentServices = computed(() => {
      switch (props.roleId ?? getRoleIdFromJwt(getAccessTokenFromSession())) {
        case USER_TYPE.STUDENT:
          return studentServices
        case USER_TYPE.TEACHER:
          return teacherServices
        case USER_TYPE.EDUCATION_STAFF:
          return educationStaffServices
        case USER_TYPE.BUSINESS:
          return businessServices
        case USER_TYPE.MANAGEMENT:
          return managementServices
      }
    })

    /**
     * @param {string | undefine} routerType ルーターのタイプ（Vue コンポーネント間の遷移か別サーバーへの遷移か）
     * @param {string} destination 遷移先（Vue コンポーネントの場合は name、HTML の場合は URL）
     */
    const handleTransferScreen = (
      destination: string,
      routerType: string = ROUTER_TYPE.VUE
    ) => {
      routerType === ROUTER_TYPE.HTML && location.assign(destination)
      routerType === ROUTER_TYPE.VUE && router.push({ name: destination })
    }

    return { currentServices, handleTransferScreen }
  },
})
