
import BaseButton from '@/components/common/BaseButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RegisterButtonGroup',

  setup(props, { emit }) {
    return { emit }
  },

  components: { BaseButton },
})
