
/**
 * 送信履歴一覧画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import MessageHistoryCard from '@/components/views/SentMessageHistoryList/MessageHistoryCard.vue'
import DateSelector from '@/components/common/selector/DateSelector.vue'
// Dependencies
import {
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  computed,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import { queryMessageSentHistoryList } from '@/request/api/information-message/sentHistory'
import { queryBranchStoreList } from '@/request/api/index'
import { getRoleIdFromJwt } from '@/Utils'
import {
  MESSAGE_HISTORY_MAX_LENGTH,
  USER_TYPE,
  STUDENT_USER_CODE_LENGTH,
} from '@/config'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { mainContainerStyle, mainContainerStyleAPP } from './style'
import { BranchStore } from '@/typings'
import { useRouter } from '@/router'
import {
  DELETE_SENT_MESSAGE,
  setGlobalParams,
  MESSAGE_COMPLETE_PAGE,
} from '@/services/GlobleParames'
import {
  MessageSentHistoryItemModel,
  MessageSentHistoryListModel,
} from '@/request/api/information-message/model/sentHistory'
import TextBox from '@/components/views/GradeDataSearch/TextBox.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BranchList from '@/components/common/InformationMessage/BranchList.vue'
import { useGlobalError } from '@/services/Hooks'
import { SENT_MESSAGE_HISTORY_KEY } from '@/views/InformationMenu.vue'
interface State {
  userType: string
  userID: string
  messageList: MessageSentHistoryListModel
  range: string[]
  searchConditionSender: string[]
  searchConditionSubject: string
  searchConditionBody: string
  searchConditionSendDtFrom: string
  searchConditionSendDtTo: string
  windowWidth: number
  selectedBranchCd: string[]
  selectedBranchName: string[]
  inputSender: string
  inputSubject: string
  inputBody: string
  inputSendDtFrom: string
  inputSendDtTo: string
  allBranchs: BranchStore[]
  attachment: boolean
  enableReply: boolean
  isNeedReply: boolean
}

interface Option {
  branchStoreList: BranchStore[]
}

interface CompareFunction {
  (
    previous: MessageSentHistoryItemModel,
    next: MessageSentHistoryItemModel
  ): number
}

export default defineComponent({
  name: 'SentMessageHistoryList',

  setup() {
    const state = reactive<State>({
      userType: '',
      userID: '',
      messageList: [],
      range: [],
      searchConditionSender: [],
      searchConditionSubject: '',
      searchConditionBody: '',
      searchConditionSendDtFrom: '',
      searchConditionSendDtTo: '',
      windowWidth: window.innerWidth,
      selectedBranchCd: [],
      selectedBranchName: [],
      inputSubject: '',
      inputBody: '',
      inputSendDtFrom: '',
      inputSendDtTo: '',
      inputSender: '',
      allBranchs: [],
      attachment: false,
      enableReply: false,
      isNeedReply: false,
    })
    const { clearGlobalError } = useGlobalError()
    const option = reactive<Option>({
      branchStoreList: [],
    })

    const router = useRouter()

    onBeforeMount(async () => {
      clearGlobalError()
      const accessToken = getAccessTokenFromSession()
      state.userType = getRoleIdFromJwt(accessToken) ?? ''
      state.userID = getUserIDFromAccessToken(accessToken)
      state.inputSender = state.userID

      const jsonData = sessionStorage.getItem(SENT_MESSAGE_HISTORY_KEY)
      if (jsonData) {
        let sessionState = JSON.parse(jsonData ?? '{}') as State
        state.inputSender = sessionState.inputSender
        state.inputSubject = sessionState.inputSubject
        state.inputBody = sessionState.inputBody
        state.inputSendDtFrom = sessionState.inputSendDtFrom
        state.inputSendDtTo = sessionState.inputSendDtTo
        state.attachment = sessionState.attachment
        state.enableReply = sessionState.enableReply
        state.isNeedReply = sessionState.isNeedReply
      }

      option.branchStoreList = await queryBranchStoreList(
        state.userType,
        state.userID
      )

      state.allBranchs = [...option.branchStoreList]
      option.branchStoreList.forEach(function (item, index) {
        state.range[index] = item.branchCd as string
      })
      refreshBranch.value++;
      if (jsonData) {
        let sessionState = JSON.parse(jsonData ?? '{}') as State
        state.allBranchs = sessionState.allBranchs
        reRenderBranch()
      }
      state.searchConditionSender = state.inputSender ? [...state.inputSender.split(",")] : []
      state.searchConditionSubject = state.inputSubject
      state.searchConditionBody = state.inputBody
      state.searchConditionSendDtFrom = state.inputSendDtFrom
      state.searchConditionSendDtTo = state.inputSendDtTo

      state.messageList = await queryMessageSentHistoryList({
        userID: state.userID,
        max: MESSAGE_HISTORY_MAX_LENGTH,
        range: state.range,
        sender: state.searchConditionSender ?? [],
        subject: state.searchConditionSubject ?? '',
        body: state.searchConditionBody ?? '',
        sendDtFrom: state.searchConditionSendDtFrom ? state.searchConditionSendDtFrom : '1900-01-01',
        sendDtTo: state.searchConditionSendDtTo ? state.searchConditionSendDtTo : '2099-01-31',
        attachment: state.attachment ? "true" : "",
        enableReply: state.enableReply ? "true" : "",
        isNeedReply: state.isNeedReply ? "true" : "",
      })

      if (state.messageList.length !== 0) {
        // メッセージ番号が同じメッセージを削除
        state.messageList = state.messageList.filter(dumplicationFilter)
        state.messageList = state.messageList.filter(studentFilter)
        state.messageList = state.messageList.filter((item) => { return item.subject !== "-" })
        await updateMessageList(state.messageList.length)
        state.messageList = state.messageList.filter(dumplicationFilter)
        state.messageList = state.messageList.filter(studentFilter)
        state.messageList = state.messageList.filter((item) => { return item.subject !== "-" })
        // 受信順でソート
        state.messageList.sort(messageListSortCallback())
      }
    })

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      sessionStorage.setItem(SENT_MESSAGE_HISTORY_KEY, JSON.stringify(state))
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
    }

    const buttonStyle = computed(() => {
      if (state.windowWidth < 800) {
        return { width: '100%', margin: '5px 0px' }
      } else {
        return { width: '150px', margin: '17px 5px 0 5px', float: 'right' }
      }
    })

    const branchNameList = computed(() => {
      const list = option.branchStoreList.map<string>(
        (item: BranchStore): string => item.branchNm as string
      )
      return ['', ...list]
    })

    const studentFilter = (message: MessageSentHistoryItemModel) => {
      return message.sender.length !== STUDENT_USER_CODE_LENGTH
    }

    const dumplicationFilter = (
      message: MessageSentHistoryItemModel,
      index: number,
      messageList: MessageSentHistoryListModel
    ) => {
      return (
        messageList.findIndex(
          (searchMessage) => searchMessage.messageNo === message.messageNo
        ) === index
      )
    }
    const formatTimeStamp = (timestamp: string) => {
      //input sample: "2024年03月21日 木 10:13:44"
      return (
        timestamp.substring(0, 4) +
        '/' +
        timestamp.substring(5, 7) +
        '/' +
        timestamp.substring(8, 10) +
        ' ' +
        timestamp.substring(14, 22)
      )
    }
    const messageListSortCallback =
      (): CompareFunction =>
        (
          previous: MessageSentHistoryItemModel,
          next: MessageSentHistoryItemModel
        ) => {
          const preSendDate = new Date(formatTimeStamp(previous.sendTimestamp))
          const nextSendDate = new Date(formatTimeStamp(next.sendTimestamp))
          return nextSendDate.getTime() - preSendDate.getTime()
        }

    const handleScroll = async (event: Event): Promise<void> => {
      const container: HTMLDivElement = event.target as HTMLDivElement

      const computedScrollHeight: number = Math.ceil(
        container.scrollTop + container.clientHeight
      )

      const scrollHeight: number = container.scrollHeight

      // スクロールした分 ＋ 表示分がスクロール全体の高度以上であれば列の最後に達したと認定
      if (computedScrollHeight >= scrollHeight) {
        await updateMessageList(0)

        state.messageList = state.messageList.filter(studentFilter)
        state.messageList.sort(messageListSortCallback())
      }
    }

    const updateMessageList = async (
      messageListLength: number
    ): Promise<void> => {
      clearGlobalError()
      let messageListLengthBefore = 0
      let getList: MessageSentHistoryListModel = []
      let setList: MessageSentHistoryListModel =
        messageListLength == 0 ? [] : state.messageList
      state.searchConditionSender = state.inputSender ? [...state.inputSender.split(",")] : []
      state.searchConditionSubject = state.inputSubject
      state.searchConditionBody = state.inputBody
      state.searchConditionSendDtFrom = state.inputSendDtFrom
      state.searchConditionSendDtTo = state.inputSendDtTo
      do {
        messageListLengthBefore = messageListLength
        getList = await queryMessageSentHistoryList({
          userID: state.userID,
          lastMessageNumber:
            setList.length == 0
              ? state.messageList[state.messageList.length - 1]?.messageNo
              : setList[setList.length - 1]?.messageNo,
          max: MESSAGE_HISTORY_MAX_LENGTH,
          range: state.range,
          sender: state.searchConditionSender ?? [],
          subject: state.searchConditionSubject ?? '',
          body: state.searchConditionBody ?? '',
          sendDtFrom: state.searchConditionSendDtFrom ? state.searchConditionSendDtFrom : '1900-01-01',
          sendDtTo: state.searchConditionSendDtTo ? state.searchConditionSendDtTo : '2099-01-31',
          attachment: state.attachment ? "true" : "",
          enableReply: state.enableReply ? "true" : "",
          isNeedReply: state.isNeedReply ? "true" : "",
        })

        // メッセージ番号が同じメッセージを削除
        getList = getList.filter(dumplicationFilter)

        // メッセージが存在しない場合
        if (getList.length == 0) {
          messageListLengthBefore = messageListLength
        } else {
          setList = [...setList, ...getList]
          setList = setList.filter(dumplicationFilter)
          messageListLength = messageListLength + getList.length
        }
      } while (
        messageListLength < MESSAGE_HISTORY_MAX_LENGTH &&
        messageListLengthBefore != messageListLength
      )

      state.messageList = [...state.messageList, ...setList]
    }

    const handleTransferToDetail = (
      message: MessageSentHistoryItemModel
    ): void => {
      sessionStorage.setItem(SENT_MESSAGE_HISTORY_KEY, JSON.stringify(state))
      setGlobalParams(DELETE_SENT_MESSAGE, true)
      setGlobalParams(MESSAGE_COMPLETE_PAGE, false)
      router.push({
        name: 'SentMessageDetail',
        params: { message: JSON.stringify(message) },
      })
    }

    const handleInputSender = (value: string): void => {
      state.inputSender = value
    }
    const handleInputSubject = (value: string): void => {
      state.inputSubject = value
    }
    const handleInputBody = (value: string): void => {
      state.inputBody = value
    }
    const handleInputSendDtFrom = (value: string): void => {
      state.inputSendDtFrom = value ?? ''
    }
    const handleInputSendDtTo = (value: string): void => {
      state.inputSendDtTo = value ?? ''
    }
    const handleSelectBranch = (value: string): void => {
      if (!value) {
        state.selectedBranchCd = []
        return
      }
      var selectedBranchStore = option.branchStoreList.find(
        (item) => item.branchNm === value
      ) as BranchStore
    }
    const handleSearch = async (): Promise<void> => {
      clearGlobalError()
      state.searchConditionSender = state.inputSender ? [...state.inputSender.split(",")] : []
      state.searchConditionSubject = state.inputSubject
      state.searchConditionBody = state.inputBody
      state.searchConditionSendDtFrom = state.inputSendDtFrom
      state.searchConditionSendDtTo = state.inputSendDtTo

      state.messageList = await queryMessageSentHistoryList({
        userID: state.userID,
        max: MESSAGE_HISTORY_MAX_LENGTH,
        range: state.range,
        sender: state.searchConditionSender,
        subject: state.searchConditionSubject ?? '',
        body: state.searchConditionBody ?? '',
        sendDtFrom: state.searchConditionSendDtFrom ? state.searchConditionSendDtFrom : '1900-01-01',
        sendDtTo: state.searchConditionSendDtTo ? state.searchConditionSendDtTo : '2099-01-31',
        attachment: state.attachment ? "true" : "",
        enableReply: state.enableReply ? "true" : "",
        isNeedReply: state.isNeedReply ? "true" : "",
      })

      if (state.messageList?.length !== 0) {
        // メッセージ番号が同じメッセージを削除
        state.messageList = state.messageList.filter(dumplicationFilter)

        state.messageList = state.messageList.filter(studentFilter)

        await updateMessageList(state.messageList.length)

        state.messageList = state.messageList.filter(dumplicationFilter)

        state.messageList = state.messageList.filter(studentFilter)

        // 受信順でソート
        state.messageList.sort(messageListSortCallback())
      }

    }

    const mainStyle = computed(() => {
      if (state.windowWidth < 800) {
        return mainContainerStyleAPP
      } else {
        return mainContainerStyle
      }
    })

    const compWidth = computed(() => {
      if (state.windowWidth < 800) {
        return '100%'
      } else {
        return '150px'
      }
    })
    const compWidthSubject = computed(() => {
      return '100%'
    })
    const compWidthSender = computed(() => {
      if (state.windowWidth < 800) {
        return '100%'
      } else {
        return '500px'
      }
    })
    const scrollContainerStyle = computed(() => {
      if (state.windowWidth < 800) {
        return { height: 'calc(100vh - 284px)', 'min-height': '180px' }
      } else {
        return { height: 'calc(100vh - 400px)', 'min-height': '180px' }
      }
    })

    const selectorContainerStyle = ref({
      border: 'solid 1px #9d9d9d',
      'border-radius': '0.3rem',
      padding: '1rem',
      color: 'gray',
      opacity: 0.8,
    })

    const isMessageMaxLength = computed<boolean>(() => {
      let retValue = false
      state.messageList.forEach(function (item) {
        if (item.subject.length > 40) {
          retValue = true
          return retValue
        }
      })
      return retValue
    })

    const refreshBranch = ref(0)
    const reRenderBranch = () => {
      refreshBranch.value++
    }

    return {
      ...toRefs(state),
      branchNameList,
      handleScroll,
      mainStyle,
      scrollContainerStyle,
      handleTransferToDetail,
      handleInputSender,
      handleSelectBranch,
      handleInputSubject,
      handleInputBody,
      handleInputSendDtFrom,
      handleInputSendDtTo,
      handleSearch,
      isMessageMaxLength,
      buttonStyle,
      selectorContainerStyle,
      compWidth,
      compWidthSubject,
      compWidthSender,
      refreshBranch,
      reRenderBranch
    }
  },

  components: {
    ManagePageContainer,
    MessageHistoryCard,
    TextBox,
    BaseButton,
    DateSelector,
    BranchList,
  },
})
