
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    textList: {
      type: Array,
      required: true,
    },

    useListNumber: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const checkMainItemExist = (item: any) => {
      return item.mainText || typeof item === 'string'
    }

    return {
      checkMainItemExist,
    }
  },
})
