import orignalStore, { useStore } from '@/store'
import {
  SET_GLOBAL_ERROR,
} from '@/store/actionTypes'
import { Store } from 'vuex'
import { SET_SSO_PARAMETERS } from '@/store/modules/on-demand/actionTypes'

export interface UseGlobalError {
  setGlobalError: (error: any | Error | string) => void
  clearGlobalError: () => void
}

export function useGlobalError(): UseGlobalError {
  let store: Store<any>

  try {
    store = useStore()
  } catch (error) {
    store = require('@/store').default
  }

  const setGlobalError = async (error: any | Error | string): Promise<void> => {
    store && (await store.dispatch(SET_GLOBAL_ERROR, error))
  }

  const clearGlobalError = async (): Promise<void> => {
    store && (await store.dispatch(SET_GLOBAL_ERROR, ''))
  }

  return {
    setGlobalError,
    clearGlobalError,
  }
}

interface OnDemandParameters {
  id: string
  timestamp: string
  // クラス ID
  courseKey?: string
  // クラス ID 最後の二桁とテストコード
  chapterKey?: string
}

const STORAGE_KEY_ON_DEMAND_PARAMETERS = 'onDemandParameters'

export interface UseOnDemand {
  setOnDemandSSOParameters: (userID: string) => Promise<void>
  setOnDemandParametersToStorage: (parameters: OnDemandParameters) => void
  getOnDemandParametersFromStorage: () => OnDemandParameters
  removeOnDemandParametersFromStorage: () => void
}

export const useOnDemand = (): UseOnDemand => {
  let store: Store<any>

  try {
    store = useStore()
  } catch (error) {
    store = orignalStore
  }

  const setOnDemandSSOParameters = async (userID: string): Promise<void> => {
    await store.dispatch(SET_SSO_PARAMETERS, userID)
  }

  const setOnDemandParametersToStorage = (
    parameters: OnDemandParameters
  ): void => {
    sessionStorage.setItem(
      STORAGE_KEY_ON_DEMAND_PARAMETERS,
      JSON.stringify(parameters)
    )
  }

  const getOnDemandParametersFromStorage = (): OnDemandParameters => {
    const data: string | null = sessionStorage.getItem(
      STORAGE_KEY_ON_DEMAND_PARAMETERS
    )

    return JSON.parse(data ?? '{}')
  }

  const removeOnDemandParametersFromStorage = (): void => {
    sessionStorage.removeItem(STORAGE_KEY_ON_DEMAND_PARAMETERS)
  }

  return {
    setOnDemandSSOParameters,
    setOnDemandParametersToStorage,
    getOnDemandParametersFromStorage,
    removeOnDemandParametersFromStorage,
  }
}
