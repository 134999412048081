import { ONE_SECOND } from '@/config'
import router from '@/router'
import {
  getDecodedPayloadFromJwt,
  getMessage,
  transferToMessagePage,
} from '@/Utils'
import Cookies from 'js-cookie'

/**
 * アクセストークンを設置する。
 * @param {string} token
 */
export const setAccessTokenToSession = (token: string): void => {
  Cookies.set(process.env.VUE_APP_ACCESS_TOKEN, token)
  if (!Cookies.get(process.env.VUE_APP_ACCESS_TOKEN)) {
    sessionStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN, token)
  }
}

/**
 * アクセストークンを設置する。
 * @param {string} token
 */
export const setRefrehTokenToSession = (token: string): void => {
  Cookies.set(process.env.VUE_APP_REFRESH_TOKEN, token)
  if (!Cookies.get(process.env.VUE_APP_REFRESH_TOKEN)) {
    sessionStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, token)
  }
}

/**
 * アクセストークンを取得する。
 * @returns {string}
 */
export const getAccessTokenFromSession = (): string => {
  return (
    (Cookies.get(process.env.VUE_APP_ACCESS_TOKEN)
      ? Cookies.get(process.env.VUE_APP_ACCESS_TOKEN)
      : sessionStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN)) ?? ''
  )
}

/**
 * リフレッシュトークンを取得する。
 * @returns {string}
 */
export const getRefreshTokenFromSession = (): string => {
  return (
    (Cookies.get(process.env.VUE_APP_REFRESH_TOKEN)
      ? Cookies.get(process.env.VUE_APP_REFRESH_TOKEN)
      : sessionStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN)) ?? ''
  )
}

/**
 * リフレッシュトークンの有効期間（ミリ秒）を取得
 * @returns {number}
 */
export const getRefreshTokenTimelimit = (): number => {
  // トークンを解読
  const payload = getDecodedPayloadFromJwt(getRefreshTokenFromSession())

  // 有効期間の取得に失敗したらエラーを投げる
  if (!payload?.exp) {
    const message = getMessage('EFR0009')
    transferToMessagePage(router, { message })
    throw new Error(message)
  }

  // トークンの有効期間を戻す
  return payload.exp * ONE_SECOND
}
