
/**
 * 支店送信履歴一覧画面
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import MessageHistoryCard from '@/components/views/SentMessageHistoryList/MessageHistoryCard.vue'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { SentMessageItem, InformationBranchItem } from '@/typings'
import { mainContainerStyle, mainContainerStyleAPP } from './style'
import { queryBranchMessageSentHistoryList } from '@/request/api/information-message/sentHistory'
import { MessageSentHistoryItemModel } from '@/request/api/information-message/model/sentHistory'
import { useRouter } from '@/router'
import {
  getGlobalParams,
  setGlobalParams,
  BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS,
  SENT_MESSAGE_DETAIL,
  DELETE_SENT_MESSAGE,
} from '@/services/GlobleParames'
// Dependencies
import { defineComponent, onBeforeMount, reactive, toRefs, computed } from 'vue'
import { moveTopPage } from '@/Utils'

interface State {
  userID: string
  messageListALL: MessageSentHistoryItemModel[]
  messageList: MessageSentHistoryItemModel[]
  stateBranch: InformationBranchItem
  stateYearMonth: string
  pageNo: number
}

interface CompareFunction {
  (
    previous: MessageSentHistoryItemModel,
    next: MessageSentHistoryItemModel
  ): number
}

export default defineComponent({
  name: 'BranchSentMessageHistoryList',
  props: {
    branch: {
      type: String,
      require: true,
    },
    yearMonth: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      messageListALL: [],
      messageList: [],
      stateBranch: JSON.parse(props.branch ?? '{}') as InformationBranchItem,
      stateYearMonth: '',
      pageNo: 0,
    })
    const router = useRouter()
    const maxlen = 35
    var j = 0
    onBeforeMount(async () => {
      if (!props.branch) {
        //メッセージ詳細画面でbrowser backボタンを押す場合、本画面のpropsのデータが設定されないで、SessionStorageから取得する。
        state.stateBranch = JSON.parse(
          (
            getGlobalParams(BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS) as {
              branch: string
              yearMonth: string
            }
          ).branch
        ) as InformationBranchItem

        state.stateYearMonth = (
          getGlobalParams(BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS) as {
            branch: string
            yearMonth: string
          }
        ).yearMonth
      } else {
        state.stateBranch = JSON.parse(
          props.branch ?? '{}'
        ) as InformationBranchItem

        state.stateYearMonth = props.yearMonth as string
      }

      state.messageListALL = await queryBranchMessageSentHistoryList({
        userID: state.stateBranch.branchCd,
        max: 99999999,
      })

      state.messageListALL = state.messageListALL.filter(
        (item) =>
          item.sendTimestamp.substring(0, 4) ===
            state.stateYearMonth.substring(0, 4) &&
          item.sendTimestamp.substring(5, 7) ===
            state.stateYearMonth.substring(5, 7)
      )

      // 受信順でソート
      state.messageListALL.sort(messageListSortCallback())
      for (var i = 0; i < maxlen; i++) {
        if (i >= state.messageListALL.length) {
          break
        }
        state.messageList = [...state.messageList, state.messageListALL[i]]
      }
      moveTopPage()
    })

    const messageListSortCallback =
      (): CompareFunction =>
      (
        previous: MessageSentHistoryItemModel,
        next: MessageSentHistoryItemModel
      ) => {
        const previousTimestamp = new Date(previous.sendTimestamp)
        const nextTimestamp = new Date(next.sendTimestamp)

        return previousTimestamp.getTime() - nextTimestamp.getTime()
      }

    const handleScroll = async (event: Event): Promise<void> => {
      const container: HTMLDivElement = event.target as HTMLDivElement

      const computedScrollHeight: number = Math.ceil(
        container.scrollTop + container.clientHeight
      )

      const scrollHeight: number = container.scrollHeight

      // スクロールした分 ＋ 表示分がスクロール全体の高度以上であれば列の最後に達したと認定
      if (computedScrollHeight >= scrollHeight) {
        state.pageNo++
        for (var i = 0; i < maxlen; i++) {
          if (i + state.pageNo * maxlen >= state.messageListALL.length) {
            break
          }
          state.messageList = [
            ...state.messageList,
            state.messageListALL[i + state.pageNo * maxlen],
          ]
        }
      }
    }

    const handleTransferToDetail = async (
      message: SentMessageItem
    ): Promise<void> => {
      //メッセージ詳細画面でbrowser backボタンを押す場合、本画面のデータが失うため、SessionStorageに保存する
      const params = {
        message: JSON.stringify(message),
      }
      setGlobalParams(DELETE_SENT_MESSAGE, false)
      setGlobalParams(SENT_MESSAGE_DETAIL, params)

      router.push({
        name: 'SentMessageDetail',
        params: params,
      })
    }

    const mainStyle = computed(() => {
      if (window.innerHeight < 800) {
        return mainContainerStyleAPP
      } else {
        return mainContainerStyle
      }
    })

    const scrollContainerStyle = computed(() => {
      if (window.innerHeight < 800) {
        return { height: 'calc(100vh - 284px)' }
      } else {
        return { height: 'calc(100vh - 400px)' }
      }
    })

    const isMessageMaxLength = computed<boolean>(() => {
      let retValue = false
      state.messageList.forEach(function (item) {
        if (item.subject.length > 40) {
          retValue = true
          return retValue
        }
      })
      return retValue
    })

    return {
      ...toRefs(state),
      handleScroll,
      mainStyle,
      scrollContainerStyle,
      handleTransferToDetail,
      isMessageMaxLength,
    }
  },

  components: { ManagePageContainer, MessageHistoryCard },
})
