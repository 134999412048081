
/**
 * メッセージ受信詳細画面
 */
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import MessageDetail from '@/components/views/InformationMessageDetail/MessageDetail.vue'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { RecievedMessageModel } from '@/request/api/information-message/model/message'
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  toRefs,
  computed,
} from 'vue'
import {
  queryRecievedMessageList,
  updateMessageReadStatus,
} from '@/request/api/information-message/message'
import { downloadFile } from '@/request/api'
import {
  getInformationMessageItemFromStorage,
  removeInformationMessageItemFromStorage,
} from '@/services/InformationMessage'
import { useStore } from '@/store'
import { getMessage } from '@/Utils'

interface State {
  userID: string
  stateMessage: RecievedMessageModel
  reply: string
  imgSrc: string[]
  refresh: boolean
  refreshNotReadNumber: number
  uploadFileError: string
}

export default defineComponent({
  name: 'InformationMessageDetail',

  setup() {
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      stateMessage: getInformationMessageItemFromStorage(),
      reply: '',
      imgSrc: [],
      refresh: false,
      refreshNotReadNumber: 0,
      uploadFileError: "",
    })
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    onBeforeMount(async () => {
      state.stateMessage = getInformationMessageItemFromStorage()

      if (state.stateMessage.attachmentList?.length > 0) {
        let index = 0
        store?.commit('setIsLoadingWithText', true)
        store?.commit('setIsLoadingText', "ダウンロード中...")
        for await (let item of state.stateMessage.attachmentList) {
          state.imgSrc[index] = await downloadFile(item)
          if (!state.imgSrc[index]) {
            state.uploadFileError = getMessage('EFR0041')
            throw new Error(getMessage('EFR0041'))
          }
          state.refresh = !state.refresh
          index++;
        }
        store?.commit('setIsLoadingWithText', false)
      }

      if (!state.stateMessage.read) {
        await updateMessageReadStatus({
          userID: state.userID,
          messageNumber: state.stateMessage.messageNo,
        })
        state.refreshNotReadNumber++
      }

      if (state.stateMessage.enableReply) {
        state.reply = await getReply(state.stateMessage.messageNo)
      }
    })

    const getReply = async (messageNo: string): Promise<string> => {
      const messageList: RecievedMessageModel[] =
        await queryRecievedMessageList({ userID: state.userID, max: 20 })

      const replyMessage: RecievedMessageModel | undefined = messageList.find(
        (message: RecievedMessageModel): boolean =>
          message.replyNo === messageNo
      )

      return replyMessage?.body ?? ''
    }

    onBeforeUnmount(async () => {
      removeInformationMessageItemFromStorage()
    })
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return { ...toRefs(state), isFromIOSAPP }
  },

  components: { ContentPageContainer, MessageDetail, SubHeader },
})
