
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'TabList',

  props: {
    tabList: {
      type: Array,
      required: true,
    },

    defaultActiveTab: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    // State
    const state = reactive({ activeIndex: props.defaultActiveTab })

    // Methods
    const handleSelectTab = (index: number) => {
      state.activeIndex = index
      emit('select-tab', index)
    }

    return { ...toRefs(state), handleSelectTab }
  },
})
