
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { Store } from 'vuex'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '此処に標題を入れてください',
      required: true,
    },
  },
  setup() {
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    return { isUserFromAPP }
  },
})
