
// Dependencies
import { reactive, defineComponent, toRefs, computed } from 'vue'
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'
import GradeDataSearchInput from '@/views/GradeDataSearch/SubPages/GradeDataSearchInput.vue'
import GradeDataDetail from '@/views/GradeDataSearch/SubPages/GradeDataDetail.vue'
import { StudentInformation } from '@/typings'
import { useStore } from '@/store'
import { Store } from 'vuex'

interface State {
  gradeDataDetailList: StudentInformation[]
}

interface DomClass {
  inputContent: string
  detailContent: string
}

export default defineComponent({
  components: {
    ManagePageContainer,
    GradeDataDetail,
    GradeDataSearchInput,
  },

  setup() {
    // Data
    const store: Store<any> = useStore()

    const searchResultTable = computed<StudentInformation[]>(
      (): StudentInformation[] => store.getters.searchResultTableForDisplay
    )

    const state = reactive<State>({
      gradeDataDetailList: searchResultTable.value
        ? searchResultTable.value
        : ([] as StudentInformation[]),
    })

    const isUserFromAPP = computed<boolean>(() => store?.state?.isUserFromAPP)

    const domClass = reactive<DomClass>({
      inputContent: `input_content ${
        isUserFromAPP.value && 'app-layout_width'
      }`,

      detailContent: `detail_content ${
        isUserFromAPP.value && 'app-layout_width'
      }`,
    })

    // Methods
    const showDetails = async (gradeDataDetailList: StudentInformation[]) => {
      //受験生明細データを設定し、明細部をレンダリングする
      state.gradeDataDetailList = gradeDataDetailList
    }

    return {
      ...toRefs(state),
      ...toRefs(domClass),
      showDetails,
    }
  },
})
