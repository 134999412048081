import { instanceBFF } from '@/request'
import {
  DeleteDestinationListParameters,
  QueryDestinationListModel,
  RegisterDestinationListParameters,
  UpdateDestinationListParameters,
} from './model/destinationListModel'
import { useGlobalError } from '@/services/Hooks'

const { setGlobalError } = useGlobalError()

/**
 * @description インフォメーション - 送信先リストを取得
 * @param parameters
 * @returns 送信先リストの配列
 */
export const queryDestinationList = async (
  destinationListName?: string
): Promise<QueryDestinationListModel> => {
  try {
    const query = `
      query {
        getDestinationList(
          input: {
            destinationListName: "${destinationListName ?? ''}"
          }
        ) {
          destinationListName
          createDate
          lastSendMsgDate
          userIdList
        }
      }
    `

    const { data } = await instanceBFF.post('', query)

    return data.data.getDestinationList ?? []
  } catch (error) {
    setGlobalError(error)
    return []
  }
}

/**
 * @description インフォメーション - 送信先リストを登録
 * @param parameters
 * @return 成功か否か
 */
export const registerDestinationList = async (
  parameters: RegisterDestinationListParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        addDestinationList(
          input: {
            destinationListName: "${parameters.destinationListName ?? ''}"
            userIdList: ${JSON.stringify(parameters.userIDList)}
          }
        )
      }
    `

    const { data } = await instanceBFF.post('', mutation)

    return data.data.addDestinationList
  } catch (error) {
    setGlobalError(error)
    return false
  }
}

/**
 * @description インフォメーション - 送信先リストを更新
 * @param parameters
 * @return 成功か否か
 */
export const updateDestinationList = async (
  parameters: UpdateDestinationListParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        updateDestinationList(
          input: {
            destinationListName: "${parameters.destinationListName ?? ''}"
            userIdList: ${JSON.stringify(parameters.userIDList)}
          }
        )
      }
    `

    const { data } = await instanceBFF.post('', mutation)

    return data.data.updateDestinationList
  } catch (error) {
    setGlobalError(error)
    return false
  }
}

/**
 * @description インフォメーション - 送信先リストを削除
 * @param parameters
 * @returns 成功か否か
 */
export const deleteDestinationList = async (
  parameters: DeleteDestinationListParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        deleteDestinationList(
          input: {
            destinationListName: "${parameters.destinationListName ?? ''}"
            userIdList: ${JSON.stringify(parameters.userIDList)}
          }
        )
      }
    `

    const { data } = await instanceBFF.post('', mutation)

    return data.data.deleteDestinationList
  } catch (error) {
    setGlobalError(error)
    return false
  }
}
