
// Components
import BaseSelector from '@/components/common/BaseSelector.vue'
import GradeTable from '@/components/views/GradeData/GradeTable.vue'

// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  watch,
} from 'vue'
import {
  queryApplicationInformationList,
  queryOtherTestClassInitializeData,
  getTestInformation,
  queryOtherGradeByTestClass,
  queryKaiToSeiGo,
} from '@/request/api'
import { useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { ApplicationInformation } from '@/typings'
import { GradeDetailByTestClass } from '@/typings/GradeByTestClass'
import {
  GetTestInformationArguments,
  TestInformation,
} from '@/typings/TrainingDay'
import { TestClassDetail } from '@/typings/TestClass'
import {
  transferToInputMarkList,
  transferTestClassKbn,
  SEISEKI_TYPE_OTHER,
} from './common'
import { useRouter } from '@/router'
import SwitchDetailButton from '@/components/views/GradeData/SwitchDetailButton.vue'
import MogishikenDetailTable from '@/components/views/GradeData/MogishikenDetailTable.vue'
import { MogiShikenDetailLst } from '@/typings/MogiShiken'
import { CONST_SELECTED_OTHER } from '@/Utils'

interface Flag {
  leftButtonVisible: boolean
  rightButtonVisible: boolean
}
interface CompareFunction {
  (
    previous: GradeDetailByTestClass,
    next: GradeDetailByTestClass
  ): number
}
export default defineComponent({
  components: {
    BaseSelector,
    GradeTable,
    SwitchDetailButton,
    MogishikenDetailTable,
  },

  props: {
    userIDProps: String,
    applicationNo: String,
    isShowMenu: Boolean
  },

  setup(props) {
    const { setGlobalError } = useGlobalError()
    const router = useRouter()

    // State
    const state = reactive({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationInfoListOther: [] as ApplicationInformation[],
      applicationInfoOther: {} as ApplicationInformation,
      otherTestClassList: [] as TestClassDetail[],
      otherTestClass: {} as TestClassDetail,
      otherGradeList: [] as GradeDetailByTestClass[],
      jukoClassCode: "",
      refreshGradeTable: 0,
      testKamokuCd: '',
      testCd: '',
      testNmDetail: '',
      otherSeisekiPage: true,
      otherDetailLst: [] as MogiShikenDetailLst[],
      gradeOfDetailToShowOther: {} as GradeDetailByTestClass,
      isLoadingGradeList:false
    })

    // Computed
    const formattedCourseOther = computed(() => {
      return `${state.applicationInfoOther?.jukoFiscalYear ?? ''}　${state.applicationInfoOther?.courseName ?? ''}`
    })

    const formattedCourseListOther = computed(() =>
      state.applicationInfoListOther.map((item) => {
        return `${item.jukoFiscalYear}　${item.courseName ?? ''}`
      })
    )

    const formattedTestClassOther = computed(() => {
      return state.otherTestClass?.hasOwnProperty('testClassNm')
        ? `${state.otherTestClass.testClassNm ?? ''}`
        : `${state.otherTestClassList?.[0]?.testClassNm}`
    })

    const formattedTestClassListOther = computed(() => {
      return state.otherTestClassList.map((item) => {
        return `${item.testClassNm ?? ''}`
      })
    })

    // Lifecycle
    onBeforeMount(async () => {
      try {
        if (props.applicationNo) {
          state.isLoadingGradeList = true
          // 申込情報リスト、設定済み申込情報を初期化
          await setOtherApplicationInfo()
          // 種別リスト初期化
          await setOtherTestClassList()
          // その他成績初期化
          await setOtherGradeList()
          state.isLoadingGradeList = false
        }
      } catch (error) {
        handleError(error as Error)
      }
    })

    watch(
      () => [props.applicationNo],
      async () => {
        try {
          state.isLoadingGradeList = true
          await setOtherApplicationInfo()
          await setOtherTestClassList()
          await setOtherGradeList()
          state.isLoadingGradeList = false
        } catch (error) {
          handleError(error as Error)
        }
      }
    )

    // Methods
    const handleError = (error: Error | unknown | string): void => {
      state.isLoadingGradeList = false
      state.otherGradeList = []
      console.log(error)
      setGlobalError(error as Error)
    }

    const setOtherApplicationInfo = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const localOtherApplicationInfoList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.applicationInfoListOther = localOtherApplicationInfoList ?? []

        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = localOtherApplicationInfoList?.find(
          (item) => item.applicationNo === props.applicationNo
        )

        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.applicationInfoOther =
          settedApplicationInformation ?? localOtherApplicationInfoList?.[0] ?? {}
        
        state.jukoClassCode = state.applicationInfoOther.jukoClassCd ?? ""

      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setOtherTestClassList = async () => {
      try {
        const getTestClassList = await queryOtherTestClassInitializeData({
          userID: state.userID,

          applicationNo:
            state.applicationInfoOther?.applicationNo ??
            props.applicationNo ??
            '',
        })

        state.otherTestClassList =
          getTestClassList?.testClassInfoLst?.sort(
            (ela, elb) =>
              parseInt(ela.sortNo ?? '99') - parseInt(elb.sortNo ?? '99')
          ) ?? []
        if(sessionStorage.getItem(CONST_SELECTED_OTHER)){
          state.otherTestClass = JSON.parse(sessionStorage.getItem(CONST_SELECTED_OTHER) as string) 
        }else{
          state.otherTestClass = state.otherTestClassList?.[0] ?? {}
          sessionStorage.setItem(CONST_SELECTED_OTHER,JSON.stringify(state.otherTestClass))
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setOtherGradeList = async () => {
      try {
        if (!state.otherTestClassList.length) {
          return
        }
        if (!state.applicationInfoListOther?.length) {
          return
        }
        if (!state.otherTestClass?.testClassKbn && !state.otherTestClassList?.[0]?.testClassKbn) {
          return
        }
        const localOtherGradeList = await queryOtherGradeByTestClass({
          userID: state.userID,

          applicationNo:
            state.applicationInfoOther?.applicationNo ??
            props.applicationNo ??
            '',

          testClassKbn:
            state.otherTestClass?.testClassKbn ??
            state.otherTestClassList?.[0]?.testClassKbn ??
            '',
        })

        if (!localOtherGradeList.length) {
          return
        }

        for (let item of localOtherGradeList) {
          if (!item.teishutsuFlg) {
            const jukoDay = state.applicationInfoOther
              .jukoFiscalYear as string

            const params: GetTestInformationArguments = {
              testCd: item.testCd,
              testKamokuCd: item.testKamokuCd,
              jukoFiscalYear: jukoDay.substring(0, 4),
              jukoClassKbn: state.jukoClassCode,
            }
            const testInfo: TestInformation = await getTestInformation(params)
            item.enableInputAnswerFlg = testInfo.teishutsuFlg
          }
          item.testCdDisplay = transferTestClassKbn(state.applicationInfoOther.jukoClassCd ?? "") + item.testCd
        }
        localOtherGradeList.sort(gradeListSortCallback())
        state.otherGradeList = localOtherGradeList ?? []
        state.otherSeisekiPage = true
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    const gradeListSortCallback =
      (): CompareFunction =>
        (
          previous: GradeDetailByTestClass,
          next: GradeDetailByTestClass
        ) => {
          return parseInt(previous.sortNo) - parseInt(next.sortNo)
        }
    const handleSelectTestClass = async (index: number) => {
      try {
        state.otherTestClass = state.otherTestClassList[index]
        sessionStorage.setItem(CONST_SELECTED_OTHER,JSON.stringify(state.otherTestClass))
        state.isLoadingGradeList = true
        await setOtherGradeList()
        state.isLoadingGradeList = false
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleTransferToInputMarkList = async (
      testCd: string,
      testKamokuCd: string
    ) => {
      transferToInputMarkList(
        testCd,
        testKamokuCd,
        state.applicationInfoOther.jukoFiscalYear as string,
        state.jukoClassCode,
        state.userID,
        state.applicationInfoOther.applicationNo as string,
        router
      )
    }
    const flag = reactive<Flag>({
      leftButtonVisible: true,
      rightButtonVisible: true,
    })
    const resetFlag = (): void => {
      flag.leftButtonVisible = true
      flag.rightButtonVisible = true
    }

    const setDetailList = async (testKamokuCd: string, testCd: string): Promise<void> => {
      try {
        if (!testKamokuCd || !testCd ) {
          return
        }

        const mogiShikenDetailList = await queryKaiToSeiGo({
          userID: state.userID,

          applicationNo:
            state.applicationInfoOther?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: testCd ?? '',
          testKamokuCd: testKamokuCd ?? '',
        })

        state.otherDetailLst = mogiShikenDetailList?.list ?? []
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleTransferToDetail = async (
      testNm: string,
      testKamokuCd: string,
      testCd: string,
    ): Promise<void> => {
      state.testKamokuCd = testKamokuCd
      state.testCd = testCd
      resetFlag()

      const currentDetailIndex = state.otherGradeList.findIndex(
        (item) => item.testKamokuCd === testKamokuCd && item.testCd === testCd
      )
      state.gradeOfDetailToShowOther = state.otherGradeList[currentDetailIndex]
      currentDetailIndex - 1 <= 0 && (flag.leftButtonVisible = false)

      currentDetailIndex + 1 >= state.otherGradeList.length &&
        (flag.rightButtonVisible = false)

      await setDetailList(testKamokuCd, testCd)
      state.testNmDetail = testNm
      state.otherSeisekiPage = false
    }

    const handlePrevious = async (): Promise<void> => {
      try {
        resetFlag()
        // 一個前の科目を取得
        const previousDetailIndex =
          state.otherGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) - 1

        if (previousDetailIndex - 1 <= 0) {
          flag.leftButtonVisible = false
        }

        const previousDetail = state.otherGradeList[previousDetailIndex]
        state.gradeOfDetailToShowOther = previousDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(previousDetail.testKamokuCd as string, previousDetail.testCd as string)

        state.testKamokuCd = previousDetail.testKamokuCd as string
        state.testCd = previousDetail.testCd as string
        state.testNmDetail = previousDetail.testNm
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleNext = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const nextDetailIndex =
          state.otherGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) + 1

        if (nextDetailIndex + 1 >= state.otherGradeList.length) {
          flag.rightButtonVisible = false
        }

        const nextDetail = state.otherGradeList[nextDetailIndex]
        state.gradeOfDetailToShowOther = nextDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(nextDetail.testKamokuCd as string, nextDetail.testCd as string)

        state.testKamokuCd = nextDetail.testKamokuCd as string
        state.testCd = nextDetail.testCd as string
        state.testNmDetail = nextDetail.testNm
      } catch (error) {
        handleError(error as Error)
      }
    }
    // Styles
    const courseSelectBarStyle = {
      background: '#f3f3f3',
      border: 'none',
    }

    const testClassSelectBarStyle = {
      border: 'solid 1px #979797',
      borderRadius: '5px',
    }

    return {
      ...toRefs(state),
      ...toRefs(flag),
      handleSelectTestClass,
      handleTransferToInputMarkList,
      handleTransferToDetail,
      handlePrevious,
      handleNext,
      courseSelectBarStyle,
      testClassSelectBarStyle,
      formattedCourseOther,
      formattedCourseListOther,
      formattedTestClassOther,
      formattedTestClassListOther,
      SEISEKI_TYPE_OTHER,
    }
  },
})
