
/**
 * 合格ダイアリ―予定入力(メモ)画面
 */
import InputWrapperTitle from '@/components/views/PassDiary/InputWrapperTitle.vue'
import PassDiaryInputPageButton from '@/components/views/PassDiary/PassDiaryInputPageButton.vue'
import MemoInput from '@/components/views/PassDiary/MemoInput.vue'
import { LabelClassification } from '@/typings/index'

import { getMessage } from '@/Utils'
import { useGlobalError } from '@/services/Hooks'

import { PropType, defineComponent, reactive, watch } from 'vue'

import { MEMO_MAX_LENGTH } from '@/config'
import { useStore } from '@/store'
import { Store } from 'vuex'
import {
  SET_SCHEDULE_MEMO,
  SET_TRANSFER_MEMO_STATUS,
} from '@/store/modules/pass-diary/actionTypes'

interface FormMemo {
  // 日次スケジュール入力(メモ)エラー
  memo: string
}

interface FormError {
  // 日次スケジュール入力(メモ)エラー
  memoError: string
}

export default defineComponent({
  name: 'AddDialyScheduleMemo',

  props: {
    memo: {
      type: String,
      required: true,
    },

    label: {
      type: Object as PropType<LabelClassification>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store: Store<any> = useStore()

    const setMemoToStore = (memo: string): Promise<void> =>
      store.dispatch(SET_SCHEDULE_MEMO, memo)

    const setTransferMemoStatusToStore = (): Promise<void> =>
      store.dispatch(SET_TRANSFER_MEMO_STATUS, true)

    const { clearGlobalError } = useGlobalError()

    const memoInput = reactive<FormMemo>({
      memo: props.memo ?? '',
    })

    // フォーム検証
    const formError = reactive<FormError>({
      memoError: '',
    })

    const handleCloseInputWrapper = async (): Promise<void> => {
      const isPassed: boolean = validateMemo()

      if (!isPassed) {
        return
      }

      await setMemoToStore(memoInput.memo)
      await setTransferMemoStatusToStore()
      emit('closeInputWrapper', memoInput.memo)
    }

    const validateMemo = (): boolean => {
      let isPassed = true

      const isLengthOver = memoInput.memo.length > MEMO_MAX_LENGTH

      if (isLengthOver) {
        formError.memoError = getMessage('EFR0007', [` ${MEMO_MAX_LENGTH} `])

        isPassed = false
      }

      return isPassed
    }

    watch(
      () => memoInput.memo,

      () => {
        clearGlobalError()
        formError.memoError = ''
      }
    )

    return {
      handleCloseInputWrapper,
      formError,
      memoInput,
    }
  },

  components: {
    InputWrapperTitle,
    PassDiaryInputPageButton,
    MemoInput,
  },
})
