
// Components
import BaseSelector from '@/components/common/BaseSelector.vue'
import SwitchDetailButton from '@/components/views/GradeData/SwitchDetailButton.vue'
import GradeTable from '@/components/views/GradeData/GradeTable.vue'
import MogishikenDetailTable from '@/components/views/GradeData/MogishikenDetailTable.vue'

// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  watch,
} from 'vue'

import {
  queryApplicationInformationList,
  queryTraningDayInitializeData,
  getTestInformation,
  queryMogiShikenDetail,
} from '@/request/api'

import { useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { ApplicationInformation } from '@/typings'
import {
  TrainingDay,
  TrainingDayDetail,
  GetTestInformationArguments,
  TestInformation,
} from '@/typings/TrainingDay'
import { GradeModel } from '@/request/api/grade/models/grade-data'
import { queryGradeByTraningDay } from '@/request/api/grade/grade-data'
import {
  transferToInputMarkList,
  transferTestClassKbn,
  SEISEKI_TYPE_KOSHUBI,
} from './common'
import { useRouter } from '@/router'
import { MogiShikenDetailLst } from '@/typings/MogiShiken'
import { CONST_SELECTED_DAY } from '@/Utils'

interface Flag {
  leftButtonVisible: boolean
  rightButtonVisible: boolean
}

export default defineComponent({
  components: {
    BaseSelector,
    GradeTable,
    SwitchDetailButton,
    MogishikenDetailTable,
  },

  props: {
    userIDProps: String,
    applicationNo: String,
    isShowMenu: Boolean
  },

  setup(props) {
    const { setGlobalError } = useGlobalError()
    const router = useRouter()

    // State
    const state = reactive({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      trainDayApplicationInfoList: [] as ApplicationInformation[],
      trainDayApplicationInfo: {} as ApplicationInformation,
      nearestTrainingDay: {} as TrainingDay,
      trainingDayList: [] as TrainingDayDetail[],
      trainingDay: {} as TrainingDayDetail,
      trainDayGradeList: [] as GradeModel[],
      jukoClassCode: "",
      refreshGradeTable: 0,
      testKamokuCd: '',
      testCd: '',
      testNmDetail: '',
      trainDaySeisekiPage: true,
      trainDayDetailLst: [] as MogiShikenDetailLst[],
      trainDaygradeOfDetailToShow: {} as GradeModel,
      isLoadingGradeList:false
    })
    const flag = reactive<Flag>({
      leftButtonVisible: true,
      rightButtonVisible: true,
    })
    // Computed
    const formattedTrainDayCourse = computed(() => {
      return `${state.trainDayApplicationInfo?.jukoFiscalYear ?? ''}　${state.trainDayApplicationInfo?.courseName ?? ''}`
    })

    const formattedTrainDayCourseList = computed(() =>
      state.trainDayApplicationInfoList.map((item) => {
        return `${item.jukoFiscalYear}　${item.courseName ?? ''}`
      })
    )

    const formattedTrainingDay = computed(() => {
      if (sessionStorage.getItem(CONST_SELECTED_DAY)) {
        state.trainingDay = JSON.parse(sessionStorage.getItem(CONST_SELECTED_DAY) as string)
        return `${state.trainingDay?.koshuDt
          }　${state.trainingDay?.ampmNm}`
      }

      const nearDayTime = state.trainingDayList.find((day) => {
        return (
          day.koshuSu === state.nearestTrainingDay.koshuChokkinSu &&
          day.koshuDt === state.nearestTrainingDay.koshuChokkinDt
        )
      })

      return `${
        state.nearestTrainingDay?.koshuChokkinDt ??
        state.trainingDay?.koshuDt ??
        ''
      }　${nearDayTime?.ampmNm ?? state.trainingDay?.ampmNm ?? ''}`
    })

    const formattedTrainingDayList = computed(() =>
      state.trainingDayList.map((item) => {
        return `${item.koshuDt ?? ''}　${item.ampmNm ?? ''}`
      })
    )

    // Lifecycle
    onBeforeMount(async () => {
      try {
        if (props.applicationNo) {
          state.isLoadingGradeList = true
          // 申込情報リスト、設定済み申込情報を初期化
          await setTrainDayApplicationInfo()
          // 講習日リスト初期化
          await setTrainingDayOptions()
          // 講習日別成績初期化
          await setTrainDayGradeList()
          state.isLoadingGradeList = false
        }
      } catch (error) {
        handleError(error as Error)
      }
    })

    watch(
      () => [props.applicationNo],

      async () => {
        try {
          state.isLoadingGradeList = true
          await setTrainDayApplicationInfo()
          await setTrainingDayOptions()
          await setTrainDayGradeList()
          state.isLoadingGradeList = false
        } catch (error) {
          handleError(error as Error)
        }
      }
    )

    // Methods
    const handleError = (error: Error | unknown | string): void => {
      state.trainDayGradeList = []
      console.log(error)
      state.isLoadingGradeList = false
      setGlobalError(error as Error)
    }

    const setTrainDayApplicationInfo = async (): Promise<void> => {
      try {
        // 申込情報一覧初期化
        const applicationInformationList: ApplicationInformation[] =
          await queryApplicationInformationList(state.userID)

        state.trainDayApplicationInfoList = applicationInformationList ?? []

        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInformationList?.find(
          (item) => item.applicationNo === props.applicationNo
        )

        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.trainDayApplicationInfo =
          settedApplicationInformation ?? applicationInformationList?.[0] ?? {}
        state.jukoClassCode = state.trainDayApplicationInfo.jukoClassCd ?? ""
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setTrainingDayOptions = async (): Promise<void> => {
      try {
        const nearestTrainingDay = await queryTraningDayInitializeData({
          userID: state.userID,

          applicationNo:
            state.trainDayApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',
        })

        state.nearestTrainingDay = nearestTrainingDay ?? {}
        state.trainingDayList = nearestTrainingDay?.koshuDateInfoLst ?? []
        if(sessionStorage.getItem(CONST_SELECTED_DAY)){
          state.trainingDay = JSON.parse(sessionStorage.getItem(CONST_SELECTED_DAY) as string) 
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setTrainDayGradeList = async () => {
      try {
        if (!state.trainingDayList.length) {
          return
        }
        if (!state.trainDayApplicationInfoList?.length) {
          return
        }
        const localTrainDayGradeList = await queryGradeByTraningDay({
          userID: state.userID,

          applicationNo:
            state.trainDayApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          koshuDt:
            state.nearestTrainingDay?.koshuChokkinDt ??
            state.trainingDay?.koshuDt ??
            state.nearestTrainingDay?.koshuDateInfoLst?.[0].koshuDt ??
            '',

          koshuSu:
            state.nearestTrainingDay?.koshuChokkinSu ??
            state.trainingDay?.koshuSu ??
            state.nearestTrainingDay?.koshuDateInfoLst?.[0].koshuSu ??
            '',
        })

        if (!localTrainDayGradeList.length) {
          return
        }

        for (let item of localTrainDayGradeList) {
          if (!item.teishutsuFlg) {
            const jukoDay = state.trainDayApplicationInfo
              .jukoFiscalYear as string

            const params: GetTestInformationArguments = {
              testCd: item.testCd,
              testKamokuCd: item.testKamokuCd,
              jukoFiscalYear: jukoDay.substring(0, 4),
              jukoClassKbn: state.jukoClassCode,
            }

            const testInfo: TestInformation = await getTestInformation(params)
            item.enableInputAnswerFlg = testInfo.teishutsuFlg
          }

          item.testCdDisplay = transferTestClassKbn(state.trainDayApplicationInfo.jukoClassCd ?? "") + item.testCd
        }

        state.trainDayGradeList = localTrainDayGradeList ?? []
        state.trainDaySeisekiPage = true
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const handleSelectCourse = async (index: number) => {
      try {
        state.trainDayApplicationInfo = state.trainDayApplicationInfoList[index]
        await setTrainingDayOptions()
        await setTrainDayGradeList()
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleSelectTrainingDay = async (index: number) => {
      try {
        state.nearestTrainingDay = {} as TrainingDay
        state.trainingDay = state.trainingDayList[index]
        sessionStorage.setItem(CONST_SELECTED_DAY,JSON.stringify(state.trainingDay))
        state.isLoadingGradeList = true
        await setTrainDayGradeList()
        state.isLoadingGradeList = false
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleTransferToInputMarkList = async (
      testCd: string,
      testKamokuCd: string
    ) => {
      transferToInputMarkList(
        testCd,
        testKamokuCd,
        state.trainDayApplicationInfo.jukoFiscalYear as string,
        state.jukoClassCode,
        state.userID,
        state.trainDayApplicationInfo.applicationNo as string,
        router
      )
    }

    const resetFlag = (): void => {
      flag.leftButtonVisible = true
      flag.rightButtonVisible = true
    }

    const setDetailList = async (testKamokuCd: string, testCd: string): Promise<void> => {
      try {
        if (!testKamokuCd || !testCd) {
          return
        }

        const mogiShikenDetailList = await queryMogiShikenDetail({
          userID: state.userID,

          applicationNo:
            state.trainDayApplicationInfo?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: testCd ?? '',
          testKamokuCd: testKamokuCd ?? '',
        })

        state.trainDayDetailLst = mogiShikenDetailList?.list ?? []
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleTransferToDetail = async (
      testNm: string,
      testKamokuCd: string,
      testCd: string
    ): Promise<void> => {
      state.testKamokuCd = testKamokuCd
      state.testCd = testCd
      resetFlag()

      const currentDetailIndex = state.trainDayGradeList.findIndex(
        (item) => item.testKamokuCd === testKamokuCd && item.testCd === testCd
      )
      state.trainDaygradeOfDetailToShow = state.trainDayGradeList[currentDetailIndex]
      currentDetailIndex - 1 <= 0 && (flag.leftButtonVisible = false)

      currentDetailIndex + 1 >= state.trainDayGradeList.length &&
        (flag.rightButtonVisible = false)

      await setDetailList(testKamokuCd, testCd)
      state.testNmDetail = testNm
      state.trainDaySeisekiPage = false
    }

    const handlePrevious = async (): Promise<void> => {
      try {
        resetFlag()
        // 一個前の科目を取得
        const previousDetailIndex =
          state.trainDayGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) - 1

        if (previousDetailIndex - 1 <= 0) {
          flag.leftButtonVisible = false
        }

        const previousDetail = state.trainDayGradeList[previousDetailIndex]
        state.trainDaygradeOfDetailToShow = previousDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(previousDetail.testKamokuCd as string, previousDetail.testCd as string)

        state.testKamokuCd = previousDetail.testKamokuCd as string
        state.testCd = previousDetail.testCd as string
        state.testNmDetail = previousDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleNext = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const nextDetailIndex =
          state.trainDayGradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd && item.testCd === state.testCd
          ) + 1

        if (nextDetailIndex + 1 >= state.trainDayGradeList.length) {
          flag.rightButtonVisible = false
        }

        const nextDetail = state.trainDayGradeList[nextDetailIndex]
        state.trainDaygradeOfDetailToShow = nextDetail
        // 詳細データを取得し、表にセットする
        await setDetailList(nextDetail.testKamokuCd as string, nextDetail.testCd as string)

        state.testKamokuCd = nextDetail.testKamokuCd as string
        state.testCd = nextDetail.testCd as string
        state.testNmDetail = nextDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    // Styles
    const courseSelectBarStyle = {
      background: '#f3f3f3',
      border: 'none',
    }

    const trainingDaySelectBarStyle = {
      border: 'solid 1px #979797',
      borderRadius: '5px',
    }

    return {
      ...toRefs(state),
      ...toRefs(flag),
      handleSelectCourse,
      handleSelectTrainingDay,
      handleTransferToInputMarkList,
      handleTransferToDetail,
      handlePrevious,
      handleNext,
      courseSelectBarStyle,
      trainingDaySelectBarStyle,
      formattedTrainDayCourse,
      formattedTrainDayCourseList,
      formattedTrainingDay,
      formattedTrainingDayList,
      SEISEKI_TYPE_KOSHUBI,
    }
  },
})
