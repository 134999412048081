
import IconBlank from '@/components/icons/IconBlank02.vue'

import { NavigationButtonInterface } from '@/typings'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'NavigationButton',
  props: {
    icon: Object as PropType<NavigationButtonInterface>,
  },

  components: { IconBlank },
})
