
/**
 * 解答登録（マークシート）画面
 */
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BaseSelector from '@/components/common/BaseSelector.vue'
import MarkButton from '@/components/common/MarkButton.vue'
import AnswerItem from '@/components/views/AnswerInputComp/AnswerItem.vue'
import AnswerInputCard from '@/components/views/AnswerInputComp/AnswerInputCard.vue'
import { useRouter } from '@/router'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import {
  defineComponent,
  onBeforeMount,
  onUnmounted,
  onMounted,
  reactive,
  toRefs,
  ref,
  computed
} from 'vue'
import { useStore } from '@/store'
import { getMessage, CONST_FROM_ANSWER_INPUT_FINISH } from '@/Utils'
import { addAnswer } from '@/request/api'
import { useGlobalError } from '@/services/Hooks'
import { TestInformation, AddAnswerArguments } from '@/typings/TrainingDay'
import {
  getGlobalParams,
  setGlobalParams,
  ANSWER_INPUT_LIST_PARAMS,
  ANSWER_COMPLETE_PAGE,
} from '@/services/GlobleParames'
interface State {
  userID: string
  windowWidth: number
  windowHeight: number
  showList: boolean
  params: Params
  sentakuSu: number
  kaitoNmList: string[]
  kaitoNmListShow: string[]
  inputIdx: number
  chuiJikoNm: string
  menjoFlg: boolean
  refreshInput: boolean
  mondaiSu: number
  optionList: string[]
  selectedOption: string
  pageNum: number
  showSubmitButton: boolean
}
interface Params {
  jukoId: string
  testCd: string
  testKamokuCd: string
  jukoFiscalYear: string
  jukoClassKbn: string
  applicationNo: string
  kaitoNmList: string[]
  testInfo: TestInformation
}

export default defineComponent({
  name: 'AnswerInput',

  setup() {
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      windowWidth: 0,
      windowHeight: 0,
      showList: true,
      params: {} as Params,
      sentakuSu: 0,
      kaitoNmList: [],
      kaitoNmListShow: [],
      inputIdx: 0,
      chuiJikoNm: '',
      menjoFlg: false,
      refreshInput: false,
      mondaiSu: 0,
      optionList: [],
      selectedOption: '',
      pageNum: 0,
      showSubmitButton: false,
    })
    const isClickSendData = ref<boolean>(false)
    const { setGlobalError } = useGlobalError()
    onBeforeMount(async () => {
      if (sessionStorage.getItem(CONST_FROM_ANSWER_INPUT_FINISH)) {
        router.push('/GradeData', () => {
          // 遷移後に何もしない
        })
      }
      state.showSubmitButton = false
      state.params = getGlobalParams(ANSWER_INPUT_LIST_PARAMS) as Params
      state.sentakuSu = state.params.testInfo.sentakuSu as number
      state.kaitoNmList = []
      for (var i = 0; i < (state.params.testInfo.mondaiSu as number); i++) {
        state.kaitoNmList = [...state.kaitoNmList, '']
        if (i % 10 == 0) {
          state.optionList = [...state.optionList, '問' + (i + 1) + '～']
        }
      }
      state.optionList = [...state.optionList, '提出する']
      state.chuiJikoNm = state.params.testInfo.chuiJikoNm as string
      state.menjoFlg = state.params.testInfo.menjoFlg as boolean
      state.mondaiSu = state.params.testInfo.mondaiSu as number
      handleSelected(0)
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleSelected = (item: number) => {
      if (item * 10 + 1 > state.kaitoNmList.length) {
        state.showSubmitButton = true
        return
      } else {
        state.showSubmitButton = false
      }
      state.pageNum = item
      state.selectedOption = state.optionList[item]
      state.kaitoNmListShow = []

      for (var i = 0; i < 10; i++) {
        if (item * 10 + i + 1 > state.kaitoNmList.length) {
          break
        }
        state.kaitoNmListShow = [
          ...state.kaitoNmListShow,
          state.kaitoNmList[i + item * 10],
        ]
      }
    }
    const handleTransferToInput = (questionIdx: number) => {
      state.showList = false
      state.inputIdx = questionIdx
      state.refreshInput = !state.refreshInput
    }
    const handleTransferToList = () => {
      state.showList = true
      handleSelected(state.pageNum)
      state.refreshInput = !state.refreshInput
    }
    const handleMarkButtonClick = (
      questionIdx: number,
      answerAfterClick: string
    ) => {
      state.kaitoNmList[questionIdx - 1] = answerAfterClick
      state.refreshInput = !state.refreshInput
    }

    const handleResize = () => {
      state.windowWidth = window.innerWidth
      state.windowHeight = window.innerHeight
    }
    const handleError = (error: Error | unknown | string): void => {
      console.log(error)
      setGlobalError(error as Error)
    }
    const router = useRouter()
    const handleSendAnswer = async () => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      // ブラウザバックで再送信した場合
      if (getGlobalParams(ANSWER_COMPLETE_PAGE)) {
        setGlobalError(getMessage('EFR0040'))
        return
      }
      try {
        let params = {} as AddAnswerArguments
        params.jukoFiscalYear = state.params.jukoFiscalYear
        params.jukoClassKbn = state.params.jukoClassKbn
        params.testCd = state.params.testCd
        params.testKamokuCd = state.params.testKamokuCd
        params.jukoId = state.params.jukoId
        params.applicationNo = state.params.applicationNo
        params.kaitoNmList = state.kaitoNmList
        const res = await addAnswer(params)
        if (res) {
          // 更新に成功した場合は完了画面へ遷移
          setGlobalParams(ANSWER_COMPLETE_PAGE, true)
          router.push({
            name: 'NormalCompleted',
            params: { msg: getMessage('EFR0026', ['解答登録']), returnPage : "GradeData" },
          })
        }
      } catch (error) {
        handleError(error as Error)
      }
    }
    const handleToPrev = (questionIdx: number) => {
      state.inputIdx = questionIdx - 1
      state.refreshInput = !state.refreshInput
    }
    const handleToNext = (questionIdx: number) => {
      state.inputIdx = questionIdx + 1
      state.refreshInput = !state.refreshInput
    }
    const submitButtonStyle = {
      width: '200px',
      height: '45px',
      'border-radius': '5px',
      background: '#ffffff',
      color: '#333333',
      margin: '0px auto',
      'font-size': '14px',
      'font-weight': '400',
    }
    const SelectBarStyle = {
      width: '200px',
      height: '45px',
      background: '#ffffff',
      border: 'none',
      'border-radius': '5px',
      margin: '0 auto',
    }
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return {
      ...toRefs(state),
      handleTransferToInput,
      handleTransferToList,
      handleMarkButtonClick,
      handleSendAnswer,
      submitButtonStyle,
      SelectBarStyle,
      handleToPrev,
      handleToNext,
      handleSelected,
      isFromIOSAPP,
      isFromAndroidAPP
    }
  },

  components: {
    ContentPageContainer,
    MarkButton,
    AnswerItem,
    AnswerInputCard,
    BaseButton,
    BaseSelector,
    SubHeader,
  },
})
