
// Components
import BaseHeader from '@/components/common/BaseHeader.vue'
import BaseFooter from '@/components/common/BaseFooter.vue'
import BaseErrorBox from '@/components/common/BaseErrorBox.vue'
import BaseMobileFooter from '@/components/common/BaseMobileFooter.vue'

// Dependencies
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { useRouter } from '@/router'
import BaseButton from '@/components/common/BaseButton.vue'

export default defineComponent({
  components: {
    BaseHeader,
    BaseFooter,
    BaseErrorBox,
    BaseMobileFooter,
    BaseButton,
  },
  props: {
    msg: {
      type: String,
      default: '処理が完了しました。',
    },
    refreshNotReadNumber: {
      type: Number,
    },
    returnPage: {
      type: String
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    const state = reactive({
      headerMonted: false,
    })

    const handleheaderMounted = () => {
      state.headerMonted = true
    }

    const handleReturn = () => {
      router.push({
        name: props.returnPage,
        params: {},
      })
    }
    const isShowReturnButton = computed<boolean>(() => props.returnPage === "GradeData")
    return { ...toRefs(state), isUserFromAPP, handleheaderMounted, handleReturn, isShowReturnButton  }
  },
})
