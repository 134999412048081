
/**
 * 個人情報変更画面
 */
// Components
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import BaseTextBoxWithLabel from '@/components/common/BaseTextBoxWithLabel.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import { useStore } from '@/store'
import { Store } from 'vuex'
// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  toRefs,
  onMounted,
  onUnmounted,
} from 'vue'
import { mainContainerStyle, mainContainerStyleAPP } from './styles'
import {
  ChangingMessage,
  InputError,
  AttendCertificationInformation,
  PersonalInformation,
} from '@/typings/PersonalInformationConfig'
import {
  queryPersonalInformationStatus,
  queryAttendCertificationInformationList,
  queryPersonalInformation,
  updateAttendCertificationInformation,
  updatePersonalInformation,
  queryApplicationInformation,
  queryPrefectureList,
  queryApplicationInformationList,
} from '@/request/api'
import { getMessage, getTextHalfSizeLength } from '@/Utils'
import { useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { ADDRESS_LENGTH, EXAM_INFORMATION, PREFECTURES_LENGTH } from '@/config'
import { ApplicationInformation, PrefectureClassification } from '@/typings'
interface State extends PersonalInformation {
  // 受験情報
  examInformation: AttendCertificationInformation
  windowWidth: number
  applicationInformationList: ApplicationInformation[]
}

export default defineComponent({
  setup() {
    const { setGlobalError, clearGlobalError } = useGlobalError()

    // 個人情報（受験情報が含まれない）
    const state = reactive<State>({
      userID: '',
      postCode: '',
      prefecture: '',
      address1: '',
      address2: '',
      address3: '',
      phoneNumber: '',
      mail: '',
      examInformation: { jukoClassKbn: '', jukoClassNm: '', jukenNo: '' },
      windowWidth: window.innerWidth,
      applicationInformationList: [],
    })
    const store: Store<any> = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const lastData = ref<PersonalInformation>({} as PersonalInformation)

    onBeforeMount(async () => {
      state.userID = getUserIDFromAccessToken(getAccessTokenFromSession())

      const data: PersonalInformation = await queryPersonalInformation(
        state.userID
      )

      Object.keys(data).forEach((item) => {
        const key = item as keyof PersonalInformation
        state[key] = data[key] as any
      })

      lastData.value = { ...state }
    })
    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    const handleResize = () => {
      state.windowWidth = window.innerWidth
    }

    const mainStyle = computed(() => {
      if (state.windowWidth < 800 || isUserFromAPP.value) {
        return mainContainerStyleAPP
      } else {
        return mainContainerStyle
      }
    })
    // 自宅都道府県
    const pulldownPlaceholderColor = computed<string>(() =>
      state.examInformation?.jukoClassKbn ? '#333333' : '#9d9d9d'
    )

    const prefectureList = ref<PrefectureClassification[]>([])

    onBeforeMount(async () => {
      prefectureList.value = await queryPrefectureList()
    })

    const handleSelectPrefecture = (name: string): void => {
      // エラー文リセット
      clearGlobalError()
      changingMessage.value = ''

      state.prefecture = name ?? ''
    }

    // 受験情報
    const pulldownOptionList = ref<AttendCertificationInformation[]>([])
    // 受験情報を取得
    onBeforeMount(async () => {
      pulldownOptionList.value = await queryAttendCertificationInformationList(
        state.userID as string
      )

      await setSelectedExamInformation()
    })
    const getApplicationInformationList = async (): Promise<void> => {
      try {
        state.applicationInformationList =
          await queryApplicationInformationList(state.userID as string)
      } catch (error) {
        setGlobalError(error as Error)
      }
    }
    const setSelectedExamInformation = async (): Promise<void> => {
      const applicationInformation: ApplicationInformation =
        await queryApplicationInformation(state.userID as string)

      pulldownOptionList.value = joinUp(
        pulldownOptionList.value,
        applicationInformation
      )
      const selected: AttendCertificationInformation | undefined =
        pulldownOptionList.value.find(
          (item) => item.jukoClassNm === applicationInformation.jukoClassName
        )

      state.examInformation = selected ?? { ...pulldownOptionList.value[0] }
      sessionStorage.setItem(
        EXAM_INFORMATION,
        JSON.stringify(selected ?? { ...pulldownOptionList.value[0] })
      )
    }
    const joinUp = (
      jukenInfoList: AttendCertificationInformation[],
      applicationInfo: ApplicationInformation
    ): AttendCertificationInformation[] => {
      let ret = jukenInfoList
      let defaultJukoClass = {
        jukoClassKbn: applicationInfo.jukoClassCd,
        jukoClassNm: applicationInfo.jukoClassName,
        jukenNo: '',
      } as AttendCertificationInformation

      if (
        !jukenInfoList.find(
          (item) => item.jukoClassNm === applicationInfo.jukoClassName
        )
      ) {
        ret = [defaultJukoClass, ...jukenInfoList]
      }
      return ret
    }
    const handleSelectCertification = (certificationCode: string): void => {
      // エラー文リセット
      clearGlobalError()
      changingMessage.value = ''

      if (!certificationCode) {
        state.examInformation = {
          jukoClassKbn: '',
          jukoClassNm: '',
          jukenNo: '',
        }

        return
      }

      // 選択肢を選択
      state.examInformation = pulldownOptionList.value.find(
        (item): boolean =>
          (item.jukoClassKbn as string | undefined) === certificationCode
      ) as AttendCertificationInformation
    }

    // 変更ボタンの活性化管理
    const isActiveSubmitButton = computed<boolean>(() => {
      if (!state.applicationInformationList?.length) {
        return false
      }
      // 個人情報変更中及び変更完了済みの場合は非活性化にする
      const isCanceled: boolean =
        changingMessage.value === changingMessageList.canceled

      if (changingMessage.value && !isCanceled) {
        return false
      }

      // lastData が空の場合はデータがまだ取得していない
      return Object.keys(lastData.value).length
        ? isPersonalInformationChanged() || isJukenNoChanged()
        : false
    })

    const isPersonalInformationChanged = (): boolean => {
      return Object.keys(state).some((key) => {
        const _key = key as keyof State

        if (
          _key === EXAM_INFORMATION ||
          _key === 'windowWidth' ||
          _key === 'applicationInformationList'
        ) {
          return false
        } else {
          return state[_key] !== lastData.value[_key]
        }
      })
    }

    const isJukenNoChanged = (): boolean => {
      const initExamInfo = JSON.parse(
        sessionStorage.getItem(EXAM_INFORMATION) ?? '{}'
      ) as AttendCertificationInformation
      return Object.keys(state).some((key) => {
        const _key = key as keyof State

        if (_key === EXAM_INFORMATION) {
          return state[_key].jukenNo !== initExamInfo.jukenNo
        } else {
          return false
        }
      })
    }

    // 更新状況管理
    const changingMessageList: ChangingMessage = {
      changing: getMessage('EFR0019', ['個人情報']),
      changed: getMessage('EFR0020', ['個人情報']),
      canceled: getMessage('EFR0021', ['個人情報']),
    }

    const changingMessage = ref<string>('')

    onBeforeMount(async () => {
      await getApplicationInformationList()
      if (state.applicationInformationList?.length) {
        const data = await queryPersonalInformationStatus(
          state.userID as string
        )
        changingMessage.value = data.message
      }
    })

    const handleInput = (data: { [property: string]: string }): void => {
      clearGlobalError()
      changingMessage.value = ''

      Object.keys(data).forEach((key) => {
        if (key === 'examNumber') {
          state.examInformation.jukenNo = data[key]
        } else {
          state[key as keyof PersonalInformation] = data[key]
        }

        formError[key as keyof InputError] = ''
      })
    }

    const handleSubmit = async (): Promise<void> => {
      try {
        changingMessage.value = changingMessageList.changing

        validate()

        if (isFormErrorExist()) {
          changingMessage.value = changingMessageList.canceled
          return
        }

        var isUpdateInformationSuccessed = true
        if (isPersonalInformationChanged()) {
          isUpdateInformationSuccessed = await updatePersonalInformation(state)
        }
        var isUpdateAttendInformationSuccessed = true
        if (isJukenNoChanged() && isUpdateInformationSuccessed) {
          isUpdateAttendInformationSuccessed =
            await updateAttendCertificationInformation({
              attendID: state.userID as string,
              certificationCode: state.examInformation.jukoClassKbn,
              examNumber: state.examInformation.jukenNo,
            })
        }
        if (
          isUpdateInformationSuccessed &&
          isUpdateAttendInformationSuccessed
        ) {
          lastData.value = { ...state }
          sessionStorage.setItem(
            EXAM_INFORMATION,
            JSON.stringify({ ...state.examInformation })
          )
          changingMessage.value = changingMessageList.changed
        } else {
          changingMessage.value = changingMessageList.canceled
        }
      } catch (error) {
        setGlobalError(error as Error)
        changingMessage.value = changingMessageList.canceled
      }
    }

    // フォーム検証
    const formError = reactive<InputError>({
      postCode: '',
      prefecture: '',
      address1: '',
      address2: '',
      address3: '',
      phoneNumber: '',
      mail: '',
      examNumber: '',
    })

    const validate = (): void => {
      if (isPersonalInformationChanged()) {
        validatePostCode()
        validatePrefecture()
        validateAddress1()
        validateAddress2()
        validateAddress3()
        validatePhoneNumber()
        validateMail()
      }
      isJukenNoChanged() && validateExamNumber()
    }

    const validatePostCode = (): void => {
      formError.postCode = ''

      if (state.postCode) {
        var isRightPost = state.postCode.includes('-')
        if (!isRightPost) {
          formError.postCode = getMessage('EFR0029')
          return
        }

        isRightPost = /^[0-9]{3}-[0-9]{4}$/.test(state.postCode)

        formError.postCode = isRightPost
          ? ''
          : getMessage('EFR0030', ['郵便番号', '123-4567'])
      }
    }

    const validatePrefecture = (): void => {
      formError.prefecture = ''

      if (!state.prefecture) {
        formError.prefecture = getMessage('EFR0005')

        return
      }

      if (getTextHalfSizeLength(state.prefecture) > PREFECTURES_LENGTH) {
        formError.prefecture = getMessage('EFR0022', ['都道府県名'])
      }
    }

    const validateAddress1 = (): void => {
      formError.address1 = ''

      if (!state.address1) {
        formError.address1 = getMessage('EFR0005')

        return
      }

      if (getTextHalfSizeLength(state.address1) > ADDRESS_LENGTH) {
        formError.address1 = getMessage('EFR0022', ['住所名'])
      }
    }

    const validateAddress2 = (): void => {
      formError.address2 = ''

      if (
        state.address2 &&
        getTextHalfSizeLength(state.address2) > ADDRESS_LENGTH
      ) {
        formError.address2 = getMessage('EFR0022', ['住所名'])
      }
    }

    const validateAddress3 = (): void => {
      formError.address3 = ''

      if (
        state.address3 &&
        getTextHalfSizeLength(state.address3) > ADDRESS_LENGTH
      ) {
        formError.address3 = getMessage('EFR0022', ['住所名'])
      }
    }

    const validatePhoneNumber = (): void => {
      formError.phoneNumber = ''

      if (state.phoneNumber.includes('-')) {
        formError.phoneNumber = getMessage('EFR0022', ['携帯番号'])
        return
      }

      const regExp = /^[0-9]{1,15}$/

      if (state.phoneNumber && !regExp.test(state.phoneNumber)) {
        formError.phoneNumber = getMessage('EFR0022', ['携帯番号'])
      }
    }

    const validateMail = (): void => {
      formError.mail = ''

      if (!state.mail) {
        formError.mail = getMessage('EFR0005')
        return
      }

      if (state.mail.length > 100) {
        formError.mail = getMessage('EFR0022', ['メールアドレス'])
        return
      }

      const isRightMail =
        /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9-])+([a-zA-Z0-9\._-]+)+$/.test(
          state.mail
        )

      formError.mail = isRightMail
        ? ''
        : getMessage('EFR0022', ['メールアドレス'])
    }

    const validateExamNumber = (): void => {
      formError.examNumber = ''

      if (!state.examInformation.jukenNo) {
        formError.examNumber = getMessage('EFR0005')
        return
      }

      if (
        !/^[a-zA-Z0-9!#-&(-/:-@¥[-`{-~]{1,20}$/.test(
          state.examInformation.jukenNo
        )
      ) {
        formError.examNumber = getMessage('EFR0027', ['1', '20'])
      }
    }

    const isFormErrorExist = (): boolean => {
      return Object.keys(formError).some(
        (key: string) => formError[key as keyof InputError].length !== 0
      )
    }
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return {
      mainStyle,
      ...toRefs(state),
      handleInput,
      pulldownPlaceholderColor,
      prefectureList,
      handleSelectPrefecture,
      pulldownOptionList,
      handleSelectCertification,
      handleSubmit,
      formError,
      changingMessage,
      isActiveSubmitButton,
      isUserFromAPP,
      isFromAndroidAPP,
      isFromIOSAPP
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem(EXAM_INFORMATION)
  },
  components: {
    ContentPageContainer,
    SubHeader,
    BaseTextBoxWithLabel,
    BaseButton,
  },
})
