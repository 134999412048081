/**
 * 試験日関連
 */

import { Certification, ExaminationDate } from '@/typings'

/**
 * 受講種別の名称を取得する。
 * @param {ExaminationDate} examinationDate
 * @param {Certification[]} certification
 * @returns {string | undefined}
 */
export const getCurriculumTypeName = (
  examinationDate: ExaminationDate,
  certification: Certification[]
): string | undefined => {
  return certification.find(
    (item) => item.kbn === examinationDate?.jukoClassCd?.toString()
  )?.name
}
