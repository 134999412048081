
// Components
import IconArrowDownTriangle from '@/components/icons/IconArrowDownTriangle.vue'
// Dependencies
import { defineComponent, PropType } from 'vue'
import { UseGlobalError, useGlobalError } from '@/services/Hooks'

export default defineComponent({
  name: 'GradeSearchSelectionBox',
  components: { IconArrowDownTriangle },

  props: {
    title: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      required: true,
      default: '',
    },

    options: {
      type: Array as PropType<string[]>,
      required: true,
      default: [],
    },

    actived: {
      type: Boolean,
      required: true,
      default: false,
    },

    required: {
      type: Boolean,
    },

    requiredStudent: {
      type: Boolean,
    },

    width: {
      type: String,
      default: '100%',
    },

    titleStyle: {
      type: Object,
    },

    selectorWrapperStyle: {
      type: Object,
    },

    displayEreaStyle: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { clearGlobalError }: UseGlobalError = useGlobalError()

    const handleSelect = (event: Event): void => {
      clearGlobalError()
      emit('select', (event.target as HTMLSelectElement).value)
    }

    return { handleSelect }
  },
})
