
// Components
import IconLogo02 from '@/components/icons/IconLogo02.vue'
import IconLogo03 from '@/components/icons/IconLogo03.vue'

// Dependencies
import { defineComponent } from 'vue'
import { logout } from '@/services/ControlLogin'
import { getMessage } from '@/Utils'

export default defineComponent({
  components: { IconLogo02, IconLogo03 },

  props: {
    isDisplayConfirmButton: {
      type: Boolean,
      default: true,
      required: false,
    },

    caution: {
      type: [String, Error],
      default: getMessage('EFR0001'),
    },
  },

  setup() {
    return { logout }
  },
})
