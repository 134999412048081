
// Components
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'

// Dependencies
import { defineComponent, computed } from 'vue'
import { getRoleIdFromJwt } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useStore } from '@/store'

export default defineComponent({
  name: 'AllRoleContainer',
  components: {
    ContentPageContainer,
    ManagePageContainer,
  },

  props: {
    height: {
      type: String,
    },

    mainContainerStyle: {
      type: Object,
    },
    manageMainContainerStyle: {
      type: Object,
    },
    useMobileTerminalMenu: {
      type: Boolean,
    },
    studyDialyReportSize: {
      type: Boolean,
    },
  },

  setup() {
    const loginUserRoleId = computed(() => {
      return getRoleIdFromJwt(getAccessTokenFromSession())
    })
    const store = useStore()
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    return { loginUserRoleId, isFromAndroidAPP, isFromIOSAPP }
  },
})
