
// アイコン - ダイアリー
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconPassDiary',

  props: {
    fill: {
      type: String,
      default: '#fff',
    },
  },
})
