
import BaseTextBox from '@/components/common/BaseTextBox.vue'
import { defineComponent, ref, onBeforeMount } from 'vue'

export default defineComponent({
  name: 'SubjectInput',
  props: {
    error: String,
    value: String,
  },

  setup(props, { emit }) {
    const subject = ref<string>('')
    onBeforeMount(() => {
      subject.value = props.value ?? ''
    })
    const handleInput = (value: string): void => {
      subject.value = value
      emit('input', value)
    }

    return { subject, handleInput }
  },

  components: { BaseTextBox },
})
