
// Components
import Loading from '@/components/common/Loading.vue'
import StudyDiaryView from '@/views/HomePage/StudyDiaryView.vue'
import ContentPageContainer from '@/components/common/ContentPageContainer.vue'
import { mainContainerStyle } from './style'
// Dependencies
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { getRoleIdFromJwt, transferToMessagePage } from '@/Utils'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { useRouter } from '@/router'
import { useStore } from '@/store'

// Vue オブジェクト
export default defineComponent({
  name: 'HomePage',

  components: {
    Loading,
    StudyDiaryView,
    ContentPageContainer,
  },

  setup() {
    const router = useRouter()
    const store = useStore()
    const loading = ref<boolean>(true)
    const isUserFromAPP = computed<boolean>(() => store.state.isUserFromAPP)

    onBeforeMount(() => {
      const roleId = getRoleIdFromJwt(getAccessTokenFromSession())
      checkRoleId(roleId)
      refreshHeader()
    })
    const isFromAndroidAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('Android')
    })
    const isFromIOSAPP = computed<boolean>(() => {
      const userAgent = window.navigator.userAgent
      return isUserFromAPP.value && userAgent.includes('iPhone')
    })
    const refreshHeader = () => store?.commit('refreshHeader')

    const checkRoleId = (roleId: string | null): void => {
      if (roleId === null) {
        transferToMessagePage(router, {
          message: 'トークン不正、システム管理者にお問い合わせください。',
        })

        return
      }

      if (roleId != '1') {
        router.push({ name: 'ManageTop', params: { roleId } })
        return
      }

      loading.value = false
    }

    return {
      loading,
      isUserFromAPP,
      mainContainerStyle,
      isFromIOSAPP,
      isFromAndroidAPP
    }
  },
})
