
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MessageDetailItemWrapper',

  props: {
    title: {
      type: String,
      require: true,
    },
  },
})
