import { isApplicationException } from '@/services/Exception'
import { getMessage } from '@/Utils'
import { ActionHandler } from 'vuex'
import {
  SET_APPLICATION_NUMBER,
  SET_GLOBAL_ERROR,
  SET_STUDENT_USER_ID,
} from './actionTypes'
import { State } from './state'

const actions: { [type: string]: ActionHandler<State, any> } = {
  [SET_GLOBAL_ERROR]: (
    { commit, state },
    error: string | Error | any
  ): void => {
    const message: string = isApplicationException(error as Error)
      ? getMessage('EFR0016')
      : (error as Error).message ?? error

    if (state.globalError && message !== '') {
      return
    }

    commit(SET_GLOBAL_ERROR, message)
  },

  [SET_STUDENT_USER_ID]: ({ commit }, userID: string): void => {
    commit(SET_STUDENT_USER_ID, userID)
  },

  [SET_APPLICATION_NUMBER]: ({ commit }, applicationNumber): void => {
    commit(SET_APPLICATION_NUMBER, applicationNumber)
  },
}

export default actions
