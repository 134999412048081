import { requestBFF } from '@/request'
import { useGlobalError } from '@/services/Hooks'
import {
  AddDailyScheduleParameters,
  UpdateDailyScheduleParameters,
  DeleteDailyByDateScheduleParameters,
} from './models/dailySchedule'

const { setGlobalError } = useGlobalError()

/**
 * @description 日次スケジュールを登録
 * @param parameter
 * @returns
 */
export const addDailySchedule = async (
  parameter: AddDailyScheduleParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        addDailySchedule(
          userId: "${parameter.userCode}",
          applicationNo: "${parameter.applicationNo}",

          input: {
            yojitsuFlg: ${parameter.dailySchedule.yojitsuFlg},
            date: "${parameter.dailySchedule.date}",
            startHour: ${parameter.dailySchedule.startHour},
            startMinute: ${parameter.dailySchedule.startMinute},
            endHour: ${parameter.dailySchedule.endHour},
            endMinute: ${parameter.dailySchedule.endMinute},
            label: "${parameter.dailySchedule.label}"
            memo: "${parameter.dailySchedule.memo}"
          }
        ) 
      }
    `

    const { data } = await requestBFF(mutation)
    return data.data.addDailySchedule
  } catch (error) {
    setGlobalError(error)
    return false
  }
}

/**
 * @description 日次スケジュールを更新
 * @param parameter
 * @returns
 */
export const updateDailySchedule = async (
  parameter: UpdateDailyScheduleParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        updateDailySchedule(
          userId: "${parameter.userCode}",
          applicationNo: "${parameter.applicationNo}",

          key: {
            yojitsuFlg: ${parameter.key.yojitsuFlg},
            date: "${parameter.key.date}",
            startHour: ${parameter.key.startHour},
            startMinute: ${parameter.key.startMinute},
            endHour: ${parameter.key.endHour},
            endMinute: ${parameter.key.endMinute},
            label: "${parameter.key.label}"
            memo: "${parameter.key.memo}"
          },

          value: {
            yojitsuFlg: ${parameter.value.yojitsuFlg},
            date: "${parameter.value.date}",
            startHour: ${parameter.value.startHour},
            startMinute: ${parameter.value.startMinute},
            endHour: ${parameter.value.endHour},
            endMinute: ${parameter.value.endMinute},
            label: "${parameter.value.label}"
            memo: "${parameter.value.memo}"
          }
        )
      }
    `

    const { data } = await requestBFF(mutation)
    return data.data.updateDailySchedule
  } catch (error) {
    setGlobalError(error)
    return false
  }
}

/**
 * @description 日次スケジュールを削除
 * @param parameter
 * @returns
 */
export const deleteDailySchedule = async (
  parameter: AddDailyScheduleParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        deleteDailySchedule(
          userId: "${parameter.userCode}",
          applicationNo: "${parameter.applicationNo}",

          input: {
            yojitsuFlg: ${parameter.dailySchedule.yojitsuFlg},
            date: "${parameter.dailySchedule.date}",
            startHour: ${parameter.dailySchedule.startHour},
            startMinute: ${parameter.dailySchedule.startMinute},
            endHour: ${parameter.dailySchedule.endHour},
            endMinute: ${parameter.dailySchedule.endMinute},
            label: "${parameter.dailySchedule.label}"
            memo: "${parameter.dailySchedule.memo}"
          }
        ) 
      }
    `

    const { data } = await requestBFF(mutation)
    return data.data.deleteDailySchedule
  } catch (error) {
    setGlobalError(error)
    return false
  }
}
/**
 * @description 日次スケジュールの指定日削除
 * @param parameter
 * @returns
 */
export const deleteDailyScheduleByDate = async (
  parameter: DeleteDailyByDateScheduleParameters
): Promise<boolean> => {
  try {
    const mutation = `
      mutation {
        deleteDailyScheduleByDate(
          userId: "${parameter.userCode}",
          applicationNo: "${parameter.applicationNo}",
          date:"${parameter.targetDate}",
        ) 
      }
    `

    const { data } = await requestBFF(mutation)
    return data.data.deleteDailyScheduleByDate
  } catch (error) {
    setGlobalError(error)
    return false
  }
}
