/**
 * 定数
 */
export const DOMAINS = {
  AUTHORIZE_SERVER: process.env.VUE_APP_AUTHORIZE_SERVER, // 認証サーバーのドメイン
  WEB_SERVER: process.env.VUE_APP_WEB_SERVER, // web サーバーのドメイン
}

// バックエンドから来たアクセストークンエラーメッセージ
export const ACCESS_TOKEN_ERROR =
  '長時間アクセスがありませんでした。再度ログインしてくだい。'
// アクセストークン更新提示用メッセージ
export const ACCESS_TOKEN_TIME_OUT = 'Access token is time out.'

// BFFへのリクエストのヘッダーのコンテンツタイプ設定
export const CONTENT_TYPE_BFF = 'application/graphql'
// BFF からのトークン取得失敗のエラー文
export const MISSING_TOKEN = 'request has not access_token'

// 各種タブのデフォルトに選択されたタブのインデックス
export const DEFAULT_ACTIVE_TAB = 0

// ログイン認証関連定数
export const CLIENT_ID = 'oauth2' // web サーバーの ID
export const CLIENT_SECRET = 'oauth2' // web サーバーの パス

export const GRANT_TYPES = {
  GET_TOKEN_FIRST_TIME: 'authorization_code', // 認証コードでデータを請求
  REFRESH_TOKEN: 'refresh_token', // 更新トークンでデータを請求
}

export const CHARSET = {
  CONTENT_TYPE: 'utf-8', // 請求で送るデータのコーディング方式
}

export const MARKS = {
  PARAMETER_SPLITER: '&', // URI のパラメーターの分割符号
  KEY_VALUE_SPLITER: '=', // URI のパラメーターのキーと値の分割符号
}

export const RESPONSE_TYPE = 'code' // 認証コードモード
export const SEND_DATA_FORMAT = 'x-www-form-urlencoded' // key=value&...のフォーマット
export const CODE_CHALLENGE_METHOD = 'S256' // PKCE対応認証リクエストのパラメーターcode_challenge_methodの設定値

// ログイン認証関連属性名
export const ATTRIBUTES_KEYS = {
  CLIENT_ID: 'client_id', // web サーバー ID のキー名
  CLIENT_SECRET: 'client_secret', // web サーバーパスのキー名
  REDIRECT_URI: 'redirect_uri', // リダイレクト URI のキー名
  RESPONSE_TYPE: 'response_type', // OAUTH2.0 の応答のタイプのキー名
  GRANT_TYPE: 'grant_type', // 認証方法のキー名
  CODE: 'code', // 一次認証で取得したコードのキー名
  CODE_VERIFIER: 'code_verifier', // PKCE対応トークンリクエストのパラメーター
  CODE_CHALLENGE: 'code_challenge', // PKCE対応認証リクエストのパラメーター
  CODE_CHALLENGE_METHOD: 'code_challenge_method', // PKCE対応認証リクエストのパラメーター
  STATE: 'state', // stateパラメータを使用したリクエストの正当性確認するCSRF対策
}

export const URIS = {
  AUTHORIZE: `${DOMAINS.AUTHORIZE_SERVER}/oauth/authorize?${ATTRIBUTES_KEYS.RESPONSE_TYPE}=${RESPONSE_TYPE}&${ATTRIBUTES_KEYS.CLIENT_ID}=${CLIENT_ID}&scope=all`, // 認証ページ
  GET_TOKEN: `${DOMAINS.AUTHORIZE_SERVER}/oauth/token`, // トークン取得用の API
}

export const ONE_DAY_MILLISECOND = 86400000 // 一日のミリ秒数

// 成績参照画面 - サブページ
export const GRADE_DATA_SUBPAGES = {
  LIST_BY_TRAINING_DAY: 'bytrainingday',
  LIST_BY_SUBJECT: 'bysubject',
  LIST_BY_TESTCLASS: 'bytestclass',
  SIMULATION: 'simulation',
  OTHER: 'other',
}

// アプリケーション例外（業務エラー）のメッセージコード
export const INVALID_ARGUMENT = 'INVALID_ARGUMENT'
// アプリケーション例外（認可エラー）のメッセージコード
export const PERMISSION_DENIED = 'PERMISSION_DENIED'

// ユーザー種類
export enum USER_TYPE {
  STUDENT = '1', // 受講生
  TEACHER = '2', // 講師
  EDUCATION_STAFF = '3', // 教務スタッフ
  BUSINESS = '4', // 営業
  MANAGEMENT = '5', // 管理者
}

// 一秒
export const ONE_SECOND = 1000

// Mobile Style Max Width
export const MOBILE_STYLE_MAX_WIDTH = 800

// オフィシャルサイト
export const OFFICIAL_SITE = 'https://www.shikaku.co.jp/'

// 認証の必要ない画面
export const VIEW_WITHOUT_AUTHORIZE: string[] = [
  // プライバシーポリシー画面
  'PrivacyPolicy',
  // 利用規約画面
  'ServiceTerms',
]

// 送信先リスト名の最大長さ
export const DESTINATION_LIST_NAME_MAX_LENGTH = 255

// 受講 ID の最大長さ
export const ATTEND_ID_MAX_LENGTH = 20

// ユーザー ID 一括取込上限
export const MAX_USER_ID_LIST_LENGTH = 3000

// 一度に請求するインフォメーションメッセージの数
export const INFORMATION_MESSAGE_NUMBER = 50

// 個人情報変更画面 - 受験情報物理名
export const EXAM_INFORMATION = 'examInformation'

// 一回で取得する送信履歴の数
export const MESSAGE_HISTORY_MAX_LENGTH = 50

// Unicode の最後の半角文字のコード
export const LAST_HALF_SIZE_CODE = 255
// 全角文字の半角桁数
export const FULL_SIZE_LETTER_LENGTH = 2
// 半角文字の半角桁数
export const HALF_SIZE_LETTER_LENGTH = 1

// 都道府県半角桁数
export const PREFECTURES_LENGTH = 8
// 住所半角桁数
export const ADDRESS_LENGTH = 36
// 一度に請求する送信履歴一覧の数

// 受講生ユーザーコード桁
export const STUDENT_USER_CODE_LENGTH = 7

// 日次スケジュール入力(メモ)の最大長さ
export const MEMO_MAX_LENGTH = 1500

// リプライメッセージ入力の最大長さ
export const MAX_REPLY_MESSAGE_LENGTH = 200

// オンデマンドへ遷移する際の引数の鍵名
export const ON_DEMAND_PARAMETERS_KEY_SEND = {
  ID: 'id',
  TIMESTAMP: 'ts',
  COURSE_KEY: 'Course key',
  CHAPTER_KEY: 'Chapter key',
}

// APP トップ画面間データ転送用の鍵名
export const ON_DEMAND_PARAMETERS_KEY_TOP_PAGE = {
  ID: 'id',
  TIMESTAMP: 'timestamp',
  COURSE_KEY: 'courseKey',
  CHAPTER_KEY: 'chapterKey',
}

// HTTP メソッド
export const HTTP_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

// フォームターゲット
export const FORM_TARGET = {
  BLANK: '_blank',
  SELF: '_self',
  PARENT: '_parent',
  TOP: '_top',
}
