
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'MarkButton',

  props: {
    title: String,
    disabled: Boolean,
    isList: {
      type: Boolean,
      require: true,
    },
    isMarked: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const showListElement = computed<boolean>(() => props.isList)
    const showMartedElement = computed<boolean>(() => props.isMarked)
    const showUnMartedElement = computed<boolean>(
      () => !props.disabled && !props.isMarked
    )
    return { emit, showListElement, showMartedElement, showUnMartedElement }
  },
})
