
// Icons
import IconCopyrightFooter from '@/components/icons/IconCopyrightFooter.vue'
import IconLogo02 from '@/components/icons/IconLogo02.vue'

// Dependencies
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  components: { IconCopyrightFooter, IconLogo02 },

  setup() {
    const store = useStore()
    const isUserFromAPP = computed(() => store.state.isUserFromAPP)

    return {
      isUserFromAPP,
    }
  },
})
