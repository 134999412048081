/**
 * インターセプト
 */
import axios, { AxiosResponse } from 'axios'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import {
  CONTENT_TYPE_BFF,
  URIS,
  SEND_DATA_FORMAT,
  CHARSET,
  INVALID_ARGUMENT,
  PERMISSION_DENIED,
  ONE_SECOND,
} from '@/config'
import { getMessage } from '@/Utils'
import { updateToken } from './api'
import { isApplicationException } from '@/services/Exception'
import router from '@/router'
import { useGlobalError } from '@/services/Hooks'

const TIMEOUT = ONE_SECOND * 30

export const instanceSignOn = axios.create({
  baseURL: URIS.GET_TOKEN,
  timeout: TIMEOUT,

  headers: {
    'Content-Type': `application/${SEND_DATA_FORMAT};charset=${CHARSET.CONTENT_TYPE}`,
  },
})

export const instanceBFF = axios.create({
  baseURL: process.env.VUE_APP_BFF,
  timeout: TIMEOUT,
  headers: { 'Content-Type': CONTENT_TYPE_BFF },
})

export const instanceBFFUpload = axios.create({
  baseURL: process.env.VUE_APP_BFF_UPLOAD,
  timeout: TIMEOUT,
})

export const instanceBFFDownload = axios.create({
  baseURL: process.env.VUE_APP_BFF_DOWNLOAD,
  timeout: TIMEOUT,
})

/**
 * @description BFF へリクエストを送信
 * @param parameters GraphQL クエリ文
 * @returns
 */
export const requestBFF = async (
  parameters: string
): Promise<AxiosResponse<any>> => {
  return instanceBFF.request({
    method: 'POST',
    url: '',
    data: parameters,
  })
}

/**
 * @description BFF へリクエストを送信
 * @param formData フォームデータ
 * @returns
 */
export const requestUploadBFF = async (
  formData: FormData
): Promise<AxiosResponse<any>> => {
  return instanceBFFUpload.post('', formData)
}

/**
 * @description BFF から画像をダウンロード
 * @param id
 */
export const requestDownloadBFF = async (id: string): Promise<any> => {
  return await instanceBFFDownload.post('', { id })
}

// アップロードインターセプト - リクエスト
instanceBFFUpload?.interceptors?.request?.use(
  (config) => {
    // 送信時点のアクセストークンを付ける
    config.headers.Authorization = `Bearer ${getAccessTokenFromSession()}`
    config.timeout = ONE_SECOND * 60
    return config
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

// ダウンロードインターセプト - リクエスト
instanceBFFDownload?.interceptors?.request?.use(
  (config) => {
    // 送信時点のアクセストークンを付ける
    config.headers.Authorization = `Bearer ${getAccessTokenFromSession()}`
    config.timeout = ONE_SECOND * 60
    return config
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

const { clearGlobalError } = useGlobalError()

// 認証サーバーインターセプト - リクエスト
instanceSignOn?.interceptors?.request?.use(
  (config) => config,

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

// 認証サーバーインターセプト - レスポンス
instanceSignOn?.interceptors?.response?.use(
  (response) => {
    clearGlobalError()
    const { is_success } = response.data

    if (!is_success) {
      console.log(`${response.data.error_code}: ${response.data.error_message}`)
      const message = getMessage('EFR0009')

      router.push({
        name: 'Message',
        params: { caution: message },
      })

      throw new Error(message)
    }

    return response
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

// BFF インターセプト - リクエスト
instanceBFF?.interceptors?.request?.use(
  (config) => {
    // 受講生成績リスト取得 API のタイムアウト時間を伸ばす
    const isGetAttendStudentList =
      typeof config.data === 'string' && config.data.includes('getJukoseiList')

    isGetAttendStudentList && (config.timeout = ONE_SECOND * 60)

    // 送信時点のアクセストークンを付ける
    config.headers.Authorization = `Bearer ${getAccessTokenFromSession()}`

    return config
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

// BFF インターセプト - レスポンス
instanceBFF?.interceptors?.response?.use(
  async (response) => {
    try {
      clearGlobalError()
      const { errors } = response.data

      if (!errors) {
        clearGlobalError()
        return response
      }

      // アクセストークンが期限切れ
      const isAccessTokenTimeout = errors.some(
        (error: { [property: string]: any }) => {
          return error?.extensions?.code === PERMISSION_DENIED
        }
      )

      if (isAccessTokenTimeout) {
        await updateToken()
        // アクセストークンが期限切れになったら更新し再び同じリクエストを送信する。
        response.config.headers.Authorization = `Bearer ${getAccessTokenFromSession()}`
        return axios.request(response.config)
      }

      return Promise.reject(
        new Error(
          // 業務エラーとシステムエラーを分ける
          isApplicationException(errors?.[0]?.message)
            ? `${INVALID_ARGUMENT}: ${errors?.[0]?.message}`
            : getMessage('EFR0010')
        )
      )
    } catch (error) {
      console.log(error)
      router.push({ name: 'Message' })
      return Promise.reject(new Error(getMessage('EFR0001')))
    }
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)

// BFF インターセプト - レスポンス
instanceBFFUpload?.interceptors?.response?.use(
  async (response) => {
    try {
      clearGlobalError()
      const data = response.data

      if (data.is_success) {
        clearGlobalError()
        return response
      }

      return Promise.reject(new Error(getMessage('EFR0010')))
    } catch (error) {
      console.log(error)
      router.push({ name: 'Message' })
      return Promise.reject(new Error(getMessage('EFR0001')))
    }
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)
// BFF インターセプト - レスポンス
instanceBFFDownload?.interceptors?.response?.use(
  async (response) => {
    try {
      clearGlobalError()
      const data = response.data
      if (data) {
        clearGlobalError()
        return response
      }

      return Promise.reject(new Error(getMessage('EFR0010')))
    } catch (error) {
      console.log(error)
      router.push({ name: 'Message' })
      return Promise.reject(new Error(getMessage('EFR0001')))
    }
  },

  (error) => {
    console.log('orignal error', error)
    return Promise.reject(new Error(getMessage('EFR0014')))
  }
)
