import { queryOnDemandSSOParameters } from '@/request/api/on-demand'
import { OnDemandSSOParametersModel } from '@/request/api/on-demand/models'
import { SET_SSO_PARAMETERS } from './actionTypes'

interface State {
  ssoParameters: OnDemandSSOParametersModel
}

const state: State = {
  ssoParameters: {
    id: '',
    ts: '',
  },
}

const getters = {}

const actions = {
  async [SET_SSO_PARAMETERS]({ commit }: any, userID: string): Promise<void> {
    const ssoParameters: OnDemandSSOParametersModel = await queryOnDemandSSOParameters(
      userID
    )

    commit(SET_SSO_PARAMETERS, ssoParameters)
  },
}

const mutations = {
  [SET_SSO_PARAMETERS](
    _state: State,
    ssoParameters: OnDemandSSOParametersModel
  ): void {
    _state.ssoParameters = ssoParameters
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
