
/**
 * 管理者画面画面タイトル
 */
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManagePageTitle',

  props: {
    title: {
      type: String,
      require: true,
    },
  },
})
