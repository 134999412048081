
import BaseButton from '@/components/common/BaseButton.vue'

import { defineComponent, PropType, reactive, toRefs } from 'vue'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import { DestinationListModel } from '@/request/api/information-message/model/destinationListModel'
import { formatDestinationListName } from '@/Utils'

interface State {
  destinationListList: Array<DestinationListModel>
}

export default defineComponent({
  name: 'DestinationListSelectTable',

  props: {
    list: {
      type: Array as PropType<DestinationListModel[]>,
      required: true,
    },
    isDestinationListBulkImport: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const state = reactive<State>({
      destinationListList: [] as DestinationListModel[],
    })

    const isDisableds = [...Array(props.list.length)].map(() => false)

    const isDisabledCheckBox = (index: number): void => {
      isDisableds.forEach((item, num) => {
        if (num != index) {
          isDisableds[num] = !isDisableds[num]
        }
      })
    }

    const disabledCheckBox = (
      item: DestinationListModel,
      index: number
    ): void => {
      if (!isDisableds.includes(true)) {
        state.destinationListList.push(item)
        isDisabledCheckBox(index)
      } else if (!isDisableds[index]) {
        state.destinationListList.pop()
        isDisabledCheckBox(index)
      }
    }

    const labelClickInput = (
      item: DestinationListModel,
      index: number
    ): void => {
      let isCheck = true
      state.destinationListList.forEach((element, num) => {
        if (item.destinationListName == element.destinationListName) {
          state.destinationListList.splice(num, 1)
          isCheck = false
        }
      })
      if (isCheck) {
        state.destinationListList.push(item)
      }
    }

    const isDeleteOnce = (): boolean => {
      let retValue = true
      if (state.destinationListList.length == 1) {
        retValue = false
      }
      return retValue
    }

    return {
      ...toRefs(state),
      formatDestinationListName,
      isDisableds,
      labelClickInput,
      disabledCheckBox,
      isDeleteOnce,
    }
  },

  methods: {
    handleSubmit: function (
      selectedDestinationLists: Array<DestinationListModel>
    ) {
      this.$emit('emitting', selectedDestinationLists)
    },
    handleDelete: function (
      selectedDestinationLists: Array<DestinationListModel>
    ) {
      const destinationListName: string =
        selectedDestinationLists[0].destinationListName
      this.$emit('delete', destinationListName)
    },
  },

  components: {
    OverlayScrollbarsComponent,
    BaseButton,
  },
})
