
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '此処に標題を入れてください',
      required: true,
    },
  },
})
