
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    subtitle: {
      type: String,
      default: '此処に副標題を入れてください',
      required: true,
    },
  },
})
