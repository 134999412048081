
/**
 * 回答一覧画面 - 返信リスト
 */
import { computed, defineComponent, PropType } from 'vue'
import { ReceiveReplyMessage } from '@/typings'
import { useRouter } from '@/router'
import VueRouter from 'vue-router'
import { VIEW_REPLY_DETAIL } from '@/router/routesName' /** 次画面実装後に修正 */

interface ReplyMessage {
  sender: string
  messageNo: string
  message: string
  sendTimestamp: string
  enableReply: Boolean
  replyed: Boolean
  replyNo: string
  lastReply: string
}

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<ReceiveReplyMessage[]>,
      required: true,
    },
    originSender: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const router: VueRouter = useRouter()
    const replyList = computed<ReplyMessage[]>(() =>
      props.list.map<ReplyMessage>(
        (item: ReceiveReplyMessage): ReplyMessage => ({
          sender: item.receiver ?? '',
          message: item.message?.body ?? '',
          sendTimestamp: item.message?.sendTimestamp ?? ('' as string),
          enableReply: item.message?.enableReply ?? false,
          replyed: item.message?.replyed ?? false,
          messageNo: item.message?.messageNo ?? '',
          replyNo: item.message?.replyNo ?? '',
          lastReply: item.message?.sender ?? "",
        })
      )
    )

    const getSenderName = (item: ReplyMessage, index: number): void => {
      const list: string[] = [item.sender]
      emit('emitting', list, index)
    }

    const goToReply = (sender: string, messageNo: string): void => {
      const parms = {
        jukoSei: sender,
        messageNo: messageNo,
        originSender: props.originSender,
      }
      router.push({ name: VIEW_REPLY_DETAIL, params: parms })
    }

    return { replyList, getSenderName, goToReply }
  },
})
