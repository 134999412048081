
/**
 * 返信画面（詳細確認画面）
 */
// Components
import ManagePageContainer from '@/components/common/ManagePageContainer.vue'

// Dependencies
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { queryReceiveReply } from '@/request/api'
import { mainContainerStyle } from './style'
import { ReceiveReplyMessage } from '@/typings'
import {
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  ref,
  watch,
} from 'vue'
import { MAX_REPLY_MESSAGE_LENGTH } from '@/config'
import { useRouter } from '@/router'
import { getMessage } from '@/Utils'
import { replyMessage } from '@/request/api/information-message/message'
import {
  VIEW_INFORMATION_MENU,
  VIEW_MANAGE_NORMAL_COMPLETED,
} from '@/router/routesName'
interface State {
  userID: string
  replyList: ReceiveReplyMessage[]
  enableReply: boolean
  showInputReply: boolean
  isRallyCompleted: boolean
}

interface PropsParams {
  messageNo: string
  jukoSei: string
}
interface FormError {
  // リプライメッセージ文字数エラー
  answerError: string
}
interface CompareFunction {
  (previous: ReceiveReplyMessage, next: ReceiveReplyMessage): number
}

export default defineComponent({
  name: 'ReplyDetail',

  props: {
    messageNo: {
      type: String,
      required: true,
    },
    jukoSei: {
      type: String,
      required: true,
    },
    originSender: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter()
    onBeforeMount(async () => {
      if (!props.messageNo || !props.jukoSei || !props.originSender) {
        router.push({
          name: VIEW_INFORMATION_MENU,
        })
        return
      }
      await setReplyList()
    })
    // リプライリスト
    const state = reactive<State>({
      userID: getUserIDFromAccessToken(getAccessTokenFromSession()),
      replyList: [] as ReceiveReplyMessage[],
      enableReply: true,
      showInputReply: false,
      isRallyCompleted: false,
    })

    // 回答欄
    const answer = ref<string>('')
    const replyed = ref<boolean>(false)
    const handleInput = (event: Event): void => {
      answer.value = (event.target as HTMLInputElement).value
    }
    const isClickSendData = ref<boolean>(false)
    // フォーム検証
    const formError = reactive<FormError>({
      answerError: '',
    })
    const messageListSortCallback =
      (): CompareFunction =>
        (previous: ReceiveReplyMessage, next: ReceiveReplyMessage) => {
          const previousTimestamp = new Date(previous.message.sendTimestamp)
          const nextTimestamp = new Date(next.message.sendTimestamp)
          return previousTimestamp.getTime() - nextTimestamp.getTime()
        }
    const setReplyList = async (): Promise<void> => {
      const receiverList: ReceiveReplyMessage[] = await queryReceiveReply(
        props.originSender + "," + props.jukoSei,
        props.messageNo
      )
      state.replyList = [
        ...state.replyList,

        ...receiverList.filter(
          (item: ReceiveReplyMessage): boolean =>
            item.receiver === props.originSender || (item.receiver === props.jukoSei && item.message?.sender != props.jukoSei)
        ),
      ]
      state.replyList.sort(messageListSortCallback())

      if (state.replyList.length > 0 && state.replyList[state.replyList.length - 1].message.sender === props.jukoSei) {
        state.showInputReply = true
      } else {
        state.showInputReply = false
      }
      state.replyList = [
        ...state.replyList.filter(
          (item: ReceiveReplyMessage): boolean =>
            item.message.subject !== "close"
        ),
      ]
    }

    // ラリーを完了するメソッド
    const completeRally = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      const successed = await replyMessage({
        sender: state.userID,
        replyNumber: props.messageNo,
        subject: "close",
        body: "close",
        enableReply: false,
        sendTo: [props.jukoSei]
      })

      if (successed) {
        replyed.value = true
        router.push({
          name: VIEW_MANAGE_NORMAL_COMPLETED,
          params: { msg: getMessage('EFR0038', ['ラリーを完了']) },
        })
      }
    }

    const sendAnswer = async (): Promise<void> => {
      // 二重送信の場合
      if (isClickSendData.value) {
        return
      }
      isClickSendData.value = true
      if (!answer.value) {
        formError.answerError = getMessage('EFR0005')
        return
      }
      if (answer.value.length > MAX_REPLY_MESSAGE_LENGTH) {
        formError.answerError = getMessage('EFR0007', [
          MAX_REPLY_MESSAGE_LENGTH,
        ])
        return
      }
      const successed = await replyMessage({
        sender: state.userID,
        replyNumber: props.messageNo,
        subject: "-",
        body: answer.value,
        enableReply: state.enableReply,
        sendTo: [props.jukoSei]
      })

      if (successed) {
        replyed.value = true
        router.push({
          name: VIEW_MANAGE_NORMAL_COMPLETED,
          params: { msg: getMessage('EFR0038', ['回答を送信']) },
        })
      }
    }
    watch(
      () => answer.value,
      () => {
        formError.answerError = ''
        isClickSendData.value = false
      }
    )
    return {
      ...toRefs(state),
      ...toRefs(formError),
      mainContainerStyle,
      answer,
      handleInput,
      sendAnswer,
      replyed,
      completeRally,
    }
  },

  components: { ManagePageContainer },
})
