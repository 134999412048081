
// Dependencies
import { defineComponent } from 'vue'
import GradeDataDetailTable from '@/components/views/GradeDataSearch/GradeDataDetailTable.vue'

export default defineComponent({
  components: {
    GradeDataDetailTable,
  },

  props: {
    gradeList: {
      type: Array,
    },
  },
})
