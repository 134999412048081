import crypto from 'crypto-js'
const GLOBLE_PARAMS = 'globleParams'
const encryptKey = 'encryptKey'
export const RECEIVE_LIST_PARAMS = 'receiveListParams'
export const REPLY_LIST_PARAMS = 'replyListParams'
export const BRANCH_SENT_MESSAGE_HISTORY_LIST_PARAMS =
  'branchSentMessageHistoryList'
export const SENT_MESSAGE_DETAIL = 'SentMessageDetail'
export const DELETE_SENT_MESSAGE = 'DeleteSentMessage'
export const MESSAGE_COMPLETE_PAGE = 'MessageCompletePage'
export const ANSWER_INPUT_LIST_PARAMS = 'AnswerInputListParams'
export const HISTORY_MESSAGE_DETAIL = 'historyMessageDetail'
export const EXAMNATION_DATE = 'examnationDate'
export const ANSWER_COMPLETE_PAGE = 'AnswerCompletePage'
// GlobleParams
export interface ParamsItem {
  paramsKey: string
  paramsValue: any
}
/**
 * propsをsessionStorageにセットする
 */
export const setGlobalParams = (key: string, params: any): void => {
  const paramsItem = {} as ParamsItem
  paramsItem.paramsKey = key
  paramsItem.paramsValue = params
  let newArray = [] as ParamsItem[]
  let isExist = false
  if (sessionStorage.getItem(GLOBLE_PARAMS)) {
    const globalParams = JSON.parse(
      crypto.AES.decrypt(
        sessionStorage.getItem(GLOBLE_PARAMS) as string,
        encryptKey
      ).toString(crypto.enc.Utf8) ?? '{}'
    )

    globalParams.forEach?.((item: ParamsItem) => {
      if (item.paramsKey === paramsItem.paramsKey) {
        newArray = [...newArray, paramsItem]
        isExist = true
      } else {
        newArray = [...newArray, item]
      }
    })
  }

  if (!isExist) {
    newArray = [...newArray, paramsItem]
  }
  // 暗号化する
  const ecryptedParams = crypto.AES.encrypt(
    JSON.stringify(newArray),
    encryptKey
  )
  sessionStorage.setItem(GLOBLE_PARAMS, ecryptedParams.toString())
}
/**
 * propsをsessionStorageから取得する
 */
export const getGlobalParams = (key: string): any => {
  const globalParams: ParamsItem[] = JSON.parse(
    crypto.AES.decrypt(
      sessionStorage.getItem(GLOBLE_PARAMS) ?? '{}',
      encryptKey
    ).toString(crypto.enc?.Utf8) ?? '{}'
  )

  return globalParams.find?.((item: ParamsItem) => item.paramsKey === key)
    ?.paramsValue
}
