export const mainContainerStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}
export const manageMainContainerStyle = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  padding: '2.25rem 0 5rem 0',
  width: '100%',
  'font-family': "'Noto Sans JP', sans-serif",
}
