
// Components
import BaseSelector from '@/components/common/BaseSelector.vue'
import SwitchDetailButton from '@/components/views/GradeData/SwitchDetailButton.vue'
import MogishikenGradeTable from '@/components/views/GradeData/MogishikenGradeTable.vue'
import MogishikenDetailTable from '@/components/views/GradeData/MogishikenDetailTable.vue'

// Dependencies
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  toRefs,
  watch,
} from 'vue'

import {
  queryApplicationInformationList,
  queryMogiShikenInitializeData,
  queryGradeOfMogiShiken,
  queryMogiShikenDetail,
} from '@/request/api'

import { useGlobalError } from '@/services/Hooks'
import { getUserIDFromAccessToken } from '@/services/UserInformation'
import { getAccessTokenFromSession } from '@/services/ControlToken'
import { ApplicationInformation } from '@/typings'
import {
  MogiShikenDetailLst,
  MogiShikenInfo,
  MogiShikenList,
  MogiShikenSeisekiLst,
} from '@/typings/MogiShiken'
import { transferTestClassKbn, SEISEKI_TYPE_MOGISHIKEN } from './common'

interface Flag {
  leftButtonVisible: boolean
  rightButtonVisible: boolean
}

export default defineComponent({
  components: {
    BaseSelector,
    MogishikenGradeTable,
    MogishikenDetailTable,
    SwitchDetailButton,
  },

  props: {
    userIDProps: String,
    applicationNo: String,
    isShowMenu: Boolean
  },

  setup(props) {
    const { setGlobalError } = useGlobalError()

    // State
    const state = reactive({
      userID:
        props.userIDProps ??
        getUserIDFromAccessToken(getAccessTokenFromSession()),

      applicationInformationList: [] as ApplicationInformation[],
      applicationInformation: {} as ApplicationInformation,
      mogiShikenList: {} as MogiShikenList,
      mogiShikenLst: [] as MogiShikenInfo[],
      mogiShikenInfo: {} as MogiShikenInfo,
      selectedMogiShiken: '',
      gradeList: [] as MogiShikenSeisekiLst[],
      hyojiFlg: false,
      mogiShikenSeisekiPage: true,
      testKamokuCd: '',
      kamokuNmDetail: '',
      mogiShikenDetailLst: [] as MogiShikenDetailLst[],
      isLoadingGradeList:false
    })

    const flag = reactive<Flag>({
      leftButtonVisible: true,
      rightButtonVisible: true,
    })

    // Computed
    const formattedCourse = computed(() => {
      return `${state.applicationInformation?.jukoFiscalYear ?? ''}　${state.applicationInformation?.courseName ?? ''}`
    })

    const formattedCourselist = computed(() =>
      state.applicationInformationList?.length > 0
        ? state.applicationInformationList.map((item) => {
            return `${item.jukoFiscalYear}　${item.courseName ?? ''}`
          })
        : []
    )

    const formattedMogiShikenList = computed(() =>
      state.mogiShikenLst?.length > 0
        ? state.mogiShikenLst.map((item) => {
            return `${item.testNm ?? ''}`
          })
        : []
    )

    // Lifecycle
    onBeforeMount(async () => {
      try {
        if (props.applicationNo) {
          state.isLoadingGradeList = true
          // 申込情報一覧初期化
          await setApplicationInformation()
          // 模擬試験リスト初期化
          await setMogiShikenList()
          // 模擬試験成績リスト初期化
          await setGradeList()
          state.isLoadingGradeList = false
        }
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    })

    watch(
      () => [props.applicationNo],

      async () => {
        try {
          state.isLoadingGradeList = true
          await setApplicationInformation()
          await setMogiShikenList()
          await setGradeList()
          state.isLoadingGradeList = false
        } catch (error) {
          handleError(error as Error)
        }
      }
    )

    // Methods
    const handleError = (error: Error | unknown | string): void => {
      state.isLoadingGradeList = false
      state.gradeList = []
      console.log(error)
      setGlobalError(error as Error)
    }

    const setApplicationInformation = async (): Promise<void> => {
      try {
        let applicationInformationList = await queryApplicationInformationList(
          state.userID
        )

        state.applicationInformationList = applicationInformationList ?? []

        // 設定済みの申込情報を探し出す
        const settedApplicationInformation = applicationInformationList?.find(
          (item) => item.applicationNo === props.applicationNo
        )

        // 設定済みの申込情報が無ければ申込情報リストの一番目でこれも無ければ空値を表示に設定
        state.applicationInformation =
          settedApplicationInformation ?? applicationInformationList?.[0] ?? {}
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setGradeList = async () => {
      try {
        if (!state.mogiShikenInfo?.testCd) {
          state.gradeList = []
          state.mogiShikenSeisekiPage = true
          return
        }

        const gradeList = await queryGradeOfMogiShiken({
          userID: state.userID,

          applicationNo:
            state.applicationInformation?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: state.mogiShikenInfo?.testCd ?? '',
        })

        let tempGradeList = [] as MogiShikenSeisekiLst[]
        for (let item of gradeList.list as MogiShikenSeisekiLst[]) {
          item.testCdDisplay = transferTestClassKbn(state.applicationInformation.jukoClassCd ?? "") + item.testCd
          tempGradeList = [...tempGradeList, item]
        }

        state.gradeList = tempGradeList
        state.hyojiFlg = gradeList?.list?.[0]?.hyojiFlg ? true : false
        state.mogiShikenSeisekiPage = true
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setMogiShikenList = async () => {
      try {
        const mogiShikenList = await queryMogiShikenInitializeData({
          userID: state.userID,

          applicationNo:
            state.applicationInformation?.applicationNo ??
            props.applicationNo ??
            '',
        })

        if (!mogiShikenList.list?.length) {
          return
        }

        state.mogiShikenList = mogiShikenList ?? {}
        state.mogiShikenLst = mogiShikenList?.list ?? []
        const nearTest =
          state.mogiShikenLst?.length > 0
            ? state.mogiShikenLst.find((mogiShiken) => {
                return mogiShiken.testCd === state.mogiShikenList.testChokkinCd
              })
            : null
        if (nearTest == null && state.mogiShikenLst?.length > 0) {
          state.mogiShikenInfo = state.mogiShikenLst[0]
          state.selectedMogiShiken = state.mogiShikenLst[0].testNm ?? ''
        } else if (nearTest == null && state.mogiShikenLst?.length == 0) {
          state.mogiShikenInfo = {}
          state.selectedMogiShiken = ''
        } else {
          state.mogiShikenInfo = nearTest ?? {}
          state.selectedMogiShiken = nearTest?.testNm ?? ''
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    const setMogiShikenDetailList = async (
      testKamokuCd: string
    ): Promise<void> => {
      try {
        if (!testKamokuCd) {
          return
        }

        const targetGrade = state.gradeList.find(
          (item) => item.testKamokuCd === testKamokuCd
        )

        const mogiShikenDetailList = await queryMogiShikenDetail({
          userID: state.userID,

          applicationNo:
            state.applicationInformation?.applicationNo ??
            props.applicationNo ??
            '',

          testCd: targetGrade?.testCd ?? '',
          testKamokuCd: testKamokuCd ?? '',
        })

        state.mogiShikenDetailLst = mogiShikenDetailList?.list ?? []
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleSelectCourse = async (index: number) => {
      try {
        state.applicationInformation = state.applicationInformationList[index]
        await setMogiShikenList()
        await setGradeList()
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleSelectTestNm = async (index: number): Promise<void> => {
      try {
        state.isLoadingGradeList = true
        state.mogiShikenInfo = state.mogiShikenLst[index]
        state.selectedMogiShiken = state.mogiShikenLst[index]?.testNm ?? ''
        await setGradeList()
        state.isLoadingGradeList = false
      } catch (error) {
        handleError((error as Error).message ?? error)
      }
    }

    const handleTransferToDetail = async (
      testNm: string,
      testKamokuCd: string
    ): Promise<void> => {
      state.testKamokuCd = testKamokuCd

      resetFlag()

      const currentDetailIndex = state.gradeList.findIndex(
        (item) => item.testKamokuCd === testKamokuCd
      )

      currentDetailIndex - 1 <= 0 && (flag.leftButtonVisible = false)

      currentDetailIndex + 1 >= state.gradeList.length &&
        (flag.rightButtonVisible = false)

      await setMogiShikenDetailList(testKamokuCd)
      state.kamokuNmDetail = testNm
      state.mogiShikenSeisekiPage = false
    }

    const resetFlag = (): void => {
      flag.leftButtonVisible = true
      flag.rightButtonVisible = true
    }

    const handlePrevious = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const previousDetailIndex =
          state.gradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd
          ) - 1

        if (previousDetailIndex - 1 <= 0) {
          flag.leftButtonVisible = false
        }

        const previousDetail = state.gradeList[previousDetailIndex]

        // 模擬試験詳細データを取得し、表にセットする
        await setMogiShikenDetailList(previousDetail.testKamokuCd as string)

        state.testKamokuCd = previousDetail.testKamokuCd as string
        state.kamokuNmDetail = previousDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    const handleNext = async (): Promise<void> => {
      try {
        resetFlag()

        // 一個前の科目を取得
        const nextDetailIndex =
          state.gradeList.findIndex(
            (item) => item.testKamokuCd === state.testKamokuCd
          ) + 1

        if (nextDetailIndex + 1 >= state.gradeList.length) {
          flag.rightButtonVisible = false
        }

        const nextDetail = state.gradeList[nextDetailIndex]

        // 模擬試験詳細データを取得し、表にセットする
        await setMogiShikenDetailList(nextDetail.testKamokuCd as string)

        state.testKamokuCd = nextDetail.testKamokuCd as string
        state.kamokuNmDetail = nextDetail.testNm as string
      } catch (error) {
        handleError(error as Error)
      }
    }

    // Styles
    const courseSelectBarStyle = {
      background: '#f3f3f3',
      border: 0,
    }
    const mogiShikenSelectBarStyle = {
      border: 'solid 1px #979797',
      borderRadius: '5px',
    }

    return {
      ...toRefs(state),
      ...toRefs(flag),
      handleSelectTestNm,
      handleSelectCourse,
      handleTransferToDetail,
      handlePrevious,
      handleNext,
      mogiShikenSelectBarStyle,
      courseSelectBarStyle,
      formattedCourse,
      formattedCourselist,
      formattedMogiShikenList,
      SEISEKI_TYPE_MOGISHIKEN,
    }
  },
})
